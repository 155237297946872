import React from 'react';
import PropTypes from 'prop-types';
import config from 'configuration';
import { modal } from '../../../utils/modal';


class Grade extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectGrade = this.handleSelectGrade.bind(this);
  }

  handleSelectGrade(e, gradeId) {
    e.preventDefault();
    this.props.onSelectGrade(gradeId);
  }

  render() {
    return (
      <div>
        <div className="gi-Modal-header">
          {/* <!-- <span className="u-colorNeutral600 u-floatLeft u-linkFake"><u>Back</u></span>
          <span className="u-colorNeutral600 u-floatRight u-linkFake"><u>Skip</u></span> --> */}
          <div className="gi-Modal-title u-textCenter u-marginTop5">
            Help us match you with the
            <br />
            right Experts for you
          </div>
        </div>
        <div className="gi-Modal-body">
          <div style={{ margin: '0 auto', width: '100%', maxWidth: '260px' }}>
            <div className="label u-marginBottom2">
              Which grade are you in?
            </div>
            {config.grades.map(gradeObj => (
              <label
                key={gradeObj.id.toString()}
                className="gi-landingAccountGradeItem"
                onClick={e => this.handleSelectGrade(e, gradeObj.id)}
                htmlFor="true"
              >
                <input
                  type="radio"
                  value={gradeObj.id}
                  defaultChecked={gradeObj.id === this.props.selectedGrade}
                />
                <span className="gi-Button u-sizeFull u-marginBottom2">
                  {gradeObj.title}
                </span>
              </label>
            ))}
          </div>
        </div>
        <div className="gi-Modal-footer">
          {/* <button
            className="gi-Button gi-Button--primary u-sizeFull"
            type="button"
          >
            Continue
          </button> */}
        </div>
      </div>
    );
  }
}

Grade.propTypes = {
  selectedGrade: PropTypes.number.isRequired,
  onSelectGrade: PropTypes.func.isRequired,
};

export default modal(Grade, { size: 'small' });