import moment from 'moment';
// import { extendMoment } from 'moment-range';

// const moment = extendMoment(rawMoment);

export const isEqual = function (value, other) {
  // Get the value type
  const type = Object
    .prototype
    .toString
    .call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) {
    return false;
  }

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) {
    return false;
  }

  // Compare the length of the length of the two items
  const valueLen = type === '[object Array]'
    ? value.length
    : Object
      .keys(value)
      .length;
  const otherLen = type === '[object Array]'
    ? other.length
    : Object
      .keys(other)
      .length;
  if (valueLen !== otherLen) {
    return false;
  }

  // Compare two items
  const compare = (item1, item2) => {
    // Get the object type
    const itemType = Object
      .prototype
      .toString
      .call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) {
        return false; // Otherwise, do a simple comparison;
      }
    } else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) {
        return false;
      }

      // Else if it's a function, convert to a string and compare Otherwise, just
      // compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) {
          return false;
        }
      } else if (item1 !== item2) {
        return false;
      }
    }
    return true;
  };

  // Compare properties
  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i += 1) {
      if (compare(value[i], other[i]) === false) {
        return false;
      }
    }
  } else {
    for (const key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        if (compare(value[key], other[key]) === false) {
          return false;
        }
      }
    }
  }

  // If nothing failed, return true
  return true;
};

export const timeAgo = (t, useNumericTimes = false) => {
  const REFERENCE = moment();
  const m = moment.unix(t);
  const secondsFromNow = Math.floor(Date.now() / 1000) - t;
  const year = Math.floor(secondsFromNow / (365 * 86400));
  if (year >= 2) {
    return `${year} years ago`;
  }
  if (year >= 1) {
    return useNumericTimes ? '1 year ago' : 'Last year';
  }

  const month = Math.floor(secondsFromNow / (31 * 86400));
  if (month >= 2) {
    return `${month} months ago`;
  }
  if (month >= 1) {
    return useNumericTimes ? '1 month ago' : 'Last month';
  }

  // const startOfCurrentWeek = REFERENCE.clone().startOf('isoWeek');
  // const startOfWeek = m.startOf('isoWeek');
  // const r = moment.range(startOfWeek, startOfCurrentWeek);
  // const weekOfYear = r.diff('w');
  const weekOfYear = Math.floor(secondsFromNow / (7 * 86400));
  if (weekOfYear >= 2) {
    return `${weekOfYear} weeks ago`;
  }
  if (weekOfYear >= 1) {
    return useNumericTimes ? '1 week ago' : 'Last week';
  }

  const day = Math.floor(secondsFromNow / 86400);
  if (day >= 2) {
    return `${day} days ago`;
  }

  const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
  const isYesterday = m.isSame(YESTERDAY, 'd');
  if (isYesterday) {
    return useNumericTimes ? '1 day ago' : 'Yesterday';
  }

  const hour = Math.floor(secondsFromNow / 3600);
  if (hour >= 2) {
    return `${hour} hours ago`;
  }
  if (hour >= 1) {
    return useNumericTimes ? '1 hour ago' : 'An hour ago';
  }

  const minute = Math.floor(secondsFromNow / 60);
  if (minute >= 2) {
    return `${minute} minutes ago`;
  }
  if (minute >= 1) {
    return useNumericTimes ? '1 minute ago' : 'A minute ago';
  }
  if (secondsFromNow >= 3) {
    return `${secondsFromNow} seconds ago`;
  }
  return useNumericTimes ? '1 second ago' : 'Just now';
};

export const queryToObject = queryString => (
  queryString
    ? JSON.parse(`{"${queryString.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
      (key, value) => (key === '' ? value : decodeURIComponent(value))
    ) : {});

export const isChrome = () => !!window.chrome && !!window.chrome.webstore;

// Firefox 1.0+
export const isFireFox = () => typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari = () => /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; }(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification)));

export const convertSpaces = str => str.replace(/ {2}/ig, ' &nbsp;');

export default {
  isEqual,
  queryToObject,
  isChrome,
  timeAgo,
  convertSpaces,
};