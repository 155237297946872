import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fingerprint from 'fingerprintjs2';
import config from 'configuration';

import AccessToken from '../utils/token';
import { getBrowser } from '../utils/app';
import Tracking from '../utils/tracking';

import AppLoading from './Common/Loading';
import AppRoute from './Common/Routes';
import Maintain from './Common/Maintain';

import { verifyToken, forceLogout } from '../actions/user.action';
import { auth, checkStatus } from '../actions/app.action';

import { SYSTEM_STATUS_MAINTENANCE } from '../constants/app.constant';

class App extends React.Component {
  static childContextTypes = {
    isFirstTime: PropTypes.bool,
    notFirstTimeAnyMore: PropTypes.func,
    openSupport: PropTypes.func,
    tokenInfo: PropTypes.shape(),
  };

  constructor(props) {
    super(props);
    // const fpInstance = new Fingerprint();

    Fingerprint.get(this.setBrowserId);
    this.state = {
      isAppLoaded: false,
    };
  }

  getChildContext = () => ({
    isFirstTime: this.isFirstTime,
    notFirstTimeAnyMore: this.notFirstTimeAnyMore,
    openSupport: this.openSupport,
    tokenInfo: this.tokenInfo,
  });

  componentWillMount() {
    const keys = AccessToken.loadKeys();
    AccessToken.init(keys);
    this.isFirstTime = !AccessToken.getBrowserId();
    this.processAuthKey(AccessToken.getAuthKey());
    this.tokenInfo = {};
  }

  componentDidMount() {
    // Handle remote auth change
    AccessToken.onChangeAuthKey((newKey) => {
      if (AccessToken.setAuthKey(newKey)) {
        this.processAuthKey(newKey);
      }
    });
    if (!this.state.isAppLoaded) {
      this.loadApp();
    }

    // we cannot detect remote auth change if the tab is not active
    // so we have to listen to focus window event
    if (window.onfocus) {
      window.onfocus = this.onWindowFocused;
    } else if (document.onfocusin) {
      document.onfocusin = this.onWindowFocused;
    }
    Tracking.init();

    this.props.checkSystemStatus();
  }

  componentWillReceiveProps(nextProps) {
    if (+nextProps.user.uid !== +this.props.user.uid) {
      if (+nextProps.user.uid > 0) {
        if (!nextProps.user.signing_up) {
          Tracking.identify(nextProps.user.uid, nextProps.user.email);
        }
      } else {
        Tracking.clearUser();
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.systemStatus === SYSTEM_STATUS_MAINTENANCE
        && nextProps.systemStatus === SYSTEM_STATUS_MAINTENANCE
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate() {
    if (!this.state.isAppLoaded) {
      this.loadApp();
    }
  }

  componentWillUnmount() {
    Tracking.close();
  }

  onWindowFocused = () => {
    if (AccessToken.isAuthKeyChange()) {
      const authKey = AccessToken.loadAuthKey();
      if (AccessToken.setAuthKey(authKey)) {
        this.processAuthKey(authKey);
      }
    }
  }

  setBrowserId = (components) => {
    const values = components.map(component => component.value);
    const browserId = Fingerprint.x64hash128(values.join(''), 31);

    AccessToken.setDeviceId(browserId);
    Tracking.setBrowserId(browserId);
  }

  loadApp = () => {
    if (AccessToken.getAuthKey() !== '') {
      this.props.verifyToken().then(
        (data) => {
          const { error } = data.payload;
          if (error) {
            if ((!error.status || (error.status !== 503 && error.status !== 423))) {
              AccessToken.setAuthKey('');
              this.props.onForceLogout();
            } else if (error.status === 423) {
              this.tokenInfo = error.data.data;
            }
          }
          this.setState({ isAppLoaded: true });
        }
      );
    } else {
      this.setState({ isAppLoaded: true });
    }
  }

  notFirstTimeAnyMore = () => {
    this.isFirstTime = false;
  }

  openSupport = (info = null) => {
    let {
      uid,
      support_priority,
      payment_transaction_count,
      consumed_credit_count,
    } = this.props.user;

    uid = info ? info.uid || uid : uid;

    const { appVersion } = config;
    const browser = getBrowser();
    const deviceId = AccessToken.getBrowserId();
    const mailBody = `


^^please enter feedback ABOVE^^


App Version: ${appVersion}
Browser: ${browser}
Browserprint: ${deviceId}
User ID: ${uid}
Additional Information: ${support_priority || ''} : ${payment_transaction_count || 0} : ${consumed_credit_count || 0}

Sent from Web app
`;
    /* eslint-enable */
    const mailSubject = (info && info.subject) ? info.subject : 'PhotoStudy Feedback';
    const mailUrl = 'mailto:support@photostudy.co';
    const mailLink = `${mailUrl}?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(mailBody)}`;

    window.open(mailLink, '_blank');
  }

  // The auth token was changed
  processAuthKey = (key) => {
    if (key !== '') {
      this.props.onAuthenticated(key);
    } else {
      this.props.onForceLogout();
    }
  }

  render() {
    if (this.props.systemStatus === SYSTEM_STATUS_MAINTENANCE) {
      return <Maintain />;
    }
    if (!this.state.isAppLoaded) {
      return <AppLoading />;
    }
    return <AppRoute />;
  }
}

App.propTypes = {
  systemStatus: PropTypes.string.isRequired,
  user: PropTypes.shape().isRequired,
  verifyToken: PropTypes.func.isRequired,
  onForceLogout: PropTypes.func.isRequired,
  onAuthenticated: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  systemStatus: state.app.systemStatus,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  verifyToken: () => dispatch(verifyToken()),
  onForceLogout: () => dispatch(forceLogout()),
  onAuthenticated: token => dispatch(auth(token)),
  checkSystemStatus: () => dispatch(checkStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
