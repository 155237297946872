import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Message, Icon } from '@ahaui/react';

import ts from 'utils/timestamp';

import PESMessageList from './PESMessageList';

class PESSession extends React.Component {
  constructor() {
    super();
    this.state = {
      timeRemain: 0,
    };
    this.countdownTimer = 0;
    this.expiry = 0;
  }

  componentDidMount() {
    this.expiry = this.props.session.expiry;
    // expiry = 0 is marked for the user views their own session.
    if (this.expiry > 0) {
      this.setUpCountDown();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.session.expiry !== this.props.session.expiry) {
      logger.debug('get newProps', 'promoTs', nextProps.session.expiry);
      this.expiry = nextProps.session.expiry;
      // expiry = 0 is marked for the user views their own session.
      if (this.expiry > 0) {
        this.setUpCountDown();
      }
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.countdownTimer);
  }

  setUpCountDown = () => {
    let expiry = this.expiry;
    let duration = expiry - ts.now();
    if (duration > 0) {
      this.setState({
        timeRemain: duration,
      });
      if (!this.countdownTimer) {
        logger.debug('setting up countdown Interval');
        this.countdownTimer = window.setInterval(this.setUpCountDown, 1 * 1000);
      }
    } else {
      logger.debug('clearing countdown');
      this.setState({
        timeRemain: 0,
      });
      window.clearInterval(this.countdownTimer);
      this.props.onExpired();
    }
  }


  render() {
    const session = this.props.session;
    const PES = session.pes;
    const steps = PES.steps;
    const isShowTimer = session.logged_in && session.expiry > 0;
    let timeRemain = this.state.timeRemain;
    let momentDuration = moment.duration(timeRemain, 's');
    let mathFormula = PES.ascii_math || PES.wolfram || PES.latex;

    return (
      <div>
        <h1 className="u-text600 u-marginBottomExtraSmall">Description:</h1>
        <p>{mathFormula}</p>

        {isShowTimer && (
          <Message variant="positive">
            <div className="u-flex u-alignItemsCenter u-paddingLeftSmall">
              <Icon name="timer" size="medium" />
            </div>
            <Message.Container>
              <Message.Content>Time left to view the solution {Math.floor(momentDuration.asHours()) + moment.utc(momentDuration.asMilliseconds()).format(":mm:ss")}</Message.Content>
            </Message.Container>
          </Message>
        )}

        <div className="u-marginTopSmall">
          <PESMessageList
            steps={steps}
          />
        </div>
      </div>
    )
  }
}

PESSession.propTypes = {
  session: PropTypes.shape().isRequired,
  onExpired: PropTypes.func,
};

export default PESSession;
