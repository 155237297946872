import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ahaui/react';
import paypalImage from 'assets/images/paypal.svg';
import mastercardImage from 'assets/images/mastercard.svg';
import visaImage from 'assets/images/visa.svg';

const CARD_IMAGES = {
  paypal: paypalImage,
  MasterCard: mastercardImage,
  Visa: visaImage,
};

const CurrentPaymentBox = ({ defaultMethod, onOpenBraintree }) => {
  const {
    text,
    methodType,
    cardType,
  } = defaultMethod;

  if (methodType == '') {
    return (
      <div>
        <p className="u-text200">All payment methods accepted. Click to select:</p>
        <div className="u-flex u-alignItemsCenter u-cursorPointer" onClick={onOpenBraintree}>
          <div className="u-border u-roundedMedium u-paddingTiny u-heightLarge u-flex u-justifyContentCenter u-alignItemsCenter u-marginRightSmall" style={{ width: 76 }}>
            <img src={paypalImage} alt="Paypal" />
          </div>
          <div className="u-border u-roundedMedium u-paddingTiny u-heightLarge u-flex u-justifyContentCenter u-alignItemsCenter u-marginRightSmall" style={{ width: 76 }}>
            <img src={mastercardImage} alt="Mastercard" />
          </div>
          <div className="u-border u-roundedMedium u-paddingTiny u-heightLarge u-flex u-justifyContentCenter u-alignItemsCenter u-marginRightSmall" style={{ width: 76 }}>
            <img src={visaImage} alt="Visa" />
          </div>
          <Icon name="card" size="medium" className="u-textPrimary" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <p className="u-text200">Current payment method. Click to change.</p>
      <div className="u-flex u-alignItemsCenter u-cursorPointer" onClick={onOpenBraintree}>
        <div className="u-border u-roundedMedium u-paddingTiny u-heightLarge u-flex u-justifyContentCenter u-alignItemsCenter u-marginRightSmall" style={{ width: 76 }}>
          <img src={CARD_IMAGES[cardType]} alt="Visa" />
        </div>
        <div className="u-text200">
          {text}
        </div>
      </div>
    </div>
  );
};

CurrentPaymentBox.propTypes = {
  defaultMethod: PropTypes.shape().isRequired,
  onOpenBraintree: PropTypes.func.isRequired,
}

export default CurrentPaymentBox;
