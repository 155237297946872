import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@ahaui/react';

import TopBanner from 'components/Common/TopBanner';
import Description from '../Common/PostView/Description';
import Picture from '../Common/PostView/Picture';
import Moods from '../Common/PostView/Moods';
import Subjects from '../Common/PostView/Subjects';
import Language from '../Common/PostView/Language';
import SendExpert from '../Common/PostView/SendExpert';
import StoreFront from '../../Common/StoreFront';
import OutOfCreditModal from '../Common/PostView/Modals/OutOfCreditModal';
import CoppaOutOfCreditModal from '../Common/PostView/Modals/CoppaOutOfCreditModal';
import PaymentUnsupported from '../Common/PostView/Modals/PaymentUnsupportedModal';
import UnknownErrorModal from '../Common/PostView/Modals/UnknownErrorModal';
import OneQuestionModal from '../Common/PostView/Modals/OneQuestionModal';
import IntroModal from '../Common/PostView/Modals/IntroModal';
import AutobidBar from '../Common/PostView/AutobidBar';

import {
  storefrontView,
} from '../../../constants/common.constant';

import {
  resetAskflowData,
  getAutobidStatus,
} from '../../../actions/askflow.action';

import {
  checkOpenQuestion,
} from '../../../actions/chat.action';


class PostView extends React.Component {
  constructor() {
    super();
    this.connected = false;
    this.getAutobidTimer = 0;
    this.state = {
      outOfCreditModalShow: false,
      coppaOutOfCreditModalShow: false,
      paymentUnsupportedShow: false,
      storeFrontModal: {
        show: false,
        view: null,
      },
      unknownErrorModalShow: false,
      oneQuestionModalShow: false,
      introModalShow: false,
      errorMessage: '',
      coppaErrorData: null,
    };
  }

  componentDidMount() {
    this.props.onResetAskflowData();
    this.setUpInterval();
    this.connected = true;
  }

  componentWillUnmount() {
    if (this.connected) {
      this.teardownInterval();
      this.connected = false;
    }
  }

  setUpInterval = () => {
    this.props.onGetAutoBidStatus();
    this.getAutobidTimer = window.setInterval(this.props.onGetAutoBidStatus, 10 * 1000);
  }

  teardownInterval = () => {
    if (this.getAutobidTimer) {
      window.clearInterval(this.getAutobidTimer);
    }
  }

  handleHideStoreFrontModal = () => {
    logger.debug('on handleHideStoreFrontModal');
    this.setState({
      storeFrontModal: {
        show: false,
        view: null,
      },
    });
  }

  handleGoToPackagesView = () => {
    logger.debug('on handleGoToStore');
    this.setState({
      outOfCreditModalShow: false,
      storeFrontModal: {
        show: true,
        view: storefrontView.PACKAGES,
      },
    });
  }

  handleHideOutOfCreditModal = () => {
    logger.debug('on handleGoToStore');
    this.setState({
      outOfCreditModalShow: false,
    });
  }

  handleHideCoppaOutOfCreditModal = () => {
    this.setState({
      coppaOutOfCreditModalShow: false,
      coppaErrorData: null,
    });
  }

  handleGoToBraintreeView = () => {
    logger.debug('on handleGoToBraintreeView');
    this.setState({
      paymentUnsupportedShow: false,
      storeFrontModal: {
        show: true,
        view: storefrontView.BRAINTREE,
      },
    });
  }

  handleOutOfCredit = () => {
    logger.debug('on handleOutOfCredit');
    this.setState({
      outOfCreditModalShow: true,
    });
  }

  handleCoppaOutOfCredit = (data) => {
    logger.debug('on handleCoppaOutOfCredit');
    this.setState({
      coppaOutOfCreditModalShow: true,
      coppaErrorData: data,
    });
  }

  handlePaymentUnsupported = () => {
    logger.debug('on handlePaymentUnsupported');
    this.setState({
      paymentUnsupportedShow: true,
    });
  }

  handleUnknownError = (msg) => {
    logger.debug('on handleUnknownError');
    this.setState({
      unknownErrorModalShow: true,
      errorMessage: msg,
    });
  };

  handleHideUnknownErrorModal = () => {
    logger.debug('on handleHideUnknownErrorModal');
    this.setState({
      unknownErrorModalShow: false,
      errorMessage: '',
    });
  }

  handleGoToOpenQuestion = () => {
    logger.debug('one handleGoToOpenQuestion');
    this.setState({
      oneQuestionModalShow: false,
    });
    this.props.onCheckOpenQuestion();
  }

  handleHideOneQuestionModal = () => {
    logger.debug('on handleHideOneQuestionModal');
    this.setState({
      oneQuestionModalShow: false,
    });
  }

  handleOneQuestionError = () => {
    logger.debug('on handleOneQuestionError');
    this.setState({
      oneQuestionModalShow: true,
    });
  }

  handleOpenIntroModal = () => {
    logger.debug('on handleOpenIntroModal');
    this.setState({
      introModalShow: true,
    });
  }

  handleHideIntroModal = () => {
    logger.debug('on handleHideIntroModal');
    this.setState({
      introModalShow: false,
    });
  }


  render() {
    const { enabledLanguages } = this.props;
    const {
      storeFrontModal, outOfCreditModalShow, coppaOutOfCreditModalShow, paymentUnsupportedShow, unknownErrorModalShow, oneQuestionModalShow, introModalShow, errorMessage, coppaErrorData,
    } = this.state;

    return (
      <>
        <TopBanner
          icon="videoCam"
          title="Expert step-by-step explanations."
          body="Instantly connect with a live expert to get personalized study help."
          button={(
            <Button variant="primary_outline" size="small" onClick={this.handleOpenIntroModal}>
              <Button.Label className="u-fontMedium">
                See How
              </Button.Label>
            </Button>
          )}
        />

        <div className="Container Container--fluid">
          <div className="u-marginHorizontalAuto u-paddingTopMedium u-paddingBottomExtraLarge u-widthFull" style={{ maxWidth: 480 }}>
            <Description />
            <Picture />
            <Moods />
            <Subjects onSelectedSubject={this.handleSelectedSubject} />

            {enabledLanguages?.length > 1 && (
              <Language />
            )}

            <SendExpert
              onOutOfCredit={this.handleOutOfCredit}
              onCoppaOutOfCredit={this.handleCoppaOutOfCredit}
              onPaymentUnsupported={this.handlePaymentUnsupported}
              onUnknownError={this.handleUnknownError}
              onOneQuestionError={this.handleOneQuestionError}
            />
          </div>
        </div>

        <AutobidBar />

        {storeFrontModal.show && (
          <StoreFront view={storeFrontModal.view} onClose={this.handleHideStoreFrontModal} type={1} />
        )}
        {outOfCreditModalShow && (
          <OutOfCreditModal onGoToPackagesView={this.handleGoToPackagesView} onClose={this.handleHideOutOfCreditModal} />
        )}
        {coppaOutOfCreditModalShow && (
          <CoppaOutOfCreditModal data={coppaErrorData} onClose={this.handleHideCoppaOutOfCreditModal} />
        )}
        {paymentUnsupportedShow && (
          <PaymentUnsupported onGoToBraintreeView={this.handleGoToBraintreeView} />
        )}
        {unknownErrorModalShow && (
          <UnknownErrorModal onClose={this.handleHideUnknownErrorModal} message={errorMessage} />
        )}
        {oneQuestionModalShow && (
          <OneQuestionModal onBackToOpenQuestion={this.handleGoToOpenQuestion} onClose={this.handleHideOneQuestionModal} />
        )}
        {introModalShow && (
          <IntroModal onClose={this.handleHideIntroModal} />
        )}
      </>
    );
  }
}

PostView.propTypes = {
  enabledLanguages: PropTypes.arrayOf(PropTypes.string.isRequired),
  onResetAskflowData: PropTypes.func.isRequired,
  onCheckOpenQuestion: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  enabledLanguages: state.user.enabled_languages,
  askflow: state.askflow,
});


const mapDispatchToProps = dispatch => ({
  onResetAskflowData: () => dispatch(resetAskflowData()),
  onCheckOpenQuestion: () => dispatch(checkOpenQuestion()),
  onGetAutoBidStatus: () => dispatch(getAutobidStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostView);
