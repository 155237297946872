import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from 'components/Common/Modal';

const OutOfCreditModal = ({ onGoToPackagesView, onClose }) => (
  <ModalBase
    headerText="Oops!"
    size="small"
    closable={true}
    onHide={onClose}
    body={(
      <p className="u-marginBottomNone">You don't have enough sessions to ask this question.</p>
    )}
    primaryButtonText="Go To Store"
    onClickPrimaryButton={onGoToPackagesView}
  />
);

OutOfCreditModal.propTypes = {
  onGoToPackagesView: PropTypes.func.isRequired,
};

export default OutOfCreditModal;
