import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from 'components/Common/Modal';

const PaymentUnsupported = ({ onGoToBraintreeView }) => (
  <ModalBase
    headerText="Oops...Not supported payment method"
    size="small"
    closable={false}
    body={(
      <p className="u-marginBottomNone">Your mobile payment method is not available and you need to choose a new method.</p>
    )}
    footerType="single"
    primaryButtonText="Go To Store"
    onClickPrimaryButton={onGoToBraintreeView}
  />
);

PaymentUnsupported.propTypes = {
  onGoToBraintreeView: PropTypes.func.isRequired,
};

export default PaymentUnsupported;
