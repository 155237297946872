import React from 'react';
import PropTypes from 'prop-types';
import { BubbleChat, Avatar } from '@ahaui/react';
import moment from 'moment';

import { convertSpaces } from 'utils/util';

import expertAvatar from 'assets/images/expert.svg';
import gotitAvatar from 'assets/images/gotit.svg';

import SuggestedResponse from './SuggestedResponse';

class MessageList extends React.Component {
  constructor(props) {
    super(props);
    this.messageWindow = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { messages, expertTyping } = this.props;
    const { messages: prevMessage } = prevProps;

    if (messages.length !== prevMessage.length || expertTyping) {
      this.messageWindow.current.scrollIntoView();
    }
  }

  handleOptionClick = (mid, optionId) => {
    logger.debug('on handleOptionClick');
    this.props.onCqMessageReply(mid, optionId);
  }

  formatTime = unixSeconds => moment.unix(unixSeconds).format('HH:mm')

  renderQuestionTitle = (question) => {
    const { user } = this.props;
    const time = question.author.uid === user.uid
      ? this.formatTime(question.created)
      : null;

    return (
      <BubbleChat
        id="question-title"
        type="inbound"
        text={<div dangerouslySetInnerHTML={{ __html: convertSpaces(question.title) }} />}
        time={time}
        className="u-textWordBreak"
      />
    );
  }

  renderQuestionImage = (question) => {
    if (question.attachments?.length > 0) {
      return (
        <BubbleChat
          id="question-image"
          type="inbound"
          time={this.formatTime(question.created)}
        >
          <BubbleChat.Image src={question.attachments[0].url} />
        </BubbleChat>
      );
    }

    return null;
  }

  renderTyping = () => {
    const { expertTyping } = this.props;

    if (expertTyping) {
      return (
        <BubbleChat
          isTyping
          type="outbound"
          avatar={this.renderExpertAvatar}
        />
      );
    }

    return null;
  }

  addAskerMessage = (messageEls, msg) => {
    if (msg.attachment) {
      const imageEl = (
        <BubbleChat
          key={`${msg.mid}-${msg.body.length}-1`}
          type="inbound"
          time={this.formatTime(msg.created)}
          className="asker-message-image"
        >
          <BubbleChat.Image src={msg.attachment.url} />
        </BubbleChat>
      );
      messageEls.push(imageEl);

      const msgEl = (
        <BubbleChat
          key={`${msg.mid}-${msg.body.length}-2`}
          type="inbound"
          text={<div dangerouslySetInnerHTML={{ __html: convertSpaces(msg.body) }} />}
          time={this.formatTime(msg.created)}
          className="asker-message u-textWordBreak"
        />
      );
      messageEls.push(msgEl);
    } else {
      const msgEl = (
        <BubbleChat
          key={`${msg.mid}-${msg.body.length}`}
          type="inbound"
          text={<div dangerouslySetInnerHTML={{ __html: convertSpaces(msg.body) }} />}
          time={this.formatTime(msg.created)}
          className="asker-message u-textWordBreak"
        />
      );
      messageEls.push(msgEl);
    }
  }

  addExpertMessage = (messageEls, msg) => {
    const messageEdited = msg.updated > msg.created;

    if (msg.options) {
      const msgEl = (
        <SuggestedResponse
          key={`${msg.mid}-${msg.body.length}`}
          created={this.formatTime(msg.created)}
          message={msg}
          onOptionClick={this.handleOptionClick}
        />
      );
      messageEls.push(msgEl);
    } else if (msg.attachment) {
      const imageEl = (
        <BubbleChat
          key={`${msg.mid}-${msg.body.length}-1`}
          type="outbound"
          avatar={this.renderExpertAvatar}
          time={this.formatTime(msg.created)}
          className="expert-message-image"
        >
          <BubbleChat.Image src={msg.attachment.url} />
        </BubbleChat>
      );
      messageEls.push(imageEl);

      const msgEl = (
        <BubbleChat
          key={`${msg.mid}-${msg.body.length}-2`}
          type="outbound"
          avatar={this.renderExpertAvatar}
          text={<div dangerouslySetInnerHTML={{ __html: convertSpaces(msg.body) }} />}
          time={this.formatTime(msg.created) + (messageEdited ? ' - Edited' : '')}
          className="expert-message u-textWordBreak"
        />
      );
      messageEls.push(msgEl);
    } else {
      const msgEl = (
        <BubbleChat
          key={`${msg.mid}-${msg.body.length}`}
          type="outbound"
          avatar={this.renderExpertAvatar}
          text={<div dangerouslySetInnerHTML={{ __html: convertSpaces(msg.body) }} />}
          time={this.formatTime(msg.created) + (messageEdited ? ' - Edited' : '')}
          className="expert-message u-textWordBreak"
        />
      );
      messageEls.push(msgEl);
    }
  }

  addSystemMessage = (messageEls, msg) => {
    if (msg.options) {
      const msgEl = (
        <SuggestedResponse
          key={`${msg.mid}-${msg.body.length}`}
          created={this.formatTime(msg.created)}
          message={msg}
          onOptionClick={this.handleOptionClick}
          isSystemMessage
        />
      );
      messageEls.push(msgEl);
    } else {
      const msgEl = (
        <BubbleChat
          key={`${msg.mid}-${msg.body.length}`}
          type="system"
          avatar={this.renderGotitAvatar}
          text={<div dangerouslySetInnerHTML={{ __html: msg.body }} />}
          time={this.formatTime(msg.created)}
          className="u-textWordBreak"
        />
      );
      messageEls.push(msgEl);
    }
  }

  renderExpertAvatar = () => (
    <Avatar
      size="small"
      src={expertAvatar}
      alt="Expert avatar"
    />
  )

  renderGotitAvatar = () => (
    <Avatar
      size="small"
      src={gotitAvatar}
      alt="System avatar"
    />
  )

  render() {
    const {
      question,
      messages,
    } = this.props;
    const messageEls = [];
    messages.forEach((msg) => {
      if (msg.sender.uid === 0) {
        this.addSystemMessage(messageEls, msg);
      } else if (msg.sender.uid === question.author.uid) {
        this.addAskerMessage(messageEls, msg);
      } else {
        this.addExpertMessage(messageEls, msg);
      }
    });

    return (
      <div className="u-flexGrow-1 u-positionRelative">
        <div className="u-positionAbsolute u-positionFull u-paddingHorizontalSmall u-overflowVerticalAuto u-webkitScrollbar">
          <div className="u-paddingVerticalSmall">
            {this.renderQuestionTitle(question)}
            {this.renderQuestionImage(question)}
            {messageEls}
            {this.renderTyping()}
            <div ref={this.messageWindow} />
          </div>
        </div>
      </div>
    );
  }
}

MessageList.propTypes = {
  user: PropTypes.shape().isRequired,
  question: PropTypes.shape().isRequired,
  expertTyping: PropTypes.bool,
  messages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onCqMessageReply: PropTypes.func.isRequired,
};

MessageList.defaultProps = {
  expertTyping: false,
};

export default MessageList;
