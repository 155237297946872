import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Icon, SafeAnchor } from '@ahaui/react';

import {
  AUTH_METHOD_FACEBOOK,
  AUTH_METHOD_EMAIL,
} from 'constants/app.constant';

import FacebookAuth from './FacebookAuth';
import PresetAuth from './PresetAuth';

import Signup from './Signup';

import {
  signupFacebook,
} from '../../../actions/user.action';

import { auth } from '../../../actions/app.action';

import AccessToken from '../../../utils/token';
import Tracking from '../../../utils/tracking';

import ErrorModal from './modals/Error';

import { navSend, navReceive, navClear } from '../../../utils/app';

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signupStep: '',
      accessToken: '',
      signupMethod: '',
      formSignup: true,
      errorMsg: '',
      errorCode: '',
      error: null,
    };

    this.navInfo = navReceive('/');
    this.isLoginFromSharedSession = this.navInfo?.from?.startsWith('shared_session_');
  }

  componentWillUnmount() {
    navClear('/');
  }

  onAuthenticated = (authToken) => {
    AccessToken.setAuthKey(authToken);
    this.props.onAuthenticated(authToken, false);
    if (this.isLoginFromSharedSession) {
      this.props.history.push(this.navInfo.path);
    }
  }

  onLoggedinFacebook = (authToken) => {
    this.onLoggedin(authToken, AUTH_METHOD_FACEBOOK);
  }

  onLoggedin = (authToken, authType) => {
    console.log({ authType });
    this.onAuthenticated(authToken);
    // Tracking.identify(uid);
    Tracking.login(true, { login_method: authType });
  }

  handleStartSignupFacebook = (accessToken) => {
    this.setState({ signupStep: 'grade', accessToken, signupMethod: AUTH_METHOD_FACEBOOK });
  }

  showError = (message, code = '') => {
    if (message || code) {
      this.setState({ error: { code, message } });
    } else {
      this.setState({ error: null });
    }
  }

  handleLoginFacebookError = (message, code = '') => {
    this.showError(message, code);
    Tracking.login(false, { login_method: AUTH_METHOD_FACEBOOK, message: code });
  }

  handleCloseMessage = () => {
    this.setState({ error: null });
  }

  handleLoginOneTimeCode = () => {
    navSend('/login', { from: 'one_time_code_login' });
    this.props.history.push('/login');
  }

  handleSignupOneTimeCode = () => {
    navSend('/login', { from: 'one_time_code_signup' });
    this.props.history.push('/login');
  }

  doSignup = (data) => {
    if (this.state.signupMethod === AUTH_METHOD_FACEBOOK) {
      return this.props.doSignupFacebook(this.state.accessToken, data);
    }
  }

  toggleSignUp = (e) => {
    e.preventDefault();
    this.setState({ formSignup: !this.state.formSignup });
  }

  render() {
    const { error, signupStep, errorMsg, formSignup } = this.state;
    const { handleClickSupport } = this.props;

    return (
      <>
        <div
          className={formSignup ? 'FlipCard' : 'FlipCard isBack'}
          style={{ height: 252 }}
        >
          <div className="FlipCardContent">
            {/* Signup */}
            <div className="FlipCardItem">
              <div className="u-marginBottomLarge u-paddingVerticalExtraSmall">
                <div className="Grid">
                  <div className="u-sizeFull lg:u-size4of12 lg:u-offset4of12">
                    {!this.accountKitEmailEnabled && (
                      <Button
                        variant="white_outline"
                        width="full"
                        className="u-marginBottomSmall"
                        onClick={this.handleSignupOneTimeCode}
                      >
                        <Button.Icon>
                          <Icon name="mail" />
                        </Button.Icon>
                        <Button.Label>Sign up with email</Button.Label>
                      </Button>
                    )}
                    <FacebookAuth
                      signup
                      onSignup={this.handleStartSignupFacebook}
                      onAuthenticated={this.onLoggedinFacebook}
                      onError={this.handleLoginFacebookError}
                    />
                    <PresetAuth signup />
                  </div>
                </div>
              </div>
              <p>
                By Signing up you agree to our
                {` `}
                <SafeAnchor
                  href="https://www.got-it.co/terms.html"
                  target="_blank"
                  className="u-textWhite u-textUnderline"
                >
                  Terms of Service
                </SafeAnchor>
              </p>
              <p>
                Already have an account? -{` `}
                <SafeAnchor
                  className="u-textWhite u-textUnderline u-cursorPointer"
                  onClick={this.toggleSignUp}
                >
                  Log in
                </SafeAnchor>
              </p>
            </div>

            {/* Login */}
            <div className="FlipCardItem FlipCardItem--back">
              <div className="u-marginBottomLarge u-paddingVerticalExtraSmall">
                <div className="Grid">
                  <div className="u-sizeFull lg:u-size4of12 lg:u-offset4of12">
                    {!this.accountKitEmailEnabled && (
                      <Button
                        variant="white_outline"
                        width="full"
                        className="u-marginBottomSmall"
                        onClick={this.handleLoginOneTimeCode}
                      >
                        <Button.Icon>
                          <Icon name="mail" />
                        </Button.Icon>
                        <Button.Label>Log in with email</Button.Label>
                      </Button>
                    )}
                    <FacebookAuth
                      onSignup={this.handleStartSignupFacebook}
                      onAuthenticated={this.onLoggedinFacebook}
                      onError={this.handleLoginFacebookError}
                    />
                    <PresetAuth />
                  </div>
                </div>
              </div>
              <p>
                Having trouble?
                {` `}
                <SafeAnchor
                  onClick={handleClickSupport}
                  target="_blank"
                  className="u-textWhite u-textUnderline"
                >
                  Contact Support
                </SafeAnchor>
              </p>
              <p>
                Don't have an account? -{` `}
                <SafeAnchor
                  className="u-textWhite u-textUnderline u-cursorPointer"
                  onClick={this.toggleSignUp}
                >
                  Sign up
                </SafeAnchor>
              </p>
            </div>
          </div>
        </div>

        {(signupStep && !errorMsg) && (
          <Signup
            doSignup={this.doSignup}
            showError={this.showError}
            signupMethod={this.state.signupMethod}
          />
        )}

        {error && (
          <ErrorModal
            code={error.code}
            message={error.message}
            onClose={this.handleCloseMessage}
          />
        )}
      </>
    );
  }
}

Account.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  doSignupFacebook: PropTypes.func.isRequired,
  onAuthenticated: PropTypes.func.isRequired,
  handleClickSupport: PropTypes.func.isRequired,
};

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
  doSignupFacebook: (token, data) => dispatch(signupFacebook(token, data)),
  onAuthenticated: (token, firstLogin) => dispatch(auth(token, firstLogin)),
});

const AccountContainer = connect(mapStateToProps, mapDispatchToProps)(Account);

export default withRouter(AccountContainer);
