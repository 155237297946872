import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Rating } from '@ahaui/react';
import config from 'configuration';
import Tracking from 'utils/tracking';
import AccessToken from 'utils/token';
import { getBrowser } from 'utils/app';
import { ratingReason } from 'constants/common.constant';
import { rateQuestion } from 'actions/chat.action';
import ModalBase from 'components/Common/Modal';

export class RatingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phase: 1,
      star: null,
    };
  }

  trackRated = (star, reason) => {
    const { question, answ } = this.props;
    Tracking.rateAnswer({
      qid: Number(question.qid),
      aid: Number(answ.aid),
      point: Number(star),
      reason: Number(reason),
    });
  }

  onRate = (star, reason) => {
    const {
      answ,
      onRating,
      onRated,
    } = this.props;

    onRating(answ.aid, star, reason).then(
      (data) => {
        this.trackRated(star, reason);
        const { result } = data.payload;
        onRated(result?.modal);
      }
    );
  }

  handleRate = () => {
    const { star } = this.state;

    logger.debug('on handleRate', 'star', star);
    if (star <= 2) {
      this.setState({
        phase: 2,
      });
    } else {
      this.onRate(star, 0);
    }
  }

  handleSelectRating = (_e, star) => {
    this.setState({
      star,
    });
  }

  handleFeedback = (reason) => {
    const { star } = this.state;

    logger.debug('on handleFeedback', 'reason', reason);
    this.onRate(star, reason);
    if (reason === ratingReason.SEND_MORE_FEEDBACK) {
      logger.debug('on openDefaultEmailClient');
      this.openDefaultEmailClient();
    }
  }

  openDefaultEmailClient = () => {
    const { user } = this.props;

    logger.debug('on openDefaultEmailClient');

    const subject = 'Got It Study Feedback';
    const body = `
^^please enter feedback ABOVE^^


App Version: ${config.appVersion}
Browser: ${getBrowser()}
Browserprint: ${AccessToken.getBrowserId()}
User ID: ${user.uid}
Additional Information: ${user.support_priority} : ${user.payment_transaction_count} : ${user.consumed_credit_count}

Sent from Web app
    `.trimEnd(); // preserve newline character at the beginning of the string
    const mailLink = `mailto:support@photostudy.co?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailLink, '_self');
  };

  renderForRate = () => {
    const { star } = this.state;

    return (
      <ModalBase
        headerText="Rate your session"
        size="small"
        closable={false}
        body={(
          <div className="u-textCenter">
            <Rating value={star} onChange={this.handleSelectRating} />
          </div>
        )}
        primaryButtonText="Send"
        onClickPrimaryButton={this.handleRate}
        disablePrimaryButton={star === null}
      />
    );
  }

  renderForFeedback = () => (
    <ModalBase
      headerText="What went wrong?"
      size="small"
      closable={false}
      body={(
        <>
          <p>We’re sorry to hear that you didn’t have a 5-star experience.</p>
          <div className="u-marginBottomSmall">
            <Button variant="secondary" width="full" onClick={() => this.handleFeedback(ratingReason.BAD_EXPLANATION)}>
              <Button.Label className="u-fontMedium">
                Bad explanation
              </Button.Label>
            </Button>
          </div>
          <div className="u-marginBottomSmall">
            <Button variant="secondary" width="full" onClick={() => this.handleFeedback(ratingReason.TOOK_TOO_LONG)}>
              <Button.Label className="u-fontMedium">
                Took too long
              </Button.Label>
            </Button>
          </div>
          <div>
            <Button variant="secondary" width="full" onClick={() => this.handleFeedback(ratingReason.SEND_MORE_FEEDBACK)}>
              <Button.Label className="u-fontMedium">
                Send more feedback
              </Button.Label>
            </Button>
          </div>
        </>
      )}
      showFooter={false}
    />
  )

  render() {
    const { phase } = this.state;

    return phase === 1
      ? this.renderForRate()
      : this.renderForFeedback();
  }
}

RatingModal.propTypes = {
  user: PropTypes.shape().isRequired,
  question: PropTypes.shape().isRequired,
  answ: PropTypes.shape().isRequired,
  onRating: PropTypes.func.isRequired,
  onRated: PropTypes.func.isRequired,
};

const mapSateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  onRating: (aid, star, reason) => dispatch(rateQuestion(aid, star, reason)),
});

export default connect(mapSateToProps, mapDispatchToProps)(RatingModal);
