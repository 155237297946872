
export const NOTI_LOAD = 'noti_load';
export const NOTI_NEW = 'noti_new';
export const NOTI_READ = 'noti_read';
export const NOTI_ON_READ = 'noti_on_read';
export const NOTI_ON_READ_ALL = 'noti_on_read_all';
export const NOTI_BLOCK_UNREAD = 'noti_block_unread';
export const NOTI_UNBLOCK_UNREAD = 'noti_unblock_unread';

export default {
  NOTI_LOAD,
  NOTI_NEW,
  NOTI_READ,
  NOTI_ON_READ_ALL,
  NOTI_ON_READ,
  NOTI_UNBLOCK_UNREAD
};