import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from 'components/Common/Modal';

const CoppaOutOfCreditModal = ({ data, onClose }) => (
  <ModalBase
    headerText={data.title}
    size="medium"
    closable
    onHide={onClose}
    body={(
      <p className="u-marginBottomNone">{data.message}</p>
    )}
    primaryButtonText={data.actions[0].title}
    onClickPrimaryButton={onClose}
  />
);

CoppaOutOfCreditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CoppaOutOfCreditModal;
