import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Tracking from 'utils/tracking';
import { Button } from '@ahaui/react';
import { hasWritingOnly } from 'utils/feature-support';

const Finish = () => {
  const history = useHistory();
  const enabledFeatures = useSelector(({ user }) => user.enabled_features);

  const handleDonePosting = () => {
    Tracking.questionDone();

    if (hasWritingOnly(enabledFeatures)) {
      history.push('/english');
    } else {
      history.push('/');
    }
  };

  return (
    <div className="Container Container--fluid">
      <div className="u-marginHorizontalAuto u-paddingMedium" style={{ width: '100%', maxWidth: 640 }}>
        <div className="u-text500 u-fontMedium u-textCenter u-marginBottomLarge">
          Problem submitted!
        </div>
        <div className="u-flex u-text200 u-marginBottomLarge">
          <div className="u-marginRightSmall">
            <span className="u-block u-backgroundPrimary u-textWhite u-paddingHorizontalExtraSmall u-roundedExtraLarge">
              1
            </span>
          </div>
          <div className="u-flexGrow1">
            Our Study Expert will get back to you within 48 hours.
          </div>
        </div>
        <div className="u-flex u-text200 u-marginBottomLarge">
          <div className="u-marginRightSmall">
            <span className="u-block u-backgroundPrimary u-textWhite u-paddingHorizontalExtraSmall u-roundedExtraLarge">
              2
            </span>
          </div>
          <div className="u-flexGrow1">
            Once completed we will email the Google document to your primary and additional email.
          </div>
        </div>

        <div className="u-flex u-text200 u-marginBottomLarge">
          <div className="u-marginRightSmall">
            <span className="u-block u-backgroundPrimary u-textWhite u-paddingHorizontalExtraSmall u-roundedExtraLarge">
              3
            </span>
          </div>
          <div className="u-flexGrow1">
            You can view comments and suggestions from our Study Expert.
          </div>
        </div>

        <div className="u-marginTopMedium u-textCenter">
          <Button variant="primary" width="min" onClick={handleDonePosting} data-testid="done-button">Done</Button>
        </div>
      </div>
    </div>
  );
};

export default Finish;
