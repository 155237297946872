import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Slider, Button } from '@ahaui/react';

import ModalBase from 'components/Common/Modal';

import ImageCropWrapper from './ImageCropWrapper';

import { cropFile, hideCropper } from '../../../../../actions/askflow.action';


class PictureCropper extends React.Component {
  constructor() {
    super();
    this.state = {
      zoomLevel: 100,
    };
    this.imageReady = false;
    this.imageTool = null;
  }

  /**
   * Get the status of image tools init.
   */
  handleImageReady = (ready) => {
    this.imageReady = ready;
  }

  /**
   * Handle reset button click.
   * Reset the crop file.
   */
  handleReset = () => {
    logger.debug('on handleReset');
    if (!this.imageReady) {
      logger.warn('Imagetool is processing');
      return;
    }
    this.imageTool.reset();
    this.setState({
      zoomLevel: 100,
    });
  }

  /**
   * Handle rotate picture.
   */
  handleRotate = () => {
    logger.debug('on handleRotate');
    if (!this.imageReady) {
      logger.warn('Imagetool is processing');
      return;
    }
    this.imageTool.rotate(90);
  }

  /**
   * Handle when finish crop
   * Dispatch the cropped action.
   */
  handleCrop = () => {
    logger.debug('on handleCrop');
    if (!this.imageReady) {
      logger.warn('Imagetool is processing');
      return;
    }
    const crop = this.imageTool.getCroppedImage();
    this.props.onCropPicture(crop);
    this.setState({
      zoomLevel: 100,
    });
    this.props.onHideCropper();
  }

  handleHideToggle = () => {
    logger.debug('on handleHideToggle');
    this.setState({
      zoomLevel: 100,
    });
    this.props.onHideCropper();
  }

  handleZooming = (value) => {
    logger.debug('on handleZooming', value);
    this.setState({
      zoomLevel: value,
    });
    this.imageTool.zoom(value / 100);
  }

  render() {
    if (!this.props.toggleCrop) return null;
    return (
      <ModalBase
        headerText="Edit Photo"
        size="large"
        closable={true}
        onHide={this.handleHideToggle}
        showFooter={false}
        body={(
          <>
            <div className="u-marginBottomMedium u-borderBottom">
              <ImageCropWrapper ref={(el) => { this.imageTool = el; }} src={this.props.originalImage} onReadyStateChange={this.handleImageReady} />
            </div>
            <div>
              <div className="u-flex u-alignItemsCenter u-marginBottomSmall u-marginHorizontalAuto" style={{ maxWidth: 464 }}>
                <Icon name="zoomOut" size="small" />
                <div className="u-flexGrow-1 u-marginHorizontalSmall">
                  <Slider
                    min={51}
                    max={150}
                    value={this.state.zoomLevel}
                    onChange={this.handleZooming}
                    variant="primary"
                    style={{ marginBottom: 0, marginTop: -8 }}
                  />
                </div>
                <Icon name="zoomIn" size="small" />
              </div>
              <div className="u-flex u-justifyContentCenter">
                <Button.Group>
                  <Button variant="secondary" onClick={this.handleReset} aria-label="Reset image">
                    <Button.Label className="u-hidden md:u-inlineBlock">
                      Reset
                    </Button.Label>
                    <Button.Icon>
                      <Icon name="replace" />
                    </Button.Icon>
                  </Button>
                  <Button variant="secondary" onClick={this.handleRotate} aria-label="Rotate image clockwise">
                    <Button.Label className="u-hidden md:u-inlineBlock">
                      Rotate
                    </Button.Label>
                    <Button.Icon>
                      <Icon name="refresh" />
                    </Button.Icon>
                  </Button>
                  <Button variant="secondary" onClick={this.handleCrop} aria-label="Crop image">
                    <Button.Label className="u-hidden md:u-inlineBlock">
                      Crop
                    </Button.Label>
                    <Button.Icon>
                      <Icon name="checkmark" />
                    </Button.Icon>
                  </Button>
                </Button.Group>
              </div>
            </div>
          </>
        )}
      />
    );
  }
}

PictureCropper.propTypes = {
  originalImage: PropTypes.string,
  toggleCrop: PropTypes.bool.isRequired,
  onCropPicture: PropTypes.func.isRequired,
  onHideCropper: PropTypes.func.isRequired,
};

PictureCropper.defaultProps = {
  originalImage: null,
};

const mapStateToProps = state => ({
  originalImage: state.askflow.originalImage,
  toggleCrop: state.askflow.toggleCrop,
});

const mapDispatchToProps = dispatch => ({
  onCropPicture: file => dispatch(cropFile(file)),
  onHideCropper: () => dispatch(hideCropper()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PictureCropper);
