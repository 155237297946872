/* eslint-disable import/newline-after-import */
/* Combine all available reducers to a single root reducer.
 *
 * CAUTION: When using the generators, this file is modified in some places.
 *          This is done via AST traversal - Some of your formatting may be lost
 *          in the process - no functionality should be broken though.
 *          This modifications only run once when the generator is invoked - if
 *          you edit them, they are not updated again.
 */
/* Populated by react-webpack-redux:reducer */
import { combineReducers } from 'redux';
import userReducer from './user.reducer';
import askflowReducer from './askflow.reducer';
import chatReducer from './chat.reducer';
import appReducer from './app.reducer';
import notificationReducer from './notification.reducer';
import historyReducer from './history.reducer';
import guideReducer from './guide.reducer';
import storefrontReducer from './storefront.reducer';
import surgeInfoReducer from './surgeInfo.reducer';

import {
  LOG_OUT,
  FORCE_LOGOUT
} from '../constants/user.constant';

const combinedReducers = combineReducers({
  app: appReducer,
  user: userReducer,
  chat: chatReducer,
  askflow: askflowReducer,
  notification: notificationReducer,
  myQuestions: historyReducer,
  sampleQuestions: guideReducer,
  storefront: storefrontReducer,
  surgeInfo: surgeInfoReducer,
});

const rootReducer = (state, action) => {
  if (action.type === FORCE_LOGOUT ||
      action.type === LOG_OUT) {
    return combinedReducers(undefined, action);
  }
  return combinedReducers(state, action);
};

export default rootReducer;
