import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import config from 'configuration';

import { getBrowser } from 'utils/app';
import AccessToken from 'utils/token';

import ModalBase from 'components/Common/Modal';

const UnknownErrorModal = ({ onClose, message }) => {
  const user = useSelector(({ user }) => user);

  const handleOpenNeedHelpMail = () => {
    logger.debug('on openDefaultEmailClient');

    const { appVersion } = config;
    const browser = getBrowser();
    const deviceId = AccessToken.getBrowserId();
    const mailLink =
      'mailto:support@photostudy.co?' +
      'subject=Got%20IT%20Study%20Error%20Feedback&' +
      'body=%0A%5E%5Eplease%20enter%20feedback%20ABOVE%5E%5E%0A%0A%0A' +
      `App%20Version%3A%20${appVersion}%20%0A` +
      `Browser%3A%20${browser}%20%0A` +
      `Browserprint%3A%20${deviceId}%20%0A` +
      `User%20ID%3A${user.uid}%20%0A` +
      'Additional%20Information' +
      `%3A%20${user.support_priority}` +
      `%20%3A%20${user.payment_transaction_count}` +
      `%20%3A%20${user.consumed_credit_count}%20%0A%0A` +
      'Sent%20from%20Web%20app';
    window.open(mailLink, '_self');
  };

  return (
    <ModalBase
      headerText="Error"
      size="small"
      closable={false}
      onHide={onClose}
      body={
        <p className="u-marginBottomNone">
          {message || 'Sorry, we were unable to sent your question.'}
        </p>
      }
      footerType="vertical-double"
      primaryButtonText="Need help"
      onClickPrimaryButton={handleOpenNeedHelpMail}
      secondaryButtonText="Cancel"
      onClickSecondaryButton={onClose}
    />
  );
};

UnknownErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default UnknownErrorModal;
