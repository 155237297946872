import { getStore } from './storage';

class AccessToken {
  constructor() {
    this.authToken = '';
    this.deviceId = '';
    this.storage = getStore('gotit_webapp_keys');
  }

  init(keys) {
    if (keys && typeof keys === 'object') {
      if (keys.authToken) {
        this.authToken = keys.authToken;
      }
      if (keys.deviceId) {
        this.deviceId = keys.deviceId;
      }
    }
  }

  getAuthKey = () => this.authToken;

  getBrowserId = () => this.deviceId;

  onChangeAuthKey(f) {
    this.storage.addListener(value => value.authToken !== this.authToken && f(value.authToken));
  }

  setAuthKey(token) {
    if (token !== this.authToken) {
      this.authToken = token;
      this.saveKeys();
      return true;
    }
    return false;
  }

  setDeviceId(id) {
    if (id !== this.deviceId) {
      this.deviceId = id;
      this.saveKeys();
    }
  }

  loadKeys = () => this.storage.get();

  loadAuthKey = () => {
    const data = this.loadKeys();
    if (data && Object.prototype.hasOwnProperty.call(data, 'authToken')) {
      return data.authToken;
    }
    return '';
  }

  isAuthKeyChange = () => this.authToken !== this.loadAuthKey();

  saveKeys() {
    this.storage.set({
      authToken: this.authToken,
      deviceId: this.deviceId,
    });
  }
}

export default new AccessToken();
