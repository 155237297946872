import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Icon } from '@ahaui/react';
import { convertSpaces } from 'utils/util';

const QSummary = ({ question, hideRating }) => {
  const { subjects = [] } = useSelector(({ surgeInfo }) => surgeInfo);

  let subjectName = '';
  if (question.meta && question.meta.child_tid) {
    subjects.forEach((subject) => {
      if (subject.tid === question.meta.child_tid) {
        subjectName = subject.title;
      }
    });
  }

  const renderRating = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <Icon
          key={i}
          name="star"
          size="extraSmall"
          className={i <= rating ? 'u-textWarning' : 'u-textLight'}
        />
      );
    }
    return stars;
  }

  return (
    <table className="Table--layoutFixed u-widthFull">
      <tbody>
        <tr>
          <td
            className="u-fontMedium u-widthExtraLarge"
            style={{ verticalAlign: 'top' }}
          >
            Title:
          </td>
          <td style={{ verticalAlign: 'top' }}>
            <div className="u-textTruncate">
              {convertSpaces(question.title)}
            </div>
          </td>
        </tr>

        {!hideRating && (
          <tr>
            <td
              className="u-fontMedium u-widthExtraLarge"
              style={{ verticalAlign: 'top' }}
            >
              Rating:
              </td>
            <td style={{ verticalAlign: 'top' }} className="u-flex u-alignItemsCenter">
              {renderRating(+question.answer_point)}
            </td>
          </tr>
        )}

        <tr>
          <td
            className="u-fontMedium u-widthExtraLarge"
            style={{ verticalAlign: 'top' }}
          >
            Topic:
          </td>
          <td style={{ verticalAlign: 'top' }} className="u-flex u-alignItemsCenter">
            {`${subjectName} by ${question.expert_name}`}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

QSummary.propTypes = {
  question: PropTypes.shape().isRequired,
  hideRating: PropTypes.bool
};

QSummary.defaultProps = {
  hideRating: false
};

export default QSummary;
