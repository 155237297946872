import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ahaui/react';

class PresetId extends React.Component {
  handleChangePresetId = (e) => {
    const { onChange } = this.props;

    onChange(e.target.value);
  }

  render() {
    const { disabled, errorMessage, presetId } = this.props;

    return (
      <Form.Group controlId="login.presetId">
        <Form.Label>Enter your User ID</Form.Label>
        <Form.Input
          isInvalid={!!errorMessage}
          type="text"
          placeholder="User ID"
          value={presetId}
          onChange={this.handleChangePresetId}
          disabled={disabled}
        />
        <Form.Feedback type="invalid" visible={!!errorMessage} role="alert">
          {errorMessage}
        </Form.Feedback>
      </Form.Group>
    );
  }
}

PresetId.propTypes = {
  errorMessage: PropTypes.oneOfType([
    PropTypes.string, PropTypes.element,
  ]),
  presetId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

PresetId.defaultProps = {
  errorMessage: '',
  presetId: '',
  disabled: false,
  onChange: () => {},
};

export default PresetId;
