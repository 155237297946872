import React from 'react';
import { PageLayout, SafeAnchor } from '@ahaui/react';

class ScreenSupport extends React.Component {

  render() {
    return (
      <PageLayout
        className="u-backgroundLightest u-overflowVerticalAuto u-webkitScrollbar"
        style={{
          height: '100vh',
        }}
      >
        <PageLayout.Header>
          <div className="u-flex u-justifyContentCenter u-paddingVerticalExtraLarge">
            <img
              src={require('assets/images/logo.svg')}
              alt="PhotoStudy logo"
              style={{ height: 40 }}
            />
          </div>
        </PageLayout.Header>
        <PageLayout.Body className="">
          <div className="Container">
            <section
              className="u-block u-marginHorizontalAuto u-border u-paddingHorizontalMedium u-paddingVerticalLarge u-backgroundWhite u-roundedMedium"
              style={{ maxWidth: 600 }}
            >
              {/* header: */}
              <div className="u-flex u-justifyContentCenter u-positionRelative u-marginBottomLarge">
                <h3 className="u-marginNone u-textCenter">Thank you for trying PhotoStudy</h3>
              </div>
              {/* body: */}
              <div className="u-textCenter">
                <p className="u-marginBottomLarge">We’re still working on creating the best experience for smaller screen sizes. Please try PhotoStudy on a bigger screen or download the app for mobile:</p>
                <div className="u-flex u-justifyContentCenter">
                  <SafeAnchor
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=co.gotitapp.android"
                    target="_blank"
                  >
                    <img
                      src={require('assets/images/landing/icon-google-play.svg')}
                      alt="Get it on Google Play"
                      className="u-block u-marginHorizontalAuto u-marginBottomExtraSmall u-maxWidthFull"
                    />
                  </SafeAnchor>
                  <div className="u-widthSmall u-heightSmall">&nbsp;</div>
                  <SafeAnchor
                    rel="noopener noreferrer"
                    href="https://itunes.apple.com/app/apple-store/id797535508?mt=8"
                    target="_blank"
                  >
                    <img
                      src={require('assets/images/landing/icon-app-store.svg')}
                      alt="Download on the App Store"
                      className="u-block u-marginHorizontalAuto u-marginBottomExtraSmall u-maxWidthFull"
                    />
                  </SafeAnchor>
                </div>
              </div>
            </section>
          </div>
        </PageLayout.Body>
      </PageLayout>
    );
  }
}

export default ScreenSupport;
