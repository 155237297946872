import { run } from './promise';
import { get } from '../utils/request';

import {
  QUESTION_LOAD_MY,
  QUESTION_LOAD_DETAIL,
  QUESTION_LOAD_SAMPLES,
  QUESTION_CHECK_OPEN,
  QUESTION_LOAD_SHARED,
} from '../constants/question.constant';

export const loadMyQuestions = (oldestQid = 0) => (
  run(
    QUESTION_LOAD_MY,
    get(`/questions?grading_start=0&start=${oldestQid}&type=my`),
    { oldestQid }
  )
);

export const loadSampleQuestions = () => (
  run(
    QUESTION_LOAD_SAMPLES,
    get('/questions?type=search&latest=1')
  )
);

export const loadQuestion = qid => (
  run(
    QUESTION_LOAD_DETAIL,
    get(`/questions?qid=${qid}`)
  )
);

export const checkOpenQuestion = () => (
  run(
    QUESTION_CHECK_OPEN,
    get('/questions/canask')
  )
);

export const loadSharedSession = (keyword) => (
  run(
    QUESTION_LOAD_SHARED,
    get('/sharesession/info', { shared_session: keyword })
  )
)

export default {
  loadMyQuestions,
  loadQuestion,
  loadSampleQuestions
};
