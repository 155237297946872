import React from 'react';

import PictureCropper from './PictureCropper';
import PictureLoader from './PictureLoader';

class Picture extends React.Component {

  render() {
    return (
      <div>
        <PictureLoader />
        <PictureCropper />
      </div>
    );
  }
}

export default Picture;
