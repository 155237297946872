import { run } from './promise';
import { get, post } from '../utils/request';
import {
  NOTI_LOAD,
  NOTI_READ,
  NOTI_ON_READ,
  NOTI_ON_READ_ALL,
  NOTI_BLOCK_UNREAD,
  NOTI_UNBLOCK_UNREAD,
 } from '../constants/notification.constant';

export const getNotis = () => (
  run(
    NOTI_LOAD,
    get('/notifications?latest=1')
  )
);

export const readNotis = nids => (
  run(
    NOTI_READ,
    post('/notifications/read', { nids: nids.join(',') }),
    { nids }
  )
);

export const onReadNotis = nids => ({
  type: NOTI_ON_READ,
  payload: nids
});

export const onReadAllNotis = () => ({
  type: NOTI_ON_READ_ALL
});

export const blockUnread = () => ({
  type: NOTI_BLOCK_UNREAD
});

export const unblockUnread = () => ({
  type: NOTI_UNBLOCK_UNREAD
});

export default {
  getNotis,
  readNotis,
  onReadNotis,
  blockUnread,
  unblockUnread
};
