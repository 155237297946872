import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalBase from 'components/Common/Modal';
import Tracking from '../../../../../utils/tracking';
import {
  flagQuestion,
} from '../../../../../actions/chat.action';


class FlagModal extends React.Component {
  trackFlagged = () => {
    logger.debug('on trackFlagged');
    const { question } = this.props;
    const { uid } = this.props.user;
    Tracking.chatFlagged({
      qid: Number(question.qid),
      student_id: Number(uid),
      tutor_id: Number(question.claimed_uid),
    });
  };

  handleFlag = () => {
    logger.debug('on handleFlag');
    this.props.onFlaggingQuestion(this.props.question.qid).then((data) => {
      const { result } = data.payload;
      if (result) {
        logger.debug('flag question succeed');
        this.props.onFlagged();
        this.trackFlagged();
      }
    });
  }

  handleClose = () => {
    logger.debug('on handleClose');
    this.props.onClose();
  }

  render() {
    return (
      <ModalBase
        headerText="Flag!"
        size="medium"
        closable={true}
        onHide={this.handleClose}
        body={(
          <>
            <p className="u-textCenter"><img src={require('assets/images/icon-flag.svg')} alt="" /></p>
            <p className="u-marginBottomNone">You're flagging this Expert for inappropriate content. Your study session will end now.</p>
          </>
        )}
        primaryButtonText="Flag Expert"
        onClickPrimaryButton={this.handleFlag}
      />
    );
  }
}

FlagModal.propTypes = {
  user: PropTypes.shape().isRequired,
  question: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onFlaggingQuestion: PropTypes.func.isRequired,
  onFlagged: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  question: state.chat.question,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  onFlaggingQuestion: qid => dispatch(flagQuestion(qid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlagModal);
