import giap from '@gotitinc/giap_javascript';
import config from 'configuration';
import ReactGA from './ga';

import AccessToken from './token';
import { getOS } from './app';

class Tracking {
  constructor() {
    this.waitingForBrowserIdEvents = [];
    this.registeredData = {};
    giap.initialize(config.GIAP_TOKEN, config.GIAP_SERVER_URL);
    ReactGA.initialize(config.GA_ID);
  }

  // Call this after App initial
  init = () => {
    this.register({
      platform: 'web',
      app_version: config.appVersion,
      browser_id: AccessToken.getBrowserId(),
      gotit_os: getOS(),
    });
  }

  register = (data) => {
    this.registeredData = { ...this.registeredData, ...data };
  }

  alias = (uid, email) => {
    this.logDebug('alias ', uid);
    this.register({ uid: +uid, student_email: email });
    giap.alias(String(uid));
  }

  identify = (uid, email) => {
    this.logDebug('identify ', uid);
    this.register({ uid: +uid, student_email: email });
    giap.identify(String(uid));
  }

  crossDomainIdentify = (distinctID) => {
    this.logDebug('crossDomainIdentify', distinctID);
    giap.identify(String(distinctID));
  }

  clearUser = () => {
    this.logDebug('clear user');
    giap.reset();
    const { uid, ...rest } = this.registeredData;
    this.registeredData = rest;
  }

  logDebug = (msg, ...args) => {
    logger.debug(`Analytics ${msg}`, ...args);
  }

  setBrowserId = (browserId) => {
    this.register({ browser_id: browserId });
    this.sendWaitingEvents();
  }

  sendWaitingEvents = () => {
    if (this.waitingForBrowserIdEvents.length > 0) {
      this.waitingForBrowserIdEvents.forEach((event) => {
        giap.track(event.name, event.data);
      });
      this.waitingForBrowserIdEvents = [];
    }
  }

  gotitTrack = (eventName, eventData) => {
    giap.track(eventName, { ...this.registeredData, ...eventData });
  }

  gaTrack = (eventName, properties = {}) => {
    // const eventData = {
    //   ...this.registeredData,
    //   ...properties,
    //   category: eventName,
    //   action: eventName,
    // };
    // return ReactGA.event(eventData);
  }

  track = (eventName, eventData = {}) => {
    this.logDebug(`track ${eventName}`, eventData);
    if (!this.registeredData.browser_id) {
      this.waitingForBrowserIdEvents.push({ name: eventName, data: eventData });
    } else {
      this.gotitTrack(eventName, eventData);
      this.gaTrack(eventName, eventData);
    }
  }

  appOpened = () => {
    this.track('Active');
  }

  viewLanding = (data, moreInfo = {}) => {
    this.track('Landing Page', data);
    this.pview(moreInfo.location || '/');
  }

  signup = (success, data) => {
    this.logDebug('signup ', success, data);
    const eventData = {
      signup_status: success ? 'success' : 'failure',
      source: 'app_web',
      ...data,
    };
    this.track('Signup', eventData);
  }

  login = (success, data) => {
    const eventData = {
      ...data,
      login_status: success ? 'success' : 'failure',
    };
    this.track('Login', eventData);
  }

  askQuestion = (data) => {
    this.track('Ask Question', data);
  }

  viewMyQuestions = (moreInfo = {}) => {
    this.track('View My Questions');
    this.pview(moreInfo.location || null);
  }

  viewQuestion = (data, moreInfo = {}) => {
    this.track('View Question', data);
    this.pview(moreInfo.location || null);
  }

  viewProfileSetting = (moreInfo = {}) => {
    this.track('edit_profile_view did appear');
    this.pview(moreInfo.location || null);
  }

  viewNotification = (moreInfo = {}) => {
    this.track('View Notification');
    this.pview(moreInfo.location || null);
  }

  rateAnswer = (data) => {
    this.track('Answer Rate', data);
  }

  shareApp = (data) => {
    this.track('Share App', data);
  }

  close = () => {
    this.track('Close');
  }

  active = (data) => {
    this.track('Active', data);
  }

  sessionStarted = (data) => {
    this.track('Start Micro Session', data);
  }

  sendMessage = (data) => {
    this.track('Send Message', data);
  }

  sessionFinished = (data) => {
    this.track('On Ramp Help Session Finish', data);
  }

  sendFeedback = (data) => {
    this.track('Send Feedback', data);
  }

  logout = (data) => {
    this.track('Logout', data);
  }

  chatFlagged = (data) => {
    this.track('Chat Flagged', data);
  }

  setGrade = (data) => {
    this.track('Set Grade', data);
  }

  viewStore = (data, moreInfo = {}) => {
    this.track('View Credit Purchase Popup', data);
    this.pview(moreInfo.location || '/storefront');
  }

  purchaseCredits = (data) => {
    this.track('Purchase Credits', data);
  }

  rufInitView = (data) => {
    this.track('RUF Initial View', data);
  }

  askFlow = (data) => {
    this.track('Web Ask Flow', data);
  }

  startAuth = (data) => {
    this.track('Login/Signup Start', data);
  }

  profileUpdated = (data) => {
    this.track('profile updated', data);
  }

  launchVideo = (data) => {
    this.track('launched video', data);
  }

  launchAsk = (data) => {
    this.track('launch ask', data);
  }

  changePrivacy = (data) => {
    this.track('change_privacy', data);
  }

  pageview = (path) => {
    ReactGA.pageview(path);
  }

  pview = (location) => {
    if (location) {
      if (typeof location === 'string') {
        this.pageview(location);
      } else {
        this.pageview(`${location.pathname}${location.search}${location.hash}`);
      }
    }
  }

  // WRITING EVENTS

  selectWritingHelp = () => {
    this.track('Select Writing Help');
  }

  selectWritingError = (data) => {
    this.track('Select Writing Error', data);
  }

  selectGoogleDoc = () => {
    this.track('Select Google Doc');
  }

  selectWordDoc = () => {
    this.track('Select Word Doc');
  }

  fileUploadError = (data) => {
    this.track('File Upload Error', data);
  }

  postQuestion = () => {
    this.track('Post Question');
  }

  questionPostError = (data) => {
    this.track('Question Post Error', data);
  }

  questionDone = () => {
    this.track('Question Done');
  }

  captchaAudio = () => {
    this.track('Captcha Audio');
  }

  captchaRefresh = () => {
    this.track('Captcha Refresh');
  }

  captchaError = (data) => {
    this.track('Captcha Error', data);
  }
}

export default new Tracking();
