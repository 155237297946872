export const packageTypes = {
  SUBSCRIPTION: 'subscription_product',
  INFO: 'product_info',
  STANDARD: 'consumable_product_standard',
  EXTENDED: 'consumable_product_extended',
  UNLIMITED: 'consumable_product_unlimited',
};
export const BRAINTREE_TOKEN_GET = 'braintree_token_get';
export const PAYMENT_METHOD_GET = 'payment_method_get';
export const PACKAGE_CHOOSED = 'packge_choosed';
export const PACKAGE_BUY = 'package_buy';
export const DEFAULT_METHOD_SET = 'default_method_set';
export const SUBSCRIPTION_UPDATE = 'subscription_update';
export const REWARD_MODAL_SHOW = 'reward_modal_show';
export const REWARD_MODAL_HIDE = 'reward_modal_hide';
