const STEM = 'stem-subject';
const WRITING = 'english-subject';
const TRIO = 'trio_program';
const COPPA = 'coppa';
const SHARED_SESSION_POOL = 'shared_session_pool';

const hasFeatureSupport = subject => enabledFeatures => enabledFeatures?.includes(subject);

export const hasStemSupport = hasFeatureSupport(STEM);
export const hasWritingSupport = hasFeatureSupport(WRITING);
export const hasTrioSupport = hasFeatureSupport(TRIO);
export const hasCoppaSupport = hasFeatureSupport(COPPA);
export const hasSharedSessionPoolSupport = hasFeatureSupport(SHARED_SESSION_POOL);

export const hasStemOnly = enabledFeatures => (
  hasStemSupport(enabledFeatures)
    && !hasWritingSupport(enabledFeatures)
);
export const hasWritingOnly = enabledFeatures => (
  hasWritingSupport(enabledFeatures)
    && !hasStemSupport(enabledFeatures)
);
