import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalBase from 'components/Common/Modal';
import {
  repostQuestion,
} from '../../../../../actions/chat.action';

class InfoModal extends React.Component {
  handleRepost = () => {
    logger.debug('on handleRepost');
    this.props.onReposting(this.props.question.qid).then((data) => {
      logger.debug('repost response', data);

      const { result, error } = data.payload;

      if (result) {
        this.props.onReposted({succeeded: true});
      }
      if (error) {
        this.props.onReposted({succeeded: false, errorMessage: error.data?.data});
      }
    });
  }

  handleClose = () => {
    logger.debug('on handleClose');
    this.props.onClose();
  }

  render() {
    const { modal } = this.props;

    if (modal.type === 'repost_modal') {
      return (
        <ModalBase
          headerText={modal.title}
          size="small"
          closable={true}
          onHide={this.handleClose}
          body={(
            <p className="u-marginBottomNone">{modal.body}</p>
          )}
          primaryButtonText={modal.button_text}
          onClickPrimaryButton={this.handleRepost}
        />
      );
    }

    if (modal.type === 'info_modal') {
      return (
        <ModalBase
          headerText={modal.title}
          size="small"
          closable={true}
          onHide={this.handleClose}
          body={(
            <>
              <p>{modal.body}</p>
              <img className="u-widthFull" alt="" src={modal.image_url} />
            </>
          )}
          showFooter={false}
        />
      );
    }

    return null;
  }
}

InfoModal.propTypes = {
  question: PropTypes.shape().isRequired,
  modal: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onReposting: PropTypes.func.isRequired,
  onReposted: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onReposting: qid => dispatch(repostQuestion(qid)),
});

export default connect(null, mapDispatchToProps)(InfoModal);
