import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SafeAnchor } from '@ahaui/react';

import ModalBase from 'components/Common/Modal';
import {
  forceLogout,
  acceptedTOS,
} from 'actions/user.action'


class TOSModal extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
    this.tos = null;
  }


  componentDidMount() {
    if (this.props.tos) {
      this.tos = this.props.tos;
      this.popUpModal();
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.tos !== this.props.tos) {
      if (nextProps.tos) {
        this.tos = nextProps.tos;
        this.popUpModal();
      }
    }
  }


  popUpModal = () => {
    logger.debug('showing the tos modal', this.tos);
    this.setState({ show: true });
  }


  handleForceLogOut = (e) => {
    logger.debug('on handleForceLogOut');
    e.preventDefault();
    this.setState({ show: false });
    this.props.forceLogout();
  }


  handleAcceptedTOS = async (e) => {
    logger.debug('on handleAcceptedTOS');
    e.preventDefault();

    const { payload: { result } } = await this.props.acceptedTOS(this.tos.id);
    if (result) {
      this.setState({ show: false });
    } else {
      this.props.ForceLogout();
    }
  }


  render() {
    if (this.state.show) {
      return (
        <ModalBase
          headerText="Updated agreement"
          size="medium"
          closable={false}
          showFooter={true}
          footerType="double"
          body={(
            <>
              <p className="u-marginBottomNone">
                We have made some changes to our&nbsp;
                <SafeAnchor href={this.tos.url}>Terms of Service.</SafeAnchor>
                <br />
                By clicking "I ACCEPT", you acknowledge that you have read and agreed to the changes.
              </p>
            </>
          )}
          primaryButtonText="I accept"
          secondaryButtonText="Not now"
          onClickPrimaryButton={this.handleAcceptedTOS}
          onClickSecondaryButton={this.handleForceLogOut}
        />
      );
    }

    return null;
  }
}

TOSModal.propTypes = {
  forceLogout: PropTypes.func.isRequired
};

const mapStateToProps = ({ user }) => ({
  tos: user.tos,
});

const mapDispatchToProps = {
  forceLogout,
  acceptedTOS,
}

export default connect(mapStateToProps, mapDispatchToProps)(TOSModal);
