import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from 'components/Common/Modal';

class RPRS extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired
  }

  handleClose = () => {
    this.props.onClose();
  }
  render() {
    return (
      <ModalBase
        headerText="Check your email!"
        size="small"
        onHide={this.handleClose}
        showFooter={false}
        body={(
          <>
            <img src={require('assets/images/icon-check-email.svg')} className="u-block u-marginHorizontalAuto u-marginBottomSmall" alt="" />
            <p className="u-marginBottomNone">To reset password, please open your email from PhotoStudy and follow the simple instruction. The link will be expired in <strong>24hrs</strong> starting from now.</p>
          </>
        )}
      />
    );
  }
}

export default RPRS;
