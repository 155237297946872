import { PROMISE_RUN } from '../constants/promise.constant';

import {
  QUESTION_LOAD_MY,
  QUESTION_UPDATED,

  QUESTIONS_PER_PAGE
} from '../constants/question.constant';

import defaultState from '../store/defaultState';

const historyReducer = (state = defaultState.myQuestions, action) => {
  if (action.type === PROMISE_RUN) {
    if (action.key === QUESTION_LOAD_MY) {
      let updatedState = {};
      let questions = action.payload.result;

      if (!Array.isArray(questions)) {
        return state;
      }

      if (questions.length > 0) {
        const newQids = questions.map(q => q.qid);
        const oldList = state.questions.reduce((l, q) => {
          if (!newQids.includes(q.qid)) {
            l.push(q);
          }
          return l;
        }, []);

        updatedState.questions = !action.oldestQid ? [...questions, ...oldList] : [...oldList, ...questions];
        if (action.oldestQid || !state.oldestQid) {
          updatedState.oldestQid = newQids[newQids.length - 1];
          updatedState.changes = defaultState.myQuestions.changes;
        }
      } else {
        updatedState.loadMore = false;
      }
      if (questions.length < QUESTIONS_PER_PAGE) {
        updatedState.loadMore = false;
      }

      const ret = { ...state, ...updatedState };
      return ret;
    }
  }

  if (action.type === QUESTION_UPDATED) {
    const { changes } = state;
    changes[action.payload.qid] = action.payload.update_type;
    return { ...state, changes };
  }
  return state;
};

export default historyReducer;
