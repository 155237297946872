import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalBase from 'components/Common/Modal';

class TriggerModal extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
    this.modal = null;
  }

  componentDidMount() {
    if (this.props.modal) {
      this.modal = this.props.modal;
      this.popUpModal();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.props.modal) {
      if (nextProps.modal) {
        this.modal = nextProps.modal;
        this.popUpModal();
      }
    }
  }

  popUpModal = () => {
    logger.debug('showing the reward modal', this.modal);
    this.setState({
      show: true,
    });
  }

  handleGotoAsk = (e) => {
    logger.debug('on handleGotoAsk');
    e.preventDefault();
    this.setState({
      show: false,
    });
    this.props.history.push('/');
  }

  handleClose = () => {
    logger.debug('on handleClose');
    this.setState({
      show: false,
    });
  }

  render() {
    if (!this.state.show) return null;
    const { modal } = this;

    if (modal.hasOwnProperty('type') && modal.type == 'survey') {
      const UID = this.props.user.uid;
      const surveyLink = `https://www.surveymonkey.com/r/${modal.hash}?QID=${modal.qid}&UID=${UID}`;

      return (
        <ModalBase
          size="large"
          footerType="single"
          onHide={this.handleClose}
          body={(
            <iframe className="u-widthFull u-marginTopSmall" style={{ height: 584 }} src={surveyLink} />
          )}
          primaryButtonText="Close Survey"
          onClickPrimaryButton={this.handleClose}
        />
      );
    }

    if (modal.hasOwnProperty('url') && modal.url.includes('/misc/rewardmodal')) {
      return (
        <ModalBase
          headerText="You've won a free session!"
          size="small"
          onHide={this.handleClose}
          body={(
            <>
              <p>
                <img src={require('assets/images/present.svg')} className="u-block u-marginHorizontalAuto u-maxWidthFull" alt="" />
              </p>
              <p className="u-marginBottomNone">
                Use your session for a FREE tutoring chat session today. Just click Ask Now.
              </p>
            </>
          )}
          footerType="single"
          primaryButtonText="Ask now"
          onClickPrimaryButton={this.handleGotoAsk}
        />
      );
    }

    if (modal.hasOwnProperty('url') && modal.url.includes('/misc/comebackrewardmodal')) {
      return (
        <ModalBase
          headerText="You've won a free session!"
          size="small"
          onHide={this.handleClose}
          body={(
            <>
              <p>
                <img src={require('assets/images/present.svg')} className="u-block u-marginHorizontalAuto u-maxWidthFull" alt="" />
              </p>
              <p className="u-marginBottomNone">
                It's on the way! Come back tomorrow to receive your FREE live Expert Session.
              </p>
            </>
          )}
          footerType="single"
          primaryButtonText="Got it"
          onClickPrimaryButton={this.handleClose}
        />
      );
    }

    if (modal.hasOwnProperty('url') && modal.url.includes('/misc/nlurewardmodal')) {
      return (
        <ModalBase
          headerText="You've won a free session!"
          size="small"
          onHide={this.handleClose}
          body={(
            <>
              <p>
                <img src={require('assets/images/present.svg')} className="u-block u-marginHorizontalAuto u-maxWidthFull" alt="" />
              </p>
              <p className="u-marginBottomNone">
                Great job! You've unlocked a FREE Live Expert Session. This has been added to your session balance.
              </p>
            </>
          )}
          footerType="single"
          primaryButtonText="Got it"
          onClickPrimaryButton={this.handleClose}
        />
      );
    }

    if (modal.hasOwnProperty('url') && modal.url.includes('/trio/welcome')) {
      return (
        <ModalBase
          headerText="Welcome to your Preset Account!"
          size="medium"
          onHide={this.handleClose}
          body={(
            <>
              <p>
                <img src={require('assets/images/icon-success.svg')} className="u-block u-marginHorizontalAuto u-maxWidthFull" alt="" />
              </p>
              <p className="u-marginBottomNone">
                Welcome to your Preset Account! use Preset Account in the future to sign in. Your tutoring sessions are now being paid for by your TRIO program.<br />
                <br />
                Get instant tutoring help!
              </p>
            </>
          )}
          footerType="single"
          primaryButtonText="Ask now"
          onClickPrimaryButton={this.handleGotoAsk}
        />
      );
    }

    if (modal.hasOwnProperty('url') && modal.url.includes('/trio/deleted')) {
      return (
        <ModalBase
          headerText="Your Preset Account has been closed"
          size="medium"
          onHide={this.handleClose}
          body={(
            <>
              <p>
                <img src={require('assets/images/closed.svg')} className="u-block u-marginHorizontalAuto u-maxWidthFull" alt="" />
              </p>
              <p className="u-marginBottomNone">
                Your Preset Account has been closed by your administrator. You will still be able to access your question history and all features of the app. Visit the store to get more sessions.
              </p>
            </>
          )}
          footerType="single"
          primaryButtonText="Got it"
          onClickPrimaryButton={this.handleClose}
        />
      );
    }
    return null;
  }
}

TriggerModal.propTypes = {
  history: PropTypes.shape().isRequired,
  modal: PropTypes.shape(),
  user: PropTypes.shape().isRequired,
};

TriggerModal.defaultProps = {
  modal: null,
};

const mapStateToProps = state => ({
  modal: state.surgeInfo.modal,
  user: state.user,
});

const mapDispatchToProps = null;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TriggerModal));
