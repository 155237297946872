import React from 'react';
import PropTypes from 'prop-types';

import {
  ERROR_CODE_DELETED_PROFILE,
  ERROR_CODE_DEVICE_CHECK,
  ERROR_CODE_PROHIBITED
} from '../../../../constants/error.constant';

import DeletedProfile from './errors/DeletedProfile';
import OneAccPerDevice from './errors/OneAccPerDevice';
import General from './errors/General';
import ServerMsg from './errors/ServerMsg';

class ErrorContainer extends React.Component {
  handleCloseError = () => {
    this.props.onClose();
  }
  render() {
    if (this.props.code === ERROR_CODE_DELETED_PROFILE) {
      return <DeletedProfile message={this.props.message} onClose={this.handleCloseError} />;
    }
    if (this.props.code === ERROR_CODE_DEVICE_CHECK) {
      return <OneAccPerDevice onClose={this.handleCloseError} />;
    }
    if (this.props.code === ERROR_CODE_PROHIBITED) {
      return <ServerMsg message={this.props.message} onClose={this.handleCloseError} />;
    }
    return <General onClose={this.handleCloseError} />;
  }
}

ErrorContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  code: PropTypes.string
};

ErrorContainer.defaultProps = {
  code: ''
};

export default ErrorContainer;
