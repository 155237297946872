import React from 'react';
import TopBanner from 'components/Common/TopBanner';

const inProgressMessage = 'Please wait until your current problem is ready before submitting another one. You can only submit one Writing and one STEM problem at a time.';

function InProgressWritingSessionBanner() {
  return (
    <TopBanner
      icon="informationCircle"
      title="Sorry, one problem at a time"
      body={inProgressMessage}
    />
  );
}

export { inProgressMessage };

export default InProgressWritingSessionBanner;
