import React from 'react';
import PropTypes from 'prop-types';

const InfoPackage = (props) => {
  let pkg = props.pkg;
  return (
    <div className="u-flex u-alignItemsCenter">
      <div className="u-flexGrow1">
        <h2 className="u-text200 u-fontMedium u-marginBottomTiny">{pkg.description}</h2>
        <p className="u-text200 u-textGray u-marginNone">{pkg.subdescription}</p>
      </div>
    </div>
  );
};

InfoPackage.propTypes = {
  pkg: PropTypes.shape().isRequired,
};

export default InfoPackage;
