const listeners = {};

const randomString = (length) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

const onLocalStorageChange = (key, callbackFn, onlyRemote = true) => {
  window.addEventListener('storage', (e) => {
    if (e.key === key) {
      callbackFn(e.newValue);
    }
  });
  if (!onlyRemote && callbackFn) {
    if (!Object.prototype.hasOwnProperty.call(listeners, key)) {
      listeners[key] = {};
    }
    const k = randomString(10);
    listeners[key][k] = callbackFn;
    return k;
  }
  return null;
};

const removeListener = (id) => {
  Object.entries(listeners).forEach(([key, chunkListeners]) => {
    if (Object.prototype.hasOwnProperty.call(chunkListeners, id)) {
      delete listeners[key][id];
    }
    if (Object.keys(listeners[key]).length === 0) {
      delete listeners[key];
    }
  });
};

const onChange = (key, value) => {
  if (Object.prototype.hasOwnProperty.call(listeners, key)) {
    Object.entries(listeners[key]).forEach(([id, callbackFn]) => callbackFn(value));
  }
};

class LocalStorageItemString {
  constructor(key) {
    this.key = key;
    this.callbackIds = [];
  }

  get = (defaultValue = null) => {
    const value = localStorage.getItem(this.key);
    return (value === null) ? defaultValue : value;
  }

  set = (value) => {
    if (value !== null) {
      localStorage.setItem(this.key, value);
      onChange(this.key, value);
    }
  }

  remove = () => localStorage.removeItem(this.key);

  addListener = (fn, onlyRemote = true) => {
    const callbackId = onLocalStorageChange(this.key, fn, onlyRemote);
    if (callbackId) {
      this.callbackIds.push(callbackId);
    }
  }

  removeAllListeners = () => this.callbackIds.forEach(id => removeListener(id));
}

class LocalStorageItemMix {
  constructor(key) {
    this.key = key;
    this.callbackIds = [];
  }

  get = (defaultValue = null) => {
    const value = localStorage.getItem(this.key);
    return (value === null) ? defaultValue : JSON.parse(value);
  }

  set = (value) => {
    if (value !== null) {
      localStorage.setItem(this.key, JSON.stringify(value));
      onChange(this.key, value);
    }
  }

  remove = () => localStorage.removeItem(this.key);

  addListener = (fn, onlyRemote = true) => {
    const callbackId = onLocalStorageChange(this.key,
      rawValue => fn(JSON.parse(rawValue)), onlyRemote);
    if (callbackId) {
      this.callbackIds.push(callbackId);
    }
  }

  removeAllListeners = () => this.callbackIds.forEach(id => removeListener(id));
}

class SessionStorageItemString {
  constructor(key) {
    this.key = key;
  }

  get = (defaultValue = null) => {
    const value = sessionStorage.getItem(this.key);
    return (value === null) ? defaultValue : value;
  }

  set = (value) => {
    if (value !== null) {
      sessionStorage.setItem(this.key, value);
    }
  }

  remove = () => sessionStorage.removeItem(this.key);
}

class SessionStorageItemMix {
  constructor(key) {
    this.key = key;
  }

  get = (defaultValue = null) => {
    const value = localStorage.getItem(this.key);
    return (value === null) ? defaultValue : JSON.parse(value);
  }

  set = (value) => {
    if (value !== null) {
      localStorage.setItem(this.key, JSON.stringify(value));
    }
  }

  remove = () => localStorage.removeItem(this.key);
}

export const getStore = (key, isString = false) => {
  if (!isString) {
    return new LocalStorageItemMix(key);
  }
  return new LocalStorageItemString(key);
};

export const getSessionStore = (key, isString = false) => {
  if (!isString) {
    return new SessionStorageItemMix(key);
  }
  return new SessionStorageItemString(key);
};

export default {
  getStore,
  getSessionStore,
};
