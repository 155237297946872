export const QUESTION_EXTENDED = 'question_extended';
export const QUESTION_EXTENDING = 'question_extending';
export const EXPERT_WAITING_SHOW = 'expert_waiting_show';
export const EXPERT_WAITING_HIDE = 'expert_wating_hide';
export const NEW_DISCUSSION_MESSAGE = 'new_discussion_message';
export const EDIT_DISCUSSION_MESSAGE = 'edit_discussion_message';
export const QUESTION_CHECK_OPEN = 'question_check_open';
export const QUESTION_OPEN_GET = 'question_open_get';
export const QUESTION_REPOST = 'question_repost';
export const QUESTION_FLAG = 'question_flag';
export const QUESTION_END = 'question_end';
export const QUESTION_RATE = 'question_rate';
export const CQ_MESSAGE_REPLY = 'cq_message_reply';
export const MESSAGE_SEND = 'message_send';
export const TIMEOUT_RESET = 'timeout_reset';
export const FIRST_REGULAR_ROUTED = 'first_regular_routed';
export const FIRST_REGULAR_CLAIMED = 'first_regular_claimed';
export const CHAT_RESET = 'chat_reset';
export const REGULAR_CLAIM_MSG_UPDATE = 'regular_claim_msg_update';
export const FLAG_MODAL_SHOW = 'flag_modal_show';
export const FLAG_MODAL_HIDE = 'flag_modal_hide';
