import React from "react";
import YouTube from "react-youtube";

function Video({ videoId, onView }) {
  return (
    <div className="video-section">
      <div className="Grid sm:Grid-withoutGutter">
        <div className="u-marginHorizontalAuto u-marginTopExtraLarge u-sizeFull sm:u-size10of12 u-textCenter ">
          <h1 className="sm:u-text600  u-text500 u-fontMedium u-textCenter">
            If you need extra help with the installation process, you can watch
            the following video tutorial.
          </h1>
        </div>
        <div className="video-wrapper  u-marginHorizontalAuto sm:u-marginTopLarge u-marginTopLarge  sm:u-size10of12 u-sizeFull">
          <YouTube
            className={"youtube-iframe-wrap"}
            videoId={videoId}
            id={videoId}
            onPlay={onView}
            iframeClassName="youtube-iframe"
          />
        </div>
        <p className="u-textCenter video-content u-marginHorizontalAuto u-marginTopLarge u-textNeutral800 sm:u-marginTopLarge sm:u-marginBottomExtraLarge sm:u-paddingHorizontalExtraLarge u-widthFull u-paddingHorizontalMedium u-marginBottomLarge">
          Simply follow the instructions and you should be ready to go within a
          couple of minutes. We’re very grateful for your participation and
          would like to thank you again for your support!
        </p>
      </div>
    </div>
  );
}

export default Video;
