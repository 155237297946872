import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ahaui/react';
import ModalBase from 'components/Common/Modal';
import Tracking from '../../../../utils/tracking';

class AuthSupportModal extends React.Component {

  handleOpenSupportGeneral = (e) => {
    e.preventDefault();
    const subject = 'PhotoStudy Feedback';
    const { info } = this.props;
    info.subject = subject;
    this.context.openSupport(info);
    Tracking.sendFeedback({ source: 'landing' });
    this.props.onClose();
  }

  handleOpenSupportAuth = (e) => {
    e.preventDefault();
    const subject = 'PhotoStudy Login/Signup Help';
    const { info } = this.props;
    info.subject = subject;
    this.context.openSupport(info);
    Tracking.sendFeedback({ source: 'landing' });
    this.props.onClose();
  }

  handleClose = () => {
    this.props.onClose();
  }

  render() {
    return (
      <ModalBase
        headerText="Contact Support"
        size="small"
        onHide={this.handleClose}
        showFooter={false}
        body={(
          <>
            <p className="">How can we help you?</p>
            <Button
              variant="secondary"
              width="full"
              className="u-marginBottomSmall"
              onClick={this.handleOpenSupportAuth}
            >
              <Button.Label>Login Issues</Button.Label>
            </Button>
            <Button
              variant="secondary"
              width="full"
              onClick={this.handleOpenSupportGeneral}
            >
              <Button.Label>Other</Button.Label>
            </Button>
          </>
        )}
      />
    );
  }
}

AuthSupportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  info: PropTypes.shape().isRequired,
};

AuthSupportModal.defaultProps = {
  info: {}
};

AuthSupportModal.contextTypes = {
  openSupport: PropTypes.func.isRequired
};

export default AuthSupportModal;
