import React from 'react';
import { Link } from 'react-router-dom';
import {
  PageLayout,
  Button,
  Icon,
} from '@ahaui/react';

export default class Maintain extends React.Component {
  render() {
    return (
      <PageLayout
        className="u-backgroundLightest u-overflowVerticalAuto u-webkitScrollbar"
        style={{
          height: '100vh',
        }}
      >
        <PageLayout.Header>
          <div className="u-flex u-justifyContentCenter u-paddingVerticalExtraLarge">
            <img
              src={require('assets/images/logo.svg')}
              alt="PhotoStudy logo"
              style={{ height: 40 }}
            />
          </div>
        </PageLayout.Header>
        <PageLayout.Body className="">
          <div className="Container">
            <section
              className="u-block u-marginHorizontalAuto u-border u-paddingHorizontalMedium u-paddingVerticalLarge u-backgroundWhite u-roundedMedium"
              style={{ maxWidth: 600 }}
            >
              {/* header: */}
              <div className="u-flex u-justifyContentCenter u-positionRelative u-marginBottomLarge">
                <h3 className="u-marginNone u-textCenter">Oops not found!</h3>
              </div>
              {/* body: */}
              <div className="u-textCenter">
                <div className="u-marginBottomMedium">
                  <img
                    src={require('assets/images/icon-404.svg')}
                    alt="Page not found"
                    className="u-block u-maxWidthFull u-marginHorizontalAuto"
                  />
                </div>
                <Link to="/">
                  <Button width="full">
                    <Button.Label className="u-fontMedium">
                      BACK TO HOME
                    </Button.Label>
                    <Button.Icon>
                      <Icon name="airplane" />
                    </Button.Icon>
                  </Button>
                </Link>
              </div>
            </section>
          </div>
        </PageLayout.Body>
      </PageLayout>
    );
  }
}
