import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { PageLayout, Button, SafeAnchor, Form, Icon } from '@ahaui/react';

import {
  resetPassword,
  checkPasswordRecoveryCode,
  requestPasswordRecovery
} from '../../actions/user.action';

import Loading from 'components/Common/Loading';
import SupportModal from '../Landing/Account/modals/Support';

class ResetPasswordComp extends React.Component {
  constructor(props) {
    super(props);

    this.STATUS_LOADING = 'loading';
    this.STATUS_EXPIRED = 'expired';
    this.STATUS_WRONG_CODE = 'wrong_code';
    this.STATUS_RESET = 'reset';
    this.STATUS_RESET_SUCCESS = 'reset_success';
    this.STATUS_REQUEST_SUCCESS = 'request_success';

    this.code = this.props.match.params.code;
    this.email = '';
    this.info = {};
    const initStatus = this.code ? this.STATUS_LOADING : this.STATUS_WRONG_CODE;

    this.state = {
      status: initStatus,
      password: '',
      errorMessage: '',
      openSupportModal: false
    };
  }

  componentDidMount() {
    this.props.doCheckPasswordRecoveryCode(this.code).then(
      (data) => {
        const { result, error } = data.payload;
        if (result) {
          this.email = result.email;
          this.setState({ status: this.STATUS_RESET });
        } else if (error && error.data) {
          this.info = error.data.info || {};
          if ((error.data.info && error.data.info.email)) {
            this.email = error.data.info.email;
          }
          if (error.data.info && error.data.info.error_code && error.data.info.error_code === 'expired') {
            this.setState({ status: this.STATUS_EXPIRED });
          } else {
            this.setState({ status: this.STATUS_WRONG_CODE });
          }
        }
      }
    );
  }

  getExpiredScreen = () => (
    <section
      className="u-block u-marginHorizontalAuto u-border u-paddingHorizontalMedium u-paddingVerticalLarge u-backgroundWhite u-roundedMedium"
      style={{ maxWidth: 600 }}
    >
      {/* header: */}
      <div className="u-flex u-justifyContentCenter u-positionRelative u-marginBottomLarge">
        <h3 className="u-marginNone">Oops! This link is expired</h3>
      </div>
      {/* body: */}
      <div className="u-textCenter">
        <img src={require('assets/images/icon-expired.svg')} className="u-block u-marginHorizontalAuto u-marginBottomLarge" alt="" />
        <p>You can reset your password by requesting another link to your email</p>
      </div>
      {/* footer: */}
      <div className="u-paddingTopExtraSmall">
        <Button width="full" onClick={this.handleRequestChangePassword}>
          <Button.Label>REQUEST ANOTHER LINK</Button.Label>
        </Button>
        <div className="u-textCenter">
          <div className="u-text200 u-textGray u-marginTopSmall">
            Having trouble resetting password?
            {` `}
            <SafeAnchor onClick={this.handleClickSupport}>
              Contact support
            </SafeAnchor>
            {`.`}
          </div>
        </div>
      </div>
    </section>
  );

  getChangePasswordSuccessScreen = () => (
    <section
      className="u-block u-marginHorizontalAuto u-border u-paddingHorizontalMedium u-paddingVerticalLarge u-backgroundWhite u-roundedMedium"
      style={{ maxWidth: 600 }}
    >
      {/* header: */}
      <div className="u-flex u-justifyContentCenter u-positionRelative u-marginBottomLarge">
        <h3 className="u-marginNone">Password reset successful</h3>
      </div>
      {/* body: */}
      <div className="u-textCenter">
        <img src={require('assets/images/icon-success.svg')} className="u-block u-marginHorizontalAuto u-marginBottomLarge" alt="" />
        <p>You have been successfully reset your password. Please use your new password when logging in.</p>
      </div>
      <div className="u-paddingTopExtraSmall">
        <Button width="full" onClick={this.handleBackToHome}>
          <Button.Label>Back to home</Button.Label>
          <Button.Icon><Icon name="airplane" /></Button.Icon>
        </Button>
      </div>
    </section>
  );

  getRequestPasswordRecoverySuccessScreen = () => (
    <section
      className="u-block u-marginHorizontalAuto u-border u-paddingHorizontalMedium u-paddingVerticalLarge u-backgroundWhite u-roundedMedium"
      style={{ maxWidth: 600 }}
    >
      {/* header: */}
      <div className="u-flex u-justifyContentCenter u-positionRelative u-marginBottomLarge">
        <h3 className="u-marginNone">Link requested successfully</h3>
      </div>
      {/* body: */}
      <div className="u-textCenter">
        <img src={require('assets/images/icon-success.svg')} className="u-block u-marginHorizontalAuto u-marginBottomLarge" alt="" />
        <p>Link requested successfully, please check your email</p>
      </div>
      <div className="u-paddingTopExtraSmall">
        <Button width="full" onClick={this.handleBackToHome}>
          <Button.Label>Back to home</Button.Label>
          <Button.Icon><Icon name="airplane" /></Button.Icon>
        </Button>
      </div>
    </section>
  );

  getNewPasswordForm = () => {
    const { password, errorMessage } = this.state;
    return (
      <section
        className="u-block u-marginHorizontalAuto u-border u-paddingHorizontalMedium u-paddingVerticalLarge u-backgroundWhite u-roundedMedium"
        style={{ maxWidth: 600 }}
      >
        {/* header: */}
        <div className="u-flex u-justifyContentCenter u-positionRelative u-marginBottomLarge">
          <h3 className="u-marginNone">Enter your new password</h3>
        </div>
        <Form onSubmit={this.handleChangePassword}>
          {/* body: */}
          <div className="">
            <Form.Group>
              <Form.Input
                isInvalid={!!errorMessage}
                type="password"
                placeholder="New Password"
                value={password}
                onChange={this.handleEnterPassword}
              />
              <Form.Feedback type="invalid" visible={!!errorMessage}>
                {errorMessage}
              </Form.Feedback>
            </Form.Group>
          </div>
          {/* footer: */}
          <div className="u-paddingTopExtraSmall">
            <Button
              width="full"
              onClick={this.handleChangePassword}
              disabled={!password}
            >
              <Button.Label>Continue</Button.Label>
            </Button>
          </div>
        </Form>
      </section>
    );
  }

  handleEnterPassword = (e) => {
    const password = e.target.value;
    // const errorMessage = this.isInvalidPassword(password);
    this.setState({ password, errorMessage: '' });
  }

  handleChangePassword = (e) => {
    e.preventDefault();
    const { password } = this.state;
    if (!password) {
      return false;
    }
    let errorMessage = this.isInvalidPassword(password);
    if (errorMessage) {
      this.setState({ errorMessage });
    } else {
      this.props.doResetPassword(this.code, password).then(
        (data) => {
          const { result, error } = data.payload;
          if (result) {
            this.setState({ status: this.STATUS_RESET_SUCCESS });
          } else if (error && error.data) {
            errorMessage = error.data.data || '';
            this.setState({ errorMessage });
          }
        }
      );
    }
  }

  handleRequestChangePassword = () => {
    if (this.email) {
      this.props.doRequestPasswordRecovery(this.email).then(
        (data) => {
          const { result } = data.payload;
          if (result) {
            this.setState({ status: this.STATUS_REQUEST_SUCCESS });
          }
        }
      );
    }
  }

  handleClickSupport = (e) => {
    e.preventDefault();
    this.setState({ openSupportModal: true });
  }

  handleCloseSupport = () => {
    this.setState({ openSupportModal: false });
  }

  isInvalidPassword = (password) => ((password.length < 8) ? 'Oops! Password must be at least 8 characters.' : '');

  handleBackToHome = () => {
    const { history } = this.props;
    history.push('/');
  }

  render() {
    const { status } = this.state;
    let content;
    if (status === this.STATUS_LOADING) {
      return <Loading />;
    } else if (status === this.STATUS_WRONG_CODE) {
      return <Redirect to="/not-found" />;
    } else if (status === this.STATUS_EXPIRED) {
      content = this.getExpiredScreen();
    } else if (status === this.STATUS_RESET_SUCCESS) {
      content = this.getChangePasswordSuccessScreen();
    } else if (status === this.STATUS_REQUEST_SUCCESS) {
      content = this.getRequestPasswordRecoverySuccessScreen();
    } else {
      content = this.getNewPasswordForm();
    }

    return (
      <PageLayout
        className="u-backgroundLightest"
        style={{
          minHeight: '100vh'
        }}
      >
        <PageLayout.Header>
          <div className="u-flex u-justifyContentCenter u-paddingVerticalExtraLarge">
            <img
              src={require('assets/images/logo.svg')}
              alt="PhotoStudy logo"
              style={{ height: 40 }}
            />
          </div>
        </PageLayout.Header>
        <PageLayout.Body className="u-flexColumn u-paddingBottomLarge">
          <div className="Container">
            {content}
          </div>

          {this.state.openSupportModal && (
            <SupportModal
              info={this.info}
              onClose={this.handleCloseSupport}
            />
          )}
        </PageLayout.Body>
      </PageLayout>
    );
  }
}

ResetPasswordComp.propTypes = {
  doResetPassword: PropTypes.func.isRequired,
  doCheckPasswordRecoveryCode: PropTypes.func.isRequired,
  doRequestPasswordRecovery: PropTypes.func.isRequired,
};

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
  doCheckPasswordRecoveryCode: code => dispatch(checkPasswordRecoveryCode(code)),
  doResetPassword: (code, password) => dispatch(resetPassword(code, password)),
  doRequestPasswordRecovery: (email) => dispatch(requestPasswordRecovery(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPasswordComp));
