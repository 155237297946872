import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PageLayout } from '@ahaui/react';

import { checkStatus } from '../../actions/app.action';

class Maintain extends React.Component {
  componentDidMount() {
    this.intId =
      setInterval(
        this.props.checkStatus,
        5000
      );
  }

  componentWillUnmount() {
    if (this.intId) {
      clearInterval(this.intId);
    }
  }

  intId = null;

  render() {
    return (
      <PageLayout
        className="u-backgroundLightest u-overflowVerticalAuto u-webkitScrollbar"
        style={{
          height: '100vh',
        }}
      >
        <PageLayout.Header>
          <div className="u-flex u-justifyContentCenter u-paddingVerticalExtraLarge">
            <img
              src={require('assets/images/logo.svg')}
              alt="PhotoStudy logo"
              style={{ height: 40 }}
            />
          </div>
        </PageLayout.Header>
        <PageLayout.Body className="">
          <div className="Container">
            <section
              className="u-block u-marginHorizontalAuto u-border u-paddingHorizontalMedium u-paddingVerticalLarge u-backgroundWhite u-roundedMedium"
              style={{ maxWidth: 600 }}
            >
              {/* header: */}
              <div className="u-flex u-justifyContentCenter u-positionRelative u-marginBottomLarge">
                <h3 className="u-marginNone u-textCenter">Maintenance Time</h3>
              </div>
              {/* body: */}
              <div className="u-textCenter">
                <div className="u-marginBottomMedium">
                  <img
                    src={require('assets/images/icon-maintenance.svg')}
                    alt="Page not found"
                    className="u-block u-maxWidthFull u-marginHorizontalAuto"
                  />
                </div>
                <p className="u-marginBottomNone">Be patient! We will come back ASAP</p>
              </div>
            </section>
          </div>
        </PageLayout.Body>
      </PageLayout>
    );
  }
}

Maintain.propTypes = {
  checkStatus: PropTypes.func.isRequired
};

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
  checkStatus: () => dispatch(checkStatus())
});

export default connect(mapStateToProps, mapDispatchToProps)(Maintain);
