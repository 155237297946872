import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  PageLayout,
  Button,
  Icon,
  Overlay,
  Tooltip,
} from '@ahaui/react';

import { isMobile, getAppPlatformCode } from 'utils/app';
import { PLATFORM_ANDROID } from 'constants/app.constant';

class ExpiredSession extends React.Component {
  constructor() {
    super();
    this.state = {
      showClipboardTxt: false,
    };
    this.shareButtonRef = React.createRef();
  }

  handleShareBtnClicked = (e) => {
    logger.debug('on handleShareBtnClicked');
    let mobilePlatform = isMobile();
    if (mobilePlatform) {
      this.handleOpenMessager();
    } else {
      this.handleCopy();
    }
  }

  handleOpenMessager = () => {
    logger.debug('on handleOpenMessager');
    let shortenedLink = this.props.match.params.shortenedLink;
    let platform = getAppPlatformCode();
    if (platform === PLATFORM_ANDROID) {
      window.open(`sms://?body=Hey.%20view%20the%20full%20solution%20to%20my%20problem%20here.%20https%3A%2F%2Fgot-it.ai/${shortenedLink}`);
    } else {
      window.open(`sms://&body=Hey.%20view%20the%20full%20solution%20to%20my%20problem%20here.%20https%3A%2F%2Fgot-it.ai/${shortenedLink}`);
    }
  }


  handleCopy = (e) => {
    logger.debug('on handleCopy');
    this.setState({ showClipboardTxt: true });
    this.copyURLToClipboard();
    setTimeout(() => {
      this.setState({ showClipboardTxt: false });
    }, 2000);
  }

  copyURLToClipboard = () => {
    let text = window.location.href;
    var textArea = document.createElement("textarea");
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';


    textArea.value = text;

    document.body.appendChild(textArea);

    textArea.select();

    var successful = false;
    try {
        successful = document.execCommand('copy');
    } catch (err) {
        successful = false;
    }

    document.body.removeChild(textArea);
    return successful;
}

  render () {
    const { showClipboardTxt } = this.state;

    return (
      <PageLayout
        className="u-backgroundLightest u-overflowVerticalAuto u-webkitScrollbar"
        style={{
          height: '100vh',
        }}
      >
        <PageLayout.Header>
          <div className="u-flex u-justifyContentCenter u-paddingVerticalExtraLarge">
            <img
              src={require('assets/images/logo.svg')}
              alt="PhotoStudy logo"
              style={{ height: 40 }}
            />
          </div>
        </PageLayout.Header>
        <PageLayout.Body className="">
          <div className="Container">
            <section
              className="u-block u-marginHorizontalAuto u-border u-paddingHorizontalMedium u-paddingVerticalLarge u-backgroundWhite u-roundedMedium"
              style={{ maxWidth: 600 }}
            >
              {/* header: */}
              <div className="u-flex u-justifyContentCenter u-positionRelative u-marginBottomLarge">
                <h3 className="u-marginNone u-textCenter">Oops! Your timer is up!</h3>
              </div>
              {/* body: */}
              <div className="u-textCenter">
                <div className="u-marginBottomSmall">
                  <img
                    src={require('assets/images/clock.svg')}
                    alt=""
                    className="u-block u-maxWidthFull u-marginHorizontalAuto"
                  />
                  <div className="u-marginTopMedium">You can still share this solution with a friend</div>
                </div>

                <Button width="full" ref={this.shareButtonRef} onClick={this.handleShareBtnClicked}>
                  <Button.Label className="u-fontMedium">
                    Share with a friend
                  </Button.Label>
                  <Button.Icon>
                    <Icon name="share" />
                  </Button.Icon>
                </Button>
                <Overlay target={this.shareButtonRef.current} show={showClipboardTxt} placement="top">
                  {props => (
                    <Tooltip {...props}>
                      Copied to clipboard
                    </Tooltip>
                  )}
                </Overlay>

                <div className="u-widthFull u-flex u-justifyContentBetween u-alignItemsCenter">
                  <hr className="u-flexGrow-1" />
                  <small className="u-marginHorizontalExtraSmall u-textLight u-fontMedium">Or</small>
                  <hr className="u-flexGrow-1" />
                </div>

                <Link to="/">
                  <Button width="full" variant="secondary">
                    <Button.Label className="u-fontMedium">
                      Ask your own question
                    </Button.Label>
                  </Button>
                </Link>
              </div>
            </section>
          </div>
        </PageLayout.Body>
      </PageLayout>
    );
  }
}

export default withRouter(ExpiredSession);
