import React from 'react';
import PropTypes from 'prop-types';
import Cropper from 'cropperjs';

class ImageCropWrapper extends React.Component {
  componentDidMount() {
    this.cropper = new Cropper(this.img, {
      viewMode: 1,
      checkCrossOrigin: true,
      ready: this.onCropperReady,
      zoomOnWheel: false,
      zoom: this.onZoom,
      dragMode: 'move',
    });
    this.ready = false;
    this.originalImage = {
      width: 0,
      height: 0,
    };
    this.preZoomRatio = 1.0;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.onReadyStateChange(false);
      this.cropper.reset().clear().replace(nextProps.src);
    }
  }

  componentWillUnmount() {
    if (this.img) {
      this.cropper.destroy();
      delete this.img;
      delete this.cropper;
    }
  }

  onZoom = (e) => {
    logger.debug('old ratio', e.detail.oldRatio);
    logger.debug('ratio', e.detail.ratio);
  }

  onCropperReady = () => {
    this.onReadyStateChange(true);
    const canvasData = this.cropper.getImageData();
    this.originalImage.width = canvasData.width;
    this.originalImage.height = canvasData.height;
  }

  onReadyStateChange = (ready) => {
    this.ready = ready;
    this.props.onReadyStateChange(ready);
  }

  getCroppedImage = () => (
    this.cropper.getCroppedCanvas().toDataURL()
  );

  getCroppedBox = () => (
    this.cropper.getData()
  );

  rotate1 = (degree) => {
    if (!this.ready) {
      return;
    }
    this.cropper.rotate(degree);
  }

  rotate = (degree) => {
    logger.debug('on rotate image');
    if (!this.ready) {
      return;
    }
    // Get offset of cropbox with canvas before rotate.
    const canvasData = this.cropper.getCanvasData();
    const cropboxData = this.cropper.getCropBoxData();
    const topOffSetCropWithCanvas = cropboxData.top - canvasData.top;
    const leftOffSetCropWithCanvas = cropboxData.left - canvasData.left;

    // Clear the cropbox and rotate.
    this.cropper.clear();
    this.cropper.rotate(degree);

    // The code to resize the image to reset and fit the image to container everytime rotate.
    /*
    // Get image position before reset.
    let currentRotateDegree = cropper.getImageData().rotate;
    logger.debug('currentRotateDegree', currentRotateDegree);
    cropper.reset();
    logger.debug('reseted the image');
    cropper.rotate(currentRotateDegree + degree);

    let container = cropper.getContainerData();
    logger.debug('container', container);
    // Get image after rotated and scale it.
    let image = cropper.getImageData();
    logger.debug('image', image);
    let imageRotated = image.rotate;
    let imageNaturalWidth = image.naturalWidth;
    let imageNaturalHeigth = image.naturalHeight;
    let biggerD = imageNaturalHeigth > imageNaturalWidth ? ImageCropWrapper.HEIGHT_D : ImageCropWrapper.WIDTH_D;
    if (biggerD === ImageCropWrapper.WIDTH_D) {
      logger.debug('bigger one is WIDTH');
      if (imageRotated === 90 || imageRotated === 270) {
        logger.debug('image width', image.width);
        let scaleRate = container.height / image.width;
        logger.debug('scale with rate ', scaleRate);
        cropper.scale(scaleRate);
      }
    }
    if (biggerD === ImageCropWrapper.HEIGHT_D) {
      logger.debug('bigger one is HEIGTH');
      if (imageRotated === 0 || imageRotated === 180) {
        logger.debug('image height', image.height);
        let scaleRate = container.width / image.height;
        logger.debug('scale with rate', scaleRate);
        cropper.scale(scaleRate);
      }
    }
    */

    // Initial the crop box.
    this.cropper.crop();

    // Resize the cropbox to the position relative with canvas before rotate
    /**
     * Set the top-left conner as the fixed point.
     * Everytime we rotate 90 dregree in reverse-clock way
     * newTopOffset = the top offset of current canvas + the offset between the crop and canvas we caculated before.
     * newLeftOffset = the current width image - (size of cropbox heigth + the top offset between the crop and canvas
     *  we caculated before) + the addtion left offset of current canvas.
     */
    const image = this.cropper.getImageData();
    let currentWidth;
    if (image.rotate === 90 || image.rotate === 270) {
      currentWidth = image.height;
    } else {
      currentWidth = image.width;
    }
    const currentCanvasData = this.cropper.getCanvasData();
    const newLeftOffSet = currentCanvasData.top + leftOffSetCropWithCanvas;
    this.cropper.setCropBoxData({
      top: newLeftOffSet,
      left: (currentWidth - (topOffSetCropWithCanvas + cropboxData.height)) + currentCanvasData.left,
      width: cropboxData.height,
      height: cropboxData.width,
    });
  }

  reset = () => {
    if (!this.ready) {
      return;
    }
    this.cropper.reset();
    logger.debug('on reset image');
  }

  zoom = (ratio) => {
    logger.debug('on zoomTo', ratio);
    // Caculate the new canvas size.
    const image = this.cropper.getImageData();
    const rotateDegree = image.rotate;
    let newCanvasWidth;
    let newCanvasHeight;
    if (rotateDegree === 90 || rotateDegree === 270) {
      newCanvasWidth = this.originalImage.height * ratio;
      newCanvasHeight = this.originalImage.width * ratio;
    } else {
      newCanvasWidth = this.originalImage.width * ratio;
      newCanvasHeight = this.originalImage.height * ratio;
    }

    // Caculate the new canvas offset.
    const curretCanvas = this.cropper.getCanvasData();
    const newCanvasTop = curretCanvas.top + ((curretCanvas.height - newCanvasHeight) / 2);
    const newCanvasLeft = curretCanvas.left + ((curretCanvas.width - newCanvasWidth) / 2);
    logger.debug(curretCanvas.left, ((curretCanvas.width - newCanvasWidth) / 2));

    // Zooming the cropBox before the canvas.
    const canvasData = this.cropper.getCanvasData();
    const cropboxData = this.cropper.getCropBoxData();
    const topOffSetCropWithCanvas = cropboxData.top - canvasData.top;
    const leftOffSetCropWithCanvas = cropboxData.left - canvasData.left;
    const newTopOffSetCropWithCanvas = (topOffSetCropWithCanvas / this.preZoomRatio) * ratio;
    const newLeftOffSetCropWithCanvas = (leftOffSetCropWithCanvas / this.preZoomRatio) * ratio;
    logger.debug('newLeftOffSetCropWithCanvas', newLeftOffSetCropWithCanvas);
    const newCropBoxTop = newTopOffSetCropWithCanvas + newCanvasTop;
    const newCropBoxLeft = newLeftOffSetCropWithCanvas + newCanvasLeft;
    const newCropBoxWidth = (cropboxData.width / this.preZoomRatio) * ratio;
    const newCropBoxHeight = (cropboxData.height / this.preZoomRatio) * ratio;
    logger.debug('newCropBoxLeft', newCropBoxLeft);

    logger.debug('currentCropBoxData', cropboxData);
    logger.debug('newCropBoxData', {
      left: newCropBoxLeft,
      top: newCropBoxTop,
      width: newCropBoxWidth,
      height: newCropBoxHeight,
    });
    this.preZoomRatio = ratio;

    if (ratio > 1) {
      this.cropper.setCanvasData({
        top: newCanvasTop,
        left: newCanvasLeft,
        width: newCanvasWidth,
        height: newCanvasHeight,
      });
      this.cropper.setCropBoxData({
        top: newCropBoxTop,
        left: newCropBoxLeft,
        width: newCropBoxWidth,
        height: newCropBoxHeight,
      });
    } else {
      this.cropper.setCropBoxData({
        top: newCropBoxTop,
        left: newCropBoxLeft,
        width: newCropBoxWidth,
        height: newCropBoxHeight,
      });
      this.cropper.setCanvasData({
        top: newCanvasTop,
        left: newCanvasLeft,
        width: newCanvasWidth,
        height: newCanvasHeight,
      });
    }
  }

  render() {
    const { src } = this.props;
    return (
      <img className="u-widthFull" alt="" ref={(img) => { this.img = img; }} src={src} crossOrigin="Anonymous" />
    );
  }
}

ImageCropWrapper.propTypes = {
  src: PropTypes.string.isRequired,
  onReadyStateChange: PropTypes.func.isRequired,
};

ImageCropWrapper.WIDTH_D = 'width_d';
ImageCropWrapper.HEIGHT_D = 'height_d';


export default ImageCropWrapper;
