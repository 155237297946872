import config from 'configuration';
import { run } from './promise';
import { get, post } from '../utils/request';

import { MULTIPART_FORMDATA, APPLICATION_JSON } from '../constants/common.constant';
import {
  GET_ASKER,
  ENGLISH_QUESTION_SUBMIT,
  GET_ENGLISH_QUESTION,
  ENGLISH_RATING,
} from '../constants/english.constant';

export const submitQuestion = data => run(
  ENGLISH_QUESTION_SUBMIT,
  post(`${config.ENGLISH_API_HOST}/es/askers/me/problems`, data, MULTIPART_FORMDATA, true, true)
);

export const getAsker = () => run(
  GET_ASKER,
  get(`${config.ENGLISH_API_HOST}/es/askers/me`, null, true, true)
);

export const getEnglishQuestion = token => run(
  GET_ENGLISH_QUESTION,
  get(`${config.ENGLISH_API_HOST}/es/askers/me/problem?token=${token}`, null, true, true)
);

export const rate = data => run(
  ENGLISH_RATING,
  post(`${config.ENGLISH_API_HOST}/es/problems/rates`, data, APPLICATION_JSON, true, true)
);
