import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { SafeAnchor, Icon, Avatar, Progress, Button } from '@ahaui/react';

import ts from 'utils/timestamp';
import { resetTimeOut } from 'actions/chat.action';
import {
  questionStateConsts,
  discussionStateConsts,
  questionPendingReview,
} from 'constants/common.constant';
import expertAvatar from 'assets/images/avatar-default-expert-3.svg'

export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeConsumed: 0,
      timeRemain: 0,
      expertInfoShown: false,
    };
    this.mounted = true;
    this.progessTimerId = 0;
    this.duration = 0;
    this.endtime = 0;
    this.expertInfoShowTimerId = 0;
  }

  componentDidMount() {
    const { question } = this.props;
    this.duration = question.discussion_time_limit;
    this.endtime = question.discussion_end_time;
    this.updateProgess();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.question.discussion_end_time !== this.props.question.discussion_end_time) {
      this.endtime = nextProps.question.discussion_end_time;
      this.updateProgess();
    }
    if (nextProps.question.discussion_time_limit !== this.props.question.discussion_time_limit) {
      this.duration = nextProps.question.discussion_time_limit;
      this.updateProgess();
    }
    if (nextProps.expertInfo !== this.props.expertInfo) {
      if (nextProps.expertInfo) {
        this.setState({ expertInfoShown: true });
        this.expertInfoShowTimerId = window.setTimeout(() => {
          if (this.mounted) {
            this.setState({ expertInfoShown: false });
          }
          this.expertInfoShowTimerId = 0;
        }, 5 * 1000);
      }
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.progessTimerId);
    if (this.expertInfoShowTimerId) {
      window.clearTimeout(this.expertInfoShowTimerId);
    }
    this.mounted = false;
  }

  updateProgess = () => {
    const timeRemain = this.endtime - ts.now();
    let timeConsumed = Math.floor((this.duration - timeRemain) * 100 / this.duration);
    timeConsumed = timeConsumed <= 100 ? timeConsumed : 100;
    if (this.mounted) {
      this.setState({
        timeRemain,
        timeConsumed,
      });
    }
    if (timeConsumed < 100) {
      this.progessTimerId = window.setTimeout(this.updateProgess, 3000);
    }
  }

  handleShowFlagModal = (e) => {
    logger.debug('on handleShowFlagModal');
    e.preventDefault();
    const { qid } = this.props.question;
    this.props.onResetingTimeOut(qid);
    this.props.onShowFlagModal();
  }

  handleHideExpertInfo = (e) => {
    logger.debug('on handleHideExpertInfo');
    e.preventDefault();
    this.setState({
      expertInfoShown: false,
    });
  }

  handleGoback = (e) => {
    logger.debug('handlegoBack');
    e.preventDefault();
    this.props.onGoBack();
  }

  render() {
    const { question, expertInfo } = this.props;
    const { expertInfoShown, timeRemain, timeConsumed } = this.state;

    const canGoBack =
      question.processing_status == questionStateConsts.FLAGGED ||
      question.processing_status == questionStateConsts.EXPLAINED ||
      question.pending_review === questionPendingReview.DEAD ||
      (question.discussion_status == discussionStateConsts.EXPIRED && question.processing_status == questionStateConsts.INPROGRESS) ||
      question.discussion_status == discussionStateConsts.STUDENT_ENDED;

    const isDiscussionInProgress = question.discussion_status == discussionStateConsts.STARTED;

    // Time left.
    // +1 delta time.
    const timeRemainDuration = moment.duration(timeRemain, 's').minutes();
    const timeLeft =
      timeRemainDuration === 0 ? 'About 1 minute left' :
      timeRemainDuration === 1 ? `${timeRemainDuration} minute left` :
      timeRemainDuration > 1 ? `${timeRemainDuration} minutes left` :
      '';

    const progressVariant =
      timeConsumed <= 60 ? 'positive' :
      timeConsumed <= 90 ? 'warning' :
      'negative';

    return (
      <div className="u-flex u-flexColumn u-widthFull u-backgroundInformationLighter">
        <div className="u-flex u-alignItemsCenter">
          {canGoBack && (
            <div className=" u-paddingVerticalExtraSmall u-paddingLeftSmall">
              <Button variant="secondary" size="small" onClick={this.handleGoback}>
                <Button.Icon>
                  <Icon name="arrowRoundBack" size="extraSmall" />
                </Button.Icon>
                <Button.Label className="u-fontMedium">
                  Back
                </Button.Label>
              </Button>
            </div>
          )}
          <div className="u-flexGrow-1">
            {isDiscussionInProgress ? (
              <div className="u-flex u-justifyContentBetween u-alignItemsCenter u-paddingHorizontalSmall u-paddingVerticalExtraSmall">
                <div className="u-fontMedium">Chat with {question.expert_name}</div>
                <div className="u-paddingHorizontalExtraSmall">{timeLeft}</div>
                <div className="u-flex">
                  {question.processing_data?.uid > 0 && (
                    <SafeAnchor onClick={this.handleShowFlagModal}>
                      <Icon name="flag" className="u-textDark" />
                    </SafeAnchor>
                  )}
                </div>
              </div>
            ) : (
              <div className="u-paddingHorizontalExtraSmall u-paddingVerticalExtraSmall u-fontMedium u-textCenter">
                PhotoStudy: Homework Help
              </div>
            )}
          </div>
        </div>

        {isDiscussionInProgress && (
          <Progress now={timeConsumed} height={4} variant={progressVariant} className="u-roundedNone" />
        )}

        {expertInfoShown && (
          <div className="u-positionAbsolute u-positionLeft u-positionRight u-overflowHidden u-zIndex2 u-backgroundInformationLighter u-text200 u-paddingSmall">
            <div className="u-flex">
              <div>
                <Avatar size="extraLarge" src={expertAvatar} />
              </div>
              <div className="u-flexGrow-1 u-paddingHorizontalSmall">
                <div className="u-fontMedium u-textPrimary">{expertInfo.title}</div>
                <div className="u-flex u-alignItemsCenter">
                  <Icon name="star" size="extraSmall" className="u-textWarning u-marginRightTiny" />
                  {expertInfo.expert_rating} rating
                </div>
                <div className="">{expertInfo.expert_info}</div>
              </div>
              <SafeAnchor className="u-paddingTiny" onClick={this.handleHideExpertInfo}>
                <Icon name="close" className="u-textLight hover:u-textGray u-cursorPointer" />
              </SafeAnchor>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Header.propTypes = {
  question: PropTypes.shape().isRequired,
  expertInfo: PropTypes.shape(),
  onShowFlagModal: PropTypes.func.isRequired,
  onResetingTimeOut: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
};

Header.defaultProps = {
  expertInfo: null,
};

const mapStateToProps = state => ({
  question: state.chat.question,
});

const mapDispatchToProps = dispatch => ({
  onResetingTimeOut: qid => dispatch(resetTimeOut(qid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
