import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button,
  SidebarMenu,
  Icon,
  Avatar,
} from '@ahaui/react';

import config from 'configuration';

import Tracking from '../../../utils/tracking';
import {
  hasStemSupport,
  hasCoppaSupport,
} from '../../../utils/feature-support';

import { launchAskSource } from '../../../constants/common.constant';


export class MainNav extends React.Component {
  static contextTypes = {
    openSupport: PropTypes.func,
  }

  handleSupport = (e) => {
    e.preventDefault();
    this.context.openSupport();
    Tracking.sendFeedback({ source: 'left-menu' });
  }

  currentTab = () => {
    const { pathname } = this.props.location;

    if (pathname.startsWith('/setting')) {
      return '/setting';
    } if (pathname === '/' || pathname.startsWith('/stem') || pathname.startsWith('/english')) {
      return '/';
    } if (pathname.startsWith('/my-sessions')) {
      return '/my-sessions';
    } if (pathname.startsWith('/storefront')) {
      return '/storefront';
    } if (pathname.startsWith('/notification')) {
      return '/notification';
    } if (pathname.startsWith('/guide') || pathname.startsWith('/sample-sessions')) {
      return '/guide';
    }
  }

  navigateToTab = (path) => {
    const { history } = this.props;

    if (path === '/') {
      Tracking.launchAsk({
        source: launchAskSource.MAIN_NAV_TAB,
      });
    }

    history.push(path);
  }

  render() {
    const { unreadNids } = this.props;

    const {
      grade, avatar, fullname, nickname, question_count, enabled_features,
    } = this.props.user;
    let gradeTitle = '';
    config.grades.map((g) => {
      if (g.id === +grade) {
        gradeTitle = g.title;
      }
      return g;
    });
    const fullName = (fullname === nickname) ? 'Add your name' : fullname;
    const isStemSupported = hasStemSupport(enabled_features);
    const isCoppaSupported = hasCoppaSupport(enabled_features);

    return (
      <div className="u-borderRight u-flex u-flexColumn" style={{ width: 280 }}>
        <SidebarMenu
          current={this.currentTab()}
          onSelect={this.navigateToTab}
          className="u-flexGrow-1 u-overflowVerticalAuto u-webkitScrollbar"
          style={{ height: 1 }}
        >
          <SidebarMenu.Item className="u-paddingHorizontalSmall u-cursorPointer" path="/setting" eventKey="/setting" aria-label="Update your profile">
            <div className="u-flex u-alignItemsCenter">
              <Avatar src={avatar} size="extraLarge" alt="Your avatar" className="u-flexShrink-0" />
              <div className="u-marginLeftSmall">
                {!isCoppaSupported && (
                  <div className="u-text400 u-textDark u-fontMedium">{fullName}</div>
                )}
                <div className="u-text200 u-textDark u-fontRegular">{gradeTitle}</div>
              </div>
            </div>
          </SidebarMenu.Item>
          <SidebarMenu.Item className="u-paddingHorizontalSmall u-cursorPointer" path="/" eventKey="/">
            <Button width="full">
              <Button.Icon><Icon name="chatBubbles" /></Button.Icon>
              <Button.Label>Ask an Expert</Button.Label>
            </Button>
          </SidebarMenu.Item>
          {isStemSupported && (
            <>
              {!!+question_count && (
                <SidebarMenu.Item className="u-paddingHorizontalMedium u-cursorPointer" icon="document" path="/my-sessions" eventKey="/my-sessions">My Sessions</SidebarMenu.Item>
              )}
              <SidebarMenu.Item className="u-paddingHorizontalMedium u-cursorPointer" icon="apps" path="/storefront" eventKey="/storefront">Store</SidebarMenu.Item>
              <SidebarMenu.Item className="u-paddingHorizontalMedium u-cursorPointer" icon="notification" path="/notification" eventKey="/notification">
                {unreadNids.length > 0 && (
                  <div
                    className="u-positionAbsolute u-widthExtraSmall u-heightExtraSmall u-backgroundPositive u-roundedCircle u-border u-borderWhite"
                    data-testid="notification-indicator"
                    style={{ left: 37, top: 18, boxSizing: 'content-box' }}
                  />
                )}
                Notifications
              </SidebarMenu.Item>
              <SidebarMenu.Item className="u-paddingHorizontalMedium u-cursorPointer" icon="videoCam" path="/guide" eventKey="/guide">What is PhotoStudy?</SidebarMenu.Item>
            </>
          )}
        </SidebarMenu>
        {!isCoppaSupported && (
          <div className="u-paddingMedium">
            <Button variant="secondary" width="full" onClick={this.handleSupport} data-testid="feedback-button">
              <Button.Icon><Icon name="flag" /></Button.Icon>
              <Button.Label>Feedback</Button.Label>
            </Button>
          </div>
        )}

      </div>
    );
  }
}

MainNav.propTypes = {
  unreadNids: PropTypes.arrayOf(PropTypes.string).isRequired,
  user: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  unreadNids: state.notification.unreadNids,
  user: state.user,
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withRouter(MainNav));
