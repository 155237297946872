import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Form, Icon } from '@ahaui/react';

import { selectLanguage } from 'actions/askflow.action';
import { getLanguageLabel } from 'utils/question';
import { LANGUAGE } from 'constants/question.constant';

const Language = () => {
  const {
    enabled_languages: enabledLanguages,
    selected_language: previousLanguage,
  } = useSelector(({ user }) => user);
  const { selectedLanguage } = useSelector(({ askflow }) => askflow);
  const dispatch = useDispatch();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const currentLanguage =
    selectedLanguage || previousLanguage || LANGUAGE.ENGLISH;

  const handleDropdownToggle = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleLanguageSelect = (language) => {
    dispatch(selectLanguage(language));
    setIsDropdownVisible(false);
  };

  // Set previous language as initial value
  useEffect(() => {
    dispatch(selectLanguage(previousLanguage));
  }, []);

  return (
    <Form.Group controlId="ask.stem.language">
      <Form.Label>Language</Form.Label>
      <Dropdown
        onToggle={handleDropdownToggle}
        onKeyPress={handleDropdownToggle}
        show={isDropdownVisible}
        drop="up"
        aria-label="Please select language"
        tabIndex={0}
      >
        <Dropdown.Toggle className="u-textLight u-lineHeightNone">
          <div className="FormInput u-flex u-widthFull u-webkitScrollbar Dropdown-toggle u-cursorPointer u-textLight u-lineHeightNone">
            <div className="u-textDark u-flexGrow-1 u-flex u-alignItemsCenter">
              <span>{getLanguageLabel(currentLanguage)}</span>
            </div>
            <div className="u-flexShrink-0">
              <Icon name="arrowDown" size="extraSmall" />
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Container className="u-paddingVerticalExtraSmall u-widthFull">
          {enabledLanguages.map(language => (
            <div
              key={language}
              onClick={() => handleLanguageSelect(language)}
              onKeyPress={() => handleLanguageSelect(language)}
              className={`${
                language === selectedLanguage ? 'u-fontBold' : ''
              } u-flex u-alignItemsCenter u-paddingHorizontalSmall u-paddingVerticalTiny hover:u-backgroundLightest u-cursorPointer`}
              role="button"
              tabIndex={0}
            >
              {getLanguageLabel(language)}
            </div>
          ))}
        </Dropdown.Container>
      </Dropdown>
    </Form.Group>
  );
};

export default Language;
