import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import StatusBar from './StatusBar';

import {
  isDead,
} from 'utils/question';


export class RegularClaimBar extends React.Component {
  constructor() {
    super();
    this.state = {
      isShow: false,
    };
    this.displayTimer = 0;
  }


  componentDidUpdate(prevProps) {
    if (this.props.questionGranted != prevProps.questionGranted) {
      if (this.props.questionGranted) {
        this.displayTimer = window.setTimeout(() => {
          logger.debug('Set timeout to hide claim bar');
          this.setState({ isShow: false });
          window.clearTimeout(this.displayTimer);
        }, 4 * 1000);
      }
    }

    if (this.props.regularClaimShow != prevProps.regularClaimShow) {
      if (this.props.regularClaimShow) {
        logger.debug('Showing claim bar');
        this.setState({ isShow: true });
      }
    }
  }


  componentWillUnmount() {
    if (this.displayTimer) {
      window.clearTimeout(this.displayTimer);
    }
  }


  render() {
    const { isShow } = this.state;
    const message = this.props.regularClaimMessage;
    const { question } = this.props;
    const questionDead = isDead(question);

    if (!isShow || questionDead) return null;
    return (
      <StatusBar message={message} />
    );
  }
}


RegularClaimBar.propTypes = {
  regularClaimShow: PropTypes.bool.isRequired,
  regularClaimMessage: PropTypes.string.isRequired,
  questionGranted: PropTypes.bool.isRequired,
  question: PropTypes.shape().isRequired,
};


const mapStateToProps = state => ({
  regularClaimShow: state.chat.regularClaimShow,
  regularClaimMessage: state.chat.regularClaimMessage,
  questionGranted: state.chat.questionGranted,
  question: state.chat.question,
});
const mapDispatchToProps = null;
export default connect(mapStateToProps, mapDispatchToProps)(RegularClaimBar);
