import React from 'react';
import TopBanner from 'components/Common/TopBanner';

const insufficientMessage = 'Oops! You\'ve reached your weekly limit for Writing problems. You can continue to submit problems for other available subjects.';

function InsufficientWritingBalanceBanner() {
  return (
    <TopBanner
      icon="informationCircle"
      title="Oops, limit reached"
      body={insufficientMessage}
    />
  );
}

export { insufficientMessage };

export default InsufficientWritingBalanceBanner;
