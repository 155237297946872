import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Tracking from 'utils/tracking';
import { ServerAPIError } from 'utils/request';
import pusher from 'utils/pusher';
import { queryToObject } from 'utils/util';
import {
  showExpertWaiting,
  hideExpertWaiting,
  getOpenQuestion,
  extendQuestion,
  replyCqMessage,
  newDiscussionMessageAction,
  editDiscussionMessageAction,
  endQuestion,
  checkOpenQuestion,
  updateRegularClaimMsg,
} from 'actions/chat.action';
import {
  questionStateConsts,
  discussionStateConsts,
  sessionEndedType,
  EXTEND_ACTION,
} from 'constants/common.constant';
import { LANGUAGE } from 'constants/question.constant';

import MessageList from 'components/Common/MessageList';
import Loading from 'components/Common/Loading';
import TypeBox from '../Common/ChatView/TypeBox';
import Header from '../Common/ChatView/Header';
import RegularClaimBar from '../Common/ChatView/RoutingStatusBar/RegularClaimBar';
import RegularRouteBar from '../Common/ChatView/RoutingStatusBar/RegularRouteBar';

import WaitingModal from '../Common/ChatView/Modals/WaitingModal';
import FlagModal from '../Common/ChatView/Modals/FlagModal';
import RatingModal from '../Common/ChatView/Modals/RatingModal';
import InfoModal from '../Common/ChatView/Modals/InfoModal';
import ConfirmExitModal from '../Common/ChatView/Modals/ConfirmExitModal';
import IdleTimeoutModal from '../Common/ChatView/Modals/IdleTimeoutModal';
import IdleWarningModal from '../Common/ChatView/Modals/IdleWarningModal';
import ExtendErrorModal from '../Common/ChatView/Modals/ExtendErrorModal';
import UnknownErrorModal from '../Common/PostView/Modals/UnknownErrorModal';

export class ChatView extends React.Component {
  constructor(props) {
    super(props);
    this.pusherReady = false;
    this.state = {
      flagModalShown: false,
      expertInfo: null,
      ratingModalShown: false,
      infoModalShown: false,
      infoModalData: null,
      confirmExitModalShown: false,
      idleWarningModalShown: false,
      idleTimeoutModalShown: false,
      expertTyping: false,
      extendErrorModal: {
        shown: false,
        msg: '',
      },
      unknownErrorModal: {
        shown: false,
        msg: '',
      },
    };
    this.unblock = null;
    this.nextPath = '/stem';
  }

  componentDidMount() {
    const { onGetOpenQuestion, chat } = this.props;

    this.setupPusher();
    onGetOpenQuestion(chat.qid);
    this.extendQuestionIfNeeded();
    this.setupBlockNavigateOut();
    window.scrollTo(0, document.body.scrollHeight);
  }

  componentWillUnmount() {
    this.unblock();
    this.props.onHideExpertWaiting();
    this.destroyPusher();
  }

  hideAllModal = () => {
    this.setState({
      flagModalShown: false,
      ratingModalShown: false,
      infoModalShown: false,
      confirmExitModalShown: false,
      idleWarningModalShown: false,
      idleTimeoutModalShown: false,
      extendErrorModal: {
        shown: false,
        msg: '',
      },
      unknownErrorModal: {
        shown: false,
        msg: '',
      },
    });
  }

  /**
   * Show confirm/rating modal if needed when user navigate out
   * when problem is explained.
   */
  setupBlockNavigateOut = () => {
    const { history } = this.props;

    this.unblock = history.block((nextLocation) => {
      // Using `chat` prop in the future here
      // Do not move this destructuring assignment outside the callback
      const { chat } = this.props;

      logger.debug('next location to navigate', nextLocation);
      this.nextPath = nextLocation.pathname;

      // Show confirm modal if needed.
      if (String(chat.question.processing_status) === questionStateConsts.EXPLAINED
        && String(chat.question.discussion_status) === discussionStateConsts.STARTED) {
        logger.debug('confirm exit modal popup');
        this.setState({
          confirmExitModalShown: true,
        });
        return false;
      }
      // Show rating modal if needed.
      if (String(chat.question.processing_status) === questionStateConsts.EXPLAINED
        && Boolean(chat.answ.vote) === false) {
        logger.debug('rating modal popup');
        this.setState({
          ratingModalShown: true,
        });
        return false;
      }
      return true;
    });
  }

  //----- Pusher ----//
  /**
   * Subscribe to presence channel
   * Listen to chat events.
   */
  setupPusher = () => {
    const { user } = this.props;

    pusher.subscribe('presence', user.uid);
    pusher.bind('presence', 'newDiscussionMessage', this.handleNewMessage);
    pusher.bind('presence', 'editDiscussionMessage', this.handleEditMessage);
    pusher.bind('presence', 'newStudentDiscussionMessage', this.handleStudentNewMessage);
    pusher.bind('presence', 'sessionIdleWarning', this.handleIdleWarning);
    pusher.bind('presence', 'sessionIdleTimeout', this.handleIdleTimeout);
    pusher.bind('presence', 'discussionSessionEnded', this.handleSessionEnded);
    pusher.bind('presence', 'studentEndsDiscussion', this.handleStudentEndedSession);
    pusher.bind('presence', 'questionGranted', this.handleQuestionGranted);
    pusher.bind('presence', 'client-tutorTyping', this.handleTyping);
    pusher.bind('presence', 'client-tutorStoppedTyping', this.handleStopTyping);
  }

  /**
   * Unbind and Unsubscribe from pusher before unmount.
   */
  destroyPusher = () => {
    pusher.unbind('presence', 'newDiscussionMessage');
    pusher.unbind('presence', 'editDiscussionMessage');
    pusher.unbind('presence', 'newStudentDiscussionMessage');
    pusher.unbind('presence', 'sessionIdleWarning');
    pusher.unbind('presence', 'sessionIdleTimeout');
    pusher.unbind('presence', 'discussionSessionEnded');
    pusher.unbind('presence', 'studentEndsDiscussion');
    pusher.unbind('presence', 'questionGranted');
    pusher.unbind('presence', 'client-tutorTyping');
    pusher.unbind('presence', 'client-tutorStoppedTyping');
    pusher.unsubscribe('presence');
  }

  //----- Extend ----//
  /**
   * Extend the question if needed.
   */
  extendQuestionIfNeeded = () => {
    const {
      match,
      location,
      onReplyCqMessage,
      onGetOpenQuestion,
      chat,
    } = this.props;

    logger.debug('checking to extend question if needed');
    if (match.params.action === EXTEND_ACTION) {
      logger.debug('extending question');
      const search = location.search.substring(1);
      const params = queryToObject(search);
      const { mid } = params;
      const optionID = params.response_id;
      onReplyCqMessage(mid, optionID).then(
        (data) => {
          const { error } = data.payload;
          const { result } = data.payload;
          if (result) {
            onGetOpenQuestion(chat.qid);
          }
          if (error && error instanceof ServerAPIError) {
            const errMsg = error.data ? error.data.data : null;
            if (errMsg) {
              logger.warn(errMsg);
              this.setState({
                extendErrorModal: {
                  shown: true,
                  msg: errMsg,
                },
              });
            }
          }
        },
      );
    }
  }

  handleHideExtendErrorModal = () => {
    logger.debug('on handleHideExtendErrorModal');
    this.setState({
      extendErrorModal: {
        shown: false,
        msg: '',
      },
    });
  }

  trackSendMessage = (data) => {
    const { user } = this.props;

    logger.debug('on trackSendMessage', data);
    const senderUID = data.sender.uid;
    // Ignore system messages.
    if (Number(senderUID) === 0) {
      return;
    }
    // Student messages.
    if (Number(senderUID) === Number(user.uid)) {
      const dataTrack = {
        qid: Number(data.qid),
        from: ChatView.messageType.STUDENT,
        to: ChatView.messageType.EXPERT,
        attachment: ChatView.attachment.NO,
        attachment_size: 0,
        text_size: data.body.length,
        message_type: Number(data.message_type),
      };
      Tracking.sendMessage(dataTrack);
      return;
    }
    // Expert messages.
    const dataTrack = {
      qid: Number(data.qid),
      from: ChatView.messageType.EXPERT,
      to: ChatView.messageType.STUDENT,
      attachment: data.attachment ? ChatView.attachment.YES : ChatView.attachment.NO,
      attachment_size: data.attachment ? Number(data.attachment.size) : 0,
      text_size: data.body.length,
      message_type: Number(data.message_type),
    };
    Tracking.sendMessage(dataTrack);
  }

  //----- MESSAGE ------//
  /**
   * Handle new messages from system/expert/student
   * Hide waiting modal if needed.
   * Update progress time of session if it's extend message.
   */
  handleNewMessage = (data) => {
    const {
      chat,
      onExtend,
      onNewMessage,
      onHideExpertWaiting,
    } = this.props;
    const { question } = chat;

    logger.debug('on handleNewMessage from pusher');
    if (Number(chat.qid) !== Number(data.qid)) {
      logger.warn('Get a new discussion message but it\'s unmatched to the current viewing problem', data);
      return;
    }
    // Hide the waiting modal if needed.
    if (chat.waitingExpertModal) {
      onHideExpertWaiting();
    }

    // Check to see it's extending message
    if (data.extended && data.discussion_end_time) {
      const discussionEndTime = parseInt(data.discussion_end_time);
      const discussionTimeLimit = question.discussion_time_limit + (discussionEndTime - question.discussion_end_time);
      onExtend(discussionEndTime, discussionTimeLimit);
    }
    // Tracking
    this.trackSendMessage(data);
    // Add message to the message list.
    const msg = pusher.convertFromPusherMessage(
      data,
      { fromLanguage: LANGUAGE.ENGLISH, toLanguage: question.lang }
    );
    onNewMessage(msg);
    window.scrollTo(0, document.body.scrollHeight);
  }

  handleStudentNewMessage = (data) => {
    const { chat, onNewMessage } = this.props;
    const { question } = chat;

    logger.debug('on handleNewMessage from pusher');
    if (Number(chat.qid) !== Number(data.qid)) {
      logger.warn('Get a new discussion message but it\'s unmatched to the current viewing problem', data);
      return;
    }
    // Tracking
    this.trackSendMessage(data);
    // Add message to the message list.
    const msg = pusher.convertFromPusherMessage(
      data,
      { fromLanguage: question.lang, toLanguage: question.lang }
    );
    onNewMessage(msg);
    window.scrollTo(0, document.body.scrollHeight);
  }

  /**
   * Handle when expert edited a message.
   */
  handleEditMessage = (data) => {
    const { chat, onEditMessage } = this.props;
    const { question } = chat;

    logger.debug('on handleEditMessage from pusher');
    if (Number(chat.qid) !== Number(data.qid)) {
      logger.warn('Get a edited discussion message but it\'s unmatched to the current viewing problem', data);
      return;
    }
    const msg = pusher.convertFromPusherMessage(
      data,
      { fromLanguage: LANGUAGE.ENGLISH, toLanguage: question.lang }
    );
    onEditMessage(msg);
  }


  //----- IDLE ------//
  /**
   * Show modal Idle warning modal.
   * @param data: not used.
   */
  handleIdleWarning = (data) => {
    logger.debug('on handleIdleWarning', data);
    this.hideAllModal();
    this.setState({
      idleWarningModalShown: true,
    });
  }

  /**
   * Hide the Idle warning modal
   */
  handleHideIdleWanringModal = (data) => {
    logger.debug('on handleHideIdleWanringModal', data);
    this.setState({
      idleWarningModalShown: false,
    });
  }

  /**
   * Send reset timeout request to API.
   * Hide Idle warning modal if needed.
   */
  handleResetedTimeout = () => {
    logger.debug('on handleResetTimeout');
    this.setState({
      idleWarningModalShown: false,
    });
  }

  handleIdleTimeout = (data) => {
    const {
      chat: {
        question,
      },
      onGetOpenQuestion,
      onEndQuestion,
    } = this.props;

    logger.debug('on handleIdleTimeout', data);
    this.hideAllModal();
    this.setState({
      idleTimeoutModalShown: true,
    });
    onGetOpenQuestion(question.qid);
    onEndQuestion(question.qid).then((dataRes) => {
      const { result } = dataRes.payload;
      const { error } = dataRes.payload;
      if (result) {
        const dataTrack = {
          qid: Number(question.qid),
          session_length: Number(result.length),
          finishing_type: sessionEndedType.IDLE_TIMEOUT,
          first_bid: Number(result.first_bid),
          claim_time: Number(result.claim_time),
          number_of_messages: Number(result.message_count),
        };
        Tracking.sessionFinished(dataTrack);
      }
      if (error) {
        logger.error('Failed to call End Question endpoint to get tracking data');
      }
    });
  }

  handleHideIdleTimeoutModal = () => {
    logger.debug('on handleHideIdleTimeoutModal');
    this.setState({
      idleTimeoutModalShown: false,
    });
  }

  handleQuestionGranted = (data) => {
    const {
      chat,
      onGetOpenQuestion,
      onUpdateRegularClaimMsg,
    } = this.props;

    logger.debug('on handleQuestionGranted', data);
    if (Number(chat.question.has_autobid) === 0) {
      logger.debug('Does not have autobid', data.processing_data);
      this.setState({
        expertInfo: data.processing_data,
      });
    }
    onGetOpenQuestion(chat.qid);
    onUpdateRegularClaimMsg(data.processing_data.title);
  }

  handleTyping = (data) => {
    logger.debug('on handleTyping', data);
    this.setState({
      expertTyping: true,
    });
  }

  handleStopTyping = (data) => {
    logger.debug('on handleStopTyping', data);
    this.setState({
      expertTyping: false,
    });
  }


  //----- Flag ------//
  handleShowFlagModal = () => {
    logger.debug('on handleShowFlagModal');
    this.hideAllModal();
    this.setState({
      flagModalShown: true,
    });
  }

  handleHideFlagModal = () => {
    logger.debug('on handleHideFlagModal');
    this.setState({
      flagModalShown: false,
    });
  }

  handleFlagged = () => {
    const { onGetOpenQuestion, chat } = this.props;

    logger.debug('on handleFlagged');
    this.handleHideFlagModal();
    onGetOpenQuestion(chat.qid);
  }


  //----- Rate & Repost --------//
  handleRated = (infoData) => {
    const {
      onGetOpenQuestion,
      chat,
      history,
    } = this.props;

    logger.debug('on handleRated');
    onGetOpenQuestion(chat.qid);
    if (infoData) {
      this.setState({
        ratingModalShown: false,
        infoModalShown: true,
        infoModalData: infoData,
      });
    } else {
      window.scrollTo(0, 0);
      this.unblock();
      history.push(this.nextPath);
      history.goForward();
    }
  }

  handleHideInfoModal = () => {
    const { history } = this.props;

    this.setState({
      infoModalShown: false,
      infoModalData: null,
    });
    this.unblock();
    history.push(this.nextPath);
    history.goForward();
  }

  handleReposted = ({ succeeded, errorMessage }) => {
    const {
      onShowExpertWaiting,
      checkOpenQuestion,
      onGetOpenQuestion,
    } = this.props;

    logger.debug('on handleReposted');
    this.hideAllModal();

    if (succeeded) {
      onShowExpertWaiting();
      checkOpenQuestion().then((data) => {
        logger.debug('checkOpenQuestion');
        const { result } = data.payload;
        if (result && Number(result.can_ask) === 0) {
          logger.debug('refresh new question after repost');
          onGetOpenQuestion(result.open_qid);
        }
      });
    } else {
      this.setState({
        unknownErrorModal: {
          shown: true,
          msg: errorMessage,
        },
      });
    }
  }

  //----- End Session ------//
  handleShowConfirmExitModal = () => {
    logger.debug('on handleShowConfirmExitModal');
    this.hideAllModal();
    this.setState({
      confirmExitModalShown: true,
    });
  }

  handleHideConfirmExitModal = () => {
    logger.debug('on handleHideConfirmExitModal');
    this.setState({
      confirmExitModalShown: false,
    });
  }

  handleEndedSession = () => {
    const {
      chat,
      onGetOpenQuestion,
    } = this.props;

    logger.debug('on handleEndedSession');
    onGetOpenQuestion(chat.qid);
    this.setState({
      confirmExitModalShown: false,
      ratingModalShown: true,
    });
  }

  handleSessionEnded = (data) => {
    const {
      chat: {
        question,
      },
      onGetOpenQuestion,
      onEndQuestion,
    } = this.props;

    logger.debug('on handleSessionEnded', data);
    onGetOpenQuestion(question.qid);
    onEndQuestion(question.qid).then((dataRes) => {
      const { result } = dataRes.payload;
      const { error } = dataRes.payload;
      if (result) {
        const dataTrack = {
          qid: Number(question.qid),
          session_length: Number(result.length),
          finishing_type: sessionEndedType.EXPIRED,
          first_bid: Number(result.first_bid),
          claim_time: Number(result.claim_time),
          number_of_messages: Number(result.message_count),
        };
        Tracking.sessionFinished(dataTrack);
      }
      if (error) {
        logger.error('Failed to call End Question endpoint to get tracking data');
      }
    });
  }

  handleStudentEndedSession = (data) => {
    const { chat, onGetOpenQuestion } = this.props;

    logger.debug('on handleStudentEndedSession', data);
    onGetOpenQuestion(chat.question.qid);
  }

  handleGoBack = () => {
    const { history } = this.props;

    logger.debug('on handleGoBack');
    history.push('/stem');
  }

  handleHideUnknownErrorModal = () => {
    this.setState({
      unknownErrorModal: {
        shown: false,
        msg: '',
      },
    });
  }

  render() {
    const {
      chat: {
        question,
        messages,
        waitingExpertModal,
        answ,
      },
      user,
      onReplyCqMessage,
    } = this.props;
    const {
      expertInfo,
      expertTyping,
      flagModalShown,
      ratingModalShown,
      infoModalShown,
      infoModalData,
      confirmExitModalShown,
      idleWarningModalShown,
      idleTimeoutModalShown,
      extendErrorModal,
      unknownErrorModal,
    } = this.state;
    const canType = String(question.discussion_status) === discussionStateConsts.STARTED;

    return (
      <div className="u-flex u-flexColumn u-widthFull u-heightFull u-text200 u-positionRelative">
        <Header onShowFlagModal={this.handleShowFlagModal} expertInfo={expertInfo} onGoBack={this.handleGoBack} />
        {Number(question.qid) === 0 ? (
          <Loading />
        ) : (
          <MessageList
            user={user}
            question={question}
            messages={messages}
            expertTyping={expertTyping}
            onCqMessageReply={onReplyCqMessage}
          />
        )}
        {canType && (<TypeBox expertTyping={expertTyping} />)}

        <RegularRouteBar />
        <RegularClaimBar />

        {waitingExpertModal && (
          <WaitingModal />
        )}
        {flagModalShown && (
          <FlagModal onFlagged={this.handleFlagged} onClose={this.handleHideFlagModal} />
        )}
        {ratingModalShown && (
          <RatingModal onRated={this.handleRated} question={question} answ={answ} />
        )}
        {infoModalShown && (
          <InfoModal question={question} modal={infoModalData} onClose={this.handleHideInfoModal} onReposted={this.handleReposted} />
        )}
        {confirmExitModalShown && (
          <ConfirmExitModal onEndedQuestion={this.handleEndedSession} onClose={this.handleHideConfirmExitModal} />
        )}
        {idleWarningModalShown && (
          <IdleWarningModal onResetedTimeOut={this.handleResetedTimeout} onClose={this.handleHideIdleWanringModal} />
        )}
        {idleTimeoutModalShown && (
          <IdleTimeoutModal onClose={this.handleHideIdleTimeoutModal} />
        )}
        {extendErrorModal.shown && (
          <ExtendErrorModal msg={extendErrorModal.msg} onClose={this.handleHideExtendErrorModal} />
        )}
        {unknownErrorModal.shown && (
          <UnknownErrorModal onClose={this.handleHideUnknownErrorModal} message={unknownErrorModal.msg} />
        )}
      </div>
    );
  }
}


ChatView.messageType = {
  STUDENT: 'Student',
  EXPERT: 'Tutor',
};

ChatView.attachment = {
  YES: 'YES',
  NO: 'NO',
};

ChatView.propTypes = {
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  chat: PropTypes.shape().isRequired,
  onNewMessage: PropTypes.func.isRequired,
  onEditMessage: PropTypes.func.isRequired,
  onShowExpertWaiting: PropTypes.func.isRequired,
  onHideExpertWaiting: PropTypes.func.isRequired,
  onGetOpenQuestion: PropTypes.func.isRequired,
  onExtend: PropTypes.func.isRequired,
  onReplyCqMessage: PropTypes.func.isRequired,
  onEndQuestion: PropTypes.func.isRequired,
  checkOpenQuestion: PropTypes.func.isRequired,
  onUpdateRegularClaimMsg: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  chat: state.chat,
  askflow: state.askflow,
});

const mapDispatchToProps = dispatch => ({
  onNewMessage: msg => dispatch(newDiscussionMessageAction(msg)),
  onEditMessage: msg => dispatch(editDiscussionMessageAction(msg)),
  onShowExpertWaiting: () => dispatch(showExpertWaiting()),
  onHideExpertWaiting: () => dispatch(hideExpertWaiting()),
  onGetOpenQuestion: qid => dispatch(getOpenQuestion(qid)),
  onExtend: (discussionEndTime, discussionTimeLimit) => dispatch(extendQuestion(discussionEndTime, discussionTimeLimit)),
  onReplyCqMessage: (mid, optionId) => dispatch(replyCqMessage(mid, optionId)),
  onEndQuestion: qid => dispatch(endQuestion(qid)),
  checkOpenQuestion: () => dispatch(checkOpenQuestion()),
  onUpdateRegularClaimMsg: msg => dispatch(updateRegularClaimMsg(msg)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatView));
