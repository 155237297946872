import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Button, Loader } from '@ahaui/react';

import config from 'configuration';
import DocFile from 'components/Ask/Common/PostView/DocFile';
import Loading from 'components/Common/Loading';

import { getAsker, submitQuestion } from 'actions/english.action';

import { getBrowser } from '../../../utils/app';
import AccessToken from '../../../utils/token';
import Tracking from '../../../utils/tracking';
import {
  ERROR_CODE_WRITING_FILE_SIZE_TOO_LARGE,
  ERROR_CODE_WRITING_FILE_TYPE_INCORRECT,
  ERROR_CODE_WRITING_GOOGLE_DOC_LINK_INCORRECT,
  ERROR_CODE_WRITING_PRIVACY_SETTINGS_INCORRECT,
  ERROR_CODE_WRITING_INVALID_DESCRIPTION_CONTENT,
  ERROR_CODE_WRITING_SESSION_IN_PROGRESS,
  ERROR_CODE_WRITING_INSUFFICIENT_BALANCE,
} from 'constants/error.constant';

import InProgressWritingSessionBanner, { inProgressMessage } from '../Common/PostView/Banners/InProgressWritingSessionBanner';
import InsufficientWritingBalanceBanner, { insufficientMessage } from '../Common/PostView/Banners/InsufficientWritingBalanceBanner';
import UnknownErrorModal from '../Common/PostView/Modals/UnknownErrorModal';
import { Description } from '../Common/PostView/Description';

import Finish from './Finish';

const MAX_FILE_SIZE = 10; // Mb

class PostView extends React.Component {
  constructor() {
    super();
    this.state = {
      statusFetched: false,
      inProgressSession: false,
      insufficientBalance: false,
      unknownErrorModalShow: false,
      email: '',
      description: '',
      file: null,
      fileType: 0,
      url: '',
      errors: {
        description: false,
        email: false,
        file: false,
        fileType: false,
        url: false,
      },
      errorMessages: {
        file: '',
        url: '',
        description: '',
        email: '',
      },
      isProcessing: false,
      finished: false,
    };
  }

  componentDidMount() {
    this.checkWritingStatus();
  }

  checkWritingStatus = async () => {
    const { payload: { result } } = await this.props.getAsker();

    if (!result) {
      return;
    }

    this.setState({ statusFetched: true });

    if (result.question && result.question.qid > 0) {
      this.setState({ inProgressSession: true });
      return;
    }

    if (!result.writing_sessions) {
      this.setState({ insufficientBalance: true });
      return;
    }
  }

  handleOpenNeedHelpMail = () => {
    logger.debug('on openDefaultEmailClient');
    const { user } = this.props;
    const { appVersion } = config;
    const browser = getBrowser();
    const deviceId = AccessToken.getBrowserId();
    const mailLink = 'mailto:support@photostudy.co?'
      + 'subject=Got%20IT%20Study%20Error%20Feedback&'
      + 'body=%0A%5E%5Eplease%20enter%20feedback%20ABOVE%5E%5E%0A%0A%0A'
      + `App%20Version%3A%20${appVersion}%20%0A`
      + `Browser%3A%20${browser}%20%0A`
      + `Browserprint%3A%20${deviceId}%20%0A`
      + `User%20ID%3A${user.uid}%20%0A`
      + 'Additional%20Information'
        + `%3A%20${user.support_priority}`
        + `%20%3A%20${user.payment_transaction_count}`
        + `%20%3A%20${user.consumed_credit_count}%20%0A%0A`
      + 'Sent%20from%20Web%20app';
    window.open(mailLink, '_self');
  }

  resetError = () => {
    this.setState({
      errors: {
        description: false,
      },
      errorMessages: {
        file: '',
        url: '',
        description: '',
        email: '',
      },
    });
  }

  handleUnknownError = () => {
    logger.debug('on handleUnknownError');
    this.setState({
      unknownErrorModalShow: true,
    });
  };

  handleHideUnknownErrorModal = () => {
    logger.debug('on handleHideUnknownErrorModal');
    this.setState({
      unknownErrorModalShow: false,
    });
  }

  handleEmailChange = (e) => {
    const { target: { value } } = e;
    const { errorMessages } = this.state;

    this.setState({ email: value });

    const re = require('email-validator');

    if (value && !re.validate(value.toLowerCase())) this.setState({ errorMessages: { ...errorMessages, email: 'Invalid email. Please try again.' } });
    else this.setState({ errorMessages: { ...errorMessages, email: '' } });
  }


  handleSubmitQuestion = async() => {
    const {
      description, email, file, url, fileType,
    } = this.state;

    const { submitQuestion } = this.props;


    this.setState({ isProcessing: true });
    this.resetError();

    Tracking.postQuestion();
    const { payload: { result, error } } = await submitQuestion(
      {
        description,
        email,
        file: fileType === 0 ? file : null,
        url: fileType === 1 ? url : '',
      }
    );

    this.setState({ isProcessing: false });

    if (result) {
      this.setState({ finished: true });
    }

    if (error) {
      const { errorMessages } = this.state;

      if (error.status === 400) {
        const { code, message, info } = error.data.detail;

        if (code === ERROR_CODE_WRITING_SESSION_IN_PROGRESS) {
          Tracking.questionPostError({ type: code, message: inProgressMessage });
          this.setState({
            inProgressSession: true,
          });
          return;
        }

        if (code === ERROR_CODE_WRITING_INSUFFICIENT_BALANCE) {
          Tracking.questionPostError({ type: code, message: insufficientMessage });
          this.setState({
            insufficientBalance: true,
          });
          return;
        }

        if (code === ERROR_CODE_WRITING_INVALID_DESCRIPTION_CONTENT) {
          Tracking.questionPostError({ type: code, message });
        }

        if (code === ERROR_CODE_WRITING_PRIVACY_SETTINGS_INCORRECT) {
          Tracking.fileUploadError({ type: code, message });
        }

        if (!info) {
          return;
        }

        this.setState({
          errorMessages: {
            ...errorMessages, [info.field]: info.message,
          },
        });
      } else {
        this.setState({
          errorMessages: {
            ...errorMessages, url: error.message,
          },
        });
      }
    }
  }

  handleDescriptionChange = (value) => {
    const { errors, errorMessages } = this.state;

    if (value.trim().length >= config.DESCRIPTION_REQUIRED_LENGTH) {
      this.setState({
        errors: {
          ...errors,
          description: false,
        },
        errorMessages: {
          ...errorMessages,
          description: '',
        },
        description: value,
      });
    } else {
      this.setState({
        errors: {
          ...errors,
          description: true,
        },
        errorMessages: {
          ...errorMessages,
          description: '',
        },
        description: value,
      });
    }
  };

  handleFileUpdate = (data) => {
    const { fileType, errorMessages } = this.state;

    this.setState({
      errorMessages: {
        ...errorMessages,
        file: '',
      },
    });

    if (fileType === 0) {
      if (!data) {
        this.setState({ file: null });
        return;
      }

      // Validate file type
      const exts = data.name.split('.');
      const ext = exts.pop();

      if (['doc', 'docx'].indexOf(ext.toLowerCase()) >= 0) {
        // Validate file size
        if (data.size / 1024 / 1024 > MAX_FILE_SIZE) {
          const tooLargeMessage = 'The attached file is too large and cannot be uploaded. Maximum file size is 10MB.';

          Tracking.fileUploadError({
            type: ERROR_CODE_WRITING_FILE_SIZE_TOO_LARGE,
            message: tooLargeMessage,
          });

          this.setState({
            errorMessages: {
              ...errorMessages,
              file: tooLargeMessage,
            },
          });
        } else {
          this.setState({ file: data });
        }
      } else {
        const incorrectFormatMessage = 'Incorrect format. The image file is invalid or the image type is not allowed. Allowed types: doc, docx.';

        Tracking.fileUploadError({
          type: ERROR_CODE_WRITING_FILE_TYPE_INCORRECT,
          message: incorrectFormatMessage,
        });

        this.setState({
          errorMessages: {
            ...errorMessages,
            file: incorrectFormatMessage,
          },
        });
      }
    } else {
      if (!data.startsWith('https://docs.google.com/document/d/')) {
        const invalidGoogleDocMessage = 'Invalid Google Docs link.';

        Tracking.fileUploadError({
          type: ERROR_CODE_WRITING_GOOGLE_DOC_LINK_INCORRECT,
          message: invalidGoogleDocMessage,
        });

        this.setState({
          errorMessages: {
            ...errorMessages,
            url: invalidGoogleDocMessage,
          },
        });

      } else {
        this.setState({
          errorMessages: {
            ...errorMessages,
            url: '',
          },
        });
      }

      this.setState({ url: data });
    }
  }

  handleFileTypeUpdate = (fileType) => {
    this.setState({ fileType });
    const { errorMessages } = this.state;

    if (fileType === 0) {
      Tracking.selectWordDoc();
      this.setState({
        errorMessages: {
          ...errorMessages,
          url: '',
        },
        url: '',
      });
    } else {
      Tracking.selectGoogleDoc();
      this.setState({
        errorMessages: {
          ...errorMessages,
          file: '',
        },
        file: null,
      });
    }
  }

  isCanSubmit = () => {
    const {
      description, file, url, errorMessages, errors, fileType,
    } = this.state;

    if (!description || (!file && fileType === 0) || (!url && fileType === 1)) return false;

    let errFields = Object.keys(errorMessages).filter(key => errorMessages[key] !== '');
    if (errFields.length > 0) return false;

    errFields = Object.keys(errors).filter(key => errors[key] === true);
    if (errFields.length > 0) return false;
    return true;
  }

  renderForm = () => {
    const {
      description,
      email,
      isProcessing,
      errorMessages,
    } = this.state;

    return (
      <div className="Container Container--fluid">
        <div className="u-marginHorizontalAuto u-paddingMedium u-widthFull" style={{ maxWidth: 480 }}>
          <Description
            description={description}
            descriptionUpdated={this.handleDescriptionChange}
            errorMessage={errorMessages.description}
          />

          <Form.Group controlId="ask.writing.alt-email">
            <Form.Label>
              Alternative email address&nbsp;
              <span className="u-textGray">(Optional)</span>
            </Form.Label>
            <Form.Input
              placeholder="Enter your alternative email"
              value={email}
              onChange={this.handleEmailChange}
              isInvalid={errorMessages.email}
            />
            <div className="u-text100 u-marginTopTiny">
              <Form.Feedback type="invalid" visible={!!errorMessages.email}>{errorMessages.email}</Form.Feedback>
              <span className="u-textGray u-text100">Enter alternative email address to receive a copy of our response</span>
            </div>
          </Form.Group>

          <Form.Group controlId="ask.writing.subject">
            <Form.Label>Subject</Form.Label>
            <Form.Select disabled>
              <option>Writing</option>
            </Form.Select>
          </Form.Group>

          <DocFile
            onChange={this.handleFileUpdate}
            onTypeChange={this.handleFileTypeUpdate}
            fileErrorMessage={errorMessages.file}
            urlErrorMessage={errorMessages.url}
          />

          <Form.Group className="u-marginTopMedium">
            <Button
              variant="primary"
              width="full"
              onClick={this.handleSubmitQuestion}
              disabled={!this.isCanSubmit() || isProcessing}
            >
              {isProcessing && (
                <Button.Icon><Loader size="small" duration={700} className="u-marginRightTiny" /></Button.Icon>
              )}
              <Button.Label>Send to Expert</Button.Label>
            </Button>

          </Form.Group>
        </div>
      </div>
    );
  }

  render() {
    const {
      statusFetched,
      finished,
      inProgressSession,
      insufficientBalance,
      unknownErrorModalShow,
    } = this.state;

    const form = this.renderForm();

    if (!statusFetched) {
      return <Loading />;
    }

    if (finished) {
      return <Finish />;
    }

    return (
      <>
        {inProgressSession && <InProgressWritingSessionBanner />}
        {insufficientBalance && <InsufficientWritingBalanceBanner />}

        {!inProgressSession && !insufficientBalance && (
          form
        )}

        {unknownErrorModalShow && (<UnknownErrorModal onNeedHelp={this.handleOpenNeedHelpMail} onClose={this.handleHideUnknownErrorModal} />)}
      </>
    )
  }
}

PostView.propTypes = {
  user: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  askflow: state.askflow,
});


const mapDispatchToProps = dispatch => ({
  getAsker: () => dispatch(getAsker()),
  submitQuestion: data => dispatch(submitQuestion(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostView);
