import invariant from 'invariant';
import Pusher from 'pusher-js';
import config from 'configuration';
import AccessToken from './token';

import {
  getAppPlatformCode,
  getBrowser,
} from './app';
import { LANGUAGE } from 'constants/question.constant';

let pusher = null;
let privateChannel = null;
let presenceChannel = null;

const connect = () => {
  const accessToken = AccessToken.getAuthKey();
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
  const headers = {};
  headers.Authorization = AccessToken.getAuthKey();
  headers.TUClientDeviceIdentifier = AccessToken.getBrowserId();
  // headers['User-Agent'] = userAgent;
  headers.TUClientLocale = window.navigator.language;
  headers.TUClientVersion = config.appVersion;
  headers.TUClientPlatform = getAppPlatformCode(userAgent);
  headers.TUClientDevice = getBrowser();
  headers['X-GotIt-App-Id'] = config.APP_ID;

  pusher = new Pusher(config.PUSHER_KEY, {
    authEndpoint: config.API_HOST + config.PUSHER_AUTH,
    cluster: 'mt1',
    auth: { headers },
  });
  privateChannel = null;
  presenceChannel = null;
};

const disconnect = () => {
  invariant(pusher, 'PusherAPI is disconnected');
  if (pusher) {
    pusher.disconnect();
    pusher = null;
  }
};

const subscribe = (channelType, id = null) => {
  invariant(pusher, 'PusherAPI is disconnected');
  invariant(
    ['private', 'presence'].includes(channelType),
    'PusherAPI invalid channel type',
  );
  if (channelType === 'private') {
    invariant(!privateChannel, 'PusherAPI private channel is subscribed');
    privateChannel = pusher.subscribe(['private', 'student', id].join('-'));
  } else if (channelType === 'presence') {
    invariant(!presenceChannel, 'PusherAPI presence channel is subscribed');
    presenceChannel = pusher.subscribe(['presence', 'student', id].join('-'));
  }
};

const unsubscribe = (channelType) => {
  invariant(
    ['private', 'presence'].includes(channelType),
    'PusherAPI invalid channel type',
  );
  if ((channelType === 'private') && pusher) {
    pusher.unsubscribe(privateChannel.name);
    privateChannel = null;
  } else if ((channelType === 'presence') && pusher) {
    pusher.unsubscribe(presenceChannel.name);
    presenceChannel = null;
  }
};

const bind = (channelType, eventName, callback) => {
  invariant(pusher, 'PusherAPI is disconnected');
  invariant(
    ['private', 'presence'].includes(channelType),
    'PusherAPI invalid channel type',
  );
  if (channelType === 'private') {
    invariant(privateChannel, 'PusherAPI private channel is not subscribed');
    privateChannel.bind(eventName, callback);
  } else if (channelType === 'presence') {
    invariant(presenceChannel, 'PusherAPI presence channel is not subscribed');
    presenceChannel.bind(eventName, callback);
  }
};

const unbind = (channelType, eventName, callback) => {
  invariant(
    ['private', 'presence'].includes(channelType),
    'PusherAPI invalid channel type',
  );
  if ((channelType === 'private') && privateChannel) {
    privateChannel.unbind(eventName, callback);
  } else if ((channelType === 'presence') && presenceChannel) {
    presenceChannel.unbind(eventName, callback);
  }
};

/**
 * Options:
 *    - fromLanguage: The language of `body` field
 *    - toLanguage: The desired language to convert to
 */
const convertFromPusherMessage = (
  data,
  { fromLanguage = LANGUAGE.ENGLISH, toLanguage = LANGUAGE.ENGLISH } = {}
) => {
  const getBody = (obj) => {
    if (fromLanguage === toLanguage) {
      return obj.body;
    }

    if (toLanguage === LANGUAGE.ENGLISH) {
      return obj.en_body || obj.body;
    }

    if (toLanguage === LANGUAGE.SPANISH) {
      return obj.es_body || obj.body;
    }
  }

  const message = {
    mid: data.mid,
    sender: data.sender,
    receiver: data.receiver,
    attachment: data.attachment,
    body: getBody(data),
    time_remaining: data.time_remaining,
    created: data.created,
    updated: data.updated,
  };
  if (data.options) {
    const newOptions = data.options.map(opt => ({
      ...opt,
      id: opt.id.toString(),
      body: getBody(opt),
    }));
    message.options = newOptions;
  }
  return message;
};

const convertToPusherMessage = data => (
  {
    mid: data.mid,
    sender: {
      uid: data.author_uid,
    },
    body: data.content,
    options: data.suggestion ? data.suggestion.options : null,
    time_remaining: data.suggestion ? data.suggestion.time_remaining : 0,
    created: data.created,
  }
);

export default {
  connect,
  disconnect,
  subscribe,
  unsubscribe,
  bind,
  unbind,
  convertFromPusherMessage,
  convertToPusherMessage,
};
