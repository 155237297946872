import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';

import SchoolModal from './modals/School';
import GradeModal from './modals/Grade';
import TOSModal from './modals/TOS';

import {
  onSignupSuccess,
  signupBegin,
  signupEnd,
} from '../../../actions/user.action';

import { auth } from '../../../actions/app.action';

import AccessToken from '../../../utils/token';
import Tracking from '../../../utils/tracking';

import { navSend, navReceive } from '../../../utils/app';
import { hasStemSupport } from '../../../utils/feature-support';

class SignupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regInfo: { grade: 0, school: '' },
      signupStep: 'grade',
      signupMethod: props.signupMethod,
    };
    this.navInfo = navReceive('/login');
    if (!this.navInfo?.from) this.navInfo = navReceive('/');
    this.isSignupFromSharedSession = this.navInfo?.from?.startsWith('shared_session_');
  }

  componentWillReceiveProps(nextProps) {
    const { signupMethod: nextSignupMethod } = nextProps;
    const { signupMethod } = this.state;
    console.log({ nextProps });
    if (signupMethod !== nextSignupMethod) this.setState({ signupMethod: nextSignupMethod });
  }

  onAuthenticated = (authToken, firstLogin = false, isStemSupported = true) => {
    AccessToken.setAuthKey(authToken);
    this.props.onAuthenticated(authToken, firstLogin);
    if (this.isSignupFromSharedSession) {
      this.props.history.push(this.navInfo.path); // redirect back to shared session after logging in
    } else if (firstLogin && isStemSupported) {
      this.props.history.push({ pathname: '/guide', search: 'autoplay=1' });
    }
  }

  handleSelectGrade = (grade) => {
    this.setState(currState => ({
      ...currState,
      regInfo: { ...currState.regInfo, grade },
    }));
  }

  handleDoneSelectingGrade = () => {
    this.setState({
      signupStep: 'school',
    });
  }

  handleEnterSchool = (school) => {
    this.setState(currState => ({
      ...currState,
      regInfo: { ...currState.regInfo, school },
      signupStep: 'tos',
    }));
  }

  handleAcceptTOS = (gdpr) => {
    this.setState(currState => ({
      ...currState,
      regInfo: { ...currState.regInfo, ...gdpr },
    }));
    const data = {
      ...gdpr,
      grade: this.state.regInfo.grade,
      school_name: this.state.regInfo.school,
    };
    this.doSignup(data).then(this.resolveSignup);
  }

  handleBackToGrade = (school) => {
    this.setState(currState => ({
      ...currState,
      signupStep: 'grade',
      regInfo: { ...currState.regInfo, school },
    }));
  }

  handleBackToSchool = () => {
    this.setState(currState => ({
      ...currState,
      signupStep: 'school',
    }));
  }

  doSignup = (data) => {
    this.props.onSignupBegin();
    return this.props.doSignup(data);
  }

  resolveSignup = (signupResponse) => {
    const signupResult = signupResponse.payload.result;
    const signupError = signupResponse.payload.error;
    if (signupResult) {
      Tracking.alias(signupResult.user.uid, signupResult.user.email);
      Tracking.signup(true, {
        signup_method: this.state.signupMethod,
        created: moment.unix(+signupResult.user.created).toISOString(),
        signup_liquidity_lever_enabled: (signupResult.user.signup_credits >= 8) ? 'YES' : 'NO',
      });
      const isStemSupported = hasStemSupport(signupResult.user.enabled_features);
      this.onAuthenticated(signupResult.auth, true, isStemSupported);
    } else if (signupError) {
      const { info } = signupError.data;
      if (info) {
        if (info.is_whitelisted) {
          navSend({ ...info, from: 'try_preset' });
          this.props.history.push('/login');
        }
      }
      const errorCode = (signupError.data.info) ? (signupError.data.info.error_code || '') : '';
      this.props.showError(signupError.data.data, errorCode);

      Tracking.signup(false, {
        signup_method: this.state.signupMethod,
        created: moment.unix(+signupResult.user.created).toISOString(),
        signup_liquidity_lever_enabled: 'NO',
        message: signupError.data.data,
      });
    }
    this.props.onSignupEnd();
  }

  render() {
    return (
      <>
        {(this.state.signupStep === 'grade')
          && (
          <GradeModal
            onSelectGrade={this.handleSelectGrade}
            onDoneSelectingGrade={this.handleDoneSelectingGrade}
            selectedGrade={this.state.regInfo.grade}
          />
          )}
        {(this.state.signupStep === 'school')
          && (
          <SchoolModal
            onEnterSchool={this.handleEnterSchool}
            enteredSchool={this.state.regInfo.school}
            onBackToGrade={this.handleBackToGrade}
          />
          )}
        {(this.state.signupStep === 'tos')
          && (
          <TOSModal
            onAcceptTOS={this.handleAcceptTOS}
            onBack={this.handleBackToSchool}
          />
          )}
      </>
    );
  }
}

SignupComponent.propTypes = {
  doSignup: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onAuthenticated: PropTypes.func.isRequired,
  onSignupBegin: PropTypes.func.isRequired,
  onSignupEnd: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
};

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
  onAuthenticated: (token, firstLogin) => dispatch(auth(token, firstLogin)),
  onSignupSuccess: user => dispatch(onSignupSuccess(user)),
  onSignupBegin: () => dispatch(signupBegin()),
  onSignupEnd: () => dispatch(signupEnd()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupComponent));
