import React from 'react';
import { Icon } from '@ahaui/react';

const TopBanner = ({ icon, title, body, button, timer }) => {
  return (
    <div className="u-backgroundInformationLighter u-paddingVerticalSmall">
      <div className="Container Container--fluid">
        <div className="u-marginHorizontalAuto u-flex u-alignItemsCenter" style={{ width: '100%', maxWidth: 528 }}>
          <div className="u-paddingRightSmall">
            <Icon size="large" name={icon} className="u-textPrimary" />
          </div>
          <div className="u-flexGrow-1">
            {title && (
              <div className="u-text200 u-fontMedium">{title}</div>
            )}
            <div className="u-text100">{body}</div>
            {timer && (
              <div className="u-flex u-paddingTopTiny">
                <div className="u-flex u-alignItemsCenter u-backgroundPositiveDark u-textWhite u-text200 u-paddingHorizontalExtraSmall u-roundedExtraLarge">
                  <Icon size="tiny" name="time" className="u-marginRightTiny" />
                  {timer}
                </div>
              </div>
            )}
          </div>
          {button && (
            <div className="u-paddingLeftSmall u-flexShrink-0">
              {button}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
