
const trim = s => s.replace(/^\s+|\s+$/g, '');

const loadGA = (options) => {
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/
  /* eslint-disable */
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments);
    }, i[r].l = 1 * new Date();
    a = s.createElement(o),
      m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', options && options.gaAddress ? options.gaAddress : 'https://www.google-analytics.com/analytics.js', 'ga');
  /* eslint-enable */
};

const log = msg => logger.debug(msg);

const internalGa = (...args) => {
  if (!window.ga) return log('ReactGA.initialize must be called first or GoogleAnalytics should be loaded manually');
  return window.ga(...args);
};

const _gaCommand = (trackerNames, ...args) => {
  const command = args[0];
  if (typeof internalGa === 'function') {
    if (typeof command !== 'string') {
      log('ga command must be a string');
      return;
    }

    internalGa(...args);
    if (Array.isArray(trackerNames)) {
      trackerNames.forEach((name) => {
        internalGa(...[`${name}.${command}`].concat(args.slice(1)));
      });
    }
  }
};

const _initialize = (gaTrackingID, options) => {
  if (!gaTrackingID) {
    log('gaTrackingID is required in initialize()');
    return;
  }

  if (options && options.gaOptions) {
    internalGa('create', gaTrackingID, options.gaOptions);
  } else {
    internalGa('create', gaTrackingID, 'auto');
  }
};


const initialize = (configsOrTrackingId, options) => {
  if (!options || options.testMode !== true) {
    if (typeof window === 'undefined') {
      return false;
    }

    loadGA(options);
  }

  if (Array.isArray(configsOrTrackingId)) {
    configsOrTrackingId.forEach((config) => {
      if (typeof config !== 'object') {
        log('All configs must be an object');
        return;
      }
      _initialize(config.trackingId, config);
    });
  } else {
    _initialize(configsOrTrackingId, options);
  }
  return true;
};

/**
 * ga:
 * Returns the original GA object.
 */
const ga = (...args) => {
  if (args.length > 0) {
    internalGa(...args);
  }

  return window.ga;
};

/**
 * set:
 * GA tracker set method
 * @param {Object} fieldsObject - a field/value pair or a group of field/value pairs on the tracker
 * @param {Array} trackerNames - (optional) a list of extra trackers to run the command on
 */
const set = (fieldsObject, trackerNames) => {
  if (!fieldsObject) {
    log('`fieldsObject` is required in .set()');
    return;
  }

  if (typeof fieldsObject !== 'object') {
    log('Expected `fieldsObject` arg to be an Object');
    return;
  }

  if (Object.keys(fieldsObject).length === 0) {
    log('empty `fieldsObject` given to .set()');
  }

  _gaCommand(trackerNames, 'set', fieldsObject);
};

/**
 * send:
 * Clone of the low level `ga.send` method
 * logger.debugING: No validations will be applied to this
 * @param  {Object} fieldObject - field object for tracking different analytics
 * @param  {Array} trackerNames - trackers to send the command to
 * @param {Array} trackerNames - (optional) a list of extra trackers to run the command on
 */
const send = (fieldObject, trackerNames) => {
  _gaCommand(trackerNames, 'send', fieldObject);
};

/**
 * pageview:
 * Basic GA pageview tracking
 * @param  {String} path - the current page page e.g. '/about'
 * @param {Array} trackerNames - (optional) a list of extra trackers to run the command on
 * @param {String} title - (optional) the page title e. g. 'My Website'
 */
const pageview = (rawPath, trackerNames, title) => {
  if (!rawPath) {
    log('path is required in .pageview()');
    return;
  }

  const path = trim(rawPath);
  if (path === '') {
    log('path cannot be an empty string in .pageview()');
    return;
  }

  const extraFields = {};
  if (title) {
    extraFields.title = title;
  }

  if (typeof ga === 'function') {
    _gaCommand(trackerNames, 'send', {
      hitType: 'pageview',
      page: path,
      ...extraFields,
    });
  }
};

/**
 * timing:
 * GA timing
 * @param args.category {String} required
 * @param args.variable {String} required
 * @param args.value  {Int}  required
 * @param args.label  {String} required
 * @param {Array} trackerNames - (optional) a list of extra trackers to run the command on
 */
const timing = ({
  category, variable, value, label,
} = {}, trackerNames) => {
  if (typeof ga === 'function') {
    if (!category || !variable || !value || typeof value !== 'number') {
      log('args.category, args.variable '
            + 'AND args.value are required in timing() '
            + 'AND args.value has to be a number');
      return;
    }

    // Required Fields
    const fieldObject = {
      hitType: 'timing',
      timingCategory: category,
      timingVar: variable,
      timingValue: value,
    };

    if (label) {
      fieldObject.timingLabel = label;
    }

    send(fieldObject, trackerNames);
  }
};

/**
 * event:
 * GA event tracking
 * @param args.category {String} required
 * @param args.action {String} required
 * @param args.label {String} optional
 * @param args.value {Int} optional
 * @param args.nonInteraction {boolean} optional
 * @param {Array} trackerNames - (optional) a list of extra trackers to run the command on
 */
export function event({
  category, action, label, value, nonInteraction, transport, ...args
} = {}, trackerNames) {
  if (typeof ga === 'function') {
    // Simple Validation
    if (!category || !action) {
      log('args.category AND args.action are required in event()');
      return;
    }

    // Required Fields
    const fieldObject = {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
    };

    // Optional Fields
    if (label) {
      fieldObject.eventLabel = label;
    }

    if (typeof value !== 'undefined') {
      if (typeof value !== 'number') {
        log('Expected `args.value` arg to be a Number.');
      } else {
        fieldObject.eventValue = value;
      }
    }

    if (typeof nonInteraction !== 'undefined') {
      if (typeof nonInteraction !== 'boolean') {
        log('`args.nonInteraction` must be a boolean.');
      } else {
        fieldObject.nonInteraction = nonInteraction;
      }
    }

    if (typeof transport !== 'undefined') {
      if (typeof transport !== 'string') {
        log('`args.transport` must be a string.');
      } else {
        if (['beacon', 'xhr', 'image'].indexOf(transport) === -1) {
          log('`args.transport` must be either one of these values: `beacon`, `xhr` or `image`');
        }

        fieldObject.transport = transport;
      }
    }

    Object.keys(args)
      .filter(key => key.substr(0, 'dimension'.length) === 'dimension')
      .forEach((key) => {
        fieldObject[key] = args[key];
      });

    Object.keys(args)
      .filter(key => key.substr(0, 'metric'.length) === 'metric')
      .forEach((key) => {
        fieldObject[key] = args[key];
      });

    // Send to GA
    send(fieldObject, trackerNames);
  }
}

export default {
  initialize,
  ga,
  set,
  send,
  pageview,
  timing,
  event,
};
