import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from 'components/Common/Modal';

class PurchaseConfirmModal extends React.Component {
  handleCancel = (e) => {
    logger.debug('on handleCancel');
    e.preventDefault();
    this.props.onCancel();
  }

  handleConfirm = (e) => {
    logger.debug('on handleConfirm, package', this.props.pkg);
    e.preventDefault();
    this.props.onConfirm(this.props.pkg);
  }

  render() {
    const { pkg } = this.props;
    return (
      <ModalBase
        headerText="Confirm your purchase"
        size="medium"
        closable={false}
        footerType='double'
        body={(
          <>
            <div>You're about to buy <strong>{pkg.description}</strong> for <strong>{pkg.price}</strong>. Do you want to complete the purchase?</div>
          </>
        )}
        primaryButtonText="Yes"
        onClickPrimaryButton={this.handleConfirm}
        secondaryButtonText="Cancel"
        onClickSecondaryButton={this.handleCancel}
      />
    );
  }
}

PurchaseConfirmModal.propTypes = {
  pkg: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default PurchaseConfirmModal;
