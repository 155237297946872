import React from 'react';
import PropTypes from 'prop-types';
import { SafeAnchor, Toggle } from '@ahaui/react';
import ModalBase from 'components/Common/Modal';

class TOS extends React.Component {
  constructor() {
    super();
    this.state = {
      agreedTOS: false,
      allowedSendMsg: false,
    };
  }

  handleAcceptedTOS = (e) => {
    logger.debug('on handleAcceptedTOS');
    e.preventDefault();
    const gdpr = { gdpr_allow_send_msg: this.state.allowedSendMsg ? 1 : 0 };
    this.props.onAcceptTOS(gdpr);
  }

  handleBack = (e) => {
    e.preventDefault();
    this.props.onBack();
  }

  handleChangeAgreedTOS = () => {
    this.setState({
      agreedTOS: !this.state.agreedTOS,
    });
  }

  handleChangeAllowedSendMsg = () => {
    this.setState({
      allowedSendMsg: !this.state.allowedSendMsg,
    });
  }

  render() {
    const { agreedTOS, allowedSendMsg } = this.state;
    const { isCoppaSupported } = this.props;

    return (
      <ModalBase
        headerText="Terms of Service"
        size="medium"
        closable={false}
        footerType="double"
        body={(
          <>
            <div className="u-flex u-marginBottomSmall">
              <div className="u-flexGrow1 u-paddingRightMedium">
                I have read and accepted the

                <SafeAnchor
                  href="https://www.got-it.co/terms.html"
                  target="_blank"
                >
                  &nbsp;Terms of Service&nbsp;
                </SafeAnchor>

                and

                <SafeAnchor
                  href="https://www.got-it.co/privacy-policy.html"
                  target="_blank"
                >
                  &nbsp;Privacy Policy
                </SafeAnchor>
              </div>
              <Toggle
                nonLabel
                checked={agreedTOS}
                onClick={this.handleChangeAgreedTOS}
              />
            </div>
            {!isCoppaSupported && (
              <div className="u-flex">
                <div className="u-flexGrow1 u-paddingRightMedium">
                Allow PhotoStudy to send you messages regarding your acount
                </div>
                <Toggle
                  nonLabel
                  checked={allowedSendMsg}
                  onClick={this.handleChangeAllowedSendMsg}
                />
              </div>
            )}

          </>
        )}
        primaryButtonText="Complete Sign up"
        onClickPrimaryButton={this.handleAcceptedTOS}
        disablePrimaryButton={!agreedTOS}
        secondaryButtonText="Back"
        onClickSecondaryButton={this.handleBack}
      />
    );
  }
}

TOS.propTypes = {
  onAcceptTOS: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  isCoppaSupported: PropTypes.bool,
};

export default TOS;
