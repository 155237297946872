const Logger = require('js-logger');

export const initLogger = () => {
  Logger.useDefaults();

  if (process.env.REACT_APP_ENV === 'prod') {
    Logger.setLevel(Logger.OFF);
  }

  window.logger = Logger;
  logger.debug({ window });
};