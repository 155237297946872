import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalBase from 'components/Common/Modal';
import { resetTimeOut } from '../../../../../actions/chat.action';


class IdleWarningModal extends React.Component {
  handleResetTimeOut = () => {
    logger.debug('on handleResetTimeOut');
    const { qid } = this.props.question;
    this.props.onResetingTimeout(qid);
    this.props.onResetedTimeOut(qid);
  }

  handleClose = () => {
    logger.debug('on handleClose');
    this.props.onClose();
  }


  render() {
    return (
      <ModalBase
        headerText="Still working?"
        size="small"
        closable={true}
        onHide={this.handleClose}
        body={(
          <p className="u-marginBottomNone">Your problem will close due to inactivity. Let us know if you're still working.</p>
        )}
        primaryButtonText="I'm still working"
        onClickPrimaryButton={this.handleResetTimeOut}
      />
    );
  }
}

IdleWarningModal.propTypes = {
  question: PropTypes.shape().isRequired,
  onResetingTimeout: PropTypes.func.isRequired,
  onResetedTimeOut: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  question: state.chat.question,
});

const mapDispatchToProps = dispatch => ({
  onResetingTimeout: qid => dispatch(resetTimeOut(qid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IdleWarningModal);
