import { PROMISE_RUN } from '../constants/promise.constant';
import defaultState from '../store/defaultState';

import {
 BRAINTREE_TOKEN_GET,
 PAYMENT_METHOD_GET,
 REWARD_MODAL_HIDE,
} from '../constants/storefront.constant';

import {
  SURGE_INFO_GET
} from '../constants/user.constant';


const storefrontReducer = (state = defaultState.storefront, action) => {
  if (action.type === PROMISE_RUN) {
    if (action.key === BRAINTREE_TOKEN_GET) {
      let result = action.payload.result;
      if (result) {
        return { ...state, token: result.token };
      }
    }
    if (action.key === SURGE_INFO_GET) {
      let result = action.payload.result;
      if (result) {
        return {
          ...state,
          loaded: true,
          balance: result.sessions_count,
          title: (result.purchase_info ? result.purchase_info.title : ''),
          subtitle: (result.purchase_info ? result.purchase_info.subtitle : ''),
          packages: (result.purchase_info ? result.purchase_info.purchase_options : null),
          promo_ts: (result.purchase_info ? result.purchase_info.promo_ts || 0 : 0),
          modal: result.modal || null,
        };
      }
    }

    if (action.key === PAYMENT_METHOD_GET) {
      let result = action.payload.result;
      if (result) {
        return {
          ...state,
          defaultMethod: {
            text: result.text,
            image: result.image,
            methodType: result.methodType,
            cardType: result.cardType,
          }
        };
      }
    }
  }

  if (action.type === REWARD_MODAL_HIDE) {
    return {
      ...state,
      modal: null,
    };
  }

  return state;
};

export default storefrontReducer;
