import React from 'react';

import StoreFront from '../Common/StoreFront';
import QuestionActiveBar from '../Common/QuestionActiveBar';

class StorePage extends React.Component {
  render() {
    return (
      <>
        <h1 className="sr-only">Store</h1>
        <StoreFront type={StorePage.TYPE} />
        <QuestionActiveBar />
      </>
    );
  }
}

StorePage.TYPE = 0; //'tab'

export default StorePage;
