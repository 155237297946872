import React, { useState } from 'react';
import { Button, Toggle, SafeAnchor, Icon } from '@ahaui/react';
import { useSelector } from 'react-redux';

import config from 'configuration';
import { getBrowser } from 'utils/app';
import AccessToken from 'utils/token';

import QuestionActiveBar from 'components/Common/QuestionActiveBar';


const GOTIT_TERMS_LINK = 'https://www.got-it.co/terms.html';

const Privacy = ({ handleGoBack }) => {
  const initCanStoreInfo = true;
  const initCanShareInfo = true;
  const initCanSendMessage = true;

  const [canStoreInfo, setCanStoreInfo] = useState(initCanStoreInfo);
  const [canShareInfo, setCanShareInfo] = useState(initCanShareInfo);
  const [canSendMessage, setCanSendMessage] = useState(initCanSendMessage);

  const buttonDisabled = (
    canStoreInfo === initCanStoreInfo &&
    canShareInfo === initCanShareInfo &&
    canSendMessage === initCanSendMessage
  );


  const user = useSelector(({ user }) => user);

  const openEmailClient = () => {
    const subject = 'Request to change privacy settings';

    const body = `
Please update my Privacy Preferences as below: (Choose to turn on or off for each option):
Allow PhotoStudy to store and audit your profile and session information: ${canStoreInfo ? 'ON' : 'OFF'}
Allow PhotoStudy to share your information with 3rd party service partners (See who they are: ${GOTIT_TERMS_LINK}): ${canShareInfo ? 'ON' : 'OFF'}
Allow PhotoStudy to send you messages regarding your account: ${canSendMessage ? 'ON' : 'OFF'}

App Version: ${config.appVersion}
Browser: ${getBrowser()}
Browserprint: ${AccessToken.getBrowserId()}
User ID: ${user.uid}
Additional Information: ${user.support_priority} : ${user.payment_transaction_count} : ${user.consumed_credit_count}
PRVC

Sent from Web app
    `.trim();

    const mailLink = `mailto:support@photostudy.co?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailLink, '_self');
  };

  return (
    <>
      <div className="Container Container--fluid u-paddingVerticalLarge">
        <div className="u-marginHorizontalAuto" style={{ width: '100%', maxWidth: 560 }}>
          <Button
            variant="secondary"
            size="small"
            className="u-marginBottomMedium"
            onClick={handleGoBack}
          >
            <Button.Icon><Icon name="arrowRoundBack" size="extraSmall" /></Button.Icon>
            <Button.Label>Back</Button.Label>
          </Button>

          <div className="u-text400 u-fontMedium u-marginBottomSmall">
            Current Privacy Settings
          </div>
          <div className="u-marginBottomLarge">
            <div className="u-flex u-alignItemsCenter u-paddingVerticalSmall u-borderBottom u-borderLightest">
              <div className="u-flexGrow-1 u-text200">
                Allow PhotoStudy to store and audit your profile and session information
              </div>
              <div className="u-flex u-alignItemsCenter u-paddingLeftExtraSmall">
                <Toggle
                  id="store-info"
                  nonLabel
                  checked={canStoreInfo}
                  onClick={() => setCanStoreInfo(!canStoreInfo)}
                  className="u-scale75 u-flex"
                />
              </div>
            </div>
            <div className="u-flex u-alignItemsCenter u-paddingVerticalSmall u-borderBottom u-borderLightest">
              <div className="u-flexGrow-1 u-text200">
                Allow PhotoStudy to share your information with 3rd party service partners
                {' ('}
                <SafeAnchor href={GOTIT_TERMS_LINK} target="_blank" className="u-textUnderline">Who are they?</SafeAnchor>
                {')'}
              </div>
              <div className="u-flex u-alignItemsCenter u-paddingLeftExtraSmall">
                <Toggle
                  id="share-info"
                  nonLabel
                  checked={canShareInfo}
                  onClick={() => setCanShareInfo(!canShareInfo)}
                  className="u-scale75 u-flex"
                />
              </div>
            </div>
            <div className="u-flex u-alignItemsCenter u-paddingVerticalSmall u-borderBottom u-borderLightest">
              <div className="u-flexGrow-1 u-text200">
                Allow PhotoStudy to send you messages regarding your account
              </div>
              <div className="u-flex u-alignItemsCenter u-paddingLeftExtraSmall">
                <Toggle
                  id="send-message"
                  nonLabel
                  checked={canSendMessage}
                  onClick={() => setCanSendMessage(!canSendMessage)}
                  className="u-scale75 u-flex"
                />
              </div>
            </div>
          </div>
          <div className="u-textGray u-text200 u-marginBottomSmall">
            Tap on the button below to send us an email about your privacy preference request.
          </div>
          <div className="u-marginBottomLarge">
            <Button
              variant="primary"
              width="full"
              disabled={buttonDisabled}
              onClick={openEmailClient}
            >
              <Button.Label>
                SEND EMAIL
              </Button.Label>
            </Button>
          </div>
        </div>
      </div>

      <QuestionActiveBar />
    </>
  );
};

export default Privacy;
