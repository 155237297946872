import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from 'components/Common/Modal';

import Tracking from '../../../../../utils/tracking';

export default class ServerMsg extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired
  }

  static contextTypes = {
    openSupport: PropTypes.func
  }

  handleClose = () => {
    this.props.onClose();
  }
  handleSupport = () => {
    const subject = 'PhotoStudy Login/Signup Help';
    this.context.openSupport({ subject });
    Tracking.sendFeedback({ source: 'auth-error' });
    this.props.onClose();
  }
  render () {
    return (
      <ModalBase
        headerText="Error"
        size="small"
        closable={false}
        footerType="vertical-double"
        body={(
          <>
            <p className="u-marginBottomNone">{this.props.message}</p>
          </>
        )}
        primaryButtonText="Support"
        onClickPrimaryButton={this.handleSupport}
        secondaryButtonText="Close"
        onClickSecondaryButton={this.handleClose}
      />
    );
  }
}
