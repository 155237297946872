import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ahaui/react';

class EmailElement extends React.Component {
  handleChangeEmail = (e) => {
    if (!this.props.disabled) {
      this.props.onChange(e.target.value);
    }
  }

  render() {
    const { disabled, errorMessage, email } = this.props;

    return (
      <Form.Group controlId="login.email">
        <Form.Label>Enter your email address</Form.Label>
        <Form.Input
          isInvalid={!!errorMessage}
          type="text"
          placeholder="email@address.com"
          value={email}
          onChange={this.handleChangeEmail}
          disabled={disabled}
        />
        <Form.Feedback type="invalid" visible={!!errorMessage} role="alert">
          {errorMessage}
        </Form.Feedback>
      </Form.Group>
    );
  }
}

EmailElement.propTypes = {
  errorMessage: PropTypes.oneOfType([
    PropTypes.string, PropTypes.element,
  ]),
  email: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

EmailElement.defaultProps = {
  errorMessage: '',
  email: '',
  disabled: false,
  onChange: () => {},
};

export default EmailElement;
