import React from 'react';
import PropTypes from 'prop-types';

import ModalBase from 'components/Common/Modal';
import CaptureKeysMac1 from 'assets/images/capture-keys-mac-1.svg';
import CaptureKeysMac2 from 'assets/images/capture-keys-mac-2.svg';
import CaptureKeysWindows1 from 'assets/images/capture-keys-windows-1.svg';
import CaptureKeysWindows2 from 'assets/images/capture-keys-windows-2.svg';

class ScreenshotInfoModal extends React.Component {
  handleClose = () => {
    logger.debug('on handleClose');
    this.props.onClose();
  }

  render() {
    const { platform } = navigator;
    logger.debug('OS platform', platform);
    let imageStep1;
    let imageStep2;
    if (platform.toLowerCase().includes(ScreenshotInfoModal.platform.MAC)) {
      imageStep1 = CaptureKeysMac1;
      imageStep2 = CaptureKeysMac2;
    } else {
      imageStep1 = CaptureKeysWindows1;
      imageStep2 = CaptureKeysWindows2;
    }

    return (
      <ModalBase
        headerText="How to take a screenshot"
        size="medium"
        closable={true}
        onHide={this.handleClose}
        showFooter={false}
        body={(
          <>
            <p>Step 1: Go to the page where you want to take a screenshot and hold down the keys:</p>
            <p className="u-textCenter"><img src={imageStep1} alt="" /></p>
            <p>Step 2: Come back to this page and hold down the keys:</p>
            <p className="u-textCenter"><img src={imageStep2} alt="" /></p>
          </>
        )}
      />
    );
  }
}


ScreenshotInfoModal.platform = {
  MAC: 'mac',
};

ScreenshotInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ScreenshotInfoModal;
