import React from 'react';
import PropTypes from 'prop-types';

import AccountKitEmail from './AccountKitEmail';

class Steps extends React.Component {

  handleStartSignupAccountKitEmail = (accessToken) => {
    this.props.onSignup(accessToken);
  }

  handleLoggedinAccountKitEmail = (auth) => {
    this.props.onAuthenticated(auth);
  }

  handleLoginAccountKitEmailError = (data, errorCode, info) => {
    this.props.onError(data, errorCode, info);
  }

  render() {
    return (
      <section className="gi-landingBSection2 u-textCenter">
        <div className="gi-Container">
          <h1>All it takes is 3 simple steps.</h1>

          <div className="gi-Grid gi-Grid--alignCenter">
            <div className="gi-Grid-cell u-sizeFill item">
              <img src="assets/images/old/landingB/landingB-step-1.svg" alt="" />
              <p className="u-fontSizeMdPlus">Post your question</p>
            </div>
            <div className="gi-Grid-cell u-sizeFit u-flexAlignSelfCenter">
              <img className="u-marginBottom5 u-marginLeftRight3" src="https://via.placeholder.com/500" alt="" />
            </div>
            <div className="gi-Grid-cell u-sizeFill item">
              <img src="https://via.placeholder.com/500" alt="" />
              <p className="u-fontSizeMdPlus">Connect to Expert Tutor in seconds</p>
            </div>
            <div className="gi-Grid-cell u-sizeFit u-flexAlignSelfCenter">
              <img className="u-marginBottom5 u-marginLeftRight3" src="https://via.placeholder.com/500" alt="" />
            </div>
            <div className="gi-Grid-cell u-sizeFill item">
              <img src="https://via.placeholder.com/500" alt="" />
              <p className="u-fontSizeMdPlus">Chat with your Expert</p>
            </div>
          </div>
          <div className="u-textCenter u-marginTop5">
            <AccountKitEmail
              buttonText={"ASK NOW"}
              onSignup={this.handleStartSignupAccountKitEmail}
              onAuthenticated={this.handleLoggedinAccountKitEmail}
              onError={this.handleLoginAccountKitEmailError}
            />
          </div>
        </div>
      </section>
    );
  }
}


Steps.propTypes = {
  onSignup: PropTypes.func.isRequired,
  onAuthenticated: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export default Steps;
