import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Composer } from '@ahaui/react';
import { questionStateConsts } from 'constants/common.constant';
import { resetTimeOut, sendMessage } from 'actions/chat.action';

export class TypeBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
    this.previousTime = 0;
  }

  /**
   * Handle trigger reset timeout
   * Will send even if trigger is enabled and at least after 5s from previous event.
  */
  triggerTimeout = () => {
    const {
      question: {
        processing_status,
        qid,
      },
      onResetingTimeout,
    } = this.props;

    if (processing_status == questionStateConsts.EXPLAINED) {
      if (Date.now() - this.previousTime >= 5000) {
        logger.debug('triggered reset timeout');
        onResetingTimeout(qid);
        this.previousTime = Date.now();
      }
    }
  }

  sendMessage = () => {
    const { question, onSendingMessage } = this.props;
    const { text } = this.state;
    const trimmedText = text.trim();

    if (!trimmedText) {
      return;
    }

    onSendingMessage(question.qid, trimmedText);
    this.setState({ text: '' });
  }

  /**
   * Send message.
   */
  handleSendButtonClicked = (e) => {
    logger.debug('on handleMessageSend - send button clicked');
    e.preventDefault();
    this.sendMessage();
  }

  /**
   * Handler events can happend when user typing in input box
   */
  handleKeydown = (e) => {
    if (e.keyCode === 13) {
      logger.debug('on handle send message - pressed enter');
      e.preventDefault();
      this.sendMessage();
    }
    this.triggerTimeout();
  }

  handleChange = (e) => {
    this.setState({ text: e.target.value });
  }

  render() {
    const { expertTyping, question } = this.props;
    const { text } = this.state;

    const showExpertWorkingQuote = !expertTyping && question.processing_status == questionStateConsts.INPROGRESS;

    return (
      <div className="u-positionRelative">
        {showExpertWorkingQuote && (
          <div className="u-positionAbsolute u-positionBottom-100 u-positionLeft u-positionRight">
            <div className="u-positionRelative u-flexInline u-paddingHorizontalSmall u-paddingVerticalExtraSmall">
              <div className="u-positionAbsolute u-positionFull u-backgroundWhite u-opacityThreeQuarter" />
              <div className="u-positionRelative u-text100 u-textGray">Give me a minute I’m working...</div>
            </div>
          </div>
        )}
        <Composer
          disabledAttachButton
          disabledSendButton={false}
          sendButtonActive={!!text.trim()}
          tooltipAttachButton="" // to silence error
          tooltipSendButton="" // to silence error
          onChange={this.handleChange}
          onKeyDown={this.handleKeydown}
          inputProps={{
            value: text,
            className: 'u-webkitScrollbar',
            placeholder: 'Write your message...',
            maxRows: 5,
          }}
          sendButtonProps={{
            onClick: this.handleSendButtonClicked,
          }}
        />
      </div>
    );
  }
}

TypeBox.propTypes = {
  question: PropTypes.shape().isRequired,
  expertTyping: PropTypes.bool.isRequired,
  onSendingMessage: PropTypes.func.isRequired,
  onResetingTimeout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  question: state.chat.question,
});

const mapDispatchToProps = dispatch => ({
  onSendingMessage: (qid, text) => dispatch(sendMessage(qid, text)),
  onResetingTimeout: qid => dispatch(resetTimeOut(qid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TypeBox);
