import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import config from 'configuration';
import { PageLayout, Button, Rating, Message } from '@ahaui/react';

import { ratingReason } from 'constants/common.constant';

import { rate, getEnglishQuestion } from 'actions/english.action';

import AccessToken from 'utils/token';
import { getBrowser } from 'utils/app';

const EnglishRating = () => {
  const { search } = useLocation();

  const dispatch = useDispatch();

  const [token, setToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [point, setPoint] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const openDefaultEmailClient = () => {
    const browser = getBrowser();
    const deviceId = AccessToken.getBrowserId();
    const { appVersion } = config;
    const mailLink = 'mailto:support@photostudy.co?'
      + 'subject=Got%20IT%20Study%20Feedback&'
      + 'body=%0A%5E%5Eplease%20enter%20feedback%20ABOVE%5E%5E%0A%0A%0A'
      + `App%20Version%3A%20${appVersion}%20%0A`
      + `Browser%3A%20${browser}%20%0A`
      + `Browserprint%3A%20${deviceId}%20%0A`
      // + `User%20ID%3A%20${user.uid}%20%0A`
      // + 'Additional%20Information'
      //   + `%3A%20${user.support_priority}`
      //   + `%20%3A%20${user.payment_transaction_count}`
      //   + `%20%3A%20${user.consumed_credit_count}%20%0A%0A`
      + 'Sent%20from%20Web%20app';

    window.open(mailLink, '_self');
  };

  const handleFeedback = async(reason) => {
    setIsProcessing(true);

    const { payload: { result, error } } = await dispatch(rate({ token, point, reason }));

    if (error) setErrorMessage(error.data.detail);

    if (result) setIsDone(true);

    setIsProcessing(false);
  };

  const handleSelectRating = (e) => {
    setPoint(parseInt(e.target.value));
  };

  const rateQuestion = async() => {
    setIsProcessing(true);

    const { payload: { result, error } } = await dispatch(rate({ token, point, reason: 0 }));

    if (result) setIsDone(true);

    if (error) setErrorMessage(error.data.detail);

    setIsProcessing(false);
  };

  useEffect(() => {
    if (token) {
      const getQuestion = async() => {
        const { payload: { result, error } } = await dispatch(getEnglishQuestion(token));

        if (result && result.rating_point > 0) setErrorMessage('Question is already rated!');

        if (error) setErrorMessage(error.data.detail.message);

        setIsLoading(false);
      };

      getQuestion();
    }
  }, [token, dispatch]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const tk = query.get('token');

    setToken(query.get('token'));

    if (!tk) setErrorMessage('Oops! There is something wrong. Please try again. ');
  }, [search]);

  if (isLoading) return null;

  return (
    <PageLayout
      className="u-backgroundLightest"
      style={{
        minHeight: '100vh'
      }}
    >
      <PageLayout.Header>
        <div className="u-flex u-justifyContentCenter u-paddingVerticalExtraLarge">
          <Link to="/">
            <img
              src={require('assets/images/logo.svg')}
              alt="PhotoStudy logo"
              style={{ height: 40 }}
            />
          </Link>
        </div>
      </PageLayout.Header>
      <PageLayout.Body className="u-flexColumn u-paddingBottomLarge">
        <div className="Container">
          {(errorMessage || isDone) ? (
            <>
              {errorMessage && (
                <section
                  className="u-block u-marginHorizontalAuto u-paddingHorizontalMedium u-paddingVerticalLarge"
                  style={{ maxWidth: 600 }}
                >
                  <div className="">
                    <Message variant="negative">
                      <Message.Container>
                        <Message.Content>
                          {errorMessage}
                        </Message.Content>
                      </Message.Container>
                    </Message>
                  </div>
                </section>
              )}

              {isDone && (
                <section
                  className="u-block u-marginHorizontalAuto u-border u-paddingHorizontalMedium u-paddingVerticalLarge u-backgroundWhite u-roundedMedium"
                  style={{ maxWidth: 600 }}
                >
                  <div className="u-flex u-justifyContentCenter u-marginBottomLarge">
                    <h3 className="u-marginNone">Thanks for rating!</h3>
                  </div>
                  <div className=" u-textCenter">
                    <p className="u-marginBottomMedium">Your session has been rated. Now you can close this window.</p>
                    <div className="u-marginBottomMedium">
                      <Rating readOnly value={point} />
                    </div>
                  </div>
                </section>
              )}
            </>
          ) : (
            <>
              {(point === 0 || point > 2) && (
                <section
                  className="u-block u-marginHorizontalAuto u-border u-paddingHorizontalMedium u-paddingVerticalLarge u-backgroundWhite u-roundedMedium"
                  style={{ maxWidth: 600 }}
                >
                  {/* header: */}
                  <div className="u-flex u-justifyContentCenter u-marginBottomLarge">
                    <h3 className="u-marginNone">Rate your session</h3>
                  </div>
                  {/* body: */}
                  <div className=" u-textCenter">
                    <p className="u-marginBottomMedium">Please don’t forget to rate your Study Expert after you have completed reviewing their comments and recommendations.</p>
                    <div className="u-marginBottomMedium">
                      <Rating
                        value={point}
                        onChange={handleSelectRating}
                      />
                    </div>
                  </div>
                  {/* footer: */}
                  <div className="u-paddingTopExtraSmall u-textCenter">
                    <Button
                      width="full"
                      disabled={point < 1 || isProcessing}
                      onClick={rateQuestion}
                    >
                      <Button.Label>Submit</Button.Label>
                    </Button>
                  </div>
                </section>
              )}

              {(point > 0 && point < 3) && (
                <section
                  className="u-block u-marginHorizontalAuto u-border u-paddingHorizontalMedium u-paddingVerticalLarge u-backgroundWhite u-roundedMedium"
                  style={{ maxWidth: 600 }}
                >
                  {/* header: */}
                  <div className="u-flex u-justifyContentCenter u-positionRelative u-marginBottomLarge">
                    <h3 className="u-marginNone">What went wrong</h3>
                  </div>
                  {/* body: */}
                  <div className="u-textCenter">
                    <p>We’re sorry to hear that you didn’t have a 5-star experience.</p>
                  </div>
                  {/* footer: */}
                  <div className="u-paddingTopExtraSmall">
                    <div className="u-marginBottomSmall">
                      <Button
                        variant="secondary"
                        width="full"
                        onClick={() => handleFeedback(ratingReason.BAD_EXPLANATION)}
                      >
                        <Button.Label>Bad Explanation</Button.Label>
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="secondary"
                        width="full"
                        onClick={() => handleFeedback(ratingReason.TOOK_TOO_LONG)}
                      >
                        <Button.Label>Took too Long</Button.Label>
                      </Button>
                    </div>
                  </div>
                </section>
              )}
            </>
          )}
        </div>
      </PageLayout.Body>
    </PageLayout>
  );
};
export default EnglishRating;
