import React from 'react';
import {
  Header as BaseHeader,
  SafeAnchor,
  Logo,
} from '@ahaui/react';


const Header = () => (
  <BaseHeader>
    <BaseHeader.Brand>
      <SafeAnchor href="/">
        <Logo
          src={require('assets/images/logo.svg')}
          height="40"
          alt="PhotoStudy"
        />
      </SafeAnchor>
    </BaseHeader.Brand>

    <BaseHeader.Main>
      <BaseHeader.Right>
        <div className="u-text400">Online Tutoring, Available 24/7</div>
      </BaseHeader.Right>
    </BaseHeader.Main>
  </BaseHeader>
);

export default Header;
