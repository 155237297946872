import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  PageLayout,
} from '@ahaui/react';

import ctaBackground from 'assets/images/shared-session-cta-background.svg';
import Header from './Common/Header';
import ExpertSession from './Common/ExpertSession';
import PESSession from './Common/PESSession';

class FullSession extends React.Component {
  constructor() {
    super();
    this.state = {
      showCTA: false,
    }
  }

  componentDidMount() {
    this.checkToShowCTA();
    window.onscroll = (() => this.checkToShowCTA());
  }

  checkToShowCTA = () => {
    logger.debug('on scroll');
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      this.setState({ showCTA: true });
    }
  }

  handleSessionExpired = () => {
    logger.warn('Session is expired');
    this.props.onExpired();
  }

  render () {
    let showCTA = this.state.showCTA;
    let sessionView;
    let session = this.props.session;
    let isPES = session.is_pes;
    if (isPES == 1) {
      sessionView = <PESSession session={session} onExpired={this.handleSessionExpired} />
    } else {
      sessionView = <ExpertSession session={session} onExpired={this.handleSessionExpired} />
    }
    // check new signup user and get a free session.
    let isNewSignup = session.new_signup;
    let isGetFreeSessionFirstTime = isNewSignup && session.balance > 0;
    let ctaText = isGetFreeSessionFirstTime ? 'ASK YOUR FREE QUESTION' : 'ASK YOUR OWN QUESTION';

    return (
      <PageLayout
        style={{
          minHeight: '100vh'
        }}
      >
        <PageLayout.Header className="u-shadowSmall u-zIndex2">
          <Header />
        </PageLayout.Header>

        <PageLayout.Body>
          <div className="Container u-paddingTopMedium" style={{ paddingBottom: 70 }}>
            {sessionView}
          </div>
          {showCTA && (
            <div className="u-positionFixed u-positionBottom u-positionLeft u-positionRight">
              <div className="u-backgroundPrimary u-positionRelative u-cursorPointer" style={{ height: 56 }}>
                <img src={ctaBackground} className="u-positionAbsolute" style={{ left: '50%', transform: 'translate(-50%, 0)' }} alt="" />
                <Link to="/">
                  <button className="u-backgroundTransparent u-borderNone u-textUppercase u-textWhite u-positionAbsolute u-cursorPointer" style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>{ctaText}</button>
                </Link>
              </div>
            </div>
          )}
        </PageLayout.Body>
      </PageLayout>
    );
  }
}

FullSession.propTypes = {
  session: PropTypes.shape().isRequired,
  onExpired: PropTypes.func.isRequired,
}

export default FullSession;
