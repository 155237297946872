import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Form, Icon } from '@ahaui/react';

import Tracking from '../../../../utils/tracking';

import { selectMood } from '../../../../actions/askflow.action';

class Moods extends React.Component {
  constructor() {
    super();
    this.state = {
      toggle: false,
      selected: false,
    };
  }

  trackMoodAdded = () => {
    Tracking.askFlow({
      action: Moods.TRACK_ACTION,
    });
  }

  handleToggle = () => {
    logger.debug('on toggle mood list, state:', this.state.toggle);
    this.setState({
      toggle: !this.state.toggle,
    });
  }

  handleMoodClicked = (mood) => {
    logger.debug('on handleMoodClicked');
    this.props.onMoodSelect(mood);
    this.setState({
      toggle: !this.state.toggle,
    });
    this.trackMoodAdded();
  }

  render() {
    const { selectedMood, moods } = this.props;

    let placeElement;
    if (!selectedMood) {
      placeElement = (
        <div className="u-textDark u-flexGrow-1">
          Please select an option
        </div>
      );
    } else {
      placeElement = (
        <div className="u-textDark u-flexGrow-1 u-flex u-alignItemsCenter">
          <img src={selectedMood.image} className="u-widthMedium u-heightMedium u-marginRightExtraSmall" />
          <span>{selectedMood.body}</span>
        </div>
      );
    }

    return (
      <Form.Group controlId="ask.stem.mindset">
        <Form.Label>Description</Form.Label>
        <Dropdown
          onToggle={this.handleToggle}
          onKeyPress={this.handleToggle}
          show={this.state.toggle}
          drop="up"
          aria-label="Please select mood"
          tabIndex={0}
        >
          <Dropdown.Toggle className="u-textLight u-lineHeightNone">
            <div className="FormInput u-flex u-widthFull u-webkitScrollbar Dropdown-toggle u-cursorPointer u-textLight u-lineHeightNone">
              {placeElement}
              <div className="u-flexShrink-0">
                <Icon name="arrowDown" size="extraSmall" />
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Container className="u-paddingVerticalExtraSmall u-widthFull">
            {moods.map((mood) => mood.type === Moods.type.REGULAR && (
              <div
                key={mood.id}
                onClick={() => this.handleMoodClicked(mood)}
                onKeyPress={() => this.handleMoodClicked(mood)}
                className={`${mood.id === selectedMood?.id ? 'u-fontBold' : ''} u-flex u-alignItemsCenter u-paddingHorizontalSmall u-paddingVerticalTiny hover:u-backgroundLightest u-cursorPointer`}
                tabIndex={0}
              >
                <img src={mood.image} alt="" className="u-widthMedium u-heightMedium u-marginRightExtraSmall" />
                {mood.body}
              </div>
            ))}
          </Dropdown.Container>
        </Dropdown>
      </Form.Group>
    );
  }
}

Moods.type = {
  REGULAR: 1,
  OTHER: 2,
};

Moods.TRACK_ACTION = 'added_mindset';

Moods.propTypes = {
  moods: PropTypes.array.isRequired,
  onMoodSelect: PropTypes.func.isRequired,
  selectedMood: PropTypes.shape(),
};

Moods.defaultProps = {
  selectedMood: null,
};

const mapStateToProps = state => ({
  moods: state.surgeInfo.moods,
  selectedMood: state.askflow.selectedMood,
});

const mapDispatchToProps = dispatch => ({
  onMoodSelect: mood => dispatch(selectMood(mood)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Moods);
