import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SafeAnchor, Media } from '@ahaui/react';
import { timeAgo } from 'utils/util';

export class QItem extends React.Component {
  questionStatuses = {
    0: { title: 'Connecting', className: 'u-textPositive' },
    4: { title: 'In progress', className: 'u-textPrimary' },
    5: { title: 'Completed', className: 'u-textDark' },
    6: { title: 'Flagged', className: 'u-textNegative' },
  };

  handleOnClick = (e) => {
    const { history, question } = this.props;

    e.preventDefault();
    history.push(`/my-sessions/${question.qid}`);
  }

  render() {
    const { question, surgeInfo, user, onClick } = this.props;

    const questionCreated = (+question.author.uid === +user.uid) ? question.created : question.updated;
    const { title, className } = question.discussion_time_left > 0
      ? this.questionStatuses['4']
      : this.questionStatuses[String(question.processing_status)];

    let subjectName = '';
    if (question.meta && question.meta.child_tid) {
      surgeInfo.subjects.forEach((subject) => {
        if (subject.tid === question.meta.child_tid) {
          subjectName = subject.title;
        }
      });
    }

    const hasAttachment = question.attachments && question.attachments.length > 0;

    let photo = require('assets/images/subject-basic-math.svg');
    if (question.subject && question.subject.title) {
      const subjectTitle = question.subject.title.toUpperCase();
      if (subjectTitle === 'ALGEBRA') {
        photo = require('assets/images/subject-algebra.svg');
      } else if (subjectTitle === 'CALCULUS') {
        photo = require('assets/images/subject-calculus.svg');
      } else if (subjectTitle === 'TRIGONOMETRY') {
        photo = require('assets/images/subject-trigonometry.svg');
      } else if (subjectTitle === 'GEOMETRY') {
        photo = require('assets/images/subject-geometry.svg');
      } else if (subjectTitle === 'CHEMISTRY') {
        photo = require('assets/images/subject-ap-chemistry.svg');
      } else if (subjectTitle === 'PHYSICS') {
        photo = require('assets/images/subject-physics.svg');
      } else if (subjectTitle === 'SAT MATH') {
        photo = require('assets/images/subject-sat-act-prep-math.svg');
      }
    }

    return (
      <SafeAnchor onClick={onClick || this.handleOnClick} className="u-textDark hover:u-textDark hover:u-textDecorationNone">
        <div className="u-border u-roundedMedium u-overflowHidden">
          {hasAttachment ? (
            <div className="u-borderBottom">
              <Media
                as="img"
                src={question.attachments[0].url}
                alt="Question attachment"
              />
            </div>
          ) : (
            <div className="u-borderBottom u-positionRelative">
              <div className="Media u-positionRelative u-block u-paddingNone u-overflowHidden Media--16by9"></div>
              <div className="u-positionAbsolute u-positionFull u-flex u-alignItemsCenter u-justifyContentCenter">
                <img
                  src={photo}
                  alt={subjectName}
                />
              </div>
            </div>
          )}
          <div className="u-textCenter u-paddingExtraSmall">
            <div className={`u-fontMedium ${className}`}>{title}</div>
            <div className="u-text100 u-marginBottomTiny">{`${subjectName} by ${question.expert_name}`}</div>
            <div className="u-text100 u-textGray u-marginBottomTiny">{timeAgo(questionCreated)}</div>
          </div>
        </div>
      </SafeAnchor>
    );
  }
}

QItem.propTypes = {
  surgeInfo: PropTypes.shape().isRequired,
  question: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
  showSubject: PropTypes.bool,
  history: PropTypes.shape().isRequired,
};

QItem.defaultProps = {
  onClick: null,
  showSubject: true,
};


const mapStateToProps = state => ({
  surgeInfo: state.surgeInfo,
  user: state.user,
});

export default connect(mapStateToProps)(withRouter(QItem));
