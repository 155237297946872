import { PROMISE_RUN } from '../constants/promise.constant';
import defaultState from '../store/defaultState';

import {
  SURGE_INFO_GET,
} from '../constants/user.constant';

const surgeInfoReducer = (state = defaultState.surgeInfo, action) => {
  if (action.type === PROMISE_RUN) {
    if (action.key === SURGE_INFO_GET) {
      const { result } = action.payload;
      if (result) {
        return {
          ...state,
          moods: result.mindsets,
          subjects: result.subjects,
          modal: result.modal || null,
          hasFreeSession: result.has_free_session,
        };
      }
    }
  }

  return state;
};

export default surgeInfoReducer;
