import React from 'react';
import PropTypes from 'prop-types';

import { modal } from '../../../utils/modal';

import {
  ERROR_CODE_DEVICE_CHECK,
  ERROR_CODE_TRIO_BLOCKED,
  ERROR_CODE_PROHIBITED
} from '../../../constants/error.constant';

import OneAccPerDevice from './errors/OneAccPerDevice';
import TrioBlocked from './errors/TrioBlocked';
import General from './errors/General';
import ServerMsg from './errors/ServerMsg';

class ErrorContainer extends React.Component {
  handleCloseError = () => {
    this.props.onClose();
  }
  render() {
    if (this.props.code === ERROR_CODE_DEVICE_CHECK) {
      return <OneAccPerDevice onClose={this.handleCloseError} />;
    }
    if (this.props.code === ERROR_CODE_TRIO_BLOCKED) {
      return <TrioBlocked onClose={this.handleCloseError} />;
    }
    if (this.props.code === ERROR_CODE_PROHIBITED) {
      const { message } = this.props;
      return <ServerMsg message={message} onClose={this.handleCloseError} />;
    }
    return <General onClose={this.handleCloseError} />;
  }
}

ErrorContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  code: PropTypes.string
};

ErrorContainer.defaultProps = {
  code: ''
};

export default modal(ErrorContainer, { size: 'small' });