import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Header,
  Logo,
  Button,
  SafeAnchor,
} from '@ahaui/react';

import Tracking from '../../../utils/tracking';
import {
  hasSharedSessionPoolSupport,
  hasStemSupport,
  hasTrioSupport,
  hasCoppaSupport,
} from '../../../utils/feature-support';

import { launchAskSource } from '../../../constants/common.constant';

class MainHeader extends React.Component {
  trackLaunchAsk = () => {
    Tracking.launchAsk({
      source: launchAskSource.MAIN_HEADER_LOGO,
    });
  }

  handleGotoAskTab = (e) => {
    logger.debug('on handleGotoAskTab');
    e.preventDefault();
    this.props.history.push('/');
    this.trackLaunchAsk();
  }

  handleGotoStoreTab = (e) => {
    logger.debug('on handleGotoStore');
    e.preventDefault();
    this.props.history.push('/storefront');
  }

  render() {
    const { balance } = this.props.storefront;
    const { enabledFeatures } = this.props;
    const isTrio = hasTrioSupport(enabledFeatures);
    const isTRIOPool = isTrio && hasSharedSessionPoolSupport(enabledFeatures);
    const isStemSupported = hasStemSupport(enabledFeatures);

    const displaySessionBalance = !isTRIOPool && isStemSupported && !hasCoppaSupport(enabledFeatures);

    return (
      <Header fullWidth>
        <Header.Brand>
          <SafeAnchor onClick={this.handleGotoAskTab}>
            <Logo
              src={require('assets/images/logo.svg')}
              height="40"
              alt="PhotoStudy"
            />
          </SafeAnchor>
        </Header.Brand>

        {displaySessionBalance && (
          <Header.Main>
            <Header.Right>
              <Button variant="primary_outline" onClick={this.handleGotoStoreTab}>
                <Button.Label>Session balance: {balance}</Button.Label>
              </Button>
            </Header.Right>
          </Header.Main>
        )}
      </Header>
    );
  }
}

MainHeader.propTypes = {
  storefront: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  enabledFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

MainHeader.defaultProps = {
  enabledFeatures: [],
};

const mapStateToProps = state => ({
  storefront: state.storefront,
  enabledFeatures: state.user.enabled_features,
});

const mapPropsToDispatch = null;

export default withRouter(connect(mapStateToProps, mapPropsToDispatch)(MainHeader));
