import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SafeAnchor } from '@ahaui/react';
import moment from 'moment';

import Tracking from '../../utils/tracking';

import { launchAskSource } from '../../constants/common.constant';

import {
  checkOpenQuestion,
  loadQuestion,
} from '../../actions/question.action';

export class QuestionActiveBar extends React.Component {
  constructor() {
    super();
    this.state = {
      openQuestion: null,
    };
    this.checkTimer = 0;
    this.getOpenQuestion = this.getOpenQuestion.bind(this);
  }

  componentDidMount() {
    this.setUpGetOpenQuestion();
    this.getOpenQuestion();
  }

  componentWillUnmount() {
    window.clearInterval(this.checkTimer);
  }

  trackLaunchAsk = () => {
    Tracking.launchAsk({
      source: launchAskSource.ACTIVE_BAR,
    });
  }

  setUpGetOpenQuestion = () => {
    this.checkTimer = window.setInterval(this.getOpenQuestion, 15 * 1000);
  }

  async getOpenQuestion() {
    const data = await this.props.onCheckOpenQuestion();
    const { result } = data.payload;
    if (result && result.can_ask == 0) {
      const dt = await this.props.onLoadQuestion(result.open_qid);
      const res = dt.payload.result;
      if (res) {
        this.setState({
          openQuestion: res.question,
        });
      }
    } else {
      this.setState({
        openQuestion: null,
      });
      window.clearInterval(this.checkTimer);
    }
  }

  handleGotoAskTab = (e) => {
    const { history } = this.props;

    logger.debug('on handleGotoAskTab');
    e.preventDefault();
    history.push('/stem');

    this.trackLaunchAsk();
  }


  render() {
    const { openQuestion } = this.state;

    if (openQuestion) {
      return (
        <div className="BottomBar u-positionFixed u-positionLeft u-positionRight u-positionBottom u-zIndex4">
          <SafeAnchor onClick={this.handleGotoAskTab} className="u-flex u-paddingVerticalExtraSmall u-paddingHorizontalSmall u-backgroundPrimary u-textWhite u-text100 hover:u-textDecorationNone hover:u-textWhite">
            <div className="u-flexGrow-1">
              Your Study Expert is working on your problem now. Click here to go to your chat session.
            </div>
            <div className="u-paddingLeftSmall u-flexShrink-0 u-hidden md:u-block">
              {moment.unix(openQuestion.created).fromNow()}
            </div>
          </SafeAnchor>
        </div>
      );
    }

    return null;
  }
}

QuestionActiveBar.propTypes = {
  onCheckOpenQuestion: PropTypes.func.isRequired,
  onLoadQuestion: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
};

const mapStateToProps = null;

const mapPropsToDispatch = dispatch => ({
  onCheckOpenQuestion: () => dispatch(checkOpenQuestion()),
  onLoadQuestion: qid => dispatch(loadQuestion(qid)),
});

export default withRouter(connect(mapStateToProps, mapPropsToDispatch)(QuestionActiveBar));
