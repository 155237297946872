export const VERIFY_TOKEN = 'verify_token';
export const FACEBOOK_LOGIN = 'facebook_login';
export const FACEBOOK_SIGNUP = 'facebook_signup';
export const ACCOUNTKIT_LOGIN = 'accountkit_login';
export const ACCOUNTKIT_SIGNUP = 'accountkit_signup';
export const ONE_TIME_CODE_REQUEST = 'one_time_code_request';
export const ONE_TIME_CODE_EXCHANGE = 'one_time_code_exchange';
export const ONE_TIME_CODE_VERIFY = 'one_time_code_verify';
export const ONE_TIME_CODE_SIGNUP = 'one_time_code_signup';
export const PRESET_LOGIN = 'preset_login';
export const SIGNUP_SUCCESS = 'signup_success';
export const LOG_OUT = 'log_out';
export const FORCE_LOGOUT = 'force_logout';
export const SURGE_INFO_GET = 'surge_info_get';
export const PROFILE_UPDATE = 'profile_update';
export const PROFILE_HOT_UPDATE = 'profile_hot_update';
export const PROFILE_SIGNUP_BEGIN = 'profile_signup_begin';
export const PROFILE_SIGNUP_END = 'profile_signup_end';
export const PROMO_CODE_REDEEM = 'promo_code_redeem';

export const PRESET_CHECK_WHITELIST = 'preset_check_whitelist';
export const PASSWORD_RECOVERY_REQUEST = 'password_recovery_request';
export const PASSWORD_RECOVERY_CHECK_CODE = 'password_recovery_check_code';
export const PASSWORD_RECOVERY_RESET = 'password_recovery_reset';
export const PASSWORD_CHECK = 'password_check';

export const STORAGE_AVATAR_KEY = 'gotit_avatar_url';
export const STORAGE_USERNAME_KEY = 'gotit_username';

export const ACCEPTED_TOS = 'accepted_tos';
export const GET_CAPTCHA = 'get_captcha';
