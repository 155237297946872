import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@ahaui/react';

const StatusBar = ({ message }) => (
  <div className="BottomBar u-positionFixed u-positionLeft u-positionRight u-positionBottom u-zIndex4">
    <div className="u-flex u-paddingVerticalExtraSmall u-paddingHorizontalSmall u-backgroundInformationLighter u-text200 u-alignItemsCenter u-justifyContentCenter">
      <Loader size="small" duration={700} className="u-marginRightExtraSmall" />
      {message}
    </div>
  </div>
);

StatusBar.propTypes = {
  message: PropTypes.string.isRequired,
};

export default StatusBar;
