import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from 'components/Common/Modal';

class ModalLogout extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired
  }

  handleClose = () => {
    this.props.onClose();
  }

  handleLogout = () => {
    this.props.onLogout();
  }

  render () {
    return (
      <ModalBase
        headerText="Log Out"
        size="small"
        onHide={this.handleClose}
        body={(
          <p className="u-marginBottomNone">Are you sure you want to log out?</p>
        )}
        footerType="vertical-double"
        primaryButtonText="Yes"
        onClickPrimaryButton={this.handleLogout}
        secondaryButtonText="No"
        onClickSecondaryButton={this.handleClose}
      />
    );
  }
}

export default ModalLogout;
