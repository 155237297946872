export const questionStateConsts = {
  CONNECTING: '0',
  INPROGRESS: '4',
  EXPLAINED: '5',
  FLAGGED: '6',
};
export const discussionStateConsts = {
  UNKNOWN: '0',
  NOT_STARTED: '10',
  STARTED: '11',
  STUDENT_ENDED: '12',
  LOST_CONNECTION: '13',
  TUTOR_ENDED: '14',
  TUTOR_LOST_CONNECTION: '15',
  EXPIRED: '19',
};
export const questionPendingReview = {
  ROUTING: '0',
  DEAD: '1',
};
export const ratingReason = {
  BAD_EXPLANATION: 4,
  TOOK_TOO_LONG: 5,
  SEND_MORE_FEEDBACK: 6,
};
export const FORM_URLENCODED = 'application/x-www-form-urlencoded';
export const MULTIPART_FORMDATA = 'multipart/form-data';
export const APPLICATION_JSON = 'application/json';
export const storefrontView = {
  PACKAGES: 'packages_view',
  BRAINTREE: 'braintree_view',
};
export const sessionEndedType = {
  STUDENT_END: 'student_end',
  STUDENT_FLAG: 'student_flag',
  IDLE_TIMEOUT: 'idle_timeout',
  EXPIRED: 'expired',
};
export const launchAskSource = {
  MAIN_HEADER_LOGO: 'header_logo_button',
  MAIN_NAV_TAB: 'tabbar',
  ACTIVE_BAR: 'active_bar',
  WHAT_IS_PHOTOSTUDY: 'what_is_photostudy',
};
export const EXTEND_ACTION = 'chat_extension';