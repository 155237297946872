import React from 'react';
import config from 'configuration';
import {
  SafeAnchor,
  Icon,
  Button,
} from '@ahaui/react';

import { getBrowser } from 'utils/app';
import AccessToken from 'utils/token';

import FacebookAuth from './FacebookAuth';
import EmailAuth from './EmailAuth';
import PresetEmailAuth from './PresetEmailAuth';

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      view: Footer.view.MAIN,
    };
    this.previousView = Footer.view.MAIN;
  }

  handleGotoLogin = () => {
    logger.debug('on handleGotoLogin');
    this.previousView = this.state.view;
    this.setState({
      view: Footer.view.LOGIN,
    });
  }

  handleGotoSignup = () => {
    logger.debug('on handleGotoSignup');
    this.previousView = this.state.view;
    this.setState({
      view: Footer.view.SIGNUP,
    });
  }

  handleBack = (e) => {
    logger.debug('on handleBack');
    e.preventDefault();
    this.setState({
      view: Footer.view.MAIN,
    });
  }

  handleOpenNeedHelpMail = (e) => {
    logger.debug('on openDefaultEmailClient');
    e.preventDefault();
    const { appVersion } = config;
    const browser = getBrowser();
    const deviceId = AccessToken.getDeviceId();
    const mailLink = 'mailto:support@photostudy.co?'
      + 'subject=PhotoStudy%20Error%20Feedback&'
      + 'body=%0A%5E%5Eplease%20enter%20feedback%20ABOVE%5E%5E%0A%0A%0A'
      + `App%20Version%3A%20${appVersion}%20%0A`
      + `Browser%3A%20${browser}%20%0A`
      + `Browserprint%3A%20${deviceId}%20%0A`
      + 'Sent%20from%20Share%20link%20app';
    window.open(mailLink, '_self');
  }

  render() {
    let view;

    switch (this.state.view) {
    case Footer.view.MAIN:
      view = (
        <>
          <h2 className="u-text500">View the solution</h2>
          <div>
            <Button style={{ width: 352 }} onClick={this.handleGotoSignup}>
              <Button.Label>Sign up</Button.Label>
            </Button>
          </div>
          <div className="u-marginTopExtraSmall">
            <small className="u-textGray">
              By Signing up you agree to our&nbsp;
              <SafeAnchor href="https://www.got-it.co/terms.html" className="u-textGray u-textUnderline">
                Terms of Service
              </SafeAnchor>
            </small>
          </div>
          <div className="u-marginTopSmall">
            Already have an account?&nbsp;
            <SafeAnchor onClick={this.handleGotoLogin}>
              Log in
            </SafeAnchor>
          </div>
        </>
      );
      break;
    case Footer.view.SIGNUP:
      view = (
        <>
          <Button variant="link" size="small" aria-label="Go back" className="u-positionAbsolute u-positionTop u-positionLeft" onClick={this.handleBack}>
            <Button.Icon>
              <Icon name="arrowRoundBack" size="extraSmall" />
            </Button.Icon>
            <Button.Label>Back</Button.Label>
          </Button>

          <h2 className="u-text500 u-marginBottomMedium">Sign up with</h2>
          <div>
            <EmailAuth signup />
            <FacebookAuth />
            <PresetEmailAuth signup />
          </div>
        </>
      );
      break;
    case Footer.view.LOGIN:
      view = (
        <>
          <Button variant="link" size="small" aria-label="Go back" className="u-positionAbsolute u-positionTop u-positionLeft" onClick={this.handleBack}>
            <Button.Icon>
              <Icon name="arrowRoundBack" size="extraSmall" />
            </Button.Icon>
            <Button.Label>Back</Button.Label>
          </Button>

          <h2 className="u-text500 u-marginBottomMedium">Log in with</h2>
          <div>
            <EmailAuth />
            <FacebookAuth />
            <PresetEmailAuth />
          </div>
        </>
      );
      break;
    default:
      break;
    }

    return (
      <div className="u-positionFixed u-positionBottom u-positionLeft u-positionRight">
        <div style={{ height: 48, backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.85))' }} />
        <div
          className="u-textCenter u-paddingVerticalSmall"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.85)', backdropFilter: 'blur(1px)', height: 180 }}
        >
          <div className="Container u-positionRelative">
            {view}
          </div>
        </div>
      </div>
    );
  }
}

Footer.view = {
  MAIN: 'main',
  LOGIN: 'login',
  SIGNUP: 'signup',
  GRADE_MODAL: 'grade',
  TRIO_BLOCKED_MODAL: 'trio_blocked_modal',
};

Footer.AUTH_METHOD_FACEBOOK = 'facebook';
Footer.AUTH_METHOD_ACCOUNTKIT_EMAIL = 'account_kit_email';
Footer.AUTH_METHOD_ACCOUNTKIT_PHONE = 'account_kit_phone';

Footer.ERROR_CODE_TRIO_BLOCKED = 'trio_blocked';
Footer.ERROR_CODE_DEVICE_CHECK = 'device_register_multi_account';
Footer.ERROR_CODE_PROHIBITED = 'prohibited';
Footer.ERROR_CODE_GENERAL = 'general';

export default Footer;
