import localConfig from './local';
import devConfig from './development';
import stagingConfig from './staging';
import prodConfig from './production';

// Settings configured here will be merged into the final config object.
const baseConfig = {
  MAX_HISTORY_ITEMS: 10,
  baseUrl: '',
  appVersion: '',
  grades: [
    { id: 13, title: 'College and Higher' },
    { id: 12, title: 'High School 12th' },
    { id: 11, title: 'High School 11th' },
    { id: 10, title: 'High School 9th/10th' },
    { id: -1, title: '6th/7th/8th or Other' },
  ],
  DESCRIPTION_REQUIRED_LENGTH: 40,
  APP_ID: 'photostudy',
};

let config = localConfig;

if (process.env.REACT_APP_ENV === 'dev') config = devConfig;
if (process.env.REACT_APP_ENV === 'prod') config = prodConfig;

export default Object.freeze(Object.assign({}, baseConfig, config));
