import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import {
  PageLayout,
  Media,
  Button,
  SafeAnchor,
  Icon,
  Carousel,
} from '@ahaui/react';

import Account from './Account';
import SupportModal from './Account/modals/Support';

import Tracking from '../../utils/tracking';
import { queryToObject } from '../../utils/util';
import { navSend, navClear } from '../../utils/app';
import AccessToken from '../../utils/token';

import userAvatar1 from 'assets/images/old/user-1@2x.png';
import userAvatar2 from 'assets/images/old/user-2@2x.png';
import userAvatar3 from 'assets/images/old/user-3@2x.png';

const QUOTES = [
  {
    name: 'Gia R',
    description: 'Econ Major, Duke U.',
    quote:
      'Before PhotoStudy, I was getting Ds in my college algebra course. However, thanks to PhotoStudy, I received a 95% on my final exam and ended the year with a 93% average. Before PhotoStudy, I was averaging a 70%. I thank you and most importantly my GPA thanks you!',
    avatarSrc: userAvatar1,
  },
  {
    name: 'Mary S.',
    description: 'Parent.',
    quote:
      "I’m a parent of high school kids taking advanced math. The Experts tells you where you messed up and what to do step-by-step. It's amazing if you ever get stuck and are frustrated with hard math!",
    avatarSrc: userAvatar2,
  },
  {
    name: 'Steve R.',
    description: 'Finance Major, SDU',
    quote:
      "This app is amazing it helped me understand something that I thought I'd never be able to comprehend! I've never bothered writing a review or feedback for anything but I felt the need to because I'm just so happy that this could potentially help me with my grades especially going into university this year.",
    avatarSrc: userAvatar3,
  },
];

export default class Landing extends React.Component {
  static contextTypes = {
    isFirstTime: PropTypes.bool.isRequired,
    notFirstTimeAnyMore: PropTypes.func.isRequired,
    openSupport: PropTypes.func.isRequired,
    tokenInfo: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    navClear('/login');
    this.state = { openSupportModal: false };
  }

  componentDidMount() {
    const trackingData = {
      is_new_user: this.context.isFirstTime ? 'YES' : 'NO',
    };
    const search = this.props.location.search.substring(1);
    const params = queryToObject(search);

    // Save UTMs.
    const utmSource = params.utm_source;
    const utmContent = params.utm_content;
    const utmCampaign = params.utm_campaign;
    const utmMedium = params.utm_medium;
    const utmTerm = params.utm_term;
    if (utmSource) {
      trackingData.utm_source = utmSource;
      trackingData.utm_content = utmContent;
      trackingData.utm_campaign = utmCampaign;
      trackingData.utm_medium = utmMedium;
      trackingData.utm_term = utmTerm;
    }
    // Move appOpened event to from App.js to here because want to keep the
    // distinct ID consistent for appOpen event when user redirected from SEO pages.
    Tracking.appOpened();
    Tracking.viewLanding(trackingData, { location: this.props.location });
    this.context.notFirstTimeAnyMore();
  }

  handleClickSupport = (e) => {
    e.preventDefault();
    this.setState({ openSupportModal: true });
  };

  handleCloseSupport = () => {
    this.setState({ openSupportModal: false });
  };

  render() {
    if (this.context.tokenInfo.missing_password && AccessToken.getAuthKey()) {
      navSend('/login', {
        ...this.context.tokenInfo,
        from: 'missing_password',
      });
      return <Redirect to="/login" />;
    }

    return (
      <PageLayout
        style={{
          minHeight: '100vh',
        }}
      >
        <PageLayout.Body className="u-flexColumn">
          <section
            className="u-backgroundPrimaryDarker u-textWhite u-textCenter u-positionRelative"
            style={{
              backgroundImage: `url(${require('assets/images/bg-cover.svg')})`,
              backgroundRepeat: 'no-repeat',
              backgroundPositionX: 'calc(50vw)',
            }}
          >
            <div className="Container">
              <header className="u-flex u-marginTopLarge u-marginBottomExtraSmall">
                <Link className="navbar-brandLogo" to="/">
                  <img
                    src={require('assets/images/logo-header.svg')}
                    alt="PhotoStudy logo"
                    style={{ height: 48 }}
                  />
                </Link>
              </header>
              <div className="u-paddingVerticalLarge">
                <h1 className="u-text800 lg:u-text1100 u-fontRegular u-marginBottomMedium">
                  Stuck on a Math or Science Problem?
                  <br />
                  Get Help From an Expert Tutor in Seconds!
                </h1>
                <p className="u-marginBottomLarge">
                  Over 3 million study sessions held with over 20,000 expert
                  tutors.
                  <br />
                  Tutors are always online and available, 24/7. Guaranteed help.
                </p>
                <Account handleClickSupport={this.handleClickSupport} />
              </div>
            </div>

            {/* for mobile only: */}
            <div className="u-flex lg:u-hidden u-heightLarge">
              <img
                src={require('assets/images/landing/cover-wave@2x.png')}
                alt=""
                className="u-heightFull u-widthFull u-block"
              />
            </div>
            {/* for desktop only: */}
            <div className="u-hidden lg:u-flex u-heightExtraLargePlus u-widthFull u-overflowHidden">
              <img
                src={require('assets/images/landing/cover-wave@2x.png')}
                alt=""
                className="u-heightFull u-block"
              />
              <img
                src={require('assets/images/landing/cover-wave@2x.png')}
                alt=""
                className="u-heightFull u-block"
              />
            </div>
          </section>
          <section className="u-backgroundLightest u-textCenter u-paddingTopExtraLargePlus u-paddingBottomExtraLargePlus">
            <div className="Container">
              <h2 className="u-text700 lg:u-text800 u-marginBottomExtraLarge">
                How PhotoStudy Works?
              </h2>
              <div className="Grid">
                <div className="u-sizeFull lg:u-size10of12 lg:u-offset1of12">
                  <Media
                    as="iframe"
                    src="https://www.youtube.com/embed/Ze13PrAsbwA"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    type="video/webm"
                    title="What is PhotoStudy"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="u-backgroundLightest u-textCenter u-paddingBottomExtraLargePlus">
            <div className="Container">
              <div className="Grid u-marginBottomLarge">
                <div className="u-sizeFull lg:u-size6of12 lg:u-offset3of12">
                  <h2 className="u-text700 lg:u-text800 u-marginBottomMedium">
                    From Stuck to PhotoStudy in Minutes
                    <br />
                    Try a Free Session!
                  </h2>
                  <p className="u-text300 lg:u-text400 u-textDark">
                    Our Expert tutors are available 24/7, so you can always get
                    personalized help whenever you need it.
                  </p>
                </div>
              </div>
              <div className="Grid">
                <div className="u-size6of12 lg:u-size4of12 u-marginTopLarge">
                  <div className="u-backgroundWhite u-heightFull u-paddingMedium u-roundedMedium">
                    <div>
                      <img
                        src={require('assets/images/landing/icon-algebra.svg')}
                        alt=""
                        className="u-block u-marginHorizontalAuto u-marginBottomExtraSmall u-maxWidthFull"
                      />
                    </div>
                    <div>Algebra</div>
                  </div>
                </div>
                <div className="u-size6of12 lg:u-size4of12 u-marginTopLarge">
                  <div className="u-backgroundWhite u-heightFull u-paddingMedium u-roundedMedium">
                    <div>
                      <img
                        src={require('assets/images/landing/icon-geometry.svg')}
                        alt=""
                        className="u-block u-marginHorizontalAuto u-marginBottomExtraSmall u-maxWidthFulls"
                      />
                    </div>
                    <div>Geometry</div>
                  </div>
                </div>
                <div className="u-size6of12 lg:u-size4of12 u-marginTopLarge">
                  <div className="u-backgroundWhite u-heightFull u-paddingMedium u-roundedMedium">
                    <div>
                      <img
                        src={require('assets/images/landing/icon-trigonometry.svg')}
                        alt=""
                        className="u-block u-marginHorizontalAuto u-marginBottomExtraSmall u-maxWidthFulls"
                      />
                    </div>
                    <div>Trigonometry</div>
                  </div>
                </div>
                <div className="u-size6of12 lg:u-size4of12 u-marginTopLarge">
                  <div className="u-backgroundWhite u-heightFull u-paddingMedium u-roundedMedium">
                    <div>
                      <img
                        src={require('assets/images/landing/icon-calculus.svg')}
                        alt=""
                        className="u-block u-marginHorizontalAuto u-marginBottomExtraSmall u-maxWidthFulls"
                      />
                    </div>
                    <div>Calculus</div>
                  </div>
                </div>
                <div className="u-size6of12 lg:u-size4of12 u-marginTopLarge">
                  <div className="u-backgroundWhite u-heightFull u-paddingMedium u-roundedMedium">
                    <div>
                      <img
                        src={require('assets/images/landing/icon-chemistry.svg')}
                        alt=""
                        className="u-block u-marginHorizontalAuto u-marginBottomExtraSmall u-maxWidthFulls"
                      />
                    </div>
                    <div>Chemistry</div>
                  </div>
                </div>
                <div className="u-size6of12 lg:u-size4of12 u-marginTopLarge">
                  <div className="u-backgroundWhite u-heightFull u-paddingMedium u-roundedMedium">
                    <div>
                      <img
                        src={require('assets/images/landing/icon-physics.svg')}
                        alt=""
                        className="u-block u-marginHorizontalAuto u-marginBottomExtraSmall u-maxWidthFulls"
                      />
                    </div>
                    <div>Physics</div>
                  </div>
                </div>
                {/* ))} */}
              </div>
            </div>
          </section>
          <section className="u-paddingVerticalExtraLargePlus">
            <div className="Container">
              <div className="Grid">
                <div className="u-sizeFull lg:u-size8of12 lg:u-offset2of12">
                  <Carousel
                    settings={{
                      arrows: true,
                      dots: true,
                      autoplay: true,
                      autoplaySpeed: 5000,
                      responsive: [
                        {
                          breakpoint: 768,
                          settings: {
                            arrows: false,
                            dots: true,
                            autoplay: true,
                            autoplaySpeed: 5000,
                            slidesToShow: 1,
                          },
                        },
                      ],
                    }}
                  >
                    {QUOTES.map((quote, index) => (
                      <Carousel.Item
                        className="lg:u-paddingHorizontalExtraLarge"
                        key={quote.name}
                      >
                        <div className="u-textCenter">
                          <div className="u-flex u-flexJustifyCenter">
                            <img
                              src={quote.avatarSrc}
                              className="u-block u-marginHorizontalAuto u-widthExtraLargePlus u-heightExtraLargePlus u-roundedCircle u-marginBottomLarge"
                              alt={`PhotoStudy user ${index + 1}`}
                            />
                          </div>
                          <blockquote className="u-textGray u-fontMedium u-text400 lg:u-text600 u-marginTopNone u-marginHorizontalNone u-marginBottomLarge u-paddingVerticalExtraSmall ">
                            “{quote.quote}”
                          </blockquote>
                          <div className=" u-fontMedium">
                            {quote.name}
                            {` - `}
                            {quote.description}
                          </div>
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </section>
          <section className="u-backgroundLightest u-textCenter u-overflowHidden">
            <div className="Container">
              <div className="Grid">
                <div className="u-sizeFull lg:u-size3of12 u-positionRelative">
                  {/* for mobile only: */}
                  <div className="lg:u-hidden">
                    <img
                      src={require('assets/images/landing/screen-1.png')}
                      srcSet={`${require('assets/images/landing/screen-1@2x.png')} 2x`}
                      alt=""
                      className="u-block u-marginHorizontalAuto"
                    />
                  </div>
                  {/* for desktop only: */}
                  <div className="u-hidden lg:u-block u-positionAbsolute u-positionTop u-positionRight">
                    <img
                      src={require('assets/images/landing/screen-1.png')}
                      srcSet={`${require('assets/images/landing/screen-1@2x.png')} 2x`}
                      alt=""
                      className="u-block u-marginHorizontalAuto"
                    />
                  </div>
                </div>
                <div className="u-sizeFull lg:u-size6of12 u-paddingVerticalExtraLargePlus lg:u-paddingVerticalHuge">
                  <div className="lg:u-paddingVerticalHuge">
                    <h2 className="u-text700 lg:u-text800 u-fontRegular u-marginBottomMedium">
                      One of the Top 10 Education Apps on App store. Get your
                      free session - try it now!
                    </h2>
                    <div className="u-flex u-justifyContentCenter">
                      <SafeAnchor
                        rel="noopener noreferrer"
                        href="https://play.google.com/store/apps/details?id=co.gotitapp.android"
                        target="_blank"
                      >
                        <img
                          src={require('assets/images/landing/icon-google-play.svg')}
                          alt="Get it on Google Play"
                          className="u-block u-marginHorizontalAuto u-marginBottomExtraSmall u-maxWidthFull"
                        />
                      </SafeAnchor>
                      <div className="u-widthSmall u-heightSmall">&nbsp;</div>
                      <SafeAnchor
                        rel="noopener noreferrer"
                        href="https://itunes.apple.com/app/apple-store/id797535508?mt=8"
                        target="_blank"
                      >
                        <img
                          src={require('assets/images/landing/icon-app-store.svg')}
                          alt="Download on the App Store"
                          className="u-block u-marginHorizontalAuto u-marginBottomExtraSmall u-maxWidthFull"
                        />
                      </SafeAnchor>
                    </div>
                  </div>
                </div>
                <div className="u-sizeFull lg:u-size3of12 u-positionRelative">
                  {/* for mobile only: */}
                  <div className="lg:u-hidden">
                    <img
                      src={require('assets/images/landing/screen-2.png')}
                      srcSet={`${require('assets/images/landing/screen-2@2x.png')} 2x`}
                      alt=""
                      className="u-block u-marginHorizontalAuto u-maxWidthFull"
                    />
                  </div>
                  {/* for desktop only: */}
                  <div className="u-hidden lg:u-block u-positionAbsolute u-positionBottom u-positionLeft">
                    <img
                      src={require('assets/images/landing/screen-2.png')}
                      srcSet={`${require('assets/images/landing/screen-2@2x.png')} 2x`}
                      alt=""
                      className="u-block u-marginHorizontalAuto u-maxWidthFull"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer className="u-backgroundPrimaryDarker u-textWhite u-paddingVerticalExtraLarge lg:u-paddingVerticalExtraLargePlus u-positionRelative">
            <div className="Container">
              <div className="Grid">
                {/* left column: */}
                <div className="u-sizeFull lg:u-size3of12 xl:u-size3of12 u-marginBottomMedium lg:u-marginBottomNone">
                  {/* logo: */}
                  <div className="u-marginBottomTiny lg:u-marginBottomMedium">
                    <img
                      src={require('assets/images/logo-footer.svg')}
                      alt="PhotoStudy"
                      style={{ height: 48 }}
                    />
                  </div>
                  {/* social links: */}
                  <div className="">
                    <Button
                      as="a"
                      variant="white"
                      size="small"
                      onlyIcon={true}
                      href="https://www.facebook.com/gotithelp/"
                      target="_blank"
                      aria-label="Facebook"
                      className="u-roundedCircle u-marginTopExtraSmall lg:u-marginTopNone u-marginRightSmall"
                    >
                      <Button.Icon>
                        <Icon name="facebook" />
                      </Button.Icon>
                    </Button>
                    <Button
                      as="a"
                      variant="white"
                      size="small"
                      onlyIcon={true}
                      href="https://twitter.com/PhotoStudy_app"
                      target="_blank"
                      aria-label="Twitter"
                      className="u-roundedCircle u-marginTopExtraSmall lg:u-marginTopNone"
                    >
                      <Button.Icon>
                        <Icon name="twitter" />
                      </Button.Icon>
                    </Button>
                  </div>
                </div>
                {/* middle column: */}
                <div className="u-sizeFull lg:u-size4of12 xl:u-size3of12 u-marginBottomMedium lg:u-marginBottomNone">
                  {/* statistics: */}
                  <div className="u-flex u-marginBottomLarge">
                    <div className="u-marginRightLarge u-paddingRightExtraSmall">
                      <div className="u-text900">3.0M+</div>
                      <div className="u-text200">Got It moments</div>
                    </div>
                    <div className="">
                      <div className="u-text900">
                        4.4
                        <Icon name="star" className="u-marginLeftTiny" />
                      </div>
                      <div className="u-text200">App store rating</div>
                    </div>
                  </div>
                  <div className="u-text100">
                    © 2021 Got It, Inc. All rights reserved.
                  </div>
                </div>
                {/* right column: */}
                <div className="u-sizeFull lg:u-size5of12 xl:u-size6of12">
                  {/* introduction: */}
                  <div className="u-text100">
                    Got It is on a mission to change the world of learning and
                    knowledge sharing. Using its advanced Knowledge-as-a-Service
                    platform technology, Got It gives all users—professionals,
                    learners and consumers—a way to access and engage with live
                    experts to help them solve problems, anytime, anywhere via
                    the ease of their mobile phone. Spun out from the YouWeb
                    incubator and backed by the Capricorn Investment Group and
                    Fosun, Got It is led by a proven leadership team and
                    dual-headquartered in the Silicon Valley and Vietnam.
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <div className="u-positionFixed u-positionRight u-positionBottom u-marginRightMedium u-marginBottomMedium">
            <Button
              style={{ borderRadius: 18 }}
              onClick={this.handleClickSupport}
            >
              <Button.Icon>
                <Icon name="mail" />
              </Button.Icon>
              <Button.Label>Support</Button.Label>
            </Button>
          </div>

          {this.state.openSupportModal && (
            <SupportModal onClose={this.handleCloseSupport} />
          )}
        </PageLayout.Body>
      </PageLayout>
    );
    /* eslint-enable */
  }
}
