import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  PageLayout, Button, Icon, SafeAnchor, Form, Separator,
} from '@ahaui/react';

import Tracking from 'utils/tracking';
import {
  AUTH_METHOD_EMAIL,
  AUTH_METHOD_PRESET,
} from 'constants/app.constant';
import {
  ERROR_MESSAGE_SCHOOL_PLAN_CHANGED,
} from 'constants/error.constant';

import { ServerAPIError } from '../../utils/request';
import { hasStemSupport } from '../../utils/feature-support';

import EmailElement from './FormElements/Email';
import PasswordElement from './FormElements/Password';
import PresetId from './FormElements/PresetId';

import OneTimeCode from '../Landing/Account/OneTimeCode';
import SupportModal from '../Landing/Account/modals/Support';
import TOSModal from '../Landing/Account/modals/TOS';

import RPRS from '../Common/modals/RequestPasswordRecoverySuccess';


import {
  loginPreset,
  checkWhitelist,
  signupAccountKit,
  requestOneTimeCode,
  requestPasswordRecovery,
  logOut,
  signupByOneTimeCode,
  getCaptcha,
} from '../../actions/user.action';

import { navSend, navReceive, navClear } from '../../utils/app';

import { auth } from '../../actions/app.action';

import AccessToken from '../../utils/token';

import AppLoading from '../Common/Loading';

import Signup from '../Landing/Account/Signup';

import ErrorModal from '../Landing/Account/modals/Error';

import Captcha from './FormElements/Captcha';

// Show this component for Anonymous or TRIO missing password user
class NormalLogin extends React.Component {
  constructor(props) {
    super(props);
    let initState = {
      loading: true, // Checking if the user is anonymous or preset or normal
      error: null,
      accessToken: '',
      exchangedOneTimeCode: '',
      email: (props.email || ''),
      password: '',
      emailErrorMessage: '',
      emailInfo: null,
      passwordErrorMessage: '',
      agreedToS: false,
      ajaxBlockers: [],
      requestPasswordRecoverySuccess: false,
      openSupportModal: false,
      showTrioTOS: false,
      oneTimeCodeResponse: null,
      captcha: null,
      captchaCode: '',
      captchaErrorMessage: '',
      isGettingCaptcha: false,
      presetType: 'email',
      presetId: '',
      presetIdErrorMessage: '',
      presetInfo: null,
      enabled_features: null,
    };
    this.navInfo = navReceive('/login');

    this.accountKitEmailEnabled = false;

    this.isOneTimeCodeLogin = false;
    this.isLoginFromSharedSession = false;
    this.isPresetSignin = (this.navInfo && this.navInfo.school_id > 0)
      || !this.navInfo
      || !this.navInfo.from
      || this.navInfo.from.startsWith('shared_session_preset_');
    // this.tryPreset = false;

    if (this.navInfo && this.navInfo.from) {
      if (this.navInfo.from == 'try_preset') {
        // this.tryPreset = true;
        const state = this.infoToState(this.navInfo);
        initState = { ...initState, ...state };
      } else if (this.navInfo.from == 'shared_session_try_preset') {
        const state = this.infoToState(this.navInfo);
        initState = { ...initState, ...state };
        this.isLoginFromSharedSession = true;
      } else if (this.navInfo.from == 'missing_password') {
        const state = this.infoToState(this.navInfo);
        initState = { ...initState, ...state };
      } else if (this.navInfo.from.startsWith('one_time_code_')) {
        this.isOneTimeCodeLogin = true;
      } else if (this.navInfo.from.startsWith('shared_session_email_')) {
        this.isOneTimeCodeLogin = true;
        this.isLoginFromSharedSession = true;
      } else if (this.navInfo.from.startsWith('shared_session_')) {
        this.isLoginFromSharedSession = true;
      }
    }
    this.lastCheck = null;

    this.AUTH_METHOD_EMAIL = 'one_time_code';

    this.state = initState;
    this.steps = [];
  }

  componentDidMount() {
    this.checkMissingPassword(this.props);
    if (!this.isPresetSignin) this.getCaptcha();
  }

  componentWillReceiveProps(nextProps) {
    this.checkMissingPassword(nextProps);
  }

  getCaptcha = () => {
    const { getCaptcha } = this.props;

    this.setState({
      captchaCode: '', captcha: null, isGettingCaptcha: true,
    });

    getCaptcha()
      .then((data) => {
        const { result, error } = data.payload;

        if (result) this.setState({ captcha: result, isGettingCaptcha: false });

        if (error) {
          const errorMessage = error.data?.data || 'Something went wrong!';
          this.setState({
            captcha: {
              ...this.state.captcha,
              error: errorMessage,
            },
            isGettingCaptcha: false,
          });
          Tracking.captchaError({ error_message: errorMessage });
        }
      });
  }

  checkMissingPassword = (props) => {
    if (props.user.uid > 0 && !props.user.missing_password) {
      // User not missing password
      this.props.history.push('/');
    } else {
      this.setState({ loading: false });
    }
  }

  goBack = () => {
    if (this.steps.length > 0) {
      const state = this.steps.pop();

      const { presetId } = this.state;

      this.setState(state);

      if (state.presetType === 'email' && presetId) {
        this.setState({ presetId, presetInfo: null });
      }

      if (!this.isPresetSignin) this.getCaptcha();
    } else {
      this.props.history.goBack();
    }
  }

  handleGotoLanding = () => {
    Tracking.logout();
    this.props.logOut();
    AccessToken.setAuthKey('');
    this.props.history.push('/');
  }

  nextStep = (state) => {
    console.log('nextstep');
    const stateToSave = state || { ...this.state };
    this.steps.push(stateToSave);

    console.log(this.steps);
  }

  handleChangeEmail = (email) => {
    this.setState({ email, emailInfo: null, emailErrorMessage: '' });
  }

  handleChangePresetId = (presetId) => {
    this.setState({ presetId, presetInfo: null, presetIdErrorMessage: '' });
  }

  handleChangePassword = (password) => {
    this.setState({ password, passwordErrorMessage: '' });
  }

  handleAgreeToS = (e) => {
    this.setState({ agreedToS: e.target.checked });
  }

  isValidEmail = (email) => {
    const re = /^([a-zA-Z0-9]+([.\-_+][a-zA-Z0-9]+){0,})+@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6})$/;
    return re.test(String(email).toLowerCase());
  }

  isInvalidPassword = password => ((password.length < 8) ? 'Oops! Password must be at least 8 characters.' : '');

  doLoginPreset = () => {
    const {
      emailInfo, presetInfo, presetType, email, presetId, password,
    } = this.state;

    let data = {};
    if (presetType === 'id') data = { preset_id: presetId, password, gdpr: {} };
    else data = { email, password, gdpr: {} };

    this.setState({ formSubmitting: true });

    this.props.doLoginPreset(data).then(
      (data) => {
        const { result } = data.payload;
        const { error } = data.payload;
        if (result && result.auth && result.user) {
          if ((presetType === 'email' && emailInfo.registered) || (presetType === 'id' && presetInfo.registered)) {
            this.onLoggedin(result.auth, AUTH_METHOD_PRESET, result.user);
          } else {
            this.onSignedUp(result.auth, result.user);
          }
        } else if (error && error instanceof ServerAPIError) {
          let errMsg = error.data ? error.data.data : null;

          if (errMsg === 'Invalid password') {
            errMsg = 'Oops! Wrong password. Please re-enter your password.';
          }

          this.setState({ showTrioTOS: false, passwordErrorMessage: errMsg });
          Tracking.login(false, { login_method: AUTH_METHOD_PRESET, message: errMsg });
        }
        this.setState({ formSubmitting: false });
      }
    );
  }

  getLoginType = () => {
    if (this.navInfo?.from?.startsWith('one_time_code_')) {
      return 'email';
    }

    return 'preset';
  }

  onCaptchaUpdate = (captchaCode) => {
    this.setState({
      captchaCode,
      captchaErrorMessage: '',
    });
  }

  handleWhitelistCheck = () => {
    const {
      email, captcha, captchaCode, presetId, presetType,
    } = this.state;
    const { doCheckWhitelist, doRequestOneTimeCode } = this.props;

    let data = {};
    if (presetType === 'id') data = { preset_id: presetId, login_type: this.getLoginType() };
    else data = { email, login_type: this.getLoginType() };

    doCheckWhitelist(data).then(
      (data) => {
        this.setState({ formSubmitting: false });
        const { result } = data.payload;
        const { error } = data.payload;
        if (result) {
          const { registered } = result;

          this.lastCheck = result;
          if (!result.is_whitelisted && !result.error_code) {
            if (this.isOneTimeCodeLogin) {
              const stateToSave = { ...this.state };
              this.setState({ formSubmitting: true });

              doRequestOneTimeCode(email, captcha.nonce, captchaCode).then(
                (data) => {
                  this.setState({ formSubmitting: false });
                  const { result } = data.payload;
                  const { error } = data.payload;
                  let errMsg = '';

                  if (error && error instanceof ServerAPIError) {
                    this.setState({ emailInfo: null });

                    if (error.data && error.data.info) {
                      errMsg = error.data.data;

                      if (error.data.info.error_code === 'invalid_captcha') {
                        this.setState({ captchaErrorMessage: errMsg, captchaCode: '' });
                        Tracking.captchaError({ error_message: errMsg });
                      } else if (error.data.info.error_code === 'invalid_param'
                        || error.data.info.error_code === 'api_rate_limit'
                        || error.data.info.error_code === 'deleted_profile'
                      ) {
                        this.setState({ emailErrorMessage: errMsg });
                      }

                      this.getCaptcha();
                    }

                    if (registered) {
                      const now = new Date();

                      Tracking.signup(false, {
                        signup_method: AUTH_METHOD_EMAIL,
                        created: moment.unix(now.getTime() / 1000).toISOString(),
                        signup_liquidity_lever_enabled: 'NO',
                        message: errMsg,
                      });
                    } else Tracking.login(false, { login_method: AUTH_METHOD_EMAIL, message: errMsg });

                    this.setState({ oneTimeCodeResponse: error, captchaCode: '' });
                  }

                  if (result) {
                    this.setState({ oneTimeCodeResponse: { status: 1 } });

                    this.lastCheck.oneTimeCodeResponse = { status: 1 };

                    this.nextStep(stateToSave);
                  }

                  this.lastCheck = null;
                }
              );
            } else if (this.isPresetSignin) {
              // This is not a preset account. Select&nbsp; {loginLink} to log in to PhotoStudy.
              const errorMessage = (
                <span>
                  {result.message}
                </span>
              );
              if (presetType === 'id') {
                this.nextStep();

                this.setState({ presetIdErrorMessage: errorMessage });
              } else {
                this.setState({ emailErrorMessage: errorMessage });
              }
            } else {
              this.nextStep();
            }
          } else {
            this.nextStep();
          }
          const state = this.infoToState(result);
          this.setState(state);
        }
        if (error && error instanceof ServerAPIError) {
          const errMsg = error.data ? error.data.data : null;

          if (presetType === 'id') this.setState({ presetIdErrorMessage: errMsg });
          else this.setState({ emailErrorMessage: errMsg });
        }
      }
    );
  }

  // submit form
  handleContinue = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const {
      email, password, emailInfo, presetType, presetId, presetInfo,
    } = this.state;

    if (!emailInfo && presetType === 'email') {
      if (this.lastCheck && this.lastCheck.email === email) {
        const state = this.infoToState(this.lastCheck);
        this.nextStep();
        this.setState(state);
        return true;
      }

      this.setState({ formSubmitting: true });

      this.handleWhitelistCheck();
    } else if (presetType === 'id' && !presetInfo) {
      if (this.lastCheck && this.lastCheck.preset_id === presetId) {
        console.log(this.lastCheck);
        const state = this.infoToState(this.lastCheck);
        console.log('www');
        this.nextStep();
        this.setState(state);
        return true;
      }

      this.setState({ formSubmitting: true });

      this.handleWhitelistCheck();
    } else {
      const passwordErrorMessage = this.isInvalidPassword(password);
      if (passwordErrorMessage) {
        this.setState({ passwordErrorMessage });
        return false;
      }

      if ((presetType === 'email' && emailInfo.registered) || (presetType === 'id' && presetInfo.registered)) {
        this.doLoginPreset();
        return true;
      }

      this.setState({ showTrioTOS: true });
    }
    return true;
  }

  handleBackToPresetSignup = () => {
    this.setState({ showTrioTOS: false });
  }

  handleTrioAcceptTOS = (gdpr) => {
    const {
      email, password,
    } = this.state;
    this.doLoginPreset(email, password, gdpr);
  }

  handleForgotPassword = (e) => {
    e.preventDefault();
    const { doRequestPasswordRecovery } = this.props;

    doRequestPasswordRecovery(this.state.email).then(
      (data) => {
        if (data.payload.result) {
          this.setState({ requestPasswordRecoverySuccess: true });
        }
      }
    );
  }

  onCloseRPRS = () => {
    this.setState({ requestPasswordRecoverySuccess: false });
  }

  onSignedUp = (authKey, user) => {
    Tracking.signup(true, {
      signup_method: 'preset',
      created: moment.unix(+user.created).toISOString(),
      signup_liquidity_lever_enabled: 'NO',
    });
    const isStemSupported = hasStemSupport(user.enabled_features);
    this.onAuthenticated(authKey, true, isStemSupported);
  }

  onLoggedin = (authToken, authType, user) => {
    Tracking.login(true, {
      login_method: authType,
    });
    const isStemSupported = hasStemSupport(user.enabled_features);
    this.onAuthenticated(authToken, false, isStemSupported);
  }

  onAuthenticated = (authToken, firstLogin = false, isStemSupported = true) => {
    AccessToken.setAuthKey(authToken);
    this.props.onAuthenticated(authToken, firstLogin);
    if (this.isLoginFromSharedSession) {
      this.props.history.push(this.navInfo.path); // redirect back to shared session after logging in
    } else if (firstLogin && isStemSupported) {
      this.props.history.push({ pathname: '/guide', search: 'autoplay=1' });
    }
  }

  infoToState = (info) => {
    let presetType = '';
    if (this.state) presetType = this.state.presetType;

    let state = {};

    if (presetType === 'id') state = {
      presetId: info.preset_id, presetInfo: info, enabled_features: info.enabled_features ? info.enabled_features : null, presetIdErrorMessage: info.message,
    };
    else state = { email: info.email, emailInfo: info, enabled_features: info.enabled_features ? info.enabled_features : null };

    if (info.error_code === 'device_register_multi_account') {
      let errorMessage = (<span>{info.message}</span>);

      if (info.message === 'Your device is tied to a different preset account') errorMessage = (
        <span>
            This device already has a preset account. Enter the email address to sign in now. If you have not signed into this device before
          {info.message === 'Your device is tied to a different preset account' && (
            <>,&nbsp;
              <a href="#" onClick={this.handleClickSupport}>contact support</a> to reset your account.
            </>
          )}
        </span>
      );

      if (presetType === 'id') {
        state.presetIdErrorMessage = errorMessage;
      } else {
        state.emailErrorMessage = errorMessage;
      }
    } else if (info.message === ERROR_MESSAGE_SCHOOL_PLAN_CHANGED) {
      if (presetType === 'id') state.presetIdErrorMessage = <span>{info.message}</span>;
      else state.emailErrorMessage = <span>{info.message}</span>;
    } else if (info.is_whitelisted && this.isPresetSignin) {
      state.emailErrorMessage = '';
      state.presetIdErrorMessage = '';
    }

    if (info && info.oneTimeCodeResponse) state.oneTimeCodeResponse = info.oneTimeCodeResponse;

    return state;
  }

  // One Time Functions
  handleStartSignupOneTimeCode = (exchangedOneTimeCode) => {
    this.setState({ signupStep: 'grade', exchangedOneTimeCode, signupMethod: this.AUTH_METHOD_EMAIL });
  }

  onLoggedinOneTimeCode = (authToken, user) => {
    this.onLoggedin(authToken, AUTH_METHOD_EMAIL, user);
  }

  handleLoginOneTimeCodeError = (message, code = '') => {
    this.showError(message, code);
    Tracking.login(false, { login_method: this.AUTH_METHOD_EMAIL, message: code });
  }

  handleTryPresetOnOneTimeCode = (emailInfo) => {
    const newState = this.infoToState(emailInfo);
    this.lastCheck = emailInfo;
    this.setState(newState);
  }

  showError = (message, code = '') => {
    if (message || code) {
      this.setState({ error: { code, message } });
    } else {
      this.setState({ error: null });
    }
  }

  handleCloseMessage = () => {
    this.setState({ error: null });
  }

  doSignup = (data) => {
    const { doSignupByOneTimeCode } = this.props;

    if (this.isOneTimeCodeLogin) {
      const { email, exchangedOneTimeCode } = this.state;
      return doSignupByOneTimeCode(email, exchangedOneTimeCode, data);
    }

    return null;
  }

  handleTryPresetOnAccountKit = (emailInfo) => {
    logger.debug('try preset: ', emailInfo);
    const newState = this.infoToState(emailInfo);
    this.lastCheck = emailInfo;
    // this.nextStep();
    this.setState(newState);
  }

  // Other functions
  handleClickSupport = (e) => {
    e.preventDefault();
    this.setState({ openSupportModal: true });
  }

  handleCloseSupport = () => {
    this.setState({ openSupportModal: false });
  }

  getOneTimeCodeLink = email => (
    <a onClick={evt => this.loginWithOneTimeCode(evt, email)} style={{ cursor: 'pointer' }}>Login with email</a>
  );

  getLoginLink = (email) => {
    if (this.accountKitEmailEnabled) {
      return this.getAccountKitLink(email);
    }
    return this.getOneTimeCodeLink(email);
  }

  loginWithOneTimeCode = (e, email) => {
    e.preventDefault();
    navClear('/login');
    navSend('/login', { from: 'one_time_code_login' });
    this.navInfo = navReceive('/login');
    this.steps = [];
    this.lastCheck = null;
    this.isOneTimeCodeLogin = true;
    this.setState({ email, emailInfo: null, emailErrorMessage: '' });
  }

  loginWithPresetId = () => {
    this.setState({ presetType: 'id' });

    this.nextStep();
  }

  canContinue = () => {
    const {
      email,
      password,
      emailInfo,
      emailErrorMessage,
      agreedToS,
      ajaxBlockers,
      presetType,
      presetId,
      presetInfo,
      presetIdErrorMessage,
    } = this.state;

    // if (presetType === 'id') {
    //   if (!presetId) return false;
    //   return true;
    // }

    const emailEditable = !(emailInfo && (emailInfo.is_whitelisted || this.isOneTimeCodeLogin) && !emailErrorMessage);
    const needPassword = (presetType === 'id' && presetInfo && presetInfo.is_whitelisted) || (!emailEditable && emailInfo.is_whitelisted);
    const needAgreeToS = false;

    if (presetType === 'id' && presetId && !presetIdErrorMessage && !ajaxBlockers.length && (!needPassword || password) && (!needAgreeToS || agreedToS)) return true;

    if (presetType === 'email' && email && !emailErrorMessage && !ajaxBlockers.length && (!needPassword || password) && (!needAgreeToS || agreedToS)) return true;

    return false;
  }

  render() {
    const {
      loading,
      email,
      emailInfo,
      formSubmitting,
      emailErrorMessage,
      passwordErrorMessage,
      oneTimeCodeResponse,
      captcha,
      captchaCode,
      captchaErrorMessage,
      isGettingCaptcha,
      presetType,
      presetId,
      presetIdErrorMessage,
      presetInfo,
    } = this.state;

    const { error, signupStep, errorMsg } = this.state;

    if (loading) {
      return <AppLoading />;
    }

    const emailEditable = !(emailInfo && (emailInfo.is_whitelisted || this.isOneTimeCodeLogin) && !emailErrorMessage);
    const presetIdEditable = (presetType !== 'id' || !(presetInfo && (presetInfo.is_whitelisted && !presetIdErrorMessage)));
    const needPassword = (presetType === 'id' && presetInfo && presetInfo.is_whitelisted && !presetIdEditable) || (!emailEditable && emailInfo.is_whitelisted);
    const createPassword = (emailInfo && (emailInfo.missing_password || !emailInfo.registered)) || (presetInfo && (presetInfo.missing_password || !presetInfo.registered));
    const canContinue = this.canContinue();
    const shouldOpenOneTimeCode = (emailInfo && !emailInfo.is_whitelisted && !emailInfo.error_code) && (oneTimeCodeResponse && oneTimeCodeResponse.status === 1) && this.isOneTimeCodeLogin;
    const canLoginWithOtherMethods = (this.navInfo && this.navInfo.from === 'missing_password');
    const canGoBack = !(this.navInfo && this.navInfo.from === 'missing_password');
    const isCoppaSupported = presetInfo && presetInfo.preset_id;

    let title = (
      <div>
        <h1 className="u-marginBottomSmall u-marginHorizontalExtraLargePlus u-textCenter u-text600">
          Preset Account
        </h1>
      </div>
    );
    let continueBtnTitle = 'CONTINUE';
    let signupMethod = '';

    if (this.navInfo) {
      if (this.navInfo.from === 'missing_password') {
        title = (
          <div>
            <h1 className="u-marginBottomSmall u-marginHorizontalExtraLargePlus u-textCenter u-text600">
              Preset Account
            </h1>
            <p className="u-textGray u-text200 u-textCenter u-marginNone">We’re updating your account for better security.<br />Please create a password now.</p>
          </div>
        );
      }
      if (this.navInfo.from === 'one_time_code_signup' || this.navInfo.from === 'shared_session_email_signup') {
        title = (
          <div>
            <h1 className="u-marginBottomSmall u-marginHorizontalExtraLargePlus u-textCenter u-text600">
              Sign up with email
            </h1>
          </div>
        );

        if (emailInfo && emailInfo.is_whitelisted) {
          if (!emailEditable) {
            title = (
              <div>
                <h1 className="u-marginBottomSmall u-marginHorizontalExtraLargePlus u-textCenter u-text600">Hooray! Your Administrator Has Created a Preset Account For You.</h1>
                <p className="u-textGray u-text200 u-textCenter u-marginNone">Enter your password to log in and get your free tutoring chat sessions!</p>
              </div>
            );
            continueBtnTitle = 'CONTINUE WITH PRESET ACCOUNT';
          }
        }

        if (this.navInfo.from === 'one_time_code_signup') signupMethod = AUTH_METHOD_EMAIL;
      } else if (this.navInfo.from === 'try_preset' || this.navInfo.from === 'one_time_code_login' || this.navInfo.from === 'shared_session_email_login' || this.navInfo.from === 'shared_session_try_preset') {
        title = (
          <div>
            <h1 className="u-marginBottomSmall u-marginHorizontalExtraLargePlus u-textCenter u-text600">
              Log in with email
            </h1>
          </div>
        );

        if (emailInfo && emailInfo.is_whitelisted) {
          if (!emailEditable) {
            title = (
              <div>
                <h1 className="u-marginBottomSmall u-marginHorizontalExtraLargePlus u-textCenter u-text600">Hooray! Your Administrator Has Created a Preset Account For You.</h1>
                <p className="u-textGray u-text200 u-textCenter u-marginNone">Enter your password to log in and get your free tutoring chat sessions!</p>
              </div>
            );
            continueBtnTitle = 'CONTINUE WITH PRESET ACCOUNT';
          }
        }
      }
    }

    return (
      <PageLayout
        className="u-backgroundLightest"
        style={{
          minHeight: '100vh',
        }}
      >
        <PageLayout.Header>
          <div className="u-flex u-justifyContentCenter u-paddingVerticalExtraLarge">
            <img
              src={require('assets/images/logo.svg')}
              alt="PhotoStudy logo"
              style={{ height: 40 }}
            />
          </div>
        </PageLayout.Header>
        <PageLayout.Body className="u-flexColumn u-paddingBottomLarge">
          <div className="Container">
            <section
              className="u-block u-marginHorizontalAuto u-border u-paddingHorizontalMedium u-paddingVerticalLarge u-backgroundWhite u-roundedMedium"
              style={{ maxWidth: 600 }}
            >
              {/* header: */}
              <div className="u-flex u-justifyContentCenter u-positionRelative u-marginBottomLarge">
                {canGoBack && (
                  <div className="u-positionAbsolute u-positionLeft u-positionTop">
                    <Button variant="link" size="small" onClick={this.goBack} aria-label="Go back">
                      <Button.Icon>
                        <Icon name="arrowRoundBack" size="extraSmall" />
                      </Button.Icon>
                      <Button.Label>Back</Button.Label>
                    </Button>
                  </div>
                )}
                {title}
              </div>
              <Form onSubmit={this.handleContinue}>
                {/* body: */}
                <div className="">
                  {presetType === 'id' ? (
                    <PresetId
                      onChange={this.handleChangePresetId}
                      presetId={presetId}
                      errorMessage={presetIdErrorMessage}
                      disabled={!presetIdEditable}
                    />
                  ) : (
                    <EmailElement
                      email={email}
                      disabled={!emailEditable}
                      onChange={this.handleChangeEmail}
                      errorMessage={emailErrorMessage}
                    />
                  )}


                  {!shouldOpenOneTimeCode && !this.isPresetSignin && !needPassword && (
                    <Captcha
                      isLoading={isGettingCaptcha}
                      captcha={captcha}
                      errorMessage={captchaErrorMessage}
                      emailErrorMessage={emailErrorMessage}
                      captchaErrorMessage={captcha && captcha.error ? captcha.error : ''}
                      getNewCaptcha={this.getCaptcha}
                      onCaptchaUpdate={this.onCaptchaUpdate}
                      imgSrc={captcha && captcha.captcha_image}
                      audioSrc={captcha && captcha.captcha_audio_data}
                    />
                  )}

                  {needPassword && (
                    <PasswordElement
                      createPassword={createPassword}
                      onChange={this.handleChangePassword}
                      errorMessage={passwordErrorMessage}
                      onEnter={this.handleContinue}
                    />
                  )}
                  {shouldOpenOneTimeCode && (
                    <OneTimeCode
                      email={email.trim()}
                      registered={(emailInfo && emailInfo.registered)}
                      onSignup={this.handleStartSignupOneTimeCode}
                      onAuthenticated={this.onLoggedinOneTimeCode}
                      onError={this.handleLoginOneTimeCodeError}
                      onTryPreset={this.handleTryPresetOnOneTimeCode}
                    />
                  )}
                </div>

                {/* footer: */}
                <div className="u-paddingTopExtraSmall">
                  {!shouldOpenOneTimeCode && (
                    <>
                      <Button
                        width="full"
                        disabled={!canContinue || formSubmitting || (!this.isPresetSignin && (isGettingCaptcha || !captcha || !captchaCode))}
                      >
                        <Button.Label>{continueBtnTitle}</Button.Label>
                      </Button>

                      {(this.navInfo && this.navInfo.from !== 'try_preset' && this.navInfo.from !== 'shared_session_try_preset') && (
                        <div className="u-textCenter">
                          <div className="u-text200 u-textGray u-marginTopSmall">
                            Tap Continue to get an Email confirmation and accept PhotoStudy’s

                            <SafeAnchor href="https://www.got-it.co/terms.html" target="_blank">
                              Terms
                            </SafeAnchor>
                            .
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {needPassword && !createPassword && !isCoppaSupported && (
                    <SafeAnchor
                      className="u-block u-textCenter u-marginTopSmall"
                      onClick={this.handleForgotPassword}
                    >
                      Forgot password?
                    </SafeAnchor>
                  )}

                  {canLoginWithOtherMethods && (
                    <SafeAnchor
                      className="u-block u-textCenter u-marginTopSmall"
                      onClick={this.handleGotoLanding}
                    >
                      Login with another method
                    </SafeAnchor>
                  )}
                </div>
                {this.isPresetSignin && presetType === 'email' && !needPassword && (
                  <>
                    <Separator label="or sign up with" className="u-paddingTopSmall" />
                    <div className="u-paddingTopSmall">
                      <Button width="full" onClick={this.loginWithPresetId}>
                        <div className="u-justifyContentBetween u-flex u-flexGrow1">
                          <Button.Icon><Icon name="contact" /></Button.Icon>
                          <Button.Label>USER ID</Button.Label>
                          <Button.Icon><Icon name="arrowForward" /></Button.Icon>
                        </div>
                      </Button>
                    </div>
                  </>
                )}

              </Form>
            </section>
          </div>

          {signupStep && !errorMsg && (
            <Signup
              doSignup={this.doSignup}
              showError={this.showError}
              signupMethod={signupMethod}
            />
          )}

          {error && (
            <ErrorModal
              code={error.code}
              message={error.message}
              onClose={this.handleCloseMessage}
            />
          )}

          {this.state.requestPasswordRecoverySuccess && (
            <RPRS onClose={this.onCloseRPRS} />
          )}

          {this.state.openSupportModal && (
            <SupportModal onClose={this.handleCloseSupport} />
          )}

          {this.state.showTrioTOS && (
            <TOSModal
              onAcceptTOS={this.handleTrioAcceptTOS}
              onBack={this.handleBackToPresetSignup}
              isCoppaSupported={isCoppaSupported}
            />
          )}
        </PageLayout.Body>
      </PageLayout>
    );
  }
}

NormalLogin.propTypes = {
  // The email is passed to this component when the user is missing password
  email: PropTypes.string,
  doCheckWhitelist: PropTypes.func.isRequired,
  doLoginPreset: PropTypes.func.isRequired,
  doRequestOneTimeCode: PropTypes.func.isRequired,
  // doCheckMissingPassword: PropTypes.func.isRequired,
  onAuthenticated: PropTypes.func.isRequired,
  doSignupAccountKit: PropTypes.func.isRequired,
  doRequestPasswordRecovery: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
};

NormalLogin.defaultProps = {
  email: '',
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  doCheckWhitelist: data => dispatch(checkWhitelist(data)),
  doLoginPreset: (email, password, gdpr) => dispatch(loginPreset(email, password, gdpr)),
  onAuthenticated: (token, firstLogin) => dispatch(auth(token, firstLogin)),
  doSignupAccountKit: (token, data) => dispatch(signupAccountKit(token, data)),
  doSignupByOneTimeCode: (email, code, data) => dispatch(signupByOneTimeCode(email, code, data)),
  doRequestPasswordRecovery: email => dispatch(requestPasswordRecovery(email)),
  doRequestOneTimeCode: (email, nonce, code) => dispatch(requestOneTimeCode(email, nonce, code)),
  logOut: () => dispatch(logOut()),
  getCaptcha: () => dispatch(getCaptcha()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NormalLogin);
