import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ahaui/react';

class PasswordElement extends React.Component {
  handleChangePassword = (e) => {
    this.props.onChange(e.target.value);
  }

  handleEnter = (e) => {
    if (e.keyCode == 13 || e.which == 13) {
      this.props.onEnter();
    }
  }

  render() {
    const { createPassword, errorMessage } = this.props;
    const title = createPassword ? 'Create your password' : 'Enter your password';

    return (
      <Form.Group controlId="login.password">
        <Form.Label>{title}</Form.Label>
        <Form.Input
          isInvalid={!!errorMessage}
          type="password"
          placeholder="Password"
          onChange={this.handleChangePassword}
          onKeyPress={this.handleEnter}
        />
        <Form.Feedback type="invalid" visible={!!errorMessage} role="alert">
          {errorMessage}
        </Form.Feedback>
      </Form.Group>
    );
  }
}

PasswordElement.propTypes = {
  createPassword: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
};

PasswordElement.defaultProps = {
  createPassword: false,
  errorMessage: '',
  onChange: () => {},
  onEnter: () => {},
};

export default PasswordElement;
