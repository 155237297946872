import {
  APP_CHECK_STATUS,
  APP_READY,
  APP_MAINTENANCE,
  APP_FORCE_UPGRADE,
  APP_FORCE_LOGOUT,
  APP_AUTH
} from '../constants/app.constant';

import { run } from './promise';
import { get } from '../utils/request';

export const checkStatus = () => (
  run(
    APP_CHECK_STATUS,
    get('/check.php', null, false, true),
    { skipLoadingState: true }
  )
);

export const ready = () => ({
  type: APP_READY
});

export const maintenance = () => ({
  type: APP_MAINTENANCE
});

export const forceUpgrade = () => ({
  type: APP_FORCE_UPGRADE
});

export const triggerForceLogoutModal = (modal) => ({
  type: APP_FORCE_LOGOUT,
  payload: { modal },
})

// App is authenticated with key
export const auth = (token, firstLogin) => ({
  type: APP_AUTH,
  payload: { token, firstLogin }
});

export default {
  checkStatus,
  ready,
  maintenance,
  forceUpgrade,
  auth
};
