import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import moment from 'moment';

import Footer from './Footer';
import Intro from './Intro';
import Steps from './Steps';
import Subjects from './Subjects';
import AvailableVersion from './AvailableVersion';
import GradeModal from './Modals/Grade';
import SchoolModal from './Modals/School';
import ErrorModal from './Modals/Error';

import Tracking from '../../utils/tracking';
import AccessToken from '../../utils/token';
import { queryToObject } from '../../utils/util';
import { hasStemSupport } from '../../utils/feature-support';

import {
  signupAccountKit,
  onSignupSuccess,
  signupBegin,
  signupEnd,
} from '../../actions/user.action';

import { auth } from '../../actions/app.action';


class SEMLanding extends React.Component {
  static contextTypes = {
    isFirstTime: PropTypes.bool.isRequired,
    notFirstTimeAnyMore: PropTypes.func.isRequired,
    openSupport: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      regInfo: { grade: 0, school: '' },
      signupStep: '',
      accessToken: '',
      signupMethod: '',
      formSignup: true,
      errorMsg: '',
      errorCode: '',
      error: null,
    };

    this.AUTH_METHOD_ACCOUNTKIT_EMAIL = 'account_kit_email';
  }

  componentDidMount() {
    const trackingData = {
      is_new_user: this.context.isFirstTime ? 'YES' : 'NO',
    };
    const search = this.props.location.search.substring(1);
    const params = queryToObject(search);

    // Save UTMs.
    const utmSource = params.utm_source;
    const utmContent = params.utm_content;
    const utmCampaign = params.utm_campaign;
    const utmMedium = params.utm_medium;
    const utmTerm = params.utm_term;
    if (utmSource) {
      trackingData.utm_source = utmSource;
      trackingData.utm_content = utmContent;
      trackingData.utm_campaign = utmCampaign;
      trackingData.utm_medium = utmMedium;
      trackingData.utm_term = utmTerm;
    }

    // Move appOpened event to from App.js to here because want to keep the
    // distinct ID consistent for appOpen event when user redirected from SEO pages.
    Tracking.appOpened();
    Tracking.viewLanding(trackingData, { location: this.props.location });
    this.context.notFirstTimeAnyMore();
  }

  onAuthenticated = (authToken, firstLogin = false, isStemSupported = true) => {
    AccessToken.setAuthKey(authToken);
    this.props.onAuthenticated(authToken, firstLogin);
    this.props.history.push({ pathname: '/' });
    if (firstLogin && isStemSupported) {
      this.props.history.push({ pathname: '/guide', search: 'autoplay=1' });
    }
  }

  handleLoggedinAccountKitEmail = (authToken) => {
    this.onAuthenticated(authToken);
    Tracking.login(true, { login_method: this.AUTH_METHOD_ACCOUNTKIT_EMAIL });
  }

  handleSelectGrade = (grade) => {
    this.setState(currState => ({
      ...currState,
      regInfo: { ...currState.regInfo, grade },
      signupStep: 'school',
    }));
  }

  handleEnterSchool = (school) => {
    this.setState(currState => ({
      ...currState,
      regInfo: { ...currState.regInfo, school },
    }));
    const data = {
      grade: this.state.regInfo.grade,
      school_name: school,
    };
    this.doSignup(data).then(this.resolveSignup);
  }

  handleStartSignupAccountKitEmail = (accessToken) => {
    this.setState({ signupStep: 'grade', accessToken, signupMethod: this.AUTH_METHOD_ACCOUNTKIT_EMAIL });
  }

  handleBackToGrade = (school) => {
    this.setState(currState => ({
      ...currState,
      signupStep: 'grade',
      regInfo: { ...currState.regInfo, school },
    }));
  }

  showError = (message, code = '') => {
    if (message || code) {
      this.setState({ error: { code, message } });
    } else {
      this.setState({ error: null });
    }
  }

  handleLoginAccountKitEmailError = (message, code = '') => {
    this.showError(message, code);
    Tracking.login(false, { login_method: this.AUTH_METHOD_ACCOUNTKIT_EMAIL, message: code });
  }

  handleCloseMessage = () => {
    this.setState({ error: null });
  }

  doSignup = (data) => {
    this.props.onSignupBegin();
    if (this.state.signupMethod === this.AUTH_METHOD_FACEBOOK) {
      return this.props.doSignupFacebook(this.state.accessToken, data);
    }
    return this.props.doSignupAccountKit(this.state.accessToken, data);
  }

  resolveSignup = (signupResponse) => {
    const signupResult = signupResponse.payload.result;
    const signupError = signupResponse.payload.error;
    if (signupResult) {
      Tracking.alias(signupResult.user.uid, signupResult.user.email);
      Tracking.signup(true, {
        signup_method: this.state.signupMethod,
        created: moment.unix(+signupResult.user.created).toISOString(),
        signup_liquidity_lever_enabled: (signupResult.user.signup_credits >= 8) ? 'YES' : 'NO',
      });

      const isStemSupported = hasStemSupport(signupResult.user.enabled_features);
      this.onAuthenticated(signupResult.auth, true, isStemSupported);
    } else if (signupError) {
      const errorCode = (signupError.data.info) ? (signupError.data.info.error_code || '') : '';
      this.showError(signupError.data.data, errorCode);
      Tracking.signup(false, {
        signup_method: this.state.signupMethod,
        created: moment.unix(+signupResult.user.created).toISOString(),
        signup_liquidity_lever_enabled: 'NO',
        message: signupError.data.data,
      });
    }
    this.props.onSignupEnd();
  }

  render() {
    const { error } = this.state;
    return (
      <div className="gi-pageWrapper">
        <div className="gi-pageBody gi-pageBody--landingB">
          <Intro
            onSignup={this.handleStartSignupAccountKitEmail}
            onAuthenticated={this.handleLoggedinAccountKitEmail}
            onError={this.handleLoginAccountKitEmailError}
          />
          <Steps
            onSignup={this.handleStartSignupAccountKitEmail}
            onAuthenticated={this.handleLoggedinAccountKitEmail}
            onError={this.handleLoginAccountKitEmailError}
          />
          <Subjects />
          <AvailableVersion
            onSignup={this.handleStartSignupAccountKitEmail}
            onAuthenticated={this.handleLoggedinAccountKitEmail}
            onError={this.handleLoginAccountKitEmailError}
          />
          <Footer />
        </div>
        <div>
          {(this.state.signupStep === 'grade' && !this.state.errorMsg)
            && (
            <GradeModal
              onSelectGrade={this.handleSelectGrade}
              selectedGrade={this.state.regInfo.grade}
            />
            )}
          {(this.state.signupStep === 'school' && !this.state.errorMsg)
            && (
            <SchoolModal
              onEnterSchool={this.handleEnterSchool}
              enteredSchool={this.state.regInfo.school}
              onBackToGrade={this.handleBackToGrade}
            />
            )}
        </div>
        {error && <ErrorModal code={error.code} message={error.message} onClose={this.handleCloseMessage} />}
      </div>
    );
  }
}

SEMLanding.propTypes = {
  history: PropTypes.shape().isRequired,
  doSignupAccountKit: PropTypes.func.isRequired,
  onAuthenticated: PropTypes.func.isRequired,
  onSignupBegin: PropTypes.func.isRequired,
  onSignupEnd: PropTypes.func.isRequired,
};

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
  doSignupAccountKit: (token, data) => dispatch(signupAccountKit(token, data)),
  onAuthenticated: (token, firstLogin) => dispatch(auth(token, firstLogin)),
  onSignupSuccess: user => dispatch(onSignupSuccess(user)),
  onSignupBegin: () => dispatch(signupBegin()),
  onSignupEnd: () => dispatch(signupEnd()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SEMLanding);
