import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@ahaui/react';

function BaseModal(props) {
  const renderFooter = () => {
    const {
      footerType,
      primaryButtonText,
      primaryButtonVariant,
      secondaryButtonText,
      secondaryButtonVariant,
      onClickPrimaryButton,
      onClickSecondaryButton,
      disablePrimaryButton,
      disableSecondaryButton,
    } = props;

    switch (footerType) {
      case 'single':
        return (
          <Button
            variant={primaryButtonVariant}
            width="full"
            onClick={onClickPrimaryButton}
            disabled={disablePrimaryButton}
          >
            {primaryButtonText}
          </Button>
        );

      case 'double':
        return (
          <Fragment>
            <Button
              variant={secondaryButtonVariant}
              onClick={onClickSecondaryButton}
              disabled={disableSecondaryButton}
            >
              {secondaryButtonText}
            </Button>
            <Button
              variant={primaryButtonVariant}
              onClick={onClickPrimaryButton}
              disabled={disablePrimaryButton}
            >
              {primaryButtonText}
            </Button>
          </Fragment>
        );

      case 'vertical-double':
        return (
          <Fragment>
            <Button
              variant={secondaryButtonVariant}
              width="full"
              onClick={onClickSecondaryButton}
              disabled={disableSecondaryButton}
            >
              {secondaryButtonText}
            </Button>
            <Button
              variant={primaryButtonVariant}
              width="full"
              onClick={onClickPrimaryButton}
              disabled={disablePrimaryButton}
            >
              {primaryButtonText}
            </Button>
          </Fragment>
        );

      default:
        return null;
    }
  };


  const {
    header,
    headerText,
    body,
    footer,
    children,
    primaryButtonText,
    onClickPrimaryButton,
    secondaryButtonText,
    onClickSecondaryButton,
    footerType,
    onHide,
    closable,
    disablePrimaryButton,
    disableSecondaryButton,
    showFooter,
    showHeader,
    primaryButtonVariant,
    secondaryButtonVariant,
    ...rest
  } = props;

  return (
    <Modal
      {...rest}
      show
      onHide={onHide}
    >
      {children || (
        <React.Fragment>
          {showHeader && (
            <Modal.Header closeButton={closable}>
              {header || (
                <Modal.Title>
                  {headerText}
                </Modal.Title>
              )}
            </Modal.Header>
          )}
          <Modal.Body>
            {body}
          </Modal.Body>
          {showFooter && (
            <Modal.Footer>
              {footer || renderFooter()}
            </Modal.Footer>
          )}
        </React.Fragment>
      )}
    </Modal>
  );
}

BaseModal.propTypes = {
  header: PropTypes.node,
  headerText: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
  primaryButtonText: PropTypes.string,
  primaryButtonVariant: PropTypes.string,
  onClickPrimaryButton: PropTypes.func,
  disablePrimaryButton: PropTypes.bool,
  secondaryButtonText: PropTypes.string,
  secondaryButtonVariant: PropTypes.string,
  onClickSecondaryButton: PropTypes.func,
  disableSecondaryButton: PropTypes.bool,
  footerType: PropTypes.string,
  onHide: PropTypes.func,
  closable: PropTypes.bool,
  showFooter: PropTypes.bool,
  showHeader: PropTypes.bool,
  relative: PropTypes.bool,
  centered: PropTypes.bool,
  size: PropTypes.string,
  id: PropTypes.string,
};

BaseModal.defaultProps = {
  footerType: 'single',
  closable: true,
  showFooter: true,
  showHeader: true,
  primaryButtonVariant: 'primary',
  secondaryButtonVariant: 'secondary',
};


export default BaseModal;
