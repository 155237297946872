import React from 'react';
import { Loader } from '@ahaui/react';

export default class extends React.Component {
  render() {
    return (
      <div className="u-widthFull u-flex u-justifyContentCenter u-paddingVerticalSmall">
        <Loader duration={700} />
      </div>
    );
  }
}
