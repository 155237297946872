import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EmptyState } from '@ahaui/react';

import NotiDetail from './NotiDetail';
import QuestionActiveBar from 'components/Common/QuestionActiveBar';
import Loading from 'components/Common/Loading';

import {
  getNotis,
  readNotis,
  onReadAllNotis,
  blockUnread,
  unblockUnread
} from 'actions/notification.action';

import Tracking from 'utils/tracking';


export class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  componentDidMount() {
    const { notifications } = this.props.notification;
    this.props.onBlockUnread();
    if (notifications.length === 0) {
      this.setState({ loading: true });
      this.props.onLoadNotifications().then(() => {
        this.setState({ loading: false });
      });
    }
    Tracking.viewNotification({ location: this.props.location });
  }

  componentWillUpdate(nextProps) {
    const { unreadNids } = nextProps.notification;
    if (unreadNids.length > 0) {
      this.props.onReadNotifications(unreadNids);
    }
  }

  componentWillUnmount() {
    this.props.onUnblockUnread();
    this.props.onNotificationsReadAll();
  }

  handleClickLoadMore = () => {
    // TODO load more notification
  }

  render() {
    const { notifications } = this.props.notification;
    const { loading } = this.state;

    let page;

    if (notifications.length === 0 && loading) {
      page = <Loading />;
    } else {
      page = (
        <div className="Container Container--fluid u-paddingTopLarge u-paddingBottomExtraLarge">
          <div className="u-marginHorizontalAuto" style={{ width: '100%', maxWidth: 560 }}>
            {notifications.length > 0 ? notifications.map((notification) => (
              <NotiDetail key={notification.nid} notification={notification} />
            )) : (
              <div className="u-marginHorizontalAuto u-marginBottomLarge u-textCenter" style={{ maxWidth: 300 }}>
                <EmptyState src={require('assets/images/empty-notifications.svg')}>
                  <EmptyState.Heading>
                    No notifications
                  </EmptyState.Heading>
                  <EmptyState.Description>
                    You don't have any notifications.
                  </EmptyState.Description>
                </EmptyState>
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <>
        <h1 className="sr-only">Notifications</h1>
        {page}
        <QuestionActiveBar />
      </>
    )
  }
}

Notification.propTypes = {
  location: PropTypes.shape().isRequired,
  notification: PropTypes.shape().isRequired,
  onLoadNotifications: PropTypes.func.isRequired,
  onReadNotifications: PropTypes.func.isRequired,
  onNotificationsReadAll: PropTypes.func.isRequired,
  onBlockUnread: PropTypes.func.isRequired,
  onUnblockUnread: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notification: state.notification,
});

const mapDispatchToProps = dispatch => ({
  onLoadNotifications: () => dispatch(getNotis()),
  onReadNotifications: (nids) => dispatch(readNotis(nids)),
  onNotificationsReadAll: () => dispatch(onReadAllNotis()),
  onBlockUnread: () => dispatch(blockUnread()),
  onUnblockUnread: () => dispatch(unblockUnread()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
