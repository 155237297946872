import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Icon } from '@ahaui/react';
import { navSend } from 'utils/app';

class PresetEmailAuth extends React.Component {
  goToLogin = () => {
    navSend('/login', { from: 'shared_session_preset_login', path: window.location.pathname });
    this.props.history.push('/login');
  }

  goToSignup = () => {
    navSend('/login', { from: 'shared_session_preset_signup', path: window.location.pathname });
    this.props.history.push('/login');
  }

  render() {
    const goToAuthentication = this.props.signup ? this.goToSignup : this.goToLogin;

    return (
      <button className="u-backgroundPrimary u-paddingExtraSmall u-roundedCircle u-borderNone u-cursorPointer hover:u-shadowLarge hover:u-scale110 u-transitionTransform u-easeInOut u-duration200 u-marginLeftMedium" onClick={goToAuthentication}>
        <span className="u-flex u-justifyContentCenter u-alignItemsCenter">
          <Icon name="contact" size="medium" className="u-textWhite" />
        </span>
      </button>
    );
  }
}

PresetEmailAuth.propTypes = {
  signup: PropTypes.bool,
}

PresetEmailAuth.defaultProps = {
  signup: false,
}

export default withRouter(PresetEmailAuth);
