import React from 'react';
import PropTypes from 'prop-types';

import Tracking from '../../../../utils/tracking';

export default class OneAccPerDeviceError extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired
  }

  static contextTypes = {
    openSupport: PropTypes.func
  }

  handleClose = () => {
    this.props.onClose();
  }
  handleSupport = () => {
    this.context.openSupport();
    Tracking.sendFeedback({ source: 'auth-error-device-check' });
    this.props.onClose();
  }
  render () {
    return (
      <div>
        <div className="gi-Modal-body u-textCenter">
          <p className="u-fontSizeLg">Error</p>
          <p>You may not register multiple PhotoStudy accounts on the same device. Feel free to contact PhotoStudy support.</p>
        </div>
        <div className="gi-Modal-footer u-textCenter">
          <button className="gi-Button u-paddingLeftRight5" onClick={this.handleClose}>Close</button>
          <button className="gi-Button gi-Button--primary u-paddingLeftRight5" onClick={this.handleSupport}>Support</button>
        </div>
      </div>
    );
  }
}
