import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ahaui/react';

class UnlimitedPackage extends React.Component {
  handleBuy = (e) => {
    logger.debug('on handleBuy, package:', this.props.pkg);
    e.preventDefault();
    this.props.onBuy(this.props.pkg);
  }

  render() {
    const { pkg } = this.props;
    return (
      <div className="u-flex u-alignItemsCenter">
        <div className="u-flexGrow1">
          <h2 className="u-text200 u-fontMedium u-marginBottomTiny">{pkg.description}</h2>
          <p className="u-text200 u-textGray u-marginNone" dangerouslySetInnerHTML={{ __html: pkg.subdescription.replace(/\n/g, '<br/>') }} />
        </div>
        <div className="u-marginLeftSmall u-flexShrink-0"><del className="u-textGray">{pkg.old_price}</del></div>
        <Button className="u-marginLeftSmall u-flexShrink-0" style={{ width: 112 }} onClick={this.handleBuy}>{pkg.price}</Button>
      </div>
    );
  }
}

UnlimitedPackage.propTypes = {
  pkg: PropTypes.shape().isRequired,
  onBuy: PropTypes.func.isRequired,
};

export default UnlimitedPackage;
