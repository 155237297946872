import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Media,
  Button,
} from '@ahaui/react';

import history from 'utils/history';
import Tracking from 'utils/tracking';

import { launchAskSource } from 'constants/common.constant';

import QItem from '../History/QItem';
import QuestionActiveBar from '../Common/QuestionActiveBar';

import { loadSampleQuestions } from '../../actions/question.action';

import { queryToObject } from '../../utils/util';

class Guide extends React.Component {
  constructor(props) {
    super(props);
    const search = props.location.search.substring(1);
    this.params = queryToObject(search);
    this.played = false;
  }

  componentDidMount() {
    const { questions } = this.props.sampleQuestions;
    if (!questions || questions.length === 0) {
      this.props.onLoadSampleQuestions();
    }
  }

  handleShowSampleDetail = (qid) => {
    history.push(`/sample-sessions/${qid}`);
  }

  handleAskButtonClick = () => {
    Tracking.launchAsk({ source: launchAskSource.WHAT_IS_PHOTOSTUDY });
    history.push('/');
  }

  render() {
    const { questions } = this.props.sampleQuestions;

    return (
      <>
        <h1 className="sr-only">What is PhotoStudy</h1>

        <div className="Container Container--fluid u-paddingTopLarge u-paddingBottomExtraLarge">
          <div className="u-marginHorizontalAuto" style={{ width: '100%', maxWidth: 528 }}>
            <param name="movie" value="http://www.youtube.com/v/Ze13PrAsbwA"></param>
            <Media as="iframe" src="https://www.youtube.com/embed/Ze13PrAsbwA" type="video/webm" title="What is PhotoStudy" />

            <div className="u-textCenter">
              <h2 className="u-text500 u-fontMedium u-textCenter u-marginVerticalMedium">Step-by-step explanations from an Expert.</h2>
              <Button
                variant="primary_outline"
                onClick={this.handleAskButtonClick}
              >
                Ask an expert
              </Button>
            </div>

            <hr className="u-marginVerticalMedium" />

            <h2 className="u-text300 u-fontMedium u-marginBottomSmall">Sample sessions</h2>
            <div className="Grid Grid--smallGutter">
              {questions.map(question => (
                <div key={question.qid} className="u-size6of12 md:u-size4of12 u-marginBottomSmall">
                  <QItem
                    question={question}
                    onClick={() => this.handleShowSampleDetail(question.qid)}
                    isMyQuestion={false}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <QuestionActiveBar />
      </>
    );
  }
}

Guide.propTypes = {
  sampleQuestions: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  user: PropTypes.shape({
    signup_credits: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string,
    ]),
  }).isRequired,
  onLoadSampleQuestions: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  sampleQuestions: state.sampleQuestions,
});

const mapDispatchToProps = dispatch => ({
  onLoadSampleQuestions: () => dispatch(loadSampleQuestions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Guide);
