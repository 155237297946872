import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SafeAnchor, Toggle } from '@ahaui/react';
import ModalBase from 'components/Common/Modal';

import AccessToken from '../../utils/token';

import {
  forceLogout,
  acceptedTOS,
} from '../../actions/user.action';

class TOSModal extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      agreedTOS: false,
      allowedSendMsg: false,
    };
    this.tos = null;
  }

  componentDidMount() {
    if (this.props.tos) {
      this.tos = this.props.tos;
      this.popUpModal();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tos !== this.props.tos) {
      if (nextProps.tos) {
        this.tos = nextProps.tos;
        this.popUpModal();
      }
    }
  }

  popUpModal = () => {
    logger.debug('showing the tos modal', this.tos);
    this.setState({
      show: true,
      agreedTOS: false,
      allowedSendMsg: this.tos.gdpr && this.tos.gdpr.allow_send_msg,
    });
  }

  handleForceOut = (e) => {
    logger.debug('on handleForceOut');
    e.preventDefault();
    AccessToken.setAuthKey('');
    this.props.onForceLogout();
  }

  handleAcceptedTOS = (e) => {
    logger.debug('on handleAcceptedTOS');
    e.preventDefault();
    const gdpr = { gdpr_allow_send_msg: this.state.allowedSendMsg ? 1 : 0 };
    this.props.onAcceptedTOS(this.tos.id, gdpr).then(
      (dataRes) => {
        const { result } = dataRes.payload;
        if (result) {
          logger.debug('hide the modal');
          this.setState({ show: false });
        } else {
          // If error occurred, log user out.
          AccessToken.setAuthKey('');
          this.props.onForceLogout();
        }
      }
    );
  }

  handleChangeAgreedTOS = () => {
    this.setState({
      agreedTOS: !this.state.agreedTOS,
    });
  }

  handleChangeAllowedSendMsg = () => {
    this.setState({
      allowedSendMsg: !this.state.allowedSendMsg,
    });
  }

  render() {
    if (!this.state.show) return null;

    const { agreedTOS, allowedSendMsg } = this.state;

    return (
      <ModalBase
        headerText="Terms of Service"
        size="medium"
        closable={false}
        footerType="single"
        body={(
          <>
            <div className="u-flex u-marginBottomSmall">
              <div className="u-flexGrow1 u-paddingRightMedium">
                I have read and accepted the

                <SafeAnchor
                  href="https://www.got-it.co/terms.html"
                  target="_blank"
                >
                  Terms of Service
                </SafeAnchor>

                and

                <SafeAnchor
                  href="https://www.got-it.co/privacy-policy.html"
                  target="_blank"
                >
                  Privacy Policy
                </SafeAnchor>
              </div>
              <Toggle
                nonLabel
                checked={agreedTOS}
                onClick={this.handleChangeAgreedTOS}
              />
            </div>
            <div className="u-flex">
              <div className="u-flexGrow1 u-paddingRightMedium">
                Allow PhotoStudy to send you messages regarding your acount
              </div>
              <Toggle
                nonLabel
                checked={allowedSendMsg}
                onClick={this.handleChangeAllowedSendMsg}
              />
            </div>
          </>
        )}
        primaryButtonText="Complete Signup"
        onClickPrimaryButton={this.handleAcceptedTOS}
        disablePrimaryButton={!agreedTOS}
      />
    );
  }
}

TOSModal.propTypes = {
  onForceLogout: PropTypes.func.isRequired,
  onAcceptedTOS: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tos: state.user.tos,
});

const mapDispatchToProps = dispatch => ({
  onForceLogout: () => dispatch(forceLogout()),
  onAcceptedTOS: (tid, gdpr) => dispatch(acceptedTOS(tid, gdpr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TOSModal);
