import React from 'react';
import ModalBase from 'components/Common/Modal';

class ForceUpgradeModal extends React.Component {

  handleRefresh = (e) => {
    e.preventDefault();
    window.location.reload();
  }

  render() {
    return (
      <ModalBase
        headerText="PhotoStudy is updated!"
        size="small"
        closable={false}
        body={(
          <>
            <p>
              <img src={require('assets/images/icon-system-updated.svg')} className="u-block u-marginHorizontalAuto u-maxWidthFull" alt="" />
            </p>
            <p className="u-marginBottomNone">
              You have to update the new version of PhotoStudy, or else you can not update later, understood?
            </p>
          </>
        )}
        primaryButtonText="Update now"
        onClickPrimaryButton={this.handleRefresh}
      />
    );
  }
}

export default ForceUpgradeModal;
