import defaultState from '../store/defaultState';

import { PROMISE_RUN } from '../constants/promise.constant';
import {
  MOOD_SELECTED,
  DESCRIPTION_UPDATED,
  SUBJECT_SELECTED,
  LANGUAGE_SELECTED,
  FILE_LOADED,
  FILE_REMOVED,
  FILE_CROPPED,
  CROP_TOGGLE,
  CROP_HIDE,
  ASKFLOW_RESET,
  AUTOBID_STATUS_GET,
} from '../constants/askflow.constant';

const askflowReducer = (state = defaultState.askflow, action) => {
  if (action.type === MOOD_SELECTED) {
    return { ...state, selectedMood: action.mood };
  }

  if (action.type === DESCRIPTION_UPDATED) {
    return { ...state, description: action.description };
  }

  if (action.type === SUBJECT_SELECTED) {
    return { ...state, selectedSubject: action.subject };
  }

  if (action.type === LANGUAGE_SELECTED) {
    return { ...state, selectedLanguage: action.language };
  }

  if (action.type === FILE_LOADED) {
    return { ...state, originalImage: action.file };
  }

  if (action.type === FILE_CROPPED) {
    return { ...state, croppedImage: action.file };
  }

  if (action.type === CROP_TOGGLE) {
    return { ...state, toggleCrop: true };
  }

  if (action.type === CROP_HIDE) {
    return { ...state, toggleCrop: false };
  }

  if (action.type === FILE_REMOVED) {
    return {
      ...state,
      croppedImage: null,
      originalImage: null,
    };
  }

  if (action.type === ASKFLOW_RESET) {
    return defaultState.askflow;
  }

  if (action.type === PROMISE_RUN) {
    if (action.key === AUTOBID_STATUS_GET) {
      const { result } = action.payload;
      if (result) {
        return { ...state, autobidStatus: result.data };
      }
    }
  }

  return state;
};

export default askflowReducer;
