import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Message,
  Icon,
  BubbleChat,
} from '@ahaui/react';

import ts from 'utils/timestamp';

import ExpertMessageList from './ExpertMessageList';

class ExpertSession extends React.Component {
  constructor() {
    super();
    this.state = {
      timeRemain: 0,
    };
    this.countdownTimer = 0;
    this.expiry = 0;
  }

  componentDidMount() {
    this.expiry = this.props.session.expiry;
    // expiry = 0 is marked for the user views their own session.
    if (this.expiry > 0) {
      this.setUpCountDown();
    }  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.session.expiry !== this.props.session.expiry) {
      logger.debug('get newProps', 'promoTs', nextProps.session.expiry);
      this.expiry = nextProps.session.expiry;
      // expiry = 0 is marked for the user views their own session.
      if (this.expiry > 0) {
        this.setUpCountDown();
      }
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.countdownTimer);
  }

  setUpCountDown = () => {
    let expiry = this.expiry;
    let duration = expiry - ts.now();
    if (duration > 0) {
      this.setState({
        timeRemain: duration,
      });
      if (!this.countdownTimer) {
        logger.debug('setting up countdown Interval');
        this.countdownTimer = window.setInterval(this.setUpCountDown, 1 * 1000);
      }
    } else {
      logger.debug('clearing countdown');
      this.setState({
        timeRemain: 0,
      });
      window.clearInterval(this.countdownTimer);
      this.props.onExpired();
    }
  }


  render() {
    const session = this.props.session;
    const question = session.question;
    const messages = session.discussion_messages;
    const isShowTimer = session.logged_in && session.expiry > 0;
    let timeRemain = this.state.timeRemain;
    let momentDuration = moment.duration(timeRemain, 's');

    return (
      <div>
        <h1 className="u-text600 u-marginBottomExtraSmall">{question.subject.title} by {question.expert_name}</h1>
        <p>{question.title}</p>

        {isShowTimer && (
          <Message variant="positive">
            <div className="u-flex u-alignItemsCenter u-paddingLeftSmall">
              <Icon name="timer" size="medium" />
            </div>
            <Message.Container>
              <Message.Content>Time left to view the solution {Math.floor(momentDuration.asHours()) + moment.utc(momentDuration.asMilliseconds()).format(":mm:ss")}</Message.Content>
            </Message.Container>
          </Message>
        )}

        <div className="u-marginTopSmall">
          {(question.attachments && question.attachments.length > 0) &&
            <BubbleChat
              type="inbound"
            >
              <BubbleChat.Image src={question.attachments[0].url} alt="Question image" />
            </BubbleChat>
          }

          <ExpertMessageList
            question={question}
            messages={messages}
          />
        </div>
      </div>
    );
  }
}

ExpertSession.propTypes = {
  session: PropTypes.shape().isRequired,
  onExpired: PropTypes.func,
};

export default ExpertSession;
