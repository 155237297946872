import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, SafeAnchor } from '@ahaui/react';
import ModalBase from 'components/Common/Modal';

class School extends React.Component {
  constructor(props) {
    super(props);
    this.state = { school: this.props.enteredSchool };
  }

  handleChangeSchool = (e) => {
    this.setState({ school: e.target.value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.school) {
      return false;
    }
    this.props.onEnterSchool(this.state.school);
  }

  handleBack = (e) => {
    e.preventDefault();
    this.props.onBackToGrade(this.state.school);
  }

  handleSkip = (e) => {
    e.preventDefault();
    this.props.onEnterSchool('');
  }

  render() {
    return (
      <ModalBase
        headerText="Help us match you with the right Experts for you"
        size="medium"
        closable={false}
        body={(
          <Form onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Label>School</Form.Label>
              <Form.Input
                type="text"
                placeholder="School Name"
                value={this.state.school}
                onChange={this.handleChangeSchool}
              />
            </Form.Group>
          </Form>
        )}
        footer={(
          <div className="u-flex u-widthFull">
            <div className="u-paddingTopExtraSmall">
              <SafeAnchor
                className="u-text200 hover:u-textPrimary hover:u-textDecorationNone"
                onClick={this.handleSkip}
              >
                Skip this step
              </SafeAnchor>
            </div>
            <div className="u-marginLeftAuto">
              <Button variant="secondary" onClick={this.handleBack}>
                <Button.Label>Back</Button.Label>
              </Button>
              <Button
                variant="primary"
                className="u-marginLeftSmall"
                onClick={this.handleSubmit}
                disabled={!this.state.school}
              >
                <Button.Label>Continue</Button.Label>
              </Button>
            </div>
          </div>
        )}
      />
    );
  }
}

School.propTypes = {
  enteredSchool: PropTypes.string,
  onEnterSchool: PropTypes.func.isRequired,
  onBackToGrade: PropTypes.func.isRequired
};

School.defaultProps = {
  enteredSchool: ''
};

export default School;
