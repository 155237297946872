import { LANGUAGE } from 'constants/question.constant';
import { SYSTEM_STATUS_READY } from '../constants/app.constant';
import { discussionStateConsts, questionStateConsts } from '../constants/common.constant';

const defaultState = {
  app: {
    systemStatus: SYSTEM_STATUS_READY,
    isAppLoaded: false,
    authToken: '',
    firstLogin: false,
    forceLogout: {
      needed: false,
      modalTitle: '',
      modalBody: '',
      modalButtonText: '',
    },
  },
  user: {
    uid: 0,
    signing_up: false,
    tos: null,
    email: null,
    fullname: null,
    avatar: null,
    grade: null,
    payment_transaction_count: 0,
    support_priority: null,
    consumed_credit_count: 0,
    enabled_features: [],
    enabled_languages: [],
    selected_language: '',
  },
  surgeInfo: {
    subjects: [],
    moods: [],
    modal: null,
    hasFreeSession: 0,
  },
  chat: {
    waitingExpertModal: false,
    regularRouteShow: false,
    regularRouteMessages: [],
    regularClaimShow: false,
    regularClaimMessage: '',
    questionGranted: false,
    qid: -1,
    question: {
      qid: 0,
      title: '',
      attachments: null,
      created: 0,
      updated: 0,
      expert_name: '',
      claimed_uid: 0,
      processing_status: questionStateConsts.CONNECTING,
      discussion_status: discussionStateConsts.NOT_STARTED,
      processing_data: null,
      pending_review: 0,
      discussion_time_limit: 0,
      discussion_end_time: 0,
      has_autobid: 0,
      in_autobid: 0,
      lang: '',
    },
    messages: [],
    answ: {
      aid: 0,
      vote: false,
    },
  },
  askflow: {
    description: '',
    toggleCrop: false,
    originalImage: null,
    croppedImage: null,
    selectedMood: null,
    selectedSubject: null,
    selectedLanguage: '',
    autobidStatus: [],
  },
  storefront: {
    loaded: false,
    balance: 0,
    title: '',
    subtitle: '',
    packages: [],
    token: '',
    nonce: '',
    promo_ts: 0,
    defaultMethod: {
      text: '',
      image: '',
      methodType: '',
      cardType: '',
    },
  },
  notification: {
    unreadNids: [],
    notifications: [],
    loadMore: false,
    unreadBlocked: false,
  },
  myQuestions: {
    changes: {},
    oldestQid: 0,
    questions: [],
    loadMore: true,
  },
  sampleQuestions: {
    questions: [],
  },
};

export default defaultState;
