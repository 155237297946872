export const MOOD_SELECTED = 'mood_selected';
export const DESCRIPTION_UPDATED = 'description_updated';
export const SUBJECT_SELECTED = 'subject_selected';
export const LANGUAGE_SELECTED = 'language_selected';
export const FILE_LOADED = 'file_loaded';
export const FILE_REMOVED = 'file_removed';
export const FILE_CROPPED = 'file_cropped';
export const CROP_TOGGLE = 'crop_toggle';
export const CROP_HIDE = 'crop_hide';
export const QUESTION_SUBMIT = 'question_submit';
export const ASKFLOW_RESET = 'askflow_reset';
export const AUTOBID_STATUS_GET = 'autobid_status_get';
