import {
  QUESTION_STATUS_ROUTING,
  QUESTION_STATUS_CLAIMED,
  QUESTION_STATUS_ANSWERED,
  LANGUAGE_LABEL,
} from '../constants/question.constant';

import {
  questionStateConsts,
  discussionStateConsts,
  questionPendingReview,
} from '../constants/common.constant';


export const isOpening = (question) => {
  const { processing_status, discussion_time_left, pending_review } = question;
  const currentStamp = Math.floor(Date.now() / 1000);
  if ((+processing_status === QUESTION_STATUS_CLAIMED ||
        +processing_status === QUESTION_STATUS_ANSWERED) &&
      +discussion_time_left > 0) {
    return true;
  }
  if (+processing_status === QUESTION_STATUS_ROUTING &&
      +pending_review === 0 &&
      (currentStamp - 1800) < +question.created) {
    return true;
  }
  return false;
};

export const isDead = (question) => {
  const questionFlagged = question.processing_status == questionStateConsts.FLAGGED;
  const questionExplained = question.processing_status == questionStateConsts.EXPLAINED;
  const questionDead = question.pending_review === questionPendingReview.DEAD;
  const questionExpired = question.discussion_status == discussionStateConsts.EXPIRED && question.processing_status == questionStateConsts.INPROGRESS;
  const questionEndedByStudent = question.discussion_status == discussionStateConsts.STUDENT_ENDED;
  return questionFlagged || questionExplained || questionDead || questionExpired || questionEndedByStudent;
};

export const getLanguageLabel = language => LANGUAGE_LABEL[language] || '';

export default {
  isOpening,
};
