import React from 'react';

export default class extends React.Component {
  render () {
    return (
      <footer className="gi-LayoutFooter page-footer">
        <div className="gi-Container">
          <div className="gi-Grid">
            <div className="gi-Grid-cell u-md-size6of12 left">
              <div className="u-table">
                <div className="u-tableCell" style={{ verticalAlign: 'top' }}>
                  <a className="footer-logo u-paddingRight5" href="/">
                    <img
                      alt=""
                      src={require("assets/images/old/logo-gotit.svg")}
                    />
                  </a>
                </div>
                <div className="u-tableCell u-paddingRight5" style={{ verticalAlign: 'top' }}>
                  <div className="u-fontSizeXxl u-fontWeightLight">
                    3.0M+
                  </div>
                  <div>
                    Got It Moments
                  </div>
                </div>
                <div className="u-tableCell" style={{ verticalAlign: 'top' }}>
                  <div className="u-fontSizeXxl u-fontWeightLight">
                    4.4
                    <img
                      alt="star"
                      className="star"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAqCAYAAADI3bkcAAAAAXNSR0IArs4c6QAAAwBJREFUWAnNmc+LTWEcxs8dQzRTU2QijUJZjWZK1EiSiynlx6BslNgoC2PhL5iSMjvFQknZ2ZBCYnajGGVHiayUQdFkFkhcn+/pdjtzzzn3fd573sv51nPf97zv8z7f57zn53tuFAWMWq22ETwA8+AdOBtQPqwU5vrALGiO8bCZAqnh8nyz0/r2Z8pFgdKEk8HUTI5ha94VLlMAJQwNtDBrXVcDpIklugIJHXHojGE6VC5HKqEbM9M2jY7YLkg5KYX3GpOryLLNmSmKXEdBkIiiwobJMgYUncOSo06TmOEpx6mQ7N5S1I8yM7k5cLKCzp25hHTH0XSTX0shw6Q6BHweCoXP46KGfQ1s4KgM+c3pQnbFNhHppzgB1oO4jVKJk5CWKMQEZ5r6q8S2qzoPYapSqTyKiZgdBF9A2eOyGa7g8jHl7th9+X+22jk8Un6fDYcjZni2sVn+ykczPFl+n7HDN/ze7eLqu0blHPgVN5fzZwZbVbz+bNjj4tsM3pbsVvEbPxdBd8NoskJHL7gJyhAfMFFN+sutQzwOvv1H1/fIvTLXYFYHA+wx+vwfm/5BvvZX2QxeDC6BP6DT8ZoEw1mT592G0B6Q9c0h1E5cR6jH21irAQgeCOWuSedZq7zNffbgUGNUJXry7HZqCwEpJMMIGs/Wbp0Iu8ceVIUlw4jZqni1KtoGT146qYZ9Vxa+nqscxT5lkGq400t0W7XsD2KYPbel+VpFrCBHOorKDEtCBc3a8FEmx3kvVgzLF0Td9BylLU7v17fVYhnEfSo5k8ceDwGfeAK5cfpQHwf2fqDGrUwjaiNZJsRM9t5q3NRHFdqGgb0nKGH/jSxV/aV4DH4hZHkPZ0dqcKKB/h5g7wtK7E0M9aui/smR4Q79y1VVuMfAnEPzlKqX4iFsf2FlxXcaz6QGCA2MWweeZonW29r/lIXAJvC1Sfwl24OCt1wK47vBBWDnfjKu5A6qdzi/o6E2APc0WANs9XpjweqVhnYDbXtht296veAhuI12jTI3/gLgmRiuZKCKygAAAABJRU5ErkJggg=="
                      width="12"
                    />
                  </div>
                  <div>
                    App Store Rating
                  </div>
                </div>
              </div>
              <p className="footer-intro u-colorNeutral500">
                <small>
                  Got It is on a mission to change the world of learning and knowledge sharing.
                  Using its advanced Knowledge-as-a-Service platform technology, Got It gives all
                  users—professionals, learners and consumers—a way to access and engage with live
                  experts to help them solve problems, anytime, anywhere via the ease of their
                  mobile phone. Spun out from the YouWeb incubator and backed by the Capricorn
                  Investment Group and Fosun, Got It is led by a proven leadership team and
                  dual-headquartered in the Silicon Valley and Vietnam.
                </small>
              </p>
            </div>
            <div className="gi-Grid-cell u-md-size6of12 right">
              <div className="gi-Grid">
                <div className="gi-Grid-cell u-size1of4">
                  <ul className="">
                    <li className="footer-link">
                      <a href="https://www.got-it.ai/kaas.html">
                        KaaS Platform
                      </a>
                    </li>
                    <li className="footer-link">
                      <a href="https://www.got-it.ai/solutions.html">
                        Solutions
                      </a>
                    </li>
                    <li className="footer-link">
                      <a href="https://www.got-it.ai/about.html">
                        About
                      </a>
                    </li>
                    <li className="footer-link">
                      <a href="https://www.got-it.ai/team.html">
                        Team
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="gi-Grid-cell u-size1of4">
                  <ul className="u-marginLeft3">
                    <li className="footer-link">
                      <a href="https://www.got-it.ai/careers.html">
                        Careers
                      </a>
                    </li>
                    <li className="footer-link">
                      <a href="https://www.got-it.ai/press.html">
                        Press
                      </a>
                    </li>
                    {/* <!--<li className="footer-link"><a href="blog.html">Blog</a></li>--> */}
                    <li className="footer-link">
                      <a href="https://www.got-it.ai/experts.html">
                        Experts
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="gi-Grid-cell u-size1of4">
                  <ul className="u-marginLeft3">
                    <li className="footer-link">
                      <a href="https://www.got-it.ai/contact.html">
                        Contact
                      </a>
                    </li>
                    <li className="footer-link">
                      <a href="https://www.got-it.co/terms.html">
                        Terms
                      </a>
                    </li>
                    {/* <!--<li className="footer-link"><a href="legal.html">Legal</a></li>--> */}
                  </ul>
                </div>
                <div className="gi-Grid-cell u-size1of4">
                  <div className="gi-sharingOnHeader">
                    <a href="https://www.facebook.com/gotithelp/" target="_blank" rel="noopener noreferrer">
                      <span className="zmdi-hc-stack zmdi-hc-lg gi-sharingOnHeader-facebook">
                        <i className="zmdi zmdi-circle zmdi-hc-stack-2x"></i>
                        <i className="zmdi zmdi-facebook zmdi-hc-stack-1x zmdi-hc-inverse"></i>
                      </span>
                    </a>
                    <a href="https://twitter.com/got_it_co" target="_blank" rel="noopener noreferrer">
                      <span className="zmdi-hc-stack zmdi-hc-lg gi-sharingOnHeader-twitter">
                        <i className="zmdi zmdi-circle zmdi-hc-stack-2x"></i>
                        <i className="zmdi zmdi-twitter zmdi-hc-stack-1x zmdi-hc-inverse"></i>
                      </span>
                    </a>
                    {/* <a href="#" target="_blank" rel="noopener noreferrer">
                      <span className="zmdi-hc-stack zmdi-hc-lg gi-sharingOnHeader-googleplus">
                        <i className="zmdi zmdi-circle zmdi-hc-stack-2x"></i>
                        <i className="zmdi zmdi-google-plus zmdi-hc-stack-1x zmdi-hc-inverse"></i>
                      </span>
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="footer-copyright u-colorNeutral500 u-marginTop4">
                <small>
                  © 2017 Got It, Inc. All rights reserved.
                </small>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
