import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import config from 'configuration';
import ModalBase from 'components/Common/Modal';
import { getBrowser } from '../../../utils/app';
import AccessToken from '../../../utils/token';
import { ServerAPIError } from '../../../utils/request';
import Tracking from '../../../utils/tracking';

import Header from './Header';
import SuncriptionPackage from './SubcriptionPackage';
import SubcriptionCancelModal from './SubcriptionCancelModal';
import StandardPackage from './StandardPackage';
import InfoPackage from './InfoPackage';
import ExtendedPackage from './ExtendedPackage';
import UnlimitedPackage from './UnlimitedPackage';
import PurchaseConfirmModal from './PurchaseConfirmModal';
import Braintree from './Braintree';
import ProcessingModal from './ProcessingModal';
import CurrentPaymentBox from './CurrentPaymentBox';
import {
  InvalidMethodFailedModal,
  SucceedModal,
  TransactionFailedModal,
  NotSupportedPaymentMethodModal,
  UnknownErrorModal,
} from './ResultModal';

import { hasTrioSupport } from '../../../utils/feature-support';

import {
  storefrontView,
} from '../../../constants/common.constant';
import {
  packageTypes,
} from '../../../constants/storefront.constant';

import {
  getSurgeInfo,
} from '../../../actions/user.action';

import {
  getPaymentMethod,
  buyPackage,
  setDefaultMethod,
  updateSubScriptionPackage,
} from '../../../actions/storefront.action';

class StoreFront extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: storefrontView.PACKAGES,
      processing: false,
      subcriptionCancelModalShow: false,
      subcriptionCancelPackage: null,
      purchaseConfirmModalShow: false,
      SucceedModalShow: false,
      InvalidMethodFailedModalShow: false,
      NotSupportedPaymentMethodModalShow: false,
      TransactionFailedModalShow: false,
      UnknownErrorModalShow: false,
      showPackagesModal: true,
    };
    this.isBuying = false;
    this.selectedPackage = null;
    const { enabled_features } = props.user;
    this.isTrio = hasTrioSupport(enabled_features);
    this.isViewTracked = false;
  }

  componentDidMount() {
    this.props.onGetPaymentMethod();
    if (this.props.view) {
      this.setState({
        view: this.props.view,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { storefront } = nextProps;
    if (storefront !== this.props.storefront) {
      if (!this.isViewTracked && storefront.loaded === true) {
        this.isViewTracked = true;
        this.trackViewed(storefront);
      }
    }
  }

  //------ Tracking ---- //
  trackViewed = (storefront) => {
    logger.debug('on trackViewed');
    const { title } = storefront;
    const { subtitle } = storefront;
    const { packages } = storefront;
    const offers = [];
    packages.forEach((pkg) => {
      offers.push(pkg.product_id);
    });
    Tracking.viewStore({
      offers,
      title,
      text: subtitle,
      type: Number(this.props.type),
    });
  }

  trackBoughtPackage = (method, pkg) => {
    logger.debug('on trackBroughPackage');
    Tracking.purchaseCredits({
      credits: Number(pkg.num_of_credits),
      method: StoreFront.methodMap[method],
      purchase_tier: pkg.product_id,
      type: Number(this.props.type),
    });
  };

  showProcessing = () => {
    logger.debug('on showProcessing');
    this.setState({
      processing: true,
      showPackagesModal: false,
    });
  }

  hideProcessing = () => {
    logger.debug('on hideProcessing');
    this.setState({
      processing: false,
    });
  }

  resetBuyingData = () => {
    logger.debug('on resetBuyingData');
    this.isBuying = false;
    this.selectedPackage = null;
  }

  //--------- Buy Package -------//
  onBuyError = (error) => {
    if (error instanceof ServerAPIError) {
      const errorPayload = error.data;
      const errorMsg = errorPayload.data.message;
      if (errorMsg === StoreFront.failedReason.MISSING_METHOD) {
        logger.warn(errorMsg);
        this.hideProcessing();
        this.setState({
          view: storefrontView.BRAINTREE,
        });
        return;
      }
      if (errorMsg === StoreFront.failedReason.INVALID_METHOD) {
        logger.warn(errorMsg);
        this.hideProcessing();
        this.setState({
          InvalidMethodFailedModalShow: true,
        });
        return;
      }
      if (errorMsg === StoreFront.failedReason.TRANSACTION_FAILED) {
        logger.warn(errorMsg);
        this.hideProcessing();
        this.setState({
          TransactionFailedModalShow: true,
        });
        this.resetBuyingData();
        return;
      }
      if (errorMsg === StoreFront.failedReason.PAYMENT_METHOD_NOT_SUPPORTED) {
        logger.warn(errorMsg);
        this.hideProcessing();
        this.setState({
          NotSupportedPaymentMethodModalShow: true,
        });
        return;
      }
    }
    // Show unknown error modal.
    this.hideProcessing();
    this.setState({
      UnknownErrorModalShow: true,
    });
  }

  onBySucceed = () => {
    this.hideProcessing();
    this.setState({
      SucceedModalShow: true,
    });
    this.props.onGetSurgeInfo();
    const pkg = this.selectedPackage;
    this.props.onGetPaymentMethod().then((data) => {
      const { result } = data.payload;
      const { error } = data.payload;
      if (result) {
        this.trackBoughtPackage(result.methodType, pkg);
      }
      if (error) {
        logger.error('Failed to get default method to Tracking', error);
      }
    });
    // Reset buying data aft
    this.resetBuyingData();
  }

  buyPackage = (pkg) => {
    logger.debug('on buyPackage, pkg', pkg);
    const productId = pkg.product_id;
    this.showProcessing();
    this.props.onBuyPackage(productId).then((data) => {
      const { result } = data.payload;
      const { error } = data.payload;
      if (result) {
        logger.debug('buy succeed', productId);
        this.onBySucceed();
      }
      if (error) {
        logger.warn('buy failed', productId, error);
        this.onBuyError(error);
      }
    });
  }

  handleConfirmBuyPackage = (pkg) => {
    logger.debug('on handleConfirmBuyPackage');
    this.setState({
      purchaseConfirmModalShow: false,
    });
    this.buyPackage(pkg);
  }

  handleHidePurchaseConfirmModal = () => {
    logger.debug('on handleHidePurchaseConfirmModal');
    this.setState({
      purchaseConfirmModalShow: false,
    });
  }

  handleBuyPackge = (pkg) => {
    logger.debug('on handleBuyStandardPackge');
    this.isBuying = true;
    this.selectedPackage = pkg;
    if (pkg.confirmation_required) {
      this.setState({
        purchaseConfirmModalShow: true,
      });
    } else {
      this.buyPackage(pkg);
    }
  }

  handleBraintreeSelect = () => {
    logger.debug('on handleBraintreeSelect');
    this.setState({
      view: storefrontView.BRAINTREE,
      NotSupportedPaymentMethodModalShow: false,
    });
  }

  // ------ Handle braintree view ----- //
  handleHadMethod = (nonce) => {
    logger.debug('on handleSetDefaultMethod');
    if (this.isBuying) {
      logger.debug('isBuying enabled, do a purchase');
      this.props.onSetDefaultMethod(nonce).then((data) => {
        const { result } = data.payload;
        if (result) {
          logger.debug('onSetDefaultMethod succeed');
          logger.debug('buying the package', this.selectedPackage);
          this.buyPackage(this.selectedPackage);
          this.setState({
            view: storefrontView.PACKAGES,
          });
        }
      });
    }
  }

  handleBackToPackagesView = (nonce) => {
    logger.debug('on handleBackToPackageView');
    this.setState({
      view: storefrontView.PACKAGES,
      showPackagesModal: true,
    });
    if (nonce) {
      this.props.onSetDefaultMethod(nonce).then(
        (data) => {
          const { result } = data.payload;
          if (result) {
            logger.debug('onSetDefaultMethod succeed');
          }
          this.props.onGetPaymentMethod();
        },
      );
    }
  }

  //------------SUBSCRIPTION----------//
  updateScriptionPackage = (productId, enable) => {
    logger.debug('on updateScriptionPackage');
    this.showProcessing();
    this.props.onUpdateSubScriptionPackage(productId, enable).then(
      (data) => {
        const { result } = data.payload;
        const { error } = data.payload;
        if (result) {
          logger.debug('finished updateScriptionPackage', data);
          this.props.onGetSurgeInfo();
          this.hideProcessing();
        }
        if (error) {
          if (error instanceof ServerAPIError) {
            const errorPayload = error.data;
            const errorMsg = errorPayload.data.message;
            logger.warn('error when updateScriptionPackage');
            if (errorMsg === StoreFront.failedReason.PAYMENT_METHOD_NOT_SUPPORTED) {
              this.hideProcessing();
              this.setState({
                NotSupportedPaymentMethodModalShow: true,
              });
              return;
            }
            if (errorMsg === StoreFront.failedReason.MISSING_METHOD) {
              this.hideProcessing();
              this.setState({
                view: storefrontView.BRAINTREE,
              });
              return;
            }
            this.hideProcessing();
            this.setState({
              UnknownErrorModalShow: true,
            });
            return;
          }
          // Show unknown error modal.
          this.hideProcessing();
          this.setState({
            UnknownErrorModalShow: true,
          });
        }
      });
  }

  handleEnableSubcription = (pkg) => {
    logger.debug('on handleSubcriptionEnable');
    const productId = pkg.product_id;
    this.updateScriptionPackage(productId, 1);
  }

  handleDisableSubcription = (pkg) => {
    logger.debug('on handleSubcriptionDisable');
    this.setState({
      subcriptionCancelModalShow: true,
      subcriptionCancelPackage: pkg,
    });
  }

  handleSubcriptionCancelConfirm = (productId) => {
    logger.debug('on handleSubcriptionCancelConfirm');
    this.setState({
      subcriptionCancelModalShow: false,
    });
    this.updateScriptionPackage(productId, 0);
  }

  handleHideScriptionCancelModal = () => {
    logger.debug('on handleHideScriptionCancelModal');
    this.setState({
      subcriptionCancelModalShow: false,
      subcriptionCancelPackage: null,
    });
  }

  //-------------Result Modal------//
  handleHideTransactionFailedModal = () => {
    logger.debug('on handleHideTransactionFailedModal');
    this.setState({
      TransactionFailedModalShow: false,
    });
  }

  handleHideInvalidMethodFailedModal = () => {
    logger.debug('on handleHideInvalidMethodFailedModal');
    this.setState({
      InvalidMethodFailedModalShow: false,
    });
  }

  handleHideUnknownErrorModal = () => {
    logger.debug('on handleHideUnknownErrorModal');
    this.setState({
      UnknownErrorModalShow: false,
    });
  }

  handleHideSucceedModal = () => {
    logger.debug('on handleHideSucceedModal');
    this.setState({
      SucceedModalShow: false,
    });
  }

  handleOpenNeedHelpMail = () => {
    logger.debug('on handleOpenNeedHelpMail');
    const { user } = this.props;
    const { appVersion } = config;
    const browser = getBrowser();
    const deviceId = AccessToken.getBrowserId();
    const mailLink = 'mailto:support@photostudy.co?'
      + 'subject=Got%20IT%20Study%20Purchase%20Error%20Feedback&'
      + 'body=%0A%5E%5Eplease%20enter%20feedback%20ABOVE%5E%5E%0A%0A%0A'
      + `App%20Version%3A%20${appVersion}%20%0A`
      + `Browser%3A%20${browser}%20%0A`
      + `Browserprint%3A%20${deviceId}%20%0A`
      + `User%20ID%3A${user.uid}%20%0A`
      + 'Additional%20Information'
        + `%3A%20${user.support_priority}`
        + `%20%3A%20${user.payment_transaction_count}`
        + `%20%3A%20${user.consumed_credit_count}%20%0A%0A`
      + 'Sent%20from%20Web%20app';
    window.open(mailLink, '_self');
  }

  renderPackages = (packages, defaultMethod) => (
    <div className="Container Container--fluid">
      <div className="u-marginHorizontalAuto u-paddingVerticalMedium" style={{ width: '100%', maxWidth: 581 }}>
        <div>
          {packages.map((pkg) => {
            let content = null;

            if (pkg.type === packageTypes.STANDARD) {
              content = (
                <StandardPackage
                  pkg={pkg}
                  onBuy={this.handleBuyPackge}
                />
              );
            }
            if (pkg.type === packageTypes.INFO) {
              content = (
                <InfoPackage
                  pkg={pkg}
                />
              );
            }
            if (pkg.type === packageTypes.EXTENDED) {
              content = (
                <ExtendedPackage
                  pkg={pkg}
                  onBuy={this.handleBuyPackge}
                />
              );
            }
            if (pkg.type === packageTypes.UNLIMITED) {
              content = (
                <UnlimitedPackage
                  pkg={pkg}
                  onBuy={this.handleBuyPackge}
                />
              );
            }
            if (pkg.type === packageTypes.SUBSCRIPTION) {
              content = (
                <SuncriptionPackage
                  pkg={pkg}
                  onEnable={this.handleEnableSubcription}
                  onDisable={this.handleDisableSubcription}
                />
              );
            }

            return (
              <Fragment key={pkg.product_id || 'info_package'}>
                {content}
                <hr className="u-marginVerticalSmall" />
              </Fragment>
            );
          })}
        </div>

        <CurrentPaymentBox
          defaultMethod={defaultMethod}
          onOpenBraintree={this.handleBraintreeSelect}
        />
      </div>
    </div>
  )

  renderStorefrontView = () => {
    const { type, onClose } = this.props;
    const { showPackagesModal } = this.state;
    const { packages } = this.props.storefront;
    const { defaultMethod } = this.props.storefront;
    if (this.isTrio) {
      return <Header />;
    }

    return (
      <>
        {type > 0 && showPackagesModal && (
          <ModalBase
            size="large"
            closable
            onHide={onClose}
            body={(
              <ModalBase
                size="large"
                closable
                onHide={onClose}
                body={(
                  <>
                    <Header />
                    {this.renderPackages(packages, defaultMethod)}
                  </>
                )}
                showFooter={false}
              />

            )}
            showFooter={false}
          />
        )}
        {!type && (
          <>
            <Header />
            {this.renderPackages(packages, defaultMethod)}
          </>
        )}

        {this.state.processing && (
          <ProcessingModal />
        )}
        {this.state.TransactionFailedModalShow && (
          <TransactionFailedModal
            onNeedHelp={this.handleOpenNeedHelpMail}
            onHide={this.handleHideTransactionFailedModal}
          />
        )}
        {this.state.InvalidMethodFailedModalShow && (
          <InvalidMethodFailedModal onHide={this.handleHideInvalidMethodFailedModal} />
        )}
        {this.state.NotSupportedPaymentMethodModalShow && (
          <NotSupportedPaymentMethodModal onGotoBraintree={this.handleBraintreeSelect} />
        )}
        {this.state.UnknownErrorModalShow && (
          <UnknownErrorModal onHide={this.handleHideUnknownErrorModal} />
        )}
        {this.state.SucceedModalShow && (
          <SucceedModal onHide={this.handleHideSucceedModal} />
        )}
        {this.state.subcriptionCancelModalShow && (
          <SubcriptionCancelModal
            pkg={this.state.subcriptionCancelPackage}
            onConfirm={this.handleSubcriptionCancelConfirm}
            onCancel={this.handleHideScriptionCancelModal}
          />
        )}
        {this.state.purchaseConfirmModalShow && (
          <PurchaseConfirmModal
            pkg={this.selectedPackage}
            onConfirm={this.handleConfirmBuyPackage}
            onCancel={this.handleHidePurchaseConfirmModal}
          />
        )}
      </>
    );
  }

  renderBraintree = () => {
    const { type, onClose } = this.props;
    const { token } = this.props.storefront;

    if (type) {
      return (
        <ModalBase
          size="large"
          closable
          onHide={onClose}
          body={(
            <>
              <Header />
              <Braintree
                token={token}
                isBuying={this.state.isBuying}
                onBack={this.handleBackToPackagesView}
                onHadMethod={this.handleHadMethod}
              />
            </>
        )}
          showFooter={false}
        />
      );
    }

    return (
      <>
        <Header />
        <Braintree
          token={token}
          isBuying={this.state.isBuying}
          onBack={this.handleBackToPackagesView}
          onHadMethod={this.handleHadMethod}
        />
      </>
    );
  }

  render() {
    return (
      <>
        {this.state.view === storefrontView.PACKAGES && this.renderStorefrontView()}
        {this.state.view === storefrontView.BRAINTREE && this.renderBraintree()}
      </>
    );
  }
}

StoreFront.failedReason = {
  MISSING_METHOD: 'missing braintree payment method',
  TRANSACTION_FAILED: 'transaction failed',
  INVALID_METHOD: 'invalid braintree payment method',
  PAYMENT_METHOD_NOT_SUPPORTED: 'payment method is not supported',
};

StoreFront.methodMap = {
  credit_card: 'CC',
  paypal: 'PayPal',
};

StoreFront.propTypes = {
  storefront: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  type: PropTypes.number.isRequired,
  view: PropTypes.string,
  onGetSurgeInfo: PropTypes.func.isRequired,
  onGetPaymentMethod: PropTypes.func.isRequired,
  onSetDefaultMethod: PropTypes.func.isRequired,
  onUpdateSubScriptionPackage: PropTypes.func.isRequired,
  onBuyPackage: PropTypes.func.isRequired,
};

StoreFront.defaultProps = {
  view: null,
};

const mapStateToProps = (state) => ({
  storefront: state.storefront,
  user: state.user,
});

const mapPropsToDispatch = (dispatch) => ({
  onGetSurgeInfo: () => dispatch(getSurgeInfo()),
  onGetPaymentMethod: () => dispatch(getPaymentMethod()),
  onBuyPackage: (productId) => dispatch(buyPackage(productId)),
  onSetDefaultMethod: (nonce) => dispatch(setDefaultMethod(nonce)),
  onUpdateSubScriptionPackage: (productId, enable) => dispatch(updateSubScriptionPackage(productId, enable)),
});

export default connect(mapStateToProps, mapPropsToDispatch)(StoreFront);
