import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  getSurgeInfo,
} from '../../../actions/user.action';
import ts from '../../../utils/timestamp';

import TopBanner from 'components/Common/TopBanner';
import { hasTrioSupport } from 'utils/feature-support';


class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      timeRemain: 0,
    };
    this.countdownTimer = 0;
    this.promoTs = 0;
  }

  componentDidMount() {
    this.promoTs = this.props.promoTs;
    this.setUpCountDown();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.promoTs !== this.props.promoTs) {
      logger.debug('get newProps', 'promoTs', nextProps.promoTs);
      this.promoTs = nextProps.promoTs;
      this.setUpCountDown();
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.countdownTimer);
  }

  setUpCountDown = () => {
    logger.debug('seting up TimedStoreFront countdown');
    const { promoTs } = this;
    const duration = promoTs - ts.now();
    if (duration > 0) {
      logger.debug('showing the timedStorefront');
      this.setState({
        timeRemain: duration,
      });
      if (!this.countdownTimer) {
        logger.debug('setting up TimedStoreFront Interval');
        this.countdownTimer = window.setInterval(this.setUpCountDown, 1 * 1000);
      }
    } else {
      logger.debug('clearing timedStorefont countdown');
      this.setState({
        timeRemain: 0,
      });
      this.props.onGetSurgeInfo();
      window.clearInterval(this.countdownTimer);
    }
  }


  render() {
    const { title, subtitle, user } = this.props;
    const { timeRemain } = this.state;

    const momentDuration = moment.duration(timeRemain, 's');
    const formattedTime = Math.floor(momentDuration.asHours()) + moment.utc(momentDuration.asMilliseconds()).format(':mm:ss');

    return (
      <TopBanner
        icon={hasTrioSupport(user.enabled_features) ? 'flash' : 'moneyBag'}
        title={title}
        body={subtitle}
        timer={timeRemain > 0 ? formattedTime : null}
      />
    );
  }
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  promoTs: PropTypes.number.isRequired,
  onGetSurgeInfo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  title: state.storefront.title,
  subtitle: state.storefront.subtitle,
  promoTs: state.storefront.promo_ts,
  user: state.user,
});


const mapPropsToDispatch = dispatch => ({
  onGetSurgeInfo: () => dispatch(getSurgeInfo()),
});

export default connect(mapStateToProps, mapPropsToDispatch)(Header);
