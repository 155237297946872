import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import StatusBar from './StatusBar';

import {
  isDead,
} from 'utils/question';


export class RegularRouteBar extends React.Component {
  constructor() {
    super();
    this.rotateTimer = 0;
    this.messageIndex = 0;
    this.state = {
      displayMessage: '',
    };
  }

  componentDidMount() {
    if (this.props.regularRouteShow) {
      this.rotateMessage();
      this.rotateTimer = window.setInterval(this.rotateMessage, 5 * 1000);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.regularRouteShow != prevProps.regularRouteShow) {
      if (this.props.regularRouteShow) {
        this.rotateMessage();
        this.rotateTimer = window.setInterval(this.rotateMessage, 5 * 1000);
      } else if (this.rotateTimer) {
        window.clearInterval(this.rotateTimer);
      }
    }

    if (this.props.question != prevProps.question) {
      const questionDead = isDead(this.props.question);
      if (questionDead) {
        if (this.rotateTimer) {
          window.clearInterval(this.rotateTimer);
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.rotateTimer) {
      window.clearInterval(this.rotateTimer);
    }
  }


  rotateMessage = () => {
    const messages = this.props.regularRouteMessages;

    if (this.messageIndex > messages.length - 1) {
      this.messageIndex = 0;
    }

    logger.debug('Rotate message ', messages, this.messageIndex);
    this.setState({
      displayMessage: messages[this.messageIndex],
    });

    this.messageIndex = this.messageIndex + 1;
  }


  render() {
    const { regularRouteShow } = this.props;
    const { question } = this.props;
    const questionDead = isDead(question);

    if (!regularRouteShow || questionDead) return null;

    return (
      <StatusBar message={this.state.displayMessage} />
    );
  }
}


RegularRouteBar.propTypes = {
  regularRouteShow: PropTypes.bool.isRequired,
  regularRouteMessages: PropTypes.array.isRequired,
  question: PropTypes.shape().isRequired,
};


const mapStateToProps = state => ({
  regularRouteShow: state.chat.regularRouteShow,
  regularRouteMessages: state.chat.regularRouteMessages,
  question: state.chat.question,
});
const mapDispatchToProps = null;
export default connect(mapStateToProps, mapDispatchToProps)(RegularRouteBar);
