import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from 'components/Common/Modal';

const IdleTimeoutModal = ({ onClose }) => (
  <ModalBase
    headerText="Sorry! You ran out of time."
    size="small"
    closable={true}
    onHide={onClose}
    body={(
      <>
        <p className="u-marginBottomNone">If you need more help try reposting your problem with more information.</p>
      </>
    )}
    primaryButtonText="OK"
    onClickPrimaryButton={onClose}
  />
);

IdleTimeoutModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default IdleTimeoutModal;
