import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalBase from 'components/Common/Modal';

import Tracking from '../../../../../utils/tracking';

import { sessionEndedType } from '../../../../../constants/common.constant';

import { endQuestion } from '../../../../../actions/chat.action';


class ConfirmExitModal extends React.Component {
  handleEndQuestion = () => {
    logger.debug('on handleEndQuestion');
    const { question } = this.props;
    this.props.onEndingQuestion(question.qid).then((data) => {
      const { result } = data.payload;
      const { error } = data.payload;
      if (result) {
        logger.debug('ended question', question.qid);
        this.props.onEndedQuestion();
        const dataTrack = {
          qid: Number(question.qid),
          session_length: Number(result.length),
          finishing_type: sessionEndedType.STUDENT_END,
          first_bid: Number(result.first_bid),
          claim_time: Number(result.claim_time),
          number_of_messages: Number(result.message_count),
        };
        Tracking.sessionFinished(dataTrack);
      }
      if (error) {
        logger.error('Failed to call End Question endpoint to get tracking data');
      }
    });
  }

  handleClose = () => {
    logger.debug('on handleClose');
    this.props.onClose();
  }

  render() {
    return (
      <ModalBase
        headerText="Are you sure you want to exit this chat?"
        size="medium"
        closable={true}
        onHide={this.handleClose}
        body={(
          <>
            <p className="u-textCenter"><img src={require('assets/images/icon-flag.svg')} alt="" /></p>
            <p className="u-marginBottomNone">You won't be able to chat with the Expert about this problem anymore.</p>
          </>
        )}
        primaryButtonText="Yes, exit chat"
        onClickPrimaryButton={this.handleEndQuestion}
      />
    );
  }
}

ConfirmExitModal.propTypes = {
  question: PropTypes.shape().isRequired,
  onEndingQuestion: PropTypes.func.isRequired,
  onEndedQuestion: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapSateToProps = state => ({
  question: state.chat.question,
});

const mapDispatchToProps = dispatch => ({
  onEndingQuestion: qid => dispatch(endQuestion(qid)),
});

export default connect(mapSateToProps, mapDispatchToProps)(ConfirmExitModal);
