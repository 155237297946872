import 'babel-polyfill';
import config from 'configuration';
import AccessToken from './token';

import {
  MULTIPART_FORMDATA,
  FORM_URLENCODED,
  APPLICATION_JSON,
} from '../constants/common.constant';

import {
  getAppPlatformCode,
  getBrowser,
} from './app';

export function ServerAPIError(status, json = null) {
  this.name = 'ServerAPIError';
  this.status = status;
  this.data = json;
  this.stack = (new Error()).stack;
}
ServerAPIError.prototype = Object.create(Error.prototype);
ServerAPIError.prototype.constructor = ServerAPIError;

const defaultHeaders = {
  Accept: APPLICATION_JSON,
  // 'Content-Type': APPLICATION_JSON,
};


const request = async (endpoint, method, body, headers = defaultHeaders, absoluteEndpoint = false, fixedHeader = false) => {
  let url = endpoint;
  if (!absoluteEndpoint && !endpoint.startsWith('http')) {
    url = config.API_HOST + endpoint;
  }

  if (!fixedHeader) {
    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
    headers.Authorization = AccessToken.getAuthKey();
    headers.TUClientDeviceIdentifier = AccessToken.getBrowserId();
    headers['User-Agent'] = userAgent;
    headers.TUClientLocale = window.navigator.language;
    headers.TUClientVersion = config.appVersion;
    headers.TUClientPlatform = getAppPlatformCode(userAgent);
    headers.TUClientDevice = getBrowser();
    headers['X-GotIt-App-Id'] = config.APP_ID;
  } else if (url.startsWith(`${config.ENGLISH_API_HOST}/es/`)) {
    headers.Authorization = `bearer ${AccessToken.getAuthKey()}`;
  }

  const response = await fetch(url, {
    method,
    headers,
    body,
  });
  if (response.status === 503) {
    const extInfo = response.headers.get('TUExtInfo');
    throw new ServerAPIError(response.status, extInfo);
  }
  const json = await response.json();
  if (response.status < 200 || response.status >= 300) {
    if (json) {
      throw new ServerAPIError(response.status, json);
    } else {
      throw new Error(response.statusText);
    }
  }
  return json;
};

export const get = (endpoint, body, absoluteEndpoint = false, fixedHeader = false) => {
  let url = endpoint;
  const headers = { ...defaultHeaders };
  if (body) {
    const paramsStr = Object.entries(body)
      .map(
        pair => pair.map(encodeURIComponent).join('=')
      )
      .join('&');
    url = `${endpoint}?${paramsStr}`;
  }
  return request(url, 'GET', null, headers, absoluteEndpoint, fixedHeader);
};

export const post = (endpoint, body = {}, encoded = FORM_URLENCODED, absoluteEndpoint = false, fixedHeader = false) => {
  let form;
  const headers = { ...defaultHeaders };
  if (encoded === FORM_URLENCODED) {
    form = Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key]) // eslint-disable-line prefer-template
    ).join('&');
    headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
  }
  if (encoded === MULTIPART_FORMDATA) {
    form = new FormData();

    Object.keys(body).forEach((key) => {
      if (key === 'file') {
        if (body[key]) {
          if (body[key].name) {
            form.append(key, body[key]);
          } else {
            logger.debug('adding file to request payload');
            form.append(key, body[key], 'file.jpg');
          }
        }
      } else {
        form.append(key, body[key]);
      }
    });
  }

  if (encoded === APPLICATION_JSON) {
    form = JSON.stringify(body);
  }
  return request(endpoint, 'POST', form, headers, absoluteEndpoint, fixedHeader);
};

export const put = (endpoint, body) => {
  const headers = { ...defaultHeaders };
  headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
  const formData = Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key]) // eslint-disable-line prefer-template
  ).join('&');
  return request(endpoint, 'PUT', formData, headers);
};

export const del = (endpoint, body) => (
  request(endpoint, 'DELETE', body)
);

export default {
  get,
  post,
  put,
  del,
};
