import "babel-polyfill";
import "whatwg-fetch";

import "@ahaui/css/dist/index.min.css";
import "assets/css/vendors.min.css";
import "assets/css/custom.css";

import WebFont from "webfontloader";
import { AppContainer } from "react-hot-loader";
import { render } from "react-dom";
import React from "react";
import { Provider } from "react-redux";

import { initLogger } from "./utils/logger-init";
import App from "./components/App";
import configureStore from "./store";
import defaultState from "./store/defaultState";
// Load fonts missing from the @gotitinc/aha-css package
WebFont.load({
  google: {
    families: ["Roboto:300,400,500"],
  },
});

// import { loadState, saveState } from './store/localStorage';

// Config store.
// const savedState = loadState();
// const state = { ...defaultState, ...savedState };

const store = configureStore(defaultState);
// store.subscribe(() => {
// saveState(store.getState());
// });

initLogger();

logger.debug("App is running");

// Render App.
render(
  <AppContainer>
    <Provider store={store}>
      <App />
    </Provider>
  </AppContainer>,
  document.getElementById("root")
);
