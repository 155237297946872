import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Router, Route, Redirect, Switch } from "react-router-dom";

import config from "configuration";

import history from "utils/history";
import { hasStemSupport, hasWritingOnly } from "utils/feature-support";

import RouteGroup from "./RouteGroup";

import Home from "./Home";

import AskStem from "../Ask/Stem";
import AskEnglish from "../Ask/English";
import Ask from "../Ask";

import MyQuestions from "../History";
import QDetail from "../History/QDetail";
import Landing from "../Landing";
import SEMLanding from "../SEMLanding";
import Notification from "../Notification";
import StoreFront from "../StoreFront";
import Setting from "../Setting";
import Guide from "../Guide";
import SampleDetail from "../Guide/SampleDetail";
import ForceUpgradeModal from "./ForceUpgradeModal";
import ForceLogoutModal from "./ForceLogoutModal";
import ResetPassword from "../ResetPassword";
import PasswordLogin from "../Login";
import Rating from "../EnglishRating";
import SharedSession from "../SharedSession";

import NotFound from "./404";

import { SYSTEM_STATUS_UPGRADE } from "../../constants/app.constant";
import BetaTestingInstruction from "components/BetaTesting";

const privateLinks = [
  "^/(my-sessions|ask|storefront|notification|setting|logout|guide|sample-sessions)$",
  "^/(my-sessions|ask|storefront|notification|setting|logout|guide|sample-sessions)/.*$",
];

const notAuthLinks = ["^/(intro)$", "^/(intro)/.*$"];

const publicLinks = [
  "^/(not-found|new-password|login|webview|rating|sessions|beta-testing)$",
  "^/(not-found|new-password|login|webview|rating|sessions|beta-testing)/.*$",
];

class AppRoute extends React.Component {
  authenticatedRoutes = () => {
    const { isStemSupported, isWritingOnly, uid } = this.props;

    if (isStemSupported) {
      return (
        <Switch>
          <Route path="/:qid(\d+)-:action(\w+)" component={Ask} />
          <Route
            path="/my-sessions/:qid(\d+)(-)?:action(\w+)?"
            component={QDetail}
          />
          <Route path="/my-sessions" component={MyQuestions} />
          <Route path="/storefront" component={StoreFront} />
          <Route path="/notification" component={Notification} />
          <Route path="/guide" component={Guide} />
          <Route path="/sample-sessions/:qid(\d+)" component={SampleDetail} />
          <Route path="/setting" component={Setting} />
          <Route path="/english" component={AskEnglish} />
          <Route path="/stem" component={AskStem} />
          <Route path="/" component={Ask} exact />
          <Redirect to="/not-found" />
        </Switch>
      );
    }

    if (isWritingOnly) {
      return (
        <Switch>
          <Route path="/setting" component={Setting} />
          <Route
            path="/english"
            render={({ location }) => <AskEnglish key={location.key} />}
          />
          <Route path="/" component={Ask} exact />
          <Redirect to="/not-found" />
        </Switch>
      );
    }

    // No subject supported
    return (
      <Switch>
        <Redirect to="/not-found" />
      </Switch>
    );
  };

  render() {
    const { authToken, uid, systemStatus, hasBeenForceLoggedOut } = this.props;
    const isAuthenticated = authToken !== "" && uid > 0;

    return (
      <Router basename={config.baseUrl} forceRefresh={false} history={history}>
        <div>
          <Route path="/beta-testing" component={BetaTestingInstruction} />

          <RouteGroup
            when={!isAuthenticated}
            publicPaths={publicLinks}
            otherPaths={privateLinks}
          >
            <Switch>
              <Route exact path="/" component={Landing}></Route>
              <Route path="/intro" component={SEMLanding}></Route>
              <Route path="/beta-testing" component={BetaTestingInstruction} />
              <Redirect to="/" />
            </Switch>
          </RouteGroup>
          <RouteGroup
            when={isAuthenticated}
            publicPaths={publicLinks}
            otherPaths={notAuthLinks}
          >
            <Route path="/beta-testing" component={BetaTestingInstruction} />
            <Home>{this.authenticatedRoutes()}</Home>
          </RouteGroup>
          <Route path="/rating" component={Rating}></Route>
          <Route path="/not-found" component={NotFound} />
          <Route path="/new-password/:code(\w+)?" component={ResetPassword} />
          <Route path="/login" component={PasswordLogin} />
          <Route path="/sessions/:keyword(\w+)" component={SharedSession} />
          {systemStatus === SYSTEM_STATUS_UPGRADE && <ForceUpgradeModal />}
          {hasBeenForceLoggedOut && <ForceLogoutModal />}
        </div>
      </Router>
    );
  }
}

AppRoute.propTypes = {
  authToken: PropTypes.string.isRequired,
  uid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isWritingOnly: PropTypes.bool.isRequired,
  systemStatus: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  authToken: state.app.authToken,
  uid: state.user.uid,
  isStemSupported: hasStemSupport(state.user.enabled_features),
  isWritingOnly: hasWritingOnly(state.user.enabled_features),
  systemStatus: state.app.systemStatus,
  hasBeenForceLoggedOut: state.app.forceLogout.needed,
});

export default connect(mapStateToProps)(AppRoute);
