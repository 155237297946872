import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Form, Button, Icon } from '@ahaui/react';

function DocFile(
  {
    onChange,
    onTypeChange,
    fileErrorMessage,
    urlErrorMessage,
  }) {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [fileType, setfileType] = useState(0);

  const onFileDrop = (files) => {
    // If current option is file url then do nothing
    if (fileType === 1) return;

    onChange(files[0]);
    setFile(files[0]);
  };

  const onFileTypeSelected = (event) => {
    event.persist();
    const { target: { value } } = event;

    onTypeChange(Number(value));

    setfileType(Number(value));

    if (Number(value) === 0) setFileUrl('');
    else setFile(null);
  };

  const onFileUrlChange = (event) => {
    const { target: { value } } = event;

    onChange(value);
    setFileUrl(value);
  };

  const onFileSelected = (event) => {
    // If current option is file url then do nothing
    if (fileType === 1) return;

    const { files } = event.target;

    onChange(files[0]);
    setFile(files[0]);
  };

  const onFileRemove = () => {
    onChange(null);

    setFile(null);
  };

  useEffect(() => {
    if (fileErrorMessage) setFile(null);
  }, [fileErrorMessage]);

  return (
    <>
      <Form.Group controlId="ask.writing.upload">
        <Form.Check
          type="radio"
          name="file_type"
          value={0}
          checked={fileType === 0}
          onChange={onFileTypeSelected}
          label="Upload a file (doc, docx)"
          className="u-marginBottomTiny"
        />
        {file ? (
          <div className="u-border u-borderUltraLight u-backgroundOpaline u-roundedMedium" style={{ height: 136 }}>
            <div className="u-positionRelative u-paddingNone u-overflowHidden u-flex u-flexColumn u-alignItemsCenter u-justifyContentCenter u-heightFull">
              <div>
                <svg height={32} viewBox="0 0 32 32" width={32} xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="m0 0h32v32h-32z" /><g strokeWidth=".7"><path d="m20.93-.35 6.407 6.315c.2.198.313.467.313.748v20.587c0 .29-.118.552-.308.742s-.452.308-.742.308h-19.6c-.29 0-.552-.118-.742-.308s-.308-.452-.308-.742v-26.6c0-.29.118-.552.308-.742s.452-.308.742-.308z" fill="#fff" stroke="#979593" transform="translate(2 2)" /><path d="m20.437-.837 7.717 7.607h-6.667c-.29 0-.552-.117-.742-.307s-.308-.453-.308-.743z" fill="#fff" stroke="#979593" strokeLinejoin="round" transform="translate(2 2)" /><path d="m11.55.35h-11.2m11.2 2.1h-11.2m11.2 2.1h-11.2m11.2 2.1h-11.2m11.2 2.1h-11.2" stroke="#c8c6c4" strokeLinecap="round" transform="translate(14.95 13.55)" /></g><g transform="translate(2 11.1)"><rect fill="#1057c0" height={14} rx="1.4" width={14} /><path d="m2.116 3.541h1.441l1.296 6.039 1.374-6.039h1.588l1.488 6.039 1.138-6.039h1.439l-1.783 7.659h-1.667l-1.432-5.95-1.391 5.95h-1.703z" fill="#fff" /></g></g></svg>
              </div>
              <div className="u-text200 u-textGray">{file.name}</div>

              <div className="u-positionAbsolute u-positionTop u-positionRight u-marginTopExtraSmall u-marginRightExtraSmall">
                <div>
                  <Button variant="secondary" size="small" onlyIcon className="u-cursorPointer" onClick={onFileRemove}>
                    <Button.Icon><Icon name="trash"/></Button.Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Dropzone onDrop={onFileDrop} noClick={fileType === 1}>
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className="u-border u-borderUltraLight u-backgroundOpaline u-roundedMedium"
                style={{ height: 136 }}
              >
                <div className="u-paddingSmall u-textCenter">
                  <div className="u-marginBottomExtraSmall">
                    <div>Drag &amp; Drop</div>
                    <div className="u-text100">or</div>
                  </div>
                  <div>
                    <Button variant="primary_outline" width="min" className="u-marginRightExtraSmall" disabled={fileType === 1}>
                      <Button.Label>Browse</Button.Label>
                      <Button.Icon><Icon name="document"/></Button.Icon>
                    </Button>
                    <input
                      {...getInputProps()}
                      type="file"
                      id="file"
                      name="file"
                      hidden
                      accept=".doc, .docx"
                      onChange={onFileSelected}
                    />
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        )}
        <Form.Feedback type="invalid" visible={!!fileErrorMessage}>{fileErrorMessage}</Form.Feedback>
      </Form.Group>

      <Form.Group controlId="ask.writing.google-doc">
        <Form.Check
          type="radio"
          name="file_type"
          value={1}
          checked={fileType === 1}
          onChange={onFileTypeSelected}
          label="Add link to Google Document"
          className="u-marginBottomTiny"
        />
        <Form.Input
          placeholder="https://docs.google.com..."
          onChange={onFileUrlChange}
          disabled={fileType !== 1}
          value={fileUrl}
          isInvalid={!!urlErrorMessage}
        />
        <Form.Feedback type="invalid" visible={!!urlErrorMessage}>{urlErrorMessage}</Form.Feedback>
      </Form.Group>
    </>
  );
}

export default DocFile;
