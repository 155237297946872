import React, { useEffect, useState, useRef } from 'react';
import { Button, Icon, Form, Loader } from '@ahaui/react';
import Tracking from 'utils/tracking';

const Captcha = ({
  isLoading,
  imgSrc,
  audioSrc,
  errorMessage,
  captchaErrorMessage,
  emailErrorMessage,
  getNewCaptcha,
  onCaptchaUpdate,
}) => {
  const [captchaCode, setCaptchaCode] = useState('');
  const audioRef = useRef(null);

  const onCaptchaAudioPlay = () => {
    if (audioRef.current) {
      audioRef.current.play();
      Tracking.captchaAudio();
    }
  };

  const onCaptchaRefresh = () => {
    getNewCaptcha();
    Tracking.captchaRefresh();
  };

  const onCaptchaCodeUpdate = (e) => {
    const captcha = e.target.value;
    setCaptchaCode(captcha);
    onCaptchaUpdate(captcha);
  };

  useEffect(() => {
    if (errorMessage || captchaErrorMessage || emailErrorMessage)
      setCaptchaCode('');
  }, [captchaErrorMessage, errorMessage, emailErrorMessage]);

  return (
    <Form.Group
      controlId="login.captcha"
      className="u-backgroundLightest u-roundedMedium u-paddingSmall"
    >
      <div className="u-flex u-justifyContentBetween u-alignItemsCenter u-marginBottomSmall">
        {isLoading && (
          <div
            className="u-backgroundWhite u-border u-borderLighter u-flex u-justifyContentCenter u-alignItemsCenter"
            style={{ height: 40, width: 150 }}
          >
            <Loader size="small" duration={700} />
          </div>
        )}

        {imgSrc && (
          <img src={imgSrc} width={150} height={40} alt="Captcha" id="captcha-image" />
        )}

        {captchaErrorMessage && (
          <div
            className="u-text100 u-textNegative"
            role="alert"
            id="captcha-error-message"
          >
            {captchaErrorMessage}
          </div>
        )}

        <audio hidden aria-hidden src={audioSrc} ref={audioRef} />

        <div
          className="u-flex u-justifyContentEnd"
          style={{ marginLeft: 'auto', minWidth: 70 }}
        >
          <Button
            type="button"
            variant="secondary"
            className="u-flexShrink-0 u-marginRightTiny"
            onlyIcon
            style={{ height: 28 }}
            onClick={onCaptchaAudioPlay}
            disabled={isLoading || !audioSrc}
            aria-label="Click to listen to the captcha characters"
            id="captcha-audio-button"
          >
            <Button.Icon>
              <Icon name="volumeHigh" size="extraSmall" />
            </Button.Icon>
          </Button>

          <Button
            type="button"
            variant="secondary"
            className="u-flexShrink-0"
            onlyIcon
            style={{ height: 28 }}
            onClick={onCaptchaRefresh}
            disabled={isLoading}
            aria-label="Generate new captcha text"
            id="captcha-refresh-button"
          >
            <Button.Icon>
              <Icon name="refresh" size="extraSmall" />
            </Button.Icon>
          </Button>
        </div>
      </div>
      <Form.Label className="sr-only">Enter Captcha Text</Form.Label>
      <Form.Input
        isInvalid={!!errorMessage}
        type="text"
        placeholder="Type the text above"
        onChange={onCaptchaCodeUpdate}
        value={captchaCode}
      />
      <Form.Feedback type="invalid" role="alert" visible={!!errorMessage}>
        {errorMessage}
      </Form.Feedback>
    </Form.Group>
  );
};

export default Captcha;
