import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from 'components/Common/Modal';

const OneQuestionModal = ({ onClose, onBackToOpenQuestion }) => (
  <ModalBase
    headerText="Sorry, one problem at a time"
    size="medium"
    closable={true}
    onHide={onClose}
    body={(
      <>
        <p className="u-textCenter">
          <img src={require('assets/images/only-one-problem.svg')} alt="" className="u-maxWidthFull" />
        </p>
        <p>Please wait until your current problem is ready before submitting another one.</p>
        <p className="u-marginBottomNone">Meanwhile, an expert is working hard on your problem.</p>
      </>
    )}
    primaryButtonText="Back to Problem"
    onClickPrimaryButton={onBackToOpenQuestion}
  />
);

OneQuestionModal.propTypes = {
  onBackToOpenQuestion: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OneQuestionModal;
