import { run } from './promise';
import { get, post } from '../utils/request';
import { MULTIPART_FORMDATA } from '../constants/common.constant';
import {
  MOOD_SELECTED,
  DESCRIPTION_UPDATED,
  SUBJECT_SELECTED,
  LANGUAGE_SELECTED,
  FILE_LOADED,
  FILE_CROPPED,
  CROP_TOGGLE,
  CROP_HIDE,
  FILE_REMOVED,
  QUESTION_SUBMIT,
  ASKFLOW_RESET,
  AUTOBID_STATUS_GET,
} from '../constants/askflow.constant';

export const selectMood = mood => ({
  type: MOOD_SELECTED,
  mood,
});

export const updateDescription = description => ({
  type: DESCRIPTION_UPDATED,
  description,
});

export const selectSubject = subject => ({
  type: SUBJECT_SELECTED,
  subject,
});

export const selectLanguage = language => ({
  type: LANGUAGE_SELECTED,
  language,
});

export const loadFile = file => ({
  type: FILE_LOADED,
  file,
});

export const removeFile = () => ({
  type: FILE_REMOVED,
});

export const cropFile = file => ({
  type: FILE_CROPPED,
  file,
});

export const toggleCropper = () => ({
  type: CROP_TOGGLE,
});

export const hideCropper = () => ({
  type: CROP_HIDE,
});

export const submitQuestion = (description, file, mood, subject, language) => {
  const data = {
    tid: subject.tid,
    mindset_id: mood.id,
    title: description,
    file,
    language,
  };
  return run(
    QUESTION_SUBMIT,
    post('/questions/add', data, MULTIPART_FORMDATA),
  );
};

export const resetAskflowData = () => ({
  type: ASKFLOW_RESET,
});


export const getAutobidStatus = () => run(
  AUTOBID_STATUS_GET,
  get('/autobid/count'),
);

const askflowActions = {};
export default askflowActions;
