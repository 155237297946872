import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { forceLogout } from 'actions/user.action';
import AccessToken from 'utils/token';
import ModalBase from 'components/Common/Modal';

function ForceLogoutModal() {
  const { modalTitle, modalBody, modalButtonText } = useSelector(({ app }) => app.forceLogout);
  const dispatch = useDispatch();
  const history = useHistory();

  const doForceLogout = () => {
    AccessToken.setAuthKey('');
    history.push('/');
    dispatch(forceLogout());
  }

  return (
    <ModalBase
      headerText={modalTitle}
      size="medium"
      closable={false}
      body={(
        <>
          <p><img src={require('assets/images/icon-sad.svg')} className="u-block u-marginHorizontalAuto" alt="" /></p>
          <p className="u-marginBottomNone">{modalBody}</p>
        </>
      )}
      primaryButtonText={modalButtonText}
      onClickPrimaryButton={doForceLogout}
    />
  );
}

export default ForceLogoutModal;
