const config = {
  appEnv: 'development',
  API_HOST: 'https://api.tutoruniverse.net',
  ENGLISH_API_HOST: 'https://api.tutoruniverse.net',
  appVersion: '0.6.2',
  PUSHER_KEY: 'cda52073e74e0e183033',
  PUSHER_AUTH: '/pusher/auth',
  FACEBOOK_APP_ID: '119353708180184',
  MIXPANEL_TOKEN: '6e2b486623748079eae6c93b7b4a6af5',
  DESCRIPTION_REQUIRED_LENGTH: 40,
  IMG_URL: 'http://img1.dev.tutorpl.us.s3.amazonaws.com',
  SHARE_SESSION_HOST: 'http://web-dev.gotitapp.co',

  AWS_REGION: 'us-east-1',
  AWS_IDENTIFY_POOL_ID: 'us-east-1:4fe7d435-a9dc-4686-8385-7c0e0b094e22',
  AWS_AMA_APP_ID: '1664417a31a44c3ab0066b8da764c8d8',

  GA_ID: 'UA-114726342-1',
  AMPLITUDE_ID: '7e445fbf5850ad103cbc3c15e794f8f6',
  GIAP_TOKEN: 'photostudy-student-dev',
  GIAP_SERVER_URL: 'https://analytics-api.got-it.ai',
};

export default config;
