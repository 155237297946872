import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import {
  Form, Icon, Button, Loader,
} from '@ahaui/react';

import config from 'configuration';

import ts from '../../../../utils/timestamp';
import Tracking from '../../../../utils/tracking';
import { dataURItoBlob } from '../../../../utils/converter';
import { ServerAPIError } from '../../../../utils/request';
import { isChrome } from '../../../../utils/util';
import { hasTrioSupport, hasSharedSessionPoolSupport } from '../../../../utils/feature-support';

import { submitQuestion } from '../../../../actions/askflow.action';


class SendExpert extends React.Component {
  constructor() {
    super();
    this.state = {
      isSending: false,
    };
    this.submitTime = 0;
    this.cropObject = null;
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onSubmitError = (error) => {
    if (error instanceof ServerAPIError) {
      const errorData = error.data;
      const { alert } = errorData.data;
      if (alert) {
        logger.debug('out of credit ERROR');
        if (alert.message === SendExpert.errMsg.OUT_OF_CREDIT) {
          this.props.onOutOfCredit();
          return;
        }
        this.props.onCoppaOutOfCredit(alert);
        return;
      }
      if (errorData.data && errorData.data === SendExpert.errMsg.PAYMENT_UNSUPPORTED) {
        logger.debug('payment unsupported');
        this.props.onPaymentUnsupported();
        return;
      }
      if (errorData.data && errorData.data === SendExpert.errMsg.ONE_QUESTION_A_TIME) {
        logger.debug('one question at time ERROR');
        this.props.onOneQuestionError();
        return;
      }
    }
    // If the error is not recognized show general error modal.
    logger.debug('unknown ERROR');
    this.props.onUnknownError(error.data.data);
  }

  trackSubmitted = (result) => {
    logger.debug('on trackSubmitted');
    const {
      selectedSubject, description, selectedLanguage, selectedMood,
    } = this.props.askflow;
    const attachmentSize = result.attachments.length > 0 ? Number(result.attachments[0].size) : 0;
    const submitTime = Math.floor(ts.now() - this.submitTime);
    const instant_connect_available = Number(result.has_autobid) === 1 ? 'YES' : 'NO';
    const data = {
      qid: Number(result.qid),
      tag: selectedSubject.title,
      attachment_size: attachmentSize,
      attachment_time: submitTime,
      ask_question_length: description.trim().length,
      instant_connect_available,
      price: 8,
      subject: selectedSubject.title,
      language: selectedLanguage,
      mood: selectedMood.body,
    };
    Tracking.askQuestion(data);
  }

  blobGet = canvas => new Promise((resolve, reject) => {
    canvas.toBlob(blob => resolve(blob));
  })

  async resizeImage (imageUrl) {
    logger.debug('on resizeImage');
    const imageBlob = dataURItoBlob(imageUrl);
    if (!isChrome()) {
      logger.warn('It is not Chrome browser, ignore resize image');
      return imageBlob;
    }
    // Resize image.
    const imageBitmap = await window.createImageBitmap(imageBlob);
    const maxImageSize = 1024 * 512;
    const { width } = imageBitmap;
    const { height } = imageBitmap;
    if (width * height > maxImageSize) {
      logger.debug('Resizing image');
      logger.debug('Before size', width, height);
      logger.debug('Before blob', imageBlob);
      const rate = width / height;
      const newWidth = Math.floor(Math.sqrt(maxImageSize * rate));
      const newHeight = Math.floor(newWidth / rate);
      const newImage = await window.createImageBitmap(imageBitmap, { resizeWidth: newWidth, resizeHeight: newHeight });
      // Create tmp canvas to get Blob back
      const tmpCanvas = document.createElement('canvas');
      tmpCanvas.width = newWidth;
      tmpCanvas.height = newHeight;
      const tmpCtx = tmpCanvas.getContext('2d');
      tmpCtx.drawImage(newImage, 0, 0, newWidth, newHeight);
      const newBlob = await this.blobGet(tmpCanvas);
      logger.debug('After size', newWidth, newHeight);
      logger.debug('After blob', newBlob);
      return newBlob;
    }
    return imageBlob;
  }

  async handleSubmit() {
    logger.debug('on handleSubmit');
    this.setState({
      isSending: true,
    });
    if (this.props.askflow.croppedImage) {
      this.cropObject = await this.resizeImage(this.props.askflow.croppedImage);
    }
    this.submitTime = ts.now();
    this.props.onSubmit(
      this.props.askflow.description.trim(),
      this.cropObject,
      this.props.askflow.selectedMood,
      this.props.askflow.selectedSubject,
      this.props.askflow.selectedLanguage,
    ).then(
      (data) => {
        this.setState({
          isSending: false,
        });
        const { result } = data.payload;
        const { error } = data.payload;
        if (result) {
          this.trackSubmitted(result);
        }
        if (error) {
          this.onSubmitError(error);
        }
      },
    );
  }


  getAutobidEnabled = (selectedSubject, autobidStatus) => {
    let enabled = false;

    if (selectedSubject == null) {
      enabled = false;
      return enabled;
    }

    autobidStatus.forEach((autobidSt) => {
      if (Number(autobidSt.status) === 1 && Number(autobidSt.sid) === Number(selectedSubject.parent_tid)) {
        enabled = true;
      }
    });
    return enabled;
  }

  render() {
    // Render button according state;
    const {
      selectedMood, selectedSubject, description, autobidStatus,
    } = this.props.askflow;
    const isEnable = selectedMood && selectedSubject && description.trim().length >= config.DESCRIPTION_REQUIRED_LENGTH;
    const isAutobidEnabled = this.getAutobidEnabled(selectedSubject, autobidStatus);
    const { isSending } = this.state;

    const { enabledFeatures } = this.props;
    const isTrio = hasTrioSupport(enabledFeatures);
    const isTRIOPool = isTrio && hasSharedSessionPoolSupport(enabledFeatures);
    const msg = isTRIOPool ? 'Your school has paid for this session' : 'You’ll be charged 1 session';
    let showMessage = isEnable;
    if (!isTRIOPool && Number(this.props.hasFreeSession) === 1) {
      showMessage = false;
    }
    return (
      <Form.Group
        className={cn(
          'u-marginVerticalMedium',
          { 'u-marginBottomExtraLarge': isAutobidEnabled },
        )}
      >
        <Button
          variant="primary"
          width="full"
          onClick={this.handleSubmit}
          disabled={!isEnable || isSending}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {isSending ? (
            <Button.Icon><Loader size="small" duration={700} className="u-marginRightTiny" /></Button.Icon>
          ) : isAutobidEnabled ? (
            <Button.Icon><Icon name="flash" /></Button.Icon>
          ) : (
            null
          )}
          <Button.Label>Send to Expert</Button.Label>
        </Button>

        {showMessage && (
          <div className=" u-textCenter u-textGray u-text200 u-marginTopExtraSmall">{msg}</div>
        )}
      </Form.Group>
    );
  }
}

SendExpert.errMsg = {
  OUT_OF_CREDIT: 'You don\'t have enough sessions to ask this question.',
  PAYMENT_UNSUPPORTED: 'payment method is not supported',
  ONE_QUESTION_A_TIME: 'Your previous question is still open',
};

SendExpert.propTypes = {
  askflow: PropTypes.shape().isRequired,
  enabledFeatures: PropTypes.arrayOf(PropTypes.string),
  hasFreeSession: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onOutOfCredit: PropTypes.func.isRequired,
  onCoppaOutOfCredit: PropTypes.func.isRequired,
  onUnknownError: PropTypes.func.isRequired,
  onOneQuestionError: PropTypes.func.isRequired,
  onPaymentUnsupported: PropTypes.func.isRequired,
};

SendExpert.defaultProps = {
  enabledFeatures: [],
};

const mapStateToProps = state => ({
  askflow: state.askflow,
  enabledFeatures: state.user.enabled_features,
  hasFreeSession: state.surgeInfo.hasFreeSession,
});

const mapDispatchToProps = {
  onSubmit: submitQuestion,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendExpert);
