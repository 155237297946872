import { PROMISE_RUN } from '../constants/promise.constant';
import {
  VERIFY_TOKEN,
  FACEBOOK_LOGIN,
  FACEBOOK_SIGNUP,
  ONE_TIME_CODE_VERIFY,
  SIGNUP_SUCCESS,
  SURGE_INFO_GET,
  PROFILE_HOT_UPDATE,
  PROFILE_SIGNUP_BEGIN,
  PROFILE_SIGNUP_END,
  PRESET_LOGIN,
  ONE_TIME_CODE_SIGNUP,
} from '../constants/user.constant';

import defaultState from '../store/defaultState';

import AccessToken from '../utils/token';

const userReducer = (state = defaultState.user, action) => {
  /* eslint-disable brace-style */
  if (action.type === PROMISE_RUN) {
    if (action.key === VERIFY_TOKEN) {
      const { result } = action.payload;
      if (result) {
        let { user } = result;
        user = { ...user, tos: result.tos };
        return { ...state, ...user };
      }
    }
    if (
      action.key === FACEBOOK_LOGIN
      || action.key === FACEBOOK_SIGNUP
      || action.key === ONE_TIME_CODE_VERIFY
      || action.key === ONE_TIME_CODE_SIGNUP
      || action.key === PRESET_LOGIN
    ) {
      const { result } = action.payload;
      if (result) {
        let { user } = result;
        user = { ...user, tos: result.tos };
        return { ...state, ...user };
      }
    }
    else if (action.key === SURGE_INFO_GET) {
      const { result } = action.payload;
      if (result && AccessToken.getAuthKey()) {
        return { ...state, ...result.user };
      }
    }
  }

  else if (action.type === SIGNUP_SUCCESS) {
    return action.payload;
  }

  else if (action.type === PROFILE_HOT_UPDATE) {
    return { ...state, ...action.payload };
  }
  else if (action.type === PROFILE_SIGNUP_BEGIN) {
    return { ...state, signing_up: true };
  }
  else if (action.type === PROFILE_SIGNUP_END) {
    return { ...state, signing_up: false };
  }
  /* eslint-enable */
  return state;
};

export default userReducer;