export const QUESTION_LOAD_MY = 'question_load_my';
export const QUESTION_LOAD_SAMPLES = 'question_load_samples';
export const QUESTION_LOAD_DETAIL = 'question_load_detail';
export const QUESTION_UPDATED = 'question_updated';
export const QUESTION_CHECK_OPEN = 'question_check';
export const QUESTION_LOAD_SHARED = 'question_load_shared';

export const QUESTION_STATUS_ROUTING = 0;
export const QUESTION_STATUS_CLAIMED = 4;
export const QUESTION_STATUS_ANSWERED = 5;
export const QUESTION_STATUS_FLAGGED = 6;

export const QUESTIONS_PER_PAGE = 10;

export const LANGUAGE = {
  ENGLISH: 'en',
  SPANISH: 'es',
};
export const LANGUAGE_LABEL = {
  [LANGUAGE.ENGLISH]: 'English (default)',
  [LANGUAGE.SPANISH]: 'Spanish',
};
