import React from 'react';
import PropTypes from 'prop-types';
import dropin from 'braintree-web-drop-in';
import { Button, Icon, Message } from '@ahaui/react';
import Loading from 'components/Common/Loading';


class Braintree extends React.Component {
  constructor() {
    super();
    this.state = {
      cardSelected: false,
      dropinState: Braintree.dropinState.LOADING,
    };
    this.backButtonDisabled = false;
    this.braintreeCheckout = null;
  }

  /**
   * Setup braintree
   */
  componentDidMount() {
    dropin.create({
      authorization: this.props.token,
      container: '#dropin-container',
      paypal: {
        flow: 'vault',
      },
    }, (error, dropinInstance) => {
      logger.debug('braintree setup done');
      if (!error) {
        this.braintreeCheckout = dropinInstance;
        this.setState({ dropinState: Braintree.dropinState.READY });
        this.setUpOnPaymentMethodRequestable();
        this.setUpOnpaymentOptionSelected();
      } else {
        logger.error('Braintree setup error', error);
        this.setState({ dropinState: Braintree.dropinState.ERROR });
      }
    });
  }

  /**
   * Setup the listener to handle the flow
   * buy -> no credit -> succeed fill a payment method for paypal.
   */
  setUpOnPaymentMethodRequestable = () => {
    this.braintreeCheckout.on('paymentMethodRequestable', (data) => {
      logger.debug('on paymentMethodRequestable', data);
      if (data.paymentMethodIsSelected && data.type === 'PayPalAccount') {
        logger.debug('calling braintree.RequestPaymentMethod');
        this.braintreeCheckout.requestPaymentMethod((error, dt) => {
          if (!error) {
            this.props.onHadMethod(dt.nonce);
          }
        });
      }
    });
  }

  /**
   * Setup the listener to handle when user want to fill a
   * credit card method. Then show the Request Payment button.
   */
  setUpOnpaymentOptionSelected = () => {
    this.braintreeCheckout.on('paymentOptionSelected', (payload) => {
      logger.debug('on PaymentOptionSelected');
      if (payload.paymentOption === 'card') {
        logger.debug('card type is chosen');
        this.setState({
          cardSelected: true,
        });
      } else {
        logger.debug('non-card type is chosen');
        this.setState({
          cardSelected: false,
        });
      }
    });
  }

  /**
   * handle Add Card button click
   */
  handleAddCard = (e) => {
    logger.debug('on handleAddCard');
    e.preventDefault();
    if (this.state.dropinState !== Braintree.dropinState.READY) {
      logger.warn('braintree instance is NOT ready');
      return;
    }
    this.braintreeCheckout.requestPaymentMethod((error, dt) => {
      if (!error) {
        this.props.onHadMethod(dt.nonce);
      }
      this.setState({ cardSelected: false });
    });
  }

  /**
   * Handle go back button click
   */
  handleBack = (e) => {
    logger.debug('on handleBack');
    e.preventDefault();
    if (this.state.dropinState !== Braintree.dropinState.READY) {
      logger.warn('braintree instance is NOT ready');
      this.props.onBack(null);
      return;
    }
    if (this.backButtonDisabled) {
      logger.warn('go back button disabled');
      return;
    }
    // Ignore auto add card when go back button click.
    const addCardShow = document.getElementsByClassName('braintree-show-card');
    if (addCardShow.length > 0) {
      logger.debug('add card box is showing. Ignore auto add payment method');
      this.props.onBack(null);
      return;
    }
    // Get default payment method.
    this.braintreeCheckout.requestPaymentMethod((error, data) => {
      logger.warn('handleback request', error, data);
      this.backButtonDisabled = false;
      if (!error) {
        this.props.onBack(data.nonce);
      } else {
        this.props.onBack(null);
      }
    });
  }

  /**
   * Handle hide the `Add card` button when return to Payment list view.
   */
  handleBraintreeDropinClick = (e) => {
    if (e.target.innerHTML.includes('Choose another way to pay')) {
      this.setState({
        cardSelected: false,
      });
    }
  }

  render() {
    return (
      <div className="Container Container--fluid">
        <div className="u-marginHorizontalAuto u-paddingVerticalMedium" style={{ width: '100%', maxWidth: 581 }}>
          {(this.state.dropinState !== Braintree.dropinState.ERROR) && (
            <div className="u-marginBottomMedium">
              <Button variant="secondary" size="small" onClick={this.handleBack}>
                <Button.Icon>
                  <Icon name="arrowRoundBack" size="extraSmall" />
                </Button.Icon>
                <Button.Label>
                  Back
                </Button.Label>
              </Button>
            </div>
          )}
          {(this.state.dropinState === Braintree.dropinState.LOADING) && (
            <Loading />
          )}
          {(this.state.dropinState === Braintree.dropinState.ERROR) && (
            <div className="u-marginBottomMedium">
              <Message variant="negative">
                <Message.Container>
                  <Message.Content>
                    Unknown error occurred. Please refresh the page.
                  </Message.Content>
                </Message.Container>
              </Message>
            </div>
          )}
          <div className="u-marginBottomMedium">
            <div
              id="dropin-container"
              style={{ display: this.state.dropinState === Braintree.dropinState.READY ? '' : 'none' }}
              onClick={this.handleBraintreeDropinClick}
            />
          </div>
          {this.state.cardSelected && (
            <div className="u-textCenter">
              <Button onClick={this.handleAddCard}>
                <Button.Label>
                  Request payment method
                </Button.Label>
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Braintree.dropinState = {
  LOADING: 'loading',
  READY: 'ready',
  ERROR: 'error',
};

Braintree.propTypes = {
  token: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onHadMethod: PropTypes.func.isRequired,
};

export default Braintree;
