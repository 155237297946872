import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, EmptyState } from '@ahaui/react';

import { loadQuestion } from 'actions/question.action';
import { replyCqMessage, showExpertWaiting } from 'actions/chat.action';

import { isOpening } from 'utils/question';
import Tracking from 'utils/tracking';
import pusher from 'utils/pusher';
import { queryToObject } from 'utils/util';
import { ServerAPIError } from 'utils/request';

import { EXTEND_ACTION } from 'constants/common.constant';
import { LANGUAGE } from 'constants/question.constant';

import MessageList from 'components/Common/MessageList';
import QuestionActiveBar from 'components/Common/QuestionActiveBar';
import Loading from 'components/Common/Loading';
import UnknownErrorModal from 'components/Ask/Common/PostView/Modals/UnknownErrorModal';
import RatingModal from 'components/Ask/Common/ChatView/Modals/RatingModal';
import InfoModal from 'components/Ask/Common/ChatView/Modals/InfoModal';
import ExtendErrorModal from 'components/Ask/Common/ChatView/Modals/ExtendErrorModal';
import QSummary from './QSummary';

export class QDetail extends React.Component {
  constructor(props) {
    super(props);
    this.qid = props.match.params.qid;
    this.unblock = null;
    this.nextPath = null;
    this.rated = false;

    this.state = {
      question: null,
      discussionMessages: null,
      answ: null,

      showRatingModal: false,
      showInfoModal: false,
      infoModalData: null,
      extendErrorModal: {
        show: false,
        msg: '',
      },
      unknownErrorModal: {
        show: false,
        msg: '',
      },
    };
  }

  componentDidMount() {
    const {
      onLoadQuestion,
      history,
      location,
    } = this.props;

    onLoadQuestion(this.qid).then(
      (data) => {
        const detail = data.payload.result;
        if (detail instanceof Object && Object.keys(detail).length > 0) {
          this.setState({
            question: detail.question,
            discussionMessages: detail.discussion_messages,
            answ: detail.answers,
          }, () => {
            this.unblock = history.block((nextPath) => {
              this.nextPath = nextPath;
              if (this.isNeedRate()) {
                this.setState({ showRatingModal: true });
                return false;
              }
              return true;
            });
            this.extendQuestionIfNeeded();
          });
          Tracking.viewQuestion({
            qid: +this.qid,
            question_status: detail.question.processing_status,
          }, { location });
        } else {
          this.setState({ question: false });
        }
      },
    );
    this.setupPusher();
  }

  componentWillUnmount() {
    if (this.unblock) {
      this.unblock();
    }
    this.destroyPusher();
  }

  /**
   * Subscribe to presence channel
   * Listen to chat events.
   */
  setupPusher = () => {
    const { user } = this.props;

    logger.debug('connecting to pusher', user.uid);
    pusher.subscribe('presence', user.uid);
    pusher.bind('presence', 'newDiscussionMessage', this.handleNewMessage);
    pusher.bind('presence', 'editDiscussionMessage', this.handleEditMessage);
  }

  /**
   * Unbind and Unsubscribe from pusher before unmount.
   */
  destroyPusher = () => {
    logger.debug('destroying pusher');
    pusher.unbind('presence', 'newDiscussionMessage');
    pusher.unbind('presence', 'editDiscussionMessage');
    pusher.unsubscribe('presence');
  }

  /**
   * Extend the question if needed.
   */
  extendQuestionIfNeeded = () => {
    const {
      match,
      location,
      onReplyCqMessage,
    } = this.props;

    logger.debug('checking to extend question if needed');
    const { action } = match.params;
    if (action === EXTEND_ACTION) {
      logger.debug('extending question');
      const search = location.search.substring(1);
      const params = queryToObject(search);
      const { mid } = params;
      const optionID = params.response_id;
      onReplyCqMessage(mid, optionID).then((data) => {
        const { error } = data.payload;
        if (error && error instanceof ServerAPIError) {
          const errMsg = error.data ? error.data.data : null;
          if (errMsg) {
            logger.warn(errMsg);
            this.setState({
              extendErrorModal: {
                show: true,
                msg: errMsg,
              },
            });
          }
        }
      });
    }
  }

  // Hide extend error modal.
  handleHideExtendErrorModal = () => {
    logger.debug('on handleHideExtendErrorModal');
    this.setState({
      extendErrorModal: {
        show: false,
        msg: '',
      },
    });
  }

  handleNewMessage = (data) => {
    logger.debug('on handleNewMessage');
    if (Number(this.state.question.qid) !== Number(data.qid)) {
      logger.warn('Get a new discussion message but it\'s unmatched to the current viewing problem', data);
      return;
    }
    // Add message to the message list.
    const body = this.state.question.lang === LANGUAGE.SPANISH ? data.es_body : data.body;
    const msg = pusher.convertFromPusherMessage(data, body);
    const newMessages = this.state.discussionMessages.concat(msg);
    this.setState({
      discussionMessages: newMessages,
    });
  }

  handleEditMessage = (data) => {
    logger.debug('on handleEditMessage from pusher');
    if (Number(this.state.question.qid) !== Number(data.qid)) {
      logger.warn('Get a edited discussion message but it\'s unmatched to the current viewing problem', data);
      return;
    }
    const body = this.state.question.lang === LANGUAGE.SPANISH ? data.es_body : data.body;
    const editedMsg = pusher.convertFromPusherMessage(data, body);
    const newMessages = this.state.discussionMessages.map((msg) => {
      if (Number(editedMsg.mid) === Number(msg.mid)) {
        return editedMsg;
      }
      return msg;
    });
    this.setState({
      discussionMessages: newMessages,
    });
  }

  getQuestionStatusTitle = (status) => {
    if (+status === 6) return 'Rejected';
    if (+status === 5) return 'Answered';
    if (+status === 4) return 'Claimed';
    if (+status === 0) return 'Unclaimed';
    return '';
  }

  isNeedRate = () => {
    const { answ } = this.state;
    return !this.rated && answ && answ.qid && !answ.vote;
  }

  goNext = (url = '') => {
    const { history } = this.props;

    history.push(url || this.nextPath || '/student');
  }

  handleClickGoBack = (e) => {
    const { history } = this.props;

    e.preventDefault();
    history.goBack();
  }

  handleRated = (infoData) => {
    logger.debug('on handleRated', infoData);
    this.rated = true;
    if (infoData) {
      this.setState({
        showRatingModal: false,
        showInfoModal: true,
        infoModalData: infoData,
      });
    } else {
      logger.warn(this.nextPath);
      this.goNext();
    }
  }

  handleHideInfoModal = () => {
    logger.debug('on handleHideInfoModal');
    this.setState({
      showInfoModal: false,
      infoModalData: null,
    });
    this.goNext();
  }

  handleReposted = ({ succeeded, errorMessage }) => {
    const { onShowExpertWaiting } = this.props;
    this.setState({
      showInfoModal: false,
    });

    if (succeeded) {
      onShowExpertWaiting();
      this.goNext('/stem');
    } else {
      this.setState({
        unknownErrorModal: {
          show: true,
          msg: errorMessage,
        },
      });
    }
  }

  handleHideUnknownErrorModal = () => {
    this.setState({
      unknownErrorModal: {
        show: false,
        msg: '',
      },
    });
    this.goNext();
  }

  render() {
    const { question, discussionMessages } = this.state;
    const { user } = this.props;

    if (question && isOpening(question)) {
      return <Redirect to="/stem" />;
    }

    let page;

    if (question === null) {
      page = <Loading />;
    } else if (question === false) {
      page = (
        <div className="u-marginHorizontalAuto u-marginVerticalLarge u-flex u-justifyContentCenter" style={{ maxWidth: 300 }}>
          <EmptyState src={require('assets/images/session-not-found.svg')} name="session">
            <EmptyState.Heading>
              Oops!
            </EmptyState.Heading>
            <EmptyState.Description>
              Session not found
            </EmptyState.Description>
          </EmptyState>
        </div>
      );
    } else {
      page = (
        <>
          <div className="u-flex u-flexColumn u-flexGrow-1 u-widthFull u-heightFull u-text200 u-positionRelative">
            {/* Banner */}
            <div className="u-backgroundPrimaryLight u-text200 u-paddingSmall">
              <div className="u-flex">
                <div className="u-flexGrow-1 u-overflowHidden">
                  <QSummary question={question} />
                </div>
                <div className="u-flex u-alignItemsCenter u-flexShrink-0 u-paddingTiny">
                  <div>
                    <Button
                      variant="secondary"
                      className="u-widthFull lg:u-widthAuto"
                      onClick={this.handleClickGoBack}
                    >
                      <Button.Label className="u-fontMedium">
                        Back
                      </Button.Label>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <MessageList
              user={user}
              question={question}
              messages={discussionMessages}
              onCqMessageReply={() => {}}
            />
          </div>
          {this.state.showRatingModal && (
            <RatingModal
              onRated={this.handleRated}
              question={this.state.question}
              answ={this.state.answ}
            />
          )}
          {this.state.showInfoModal && (
            <InfoModal
              question={this.state.question}
              modal={this.state.infoModalData}
              onClose={this.handleHideInfoModal}
              onReposted={this.handleReposted}
            />
          )}
          {this.state.extendErrorModal.show && (
            <ExtendErrorModal
              msg={this.state.extendErrorModal.msg}
              onClose={this.handleHideExtendErrorModal}
            />
          )}
          {this.state.unknownErrorModal.show && (
            <UnknownErrorModal
              onClose={this.handleHideUnknownErrorModal}
              message={this.state.unknownErrorModal.msg}
            />
          )}
        </>
      );
    }

    return (
      <>
        <h1 className="sr-only">Past Session Detail</h1>
        {page}
        <QuestionActiveBar />
      </>
    );
  }
}

QDetail.propTypes = {
  onLoadQuestion: PropTypes.func.isRequired,
  onReplyCqMessage: PropTypes.func.isRequired,
  onShowExpertWaiting: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  onLoadQuestion: qid => dispatch(loadQuestion(qid)),
  onReplyCqMessage: (mid, optionId) => dispatch(replyCqMessage(mid, optionId)),
  onShowExpertWaiting: () => dispatch(showExpertWaiting()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QDetail);
