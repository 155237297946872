import React from 'react';
import PropTypes from 'prop-types';
import Cropper from 'cropperjs';

class ImageTools extends React.Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    crop: PropTypes.shape(),
    onReadyStateChange: PropTypes.func,
  };

  static defaultProps = {
    crop: {},
    onReadyStateChange: () => {},
  }

  componentDidMount() {
    this.cropper = new Cropper(this.img, {
      viewMode: 1,
      checkCrossOrigin: true,
      zoomable: false,
      aspectRatio: 1 / 1,
      ready: this.onCropperReady,
      data: this.props.crop,
      minCropBoxWidth: 60,
      minCropBoxHeight: 60,
    });
    this.ready = false;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.onReadyStateChange(false);
      this.cropper.reset().clear().replace(nextProps.src);
    }
  }

  componentWillUnmount() {
    if (this.img) {
      this.cropper.destroy();
      delete this.img;
      delete this.cropper;
    }
  }

  onCropperReady = () => {
    this.onReadyStateChange(true);
  }

  onReadyStateChange = (ready) => {
    this.ready = ready;
    this.props.onReadyStateChange(ready);
  }

  getCroppedImage = (canvasOption = {}) => (
    this.cropper.getCroppedCanvas(canvasOption).toDataURL('image/jpeg')
  );

  getCroppedBox = () => (
    this.cropper.getData()
  );

  rotate = (degree) => {
    if (!this.ready) {
      return;
    }
    this.cropper.rotate(degree);
    logger.debug('on rotale image');
  }

  reset = () => {
    if (!this.ready) {
      return;
    }
    this.cropper.reset();
    logger.debug('on reset image');
  }

  render() {
    const {
      src,
    } = this.props;
    return (
      <img className="u-widthFull" alt="" ref={(img) => { this.img = img; }} src={src} crossOrigin="Anonymous" />
    );
  }
}


export default ImageTools;
