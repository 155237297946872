import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from 'components/Common/Modal';

class SubcriptionCancelModal extends React.Component {
  handleCancel = (e) => {
    logger.debug('on handleCancel');
    e.preventDefault();
    this.props.onCancel();
  }

  handleConfirm = (e) => {
    logger.debug('on handleConfirm');
    e.preventDefault();
    this.props.onConfirm(this.props.pkg.product_id);
  }

  render() {
    return (
      <ModalBase
        headerText="Disable Auto-payment"
        size="medium"
        closable={false}
        footerType='double'
        body={(
          <div>Our automatic payments are safe, convenient and have no hidden fees. We will send a receipt with each payment. Are you sure you want to disable?</div>
        )}
        primaryButtonText="Yes"
        onClickPrimaryButton={this.handleConfirm}
        secondaryButtonText="Cancel"
        onClickSecondaryButton={this.handleCancel}
      />
    );
  }
}

SubcriptionCancelModal.propTypes = {
  pkg: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default SubcriptionCancelModal;
