import React from 'react';
import PropTypes from 'prop-types';
import { BubbleChat, Avatar } from '@ahaui/react';
import expertAvatar from 'assets/images/expert.svg';
import gotitAvatar from 'assets/images/gotit.svg';

class SuggestedResponse extends React.Component {
  constructor(props) {
    super(props);
    const { message } = this.props;
    this.state = {
      enabled: message.options.every((o) => !o.selected),
    };
    this.timerId = 0;
  }

  /**
   * Set timeout for CQ message 30s for all message.
   */
  componentDidMount() {
    const { message } = this.props;
    const { enabled } = this.state;

    if (enabled) {
      this.timerId = window.setTimeout(() => {
        this.setState({
          enabled: false,
        });
        this.timerId = 0;
      }, message.time_remaining * 1000);
    }
  }

  /**
   * Remove timer when component unmount.
   */
  componentWillUnmount() {
    if (this.timerId > 0) {
      window.clearTimeout(this.timerId);
    }
  }

  /**
   * Send reply action.
   * Remove timer.
   */
  handleOptionClick = (optionId) => {
    const { message, onOptionClick } = this.props;
    const { enabled } = this.state;

    logger.debug('on handleOptionClick');
    if (!enabled) {
      return false;
    }
    onOptionClick(message.mid, optionId);
    this.setState({
      enabled: false,
    });
    if (this.timerId > 0) {
      clearTimeout(this.timerId);
    }
    return false;
  }

  render() {
    const { message, created, isSystemMessage } = this.props;
    const { enabled } = this.state;

    const options = message.options.map(({ id, body }) => ({ id, name: body }));
    const currentOption = message.options.find(({ selected }) => selected === 1)?.id;
    const disabledOption = !!currentOption || !enabled;

    const type = isSystemMessage ? 'system' : 'outbound';
    const avatar = isSystemMessage ? (
      <Avatar
        size="small"
        className="u-flexShrink-0 u-marginRightExtraSmall u-marginTopExtraSmall"
        src={gotitAvatar}
        alt="System avatar"
      />
    ) : (
      <Avatar
        size="small"
        className="u-flexShrink-0 u-marginRightExtraSmall u-marginTopExtraSmall"
        src={expertAvatar}
        alt="Expert avatar"
      />
    );

    return (
      <BubbleChat
        text={message.body}
        type={type}
        avatar={() => avatar}
        time={created}
        disabledOption={disabledOption}
        currentOption={currentOption}
        options={options}
        onSelectOption={this.handleOptionClick}
        className="u-textWordBreak"
      />
    );
  }
}

SuggestedResponse.propTypes = {
  onOptionClick: PropTypes.func.isRequired,
  created: PropTypes.string.isRequired,
  message: PropTypes.shape().isRequired,
  isSystemMessage: PropTypes.bool,
};

SuggestedResponse.defaultProps = {
  isSystemMessage: false,
}

export default SuggestedResponse;
