import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ahaui/react';
import config from 'configuration';
import ModalBase from 'components/Common/Modal';


class Grade extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectGrade = this.handleSelectGrade.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  handleSelectGrade(gradeId) {
    this.props.onSelectGrade(gradeId);
  }

  handleContinue() {
    this.props.onDoneSelectingGrade();
  }

  render() {
    return (
      <ModalBase
        headerText="Help us match you with the right Experts for you"
        size="medium"
        closable={false}
        footerType="single"
        body={(
          <>
            <p className="">Which grade are you in?</p>
            {config.grades.map(({ id, title }) => (
              <Form.Check
                key={id}
                name="grade"
                value={id}
                checked={id === this.props.selectedGrade}
                onChange={() => this.handleSelectGrade(id)}
                type="radio"
                label={title}
                id={`grade-${id}`}
                className="u-marginBottomExtraSmall"
              />
            ))}
          </>
        )}
        primaryButtonText="Continue"
        onClickPrimaryButton={this.handleContinue}
        disablePrimaryButton={!this.props.selectedGrade}
      />
    );
  }
}

Grade.propTypes = {
  selectedGrade: PropTypes.number.isRequired,
  onSelectGrade: PropTypes.func.isRequired,
  onDoneSelectingGrade: PropTypes.func.isRequired,
};

export default Grade;
