import { run } from './promise';
import { get, post, put } from '../utils/request';
import {
  VERIFY_TOKEN,
  FACEBOOK_LOGIN,
  FACEBOOK_SIGNUP,
  ACCOUNTKIT_LOGIN,
  ACCOUNTKIT_SIGNUP,
  ONE_TIME_CODE_REQUEST,
  ONE_TIME_CODE_VERIFY,
  ONE_TIME_CODE_SIGNUP,
  PRESET_LOGIN,
  SIGNUP_SUCCESS,
  LOG_OUT,
  FORCE_LOGOUT,
  SURGE_INFO_GET,
  PROFILE_UPDATE,
  PROFILE_HOT_UPDATE,
  PROFILE_SIGNUP_BEGIN,
  PROFILE_SIGNUP_END,
  PROMO_CODE_REDEEM,
  PRESET_CHECK_WHITELIST,
  PASSWORD_RECOVERY_REQUEST,
  PASSWORD_RECOVERY_CHECK_CODE,
  PASSWORD_RECOVERY_RESET,
  PASSWORD_CHECK,
  ACCEPTED_TOS,
  GET_CAPTCHA,
} from '../constants/user.constant';

import {
  MULTIPART_FORMDATA,
} from '../constants/common.constant';

export const verifyToken = () => (
  run(
    VERIFY_TOKEN,
    get('/accounts/auth')
  )
);

export const loginFacebook = token => (
  run(
    FACEBOOK_LOGIN,
    post('/accounts/facebook/auth', { access_token: token })
  )
);

export const signupFacebook = (token, data) => (
  run(
    FACEBOOK_SIGNUP,
    post('/accounts/facebook/register', { ...data, access_token: token })
  )
);

export const loginAccountKit = token => (
  run(
    ACCOUNTKIT_LOGIN,
    post('/accounts/accountkit/auth', { code: token })
  )
);

export const signupAccountKit = (token, data) => (
  run(
    ACCOUNTKIT_SIGNUP,
    post('/accounts/accountkit/register', { ...data, access_token: token })
  )
);

export const requestOneTimeCode = (email, nonce, captcha_code) => (
  run(
    ONE_TIME_CODE_REQUEST,
    post('/accounts/gotit/code', { email, nonce, captcha_code })
  )
);

export const verifyOneTimeCode = (email, code, data = {}) => (
  run(
    ONE_TIME_CODE_VERIFY,
    post('/accounts/gotit/auth', { ...data, email, code })
  )
);

export const signupByOneTimeCode = (email, code, data = {}) => (
  run(
    ONE_TIME_CODE_SIGNUP,
    post('/accounts/gotit/register', { ...data, email, code })
  )
);

export const loginPreset = data => (
  run(
    PRESET_LOGIN,
    post('/accounts/presetsignin', data)
  )
);

export const checkWhitelist = data => (
  run(
    PRESET_CHECK_WHITELIST,
    get('/accounts/whitelist', data)
  )
);

export const checkMissingPassword = () => (
  run(
    PASSWORD_CHECK,
    get('/accounts/password')
  )
);

export const requestPasswordRecovery = email => (
  run(
    PASSWORD_RECOVERY_REQUEST,
    post('/accounts/password/recovery', { email })
  )
);

export const checkPasswordRecoveryCode = code => (
  run(
    PASSWORD_RECOVERY_CHECK_CODE,
    get('/accounts/password/recovery', { code })
  )
);

export const resetPassword = (code, new_password) => (
  run(
    PASSWORD_RECOVERY_RESET,
    put('/accounts/password/recovery', { code, new_password })
  )
);


export const onSignupSuccess = user => ({
  type: SIGNUP_SUCCESS,
  payload: user,
});

export const logOut = () => ({
  type: LOG_OUT,
});

export const forceLogout = () => ({
  type: FORCE_LOGOUT,
});

export const getSurgeInfo = () => (
  run(
    SURGE_INFO_GET,
    get('/questions/surgeinfo')
  )
);

export const updateProfile = data => (
  run(
    PROFILE_UPDATE,
    post('/accounts/setting', data, MULTIPART_FORMDATA)
  )
);

export const hotUpdateProfile = data => ({
  type: PROFILE_HOT_UPDATE,
  payload: data,
});

export const redeemPromoCode = code => (
  run(
    PROMO_CODE_REDEEM,
    post('/profiles', { promo_code: code })
  )
);

export const signupBegin = () => ({
  type: PROFILE_SIGNUP_BEGIN,
});

export const signupEnd = () => ({
  type: PROFILE_SIGNUP_END,
});

export const acceptedTOS = (id, gdpr = {}) => (
  run(
    ACCEPTED_TOS,
    post('/tos/agree', { ...gdpr, id })
  )
);

export const getCaptcha = () => (
  run(
    GET_CAPTCHA,
    get('/captcha')
  )
);

export default {
  verifyToken,
  loginFacebook,
  signupFacebook,
  loginAccountKit,
  signupAccountKit,
  logOut,
  forceLogout,
  updateProfile,
  redeemPromoCode,
  signupBegin,
  signupEnd,
  loginPreset,
  checkWhitelist,
  checkMissingPassword,
  requestPasswordRecovery,
  checkPasswordRecoveryCode,
  resetPassword,
  acceptedTOS,
  getCaptcha,
};
