import { run } from './promise';
import { get, post } from '../utils/request';

import {
  EXPERT_WAITING_SHOW,
  EXPERT_WAITING_HIDE,
  QUESTION_EXTENDED,
  QUESTION_CHECK_OPEN,
  QUESTION_OPEN_GET,
  QUESTION_FLAG,
  FLAG_MODAL_SHOW,
  FLAG_MODAL_HIDE,
  QUESTION_END,
  CQ_MESSAGE_REPLY,
  MESSAGE_SEND,
  NEW_DISCUSSION_MESSAGE,
  EDIT_DISCUSSION_MESSAGE,
  QUESTION_RATE,
  TIMEOUT_RESET,
  QUESTION_REPOST,
  FIRST_REGULAR_CLAIMED,
  FIRST_REGULAR_ROUTED,
  REGULAR_CLAIM_MSG_UPDATE,
} from '../constants/chat.constant';
import { MULTIPART_FORMDATA } from '../constants/common.constant';

export const showExpertWaiting = () => ({
  type: EXPERT_WAITING_SHOW
});

export const hideExpertWaiting = () => ({
  type: EXPERT_WAITING_HIDE
});

export const extendQuestion = (discussionEndTime, discussionTimeLimit) => ({
  type: QUESTION_EXTENDED,
  discussionEndTime,
  discussionTimeLimit
});

export const checkOpenQuestion = () => (
  run(
    QUESTION_CHECK_OPEN,
    get('/questions/canask')
  )
);

export const getOpenQuestion = qid => (
  run(
    QUESTION_OPEN_GET,
    get('/questions', { qid })
  )
);

export const flagQuestion = qid => (
  run(
    QUESTION_FLAG,
    post('/questions/end', { qid, flag: 1 })
  )
);

export const endQuestion = qid => (
  run(
    QUESTION_END,
    post('/questions/end', { qid, flag: 0 })
  )
);


export const rateQuestion = (aid, star, reason) => {
  let data = {
    aid,
    point: star,
    reason,
  };
  return run(
    QUESTION_RATE,
    post('/answers/vote', data, MULTIPART_FORMDATA)
  );
};

export const showFlagModal = () => ({
  type: FLAG_MODAL_SHOW
});

export const hideFlagModal = () => ({
  type: FLAG_MODAL_HIDE
});

export const replyCqMessage = (mid, optionId) => {
  let body = {
    response_id: optionId
  };
  let url = '/discussion/message/' + mid;
  return run(
    CQ_MESSAGE_REPLY,
    post(url, body)
  );
};

export const sendMessage = (qid, body) => (
  run(
    MESSAGE_SEND,
    post('/questions/discussion/send', { qid, body }, MULTIPART_FORMDATA)
  )
);

export const newDiscussionMessageAction = msg => ({
  type: NEW_DISCUSSION_MESSAGE,
  msg
});

export const editDiscussionMessageAction = msg => ({
  type: EDIT_DISCUSSION_MESSAGE,
  msg
});

export const resetTimeOut = (qid) => (
  run(
    TIMEOUT_RESET,
    post('/questions/discussion/resettimeout', { qid }, MULTIPART_FORMDATA)
  )
);

export const repostQuestion = qid => (
  run(
    QUESTION_REPOST,
    post(`/questions/repost/${qid}`),
    { qid }
  )
);

export const showRegularClaimed = data => ({
  type: FIRST_REGULAR_CLAIMED,
  data
});

export const showRegularRouted = data => ({
  type: FIRST_REGULAR_ROUTED,
  data
});

export const updateRegularClaimMsg = msg => ({
  type: REGULAR_CLAIM_MSG_UPDATE,
  msg
});

const chatActions = {};

export default chatActions;
