import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@ahaui/react';

import ModalBase from 'components/Common/Modal';
import ImageTools from './ImageTools';


class PictureCropper extends React.Component {
  static propTypes = {
    imageUrl: PropTypes.string.isRequired,
    onCrop: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  imageReady = false;

  imageTool = null;

  /**
   * Get the status of image tools init.
   */
  handleImageReady = (ready) => {
    this.imageReady = ready;
  }

  /**
   * Handle reset button click.
   * Reset the crop file.
   */
  handleReset = (e) => {
    e.preventDefault();
    logger.debug('on handleReset');
    if (!this.imageReady) {
      logger.warn('Imagetool is processing');
      return;
    }
    this.imageTool.reset();
  }

  /**
   * Handle rolate picture.
   */
  handleRotate = (e) => {
    e.preventDefault();
    logger.debug('on hadleRolate');
    if (!this.imageReady) {
      logger.warn('Imagetool is processing');
      return;
    }
    this.imageTool.rotate(90);
  }

  /**
   * Handle when finish crop
   * Dispatch the cropped action.
   */
  handleCrop = (e) => {
    e.preventDefault();
    logger.debug('on handleCrop');
    if (!this.imageReady) {
      logger.warn('Imagetool is processing');
      return;
    }
    const crop = this.imageTool.getCroppedImage({
      fillColor: '#fff',
      width: 400,
      height: 400,
    });
    this.props.onCrop(crop);
    this.props.onClose();
  }

  handleClose = () => {
    this.props.onClose();
  }

  render() {
    return (
      <ModalBase
        headerText="Edit Avatar"
        size="large"
        closable={true}
        onHide={this.handleClose}
        showFooter={false}
        body={(
          <>
            <div className="u-marginBottomMedium u-borderBottom">
              <ImageTools
                ref={(el) => { this.imageTool = el; }}
                src={this.props.imageUrl}
                onReadyStateChange={this.handleImageReady}
              />
            </div>
            <div>
              <div className="u-flex u-justifyContentCenter">
                <Button.Group>
                  <Button variant="secondary" onClick={this.handleReset}>
                    <Button.Label className="u-hidden md:u-inlineBlock">
                      Reset
                    </Button.Label>
                    <Button.Icon>
                      <Icon name="replace" />
                    </Button.Icon>
                  </Button>
                  <Button variant="secondary" onClick={this.handleRotate}>
                    <Button.Label className="u-hidden md:u-inlineBlock">
                      Rotate
                    </Button.Label>
                    <Button.Icon>
                      <Icon name="refresh" />
                    </Button.Icon>
                  </Button>
                  <Button variant="secondary" onClick={this.handleCrop}>
                    <Button.Label className="u-hidden md:u-inlineBlock">
                      Crop
                    </Button.Label>
                    <Button.Icon>
                      <Icon name="checkmark" />
                    </Button.Icon>
                  </Button>
                </Button.Group>
              </div>
            </div>
          </>
        )}
      />
    );
  }
}

export default PictureCropper;
