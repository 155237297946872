import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EmptyState } from '@ahaui/react';
import { loadMyQuestions } from 'actions/question.action';
import Tracking from 'utils/tracking';

import QuestionActiveBar from 'components/Common/QuestionActiveBar';
import Loading from 'components/Common/Loading';
import QItem from './QItem';

export class History extends React.Component {
  state = {
    loading: false,
  };

  mounted = true;

  componentWillMount() {
    this.handleLoadQuestions();
  }

  componentDidMount() {
    const { location } = this.props;
    Tracking.viewMyQuestions({ location });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleScroll = ({ target }) => {
    const { myQuestions } = this.props;

    if (!myQuestions.loadMore) {
      return;
    }

    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      this.handleLoadQuestions(myQuestions.oldestQid);
    }
  }

  handleLoadQuestions = (oldestQid = 0) => {
    const { loadQuestions } = this.props;

    this.setState({ loading: true });
    loadQuestions(oldestQid).then(() => {
      if (this.mounted) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    const { questions, oldestQid, loadMore } = this.props.myQuestions;
    const { loading } = this.state;

    let page;

    if (loading && !oldestQid) {
      page = <Loading />;
    } else if (!loading && questions.length === 0) {
      page = (
        <div className="Container Container--fluid u-paddingVerticalLarge">
          <div className="u-marginHorizontalAuto" style={{ width: '100%', maxWidth: 536 }}>
            <div className="u-marginHorizontalAuto u-marginBottomLarge u-textCenter" style={{ maxWidth: 300 }}>
              <EmptyState src={require('assets/images/empty-history.svg')}>
                <EmptyState.Heading>
                  No sessions
                </EmptyState.Heading>
                <EmptyState.Description>
                  You don't have any past sessions.
                </EmptyState.Description>
              </EmptyState>
            </div>
          </div>
        </div>
      );
    } else {
      page = (
        <div onScroll={this.handleScroll} className="u-positionAbsolute u-positionFull u-overflowVerticalAuto u-webkitScrollbar">
          <div className="Container Container--fluid u-paddingTopLarge u-paddingBottomExtraLarge">
            <div className="u-marginHorizontalAuto" style={{ width: '100%', maxWidth: 536 }}>
              <div className="Grid Grid--smallGutter">
                {questions.map(question => (
                  <div key={question.qid} className="u-size6of12 md:u-size4of12 u-marginBottomSmall">
                    <QItem key={question.qid} question={question} />
                  </div>
                ))}
              </div>

              {loadMore && questions.length > 0 && loading && (
                <Loading />
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <h1 className="sr-only">My Sessions</h1>
        {page}
        <QuestionActiveBar />
      </>
    )
  }
}

History.propTypes = {
  location: PropTypes.shape().isRequired,
  myQuestions: PropTypes.shape().isRequired,
  loadQuestions: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  myQuestions: state.myQuestions,
});

const mapDispatchToProps = dispatch => ({
  loadQuestions: oldestQid => dispatch(loadMyQuestions(oldestQid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
