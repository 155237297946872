import { FORCE_LOGOUT } from 'constants/user.constant';
import {
  APP_CHECK_STATUS,
  APP_READY,
  APP_MAINTENANCE,
  APP_FORCE_UPGRADE,
  APP_FORCE_LOGOUT,
  APP_AUTH,
  SYSTEM_STATUS_READY,
  SYSTEM_STATUS_MAINTENANCE,
  SYSTEM_STATUS_UPGRADE
} from '../constants/app.constant';

import { PROMISE_RUN } from '../constants/promise.constant';

import defaultState from '../store/defaultState';

export default (state = defaultState.app, action) => {
  switch (action.type) {
    case APP_READY:
      return { ...state, systemStatus: SYSTEM_STATUS_READY };
    case APP_MAINTENANCE:
      return { ...state, systemStatus: SYSTEM_STATUS_MAINTENANCE };
    case APP_FORCE_UPGRADE:
      return { ...state, systemStatus: SYSTEM_STATUS_UPGRADE };
    case APP_FORCE_LOGOUT:
      return {
        ...state,
        forceLogout: {
          ...state.forceLogout,
          needed: true,
          modalTitle: action.payload.modal.title,
          modalBody: action.payload.modal.body,
          modalButtonText: action.payload.modal.button_text,
        },
      };
    case FORCE_LOGOUT:
      return { ...state, forceLogout: { ...defaultState.app.forceLogout } };
    case APP_AUTH:
      return {
        ...state,
        authToken: action.payload.token,
        firstLogin: action.payload.firstLogin
      };
    case PROMISE_RUN:
      switch (action.key) {
        case APP_CHECK_STATUS:
          return { ...state, systemStatus: SYSTEM_STATUS_READY };
        default:
          return state;
      }
    default:
      return state;
  }
};
