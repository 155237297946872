import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  getAsker,
} from 'actions/english.action';
import { hasWritingSupport } from 'utils/feature-support';

import PostView from './PostView';

export class Ask extends React.Component {
  // Check once after mount to redirect to the appropriate route
  componentDidMount() {
    const { history, isWritingSupported } = this.props;

    if (!isWritingSupported) {
      history.push('/');
    }
  }

  render() {
    return (
      <PostView />
    );
  }
}

const mapStateToProps = state => ({
  isWritingSupported: hasWritingSupport(state.user.enabled_features),
});

export default connect(mapStateToProps, { getAsker })(withRouter(Ask));
