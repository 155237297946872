import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EmptyState, Button } from '@ahaui/react';

import { loadQuestion } from '../../actions/question.action';

import Loading from 'components/Common/Loading';
import QuestionActiveBar from 'components/Common/QuestionActiveBar';
import MessageList from '../Common/MessageList';
import QSummary from '../History/QSummary';


class SampleDetail extends React.Component {
  constructor(props) {
    super(props);
    this.qid = props.match.params.qid;

    this.defaultState = {
      question: null,
      discussionMessages: null,
      answer: null
    };
    this.state = { ...this.defaultState };
  }

  componentDidMount() {
    this.props.onLoadQuestion(this.qid).then(
      (data) => {
        let detail = data.payload.result;
        if (detail instanceof Object && Object.keys(detail).length > 0) {
          this.setState({
            ...this.defaultState,
            question: detail.question,
            discussionMessages: detail.discussion_messages,
            answer: detail.answers
          }, () => {
          });
        } else {
          this.setState({ question: false });
        }
      }
    );
  }

  getQuestionStatusTitle = (status) => {
    if (+status === 6) return 'Rejected';
    if (+status === 5) return 'Answered';
    if (+status === 4) return 'Claimed';
    if (+status === 0) return 'Unclaimed';
    return '';
  }

  handleClickGoBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  }

  render() {
    const { question, discussionMessages } = this.state;
    const user = this.props.user;

    let page;

    if (question === null) {
      page = (
        <Loading />
      );
    } else if (question === false) {
      page = (
        <div className="u-marginHorizontalAuto u-marginVerticalLarge u-flex u-justifyContentCenter" style={{ maxWidth: 300 }}>
          <EmptyState src={require('assets/images/session-not-found.svg')} name="session">
            <EmptyState.Heading>
              Oops!
            </EmptyState.Heading>
            <EmptyState.Description>
              Session not found
            </EmptyState.Description>
          </EmptyState>
        </div>
      );
    } else {
      page = (
        <div className="u-flex u-flexColumn u-widthFull u-heightFull u-text200 u-positionRelative">
          <div className="u-backgroundPrimaryLight u-text200 u-paddingSmall">
            <div className="u-flex">
              <div className="u-flexGrow-1 u-overflowHidden">
                <QSummary question={question} hideRating />
              </div>
              <div className="u-flex u-alignItemsCenter u-flexShrink-0 u-paddingTiny">
                <div>
                  <Button
                    variant="primary_outline"
                    className="u-widthFull lg:u-widthAuto"
                    onClick={this.handleClickGoBack}
                  >
                    <Button.Label className="u-fontMedium">
                      Back
                    </Button.Label>
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <MessageList
            user={user}
            question={question}
            messages={discussionMessages}
            onCqMessageReply={() => {}}
          />
        </div>
      );
    }

    return (
      <>
        <h1 className="sr-only">Sample Session Detail</h1>
        {page}
        <QuestionActiveBar />
      </>
    )
  }
}

SampleDetail.propTypes = {
  onLoadQuestion: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  onLoadQuestion: qid => dispatch(loadQuestion(qid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SampleDetail);
