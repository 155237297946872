import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Button, Icon } from '@ahaui/react';

import Tracking from '../../../../../utils/tracking';

import ErrorModal from './ErrorModal';
import ScreenshotModal from './ScreenshotInfoModal';

import {
  loadFile,
  toggleCropper,
  removeFile,
} from '../../../../../actions/askflow.action';

class PictureLoader extends React.Component {
  constructor() {
    super();
    this.state = {
      boxDragEntered: false,
      errorModalShow: false,
      errorMsg: '',
      screenshotModalShow: false,
    };
    this.fileInputEl = null;
  }

  componentDidMount() {
    this.setUpListenToPasteEvent();
  }

  setUpListenToPasteEvent = () => {
    document.addEventListener('paste', (e) => {
      this.handlePasteFile(e);
    });
  }

  trackAddImage = (actionType) => {
    Tracking.askFlow({
      action: actionType,
    });
  }

  getImageFile = (file) => {
    if (PictureLoader.SUPPORT_FILE.indexOf(file.type) === -1) {
      logger.error('file type is not support just png and jpg. File\'s type is ', file.type);
      return [null, PictureLoader.errorCode.INVALID_TYPE];
    }

    return [window.URL.createObjectURL(file), null];
  }

  /**
   * Remove image
   */
  handleRemoveImage = () => {
    logger.debug('on handleRemoveImage');
    this.props.onRemoveFile();
  }

  /**
   * Redo crop image.
   */
  handleRedoCropImage = () => {
    logger.debug('on handleRedoCropImage');
    this.props.onToggleCropper();
  }

  /**
   * Handle update state when file loaded.
   * Show error modal if any.
   * Otherwise get the Image Blob url and
   * popup Cropper Modal
   */
  handleFileLoaded = (file) => {
    logger.debug('on handleFileChange');
    const [image, error] = this.getImageFile(file);
    logger.debug('image', image);
    logger.debug('error', error);
    if (!error) {
      logger.debug('get file succeed, processing...');
      this.props.onLoadFile(image);
      this.props.onToggleCropper();
      if (this.fileInputEl) {
        this.fileInputEl.value = null;
      }
    } else {
      logger.debug('error', error);
      if (error === PictureLoader.errorCode.INVALID_TYPE) {
        this.setState({
          errorModalShow: true,
          errorMsg: 'Your file type is not supported. Please try with a jpg, jpeg, png file',
        });
      }
    }
  }

  handleChooseFile = (e) => {
    logger.debug('on handleChooseFile');
    let file;
    const fileList = e.target.files;
    if (fileList && fileList.length) {
      file = fileList[0];
      logger.debug('file added', file);
      this.handleFileLoaded(file);
      this.trackAddImage(PictureLoader.action.ADDED_IMAGE_FILE);
    }
  }

  handleDropFile = (e) => {
    logger.debug('on handleDropFile');
    // this.setState({ boxDragEntered: false });
    let file;
    const { dataTransfer } = e;
    if (dataTransfer.files && dataTransfer.files.length) {
      file = dataTransfer.files[0];
      logger.debug('file added', file);
      this.handleFileLoaded(file);
      this.trackAddImage(PictureLoader.action.ADDED_IMAGE_DROP);
    }
  }

  handlePasteFile = (e) => {
    logger.debug('on handlePasteFile');
    let file;
    const clipBoardData = e.clipboardData;
    if (clipBoardData.files && clipBoardData.files.length) {
      file = clipBoardData.files[0];
      logger.debug('file added', file);
      this.setState({
        screenshotModalShow: false,
      });
      this.handleFileLoaded(file);
      this.trackAddImage(PictureLoader.action.ADDED_SCREENSHOT);
    }
  }

  handleDragOver = (e) => {
    e.preventDefault();
  }

  handleDragEnter = (e) => {
    // logger.debug('on handleDragEnter');
    // this.setState({ boxDragEntered: true });
  }

  handleDragLeave = (e) => {
    // logger.debug('on handleDragLeave');
    // this.setState({ boxDragEntered: false });
  }

  handleHideErrorModal = () => {
    logger.debug('on handleHideErrorModal');
    this.setState({
      errorModalShow: false,
      errorMsg: '',
    });
  }

  handleHideScreenshotModal = () => {
    logger.debug('on handleHideScreenshotModal');
    this.setState({
      screenshotModalShow: false,
    });
  }

  handleShowScreenshotModal = () => {
    logger.debug('on handleShowScreenshotModal');
    this.setState({
      screenshotModalShow: true,
    });
  }

  handleOpenFileUploadPopup = (e) => {
    logger.debug('on handleOpenFileUploadPopup');
    // e.preventDefault();
    this.fileInputEl.click();
  }

  render() {
    let placeElement;
    if (!this.props.croppedImage) {
      placeElement = (
        <div
          className="u-border u-borderUltraLight u-backgroundOpaline u-roundedMedium"
          style={{ height: 184 }}
          onDragOver={this.handleDragOver}
          onDrop={this.handleDropFile}
          onDragEnter={this.handleDragEnter}
          onDragLeave={this.handleDragLeave}
        >
          <div className="u-paddingSmall u-heightFull u-flex u-flexColumn u-justifyContentCenter u-alignItemsCenter">
            <div className="u-marginBottomExtraSmall u-textCenter">
              <div>Drag &amp; Drop</div>
              <div className="u-text100">or select an option below</div>
            </div>
            <div>
              <Button variant="primary_outline" width="min" className="u-marginRightExtraSmall" onClick={this.handleOpenFileUploadPopup}>
                <input ref={(el) => { this.fileInputEl = el; }} id="image_upload" type="file" hidden onChange={this.handleChooseFile} />
                <Button.Label>Browse</Button.Label>
                <Button.Icon><Icon name="images"/></Button.Icon>
              </Button>
              <Button variant="primary_outline" width="min" onClick={this.handleShowScreenshotModal}>
                <Button.Label>Screenshot</Button.Label>
                <Button.Icon><Icon name="camera"/></Button.Icon>
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      placeElement = (
        <div className="u-border u-borderUltraLight u-backgroundOpaline u-roundedMedium">
          <div className="Media u-positionRelative u-block u-paddingNone u-overflowHidden Media--16by9">
            <img src={this.props.croppedImage} className="Media-item" alt="" style={{ objectFit: 'contain' }} />
            <div className="u-positionAbsolute u-positionTop u-positionRight u-marginTopExtraSmall u-marginRightExtraSmall">
              <div>
                <Button variant="secondary" size="small" onlyIcon className="u-marginBottomExtraSmall u-cursorPointer" onClick={this.handleRedoCropImage}>
                  <Button.Icon><Icon name="options"/></Button.Icon>
                </Button>
              </div>
              <div>
                <Button variant="secondary" size="small" onlyIcon className="u-cursorPointer" onClick={this.handleRemoveImage}>
                  <Button.Icon><Icon name="trash"/></Button.Icon>
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Form.Group controlId="ask.stem.attachment">
        <Form.Label>Or upload a picture</Form.Label>
        {placeElement}
        {this.state.errorModalShow && <ErrorModal msg={this.state.errorMsg} onClose={this.handleHideErrorModal} />}
        {this.state.screenshotModalShow && <ScreenshotModal onClose={this.handleHideScreenshotModal} />}
      </Form.Group>
    );
  }
}

PictureLoader.SUPPORT_FILE = [
  'image/jpg',
  'image/png',
  'image/jpeg',
];

PictureLoader.errorCode = {
  INVALID_TYPE: 'invalid_type',
};


PictureLoader.action = {
  ADDED_SCREENSHOT: 'added_screenshot',
  ADDED_IMAGE_FILE: 'added_image_file',
  ADDED_IMAGE_DROP: 'added_image_drop',
};

PictureLoader.propTypes = {
  croppedImage: PropTypes.string,
  onLoadFile: PropTypes.func.isRequired,
  onToggleCropper: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
};

PictureLoader.defaultProps = {
  croppedImage: null,
};

const mapStateToProps = state => ({
  croppedImage: state.askflow.croppedImage,
});

const mapDispatchToProps = dispatch => ({
  onLoadFile: file => dispatch(loadFile(file)),
  onToggleCropper: () => dispatch(toggleCropper()),
  onRemoveFile: () => dispatch(removeFile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PictureLoader);
