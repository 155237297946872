import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from 'components/Common/Modal';

const ExtendErrorModal = ({ msg, onClose }) => (
  <ModalBase
    headerText="Error"
    size="small"
    onHide={onClose}
    body={(
      <p className="u-marginBottomNone">{msg}</p>
    )}
    primaryButtonText="OK"
    onClickPrimaryButton={onClose}
  />
);


ExtendErrorModal.propTypes = {
  msg: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExtendErrorModal;
