import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Icon } from '@ahaui/react';
import config from 'configuration';
import { loginFacebook } from '../../../actions/user.action';
import Tracking from '../../../utils/tracking';

import { navSend, navReceive } from '../../../utils/app';

class FacebookAuth extends React.Component {
  state = {
    fbStatus: '',
    fbToken: '',
    mode: 'signup',
    processing: false,
  };

  navInfo = navReceive('/')

  buttonRef = React.createRef();

  /**
   * Init Facebook SDK.
   */
  componentDidMount() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: config.FACEBOOK_APP_ID,
        cookie: true,
        version: 'v2.8',
      });
      window.FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          this.setState({
            fbStatus: response.status,
            fbToken: response.authResponse.accessToken,
          });
        }
      });
      this.autoTriggerIfNeeded();
    };
    (function (d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    this.autoTriggerIfNeeded();
  }

  autoTriggerIfNeeded = () => {
    if (this.navInfo?.from === 'shared_session_facebook_login') {
      this.buttonRef.current.click();
    }
  }

  onError(apiError) {
    const errorCode = (apiError.info && apiError.info.error_code) ? apiError.info.error_code : '';
    this.props.onError(apiError.data, errorCode, apiError.info);
  }

  /**
   * Get Facebook access token.
   * @param {*} e : default FB authen click event
   */
  handleLogin = (e) => {
    e.preventDefault();
    Tracking.startAuth({ source: 'facebook' });
    if (!window.FB) {
      return false;
    }

    const { fbStatus } = this.state;
    const { fbToken } = this.state;
    if (fbStatus === 'connected') {
      this.doLogin(fbToken);
    } else {
      window.FB.login((response) => {
        if (!response.authResponse?.accessToken) return;
        this.doLogin(response.authResponse.accessToken);
      }, { scope: 'public_profile,email' });
    }

    return false;
  }

  /**
   * dispatch FB log in action.
   * @param {string} accessToken : FB access token
   */
  doLogin(accessToken) {
    this.setState({
      processing: true,
    });
    this.props.doLogin(accessToken).then(
      (data) => {
        const { result } = data.payload;
        const { error } = data.payload;
        if (result) {
          this.props.onAuthenticated(result.auth);
        } else if (error && error.data) {
          const { info } = error.data;
          if (info) {
            if (info.is_whitelisted) {
              // Error: TRIO user try to login by Facebook | Account Kit
              if (this.navInfo?.from === 'shared_session_facebook_login') {
                navSend('/login', {
                  ...info,
                  from: 'shared_session_try_preset',
                  path: this.navInfo.path,
                });
              } else {
                navSend('/login', { ...info, from: 'try_preset' });
              }
              this.props.history.push('/login');
            }
            if (info.error_code && info.error_code === 'profile_not_found') {
              this.props.onSignup(accessToken);
            } else {
              this.onError(error.data);
            }
          } else {
            this.onError(error.data);
          }
          logger.debug('Login failed', error);
        }
      }
    );
  }

  render() {
    return (
      <Button
        variant="white_outline"
        width="full"
        className="u-marginBottomSmall"
        onClick={this.handleLogin}
        ref={this.buttonRef}
      >
        <Button.Icon>
          <Icon name="facebook" />
        </Button.Icon>
        <Button.Label>
          {this.props.signup
            ? 'Sign up with Facebook'
            : 'Log in with Facebook'}
        </Button.Label>
      </Button>
    );
  }
}

FacebookAuth.propTypes = {
  signup: PropTypes.bool.isRequired,
  doLogin: PropTypes.func.isRequired,
  onSignup: PropTypes.func.isRequired,
  onAuthenticated: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

FacebookAuth.defaultProps = {
  signup: false,
};

const mapDispatchToProps = dispatch => ({
  doLogin: token => dispatch(loginFacebook(token)),
});

const FacebookAuthContainer = connect(null, mapDispatchToProps)(FacebookAuth);
export default withRouter(FacebookAuthContainer);
