import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import config from 'configuration';
import {
  Button,
  Icon,
  Avatar,
  Form,
  Dropdown,
  ToastContainer,
  toast,
} from '@ahaui/react';

import QuestionActiveBar from '../Common/QuestionActiveBar';

import {
  updateProfile,
  hotUpdateProfile,
  logOut,
  requestPasswordRecovery,
  redeemPromoCode,
} from '../../actions/user.action';

import { dataURItoBlob } from '../../utils/converter';
import AccessToken from '../../utils/token';
import Tracking from '../../utils/tracking';
import { hasTrioSupport, hasCoppaSupport } from '../../utils/feature-support';

import AvatarUploader from './Avatar/Uploader';
import ModalLogout from './ModalLogout';
import ModalChangingPasswordConfirm from './ModalChangingPasswordConfirm';
import ModalRedeemPromoCodeSuccess from './ModalRedeemPromoCodeSuccess';
import PrivacyCenter from './PrivacyCenter';

import RPRS from '../Common/modals/RequestPasswordRecoverySuccess';

class Setting extends React.Component {
  constructor(props) {
    super(props);

    const { user } = props;
    this.initData(user);
    this.state = {
      ...this.initialData,
      showGradeList: false,
      errors: {},
      promoError: '',
      showChangingPasswordConfrimation: false,
      requestPasswordRecoverySuccess: false,
      redeemPromoCodeSuccessInfo: null,
      showPrivacySettings: false,
    };
  }

  componentDidMount() {
    Tracking.viewProfileSetting({ location: this.props.location });
  }

  willReceiveProps = false;

  // eslint-disable-line react/sort-comp
  componentWillReceiveProps() {
    this.willReceiveProps = true;
  }

  // shouldComponentUpdate(nextProps) {
  //   if (!this.willReceiveProps) {
  //     return true;
  //   }
  //   this.willReceiveProps = false;
  //   if (nextProps.user.grade === this.props.user.grade) {
  //     return false;
  //   }
  //   return true;
  // }

  componentWillUpdate(nextProps) {
    this.initData(nextProps.user);
  }

  initData = (user) => {
    this.initialData = {
      fullname: (user.fullname !== user.nickname) ? user.fullname : '',
      grade: +user.grade,
      avatar: user.avatar,
      promoCode: '',
    };
  }

  handleChangePromoCode = (e) => {
    this.setState({ promoCode: e.target.value.trim() });
  }

  handleSubmitPromoCode = (e) => {
    e.preventDefault();

    if (!this.state.promoCode) {
      return false;
    }
    this.props.onRedeemPromoCode(this.state.promoCode).then(
      (data) => {
        logger.debug(data.payload.result);
        if (data.payload.result.is_valid) {
          this.setState({ redeemPromoCodeSuccessInfo: data.payload.result });
        } else {
          this.setState({ promoError: data.payload.result.message });
        }
      }
    );
  }

  handelClosePromoCodeErrorMsg = () => {
    this.setState({ promoError: '' });
  }

  handelClosePromoCodeSuccessMsg = () => {
    this.setState({ redeemPromoCodeSuccessInfo: null });
  }

  handleChangeName = (e) => {
    this.setState({ fullname: e.target.value });
  }

  handleToggleGradeList = () => {
    this.setState({ showGradeList: !this.state.showGradeList });
  }

  handleChangeGrade = (grade) => {
    this.setState({
      grade,
      showGradeList: !this.state.showGradeList,
    });
  }

  handleSubmitProfileInfo = () => {
    if (!Object.keys(this.state.errors).length) {
      const { fullname, grade } = this.state;
      const uploadData = { grade };
      if (fullname && fullname !== this.initialData.fullname) {
        uploadData.fullname = fullname;
      }
      this.props.onUpdateProfile(uploadData).then(
        (data) => {
          Tracking.setGrade({ grade });
          Tracking.profileUpdated({
            status: 'completed',
            profile_item: 'name',
            previous_value_exists: !!this.initialData.fullname,
          });
          this.props.onHotUpdateProfile(data.payload.result);
          this.notifySuccess('Your profile was updated successfully!');
        }
      );
    }
  }

  handleChangeAvatar = (avatarUrl) => {
    this.setState({ avatar: avatarUrl });
    if (avatarUrl !== this.initialData.avatar && avatarUrl.startsWith('data:image/jpeg;base64,')) {
      const uploadData = { file: dataURItoBlob(avatarUrl) };
      this.props.onUpdateProfile(uploadData).then(
        (data) => {
          this.props.onHotUpdateProfile(data.payload.result);
          this.notifySuccess('Your avatar was updated successfully!');
          Tracking.profileUpdated({
            status: 'completed',
            profile_item: 'image',
            previous_value_exists: !!this.initialData.avatar,
          });
        }
      );
    }
  }

  notifySuccess = (message) => {
    toast.success(() => (
      <div className="u-flex u-flexGrow1">
        <div className="u-marginRightExtraSmall">
          <Icon name="checkmarkCircle" size="medium" />
        </div>
        <div className="u-flexGrow1">
          <div className="u-fontMedium u-marginBottomExtraSmall">Success</div>
          <div>{message}</div>
        </div>
      </div>
    ));
  }

  handleShowLogoutConfirmModal = () => {
    this.setState({ showLogout: true });
  }

  handleHideLogoutConfirmModal = () => {
    this.setState({ showLogout: false });
  }

  handleLogOut = () => {
    Tracking.logout();
    this.props.logOut();
    AccessToken.setAuthKey('');
  }

  handleChangePassword = () => {
    this.setState({ showChangingPasswordConfrimation: true });
  }

  handleCloseChangingPasswordConfirmation = () => {
    this.setState({ showChangingPasswordConfrimation: false });
  }

  handleAcceptChangePassword = () => {
    this.setState({ showChangingPasswordConfrimation: false });
    this.props.doRequestPasswordRecovery('').then(
      (data) => {
        if (data.payload.result) {
          this.setState({ requestPasswordRecoverySuccess: true });
        }
      }
    );
  }

  handleCloseRPRS = () => {
    this.setState({ requestPasswordRecoverySuccess: false });
  }

  handleShowPrivacySettings = () => {
    this.setState({ showPrivacySettings: true });
  }

  handleHidePrivacySettings = () => {
    this.setState({ showPrivacySettings: false });
  }

  render() {
    const { showGradeList, showPrivacySettings, grade } = this.state;
    let gradeTitle = '';
    config.grades.forEach((gradeObj) => {
      if (+gradeObj.id === +this.state.grade) {
        gradeTitle = gradeObj.title;
      }
    });

    const { user } = this.props;

    let page;

    if (showPrivacySettings) {
      page = <PrivacyCenter handleGoBack={this.handleHidePrivacySettings} />;
    } else {
      page = (
        <>
          <ToastContainer />

          <img src={require('assets/images/settings-cover.svg')} alt="" className="u-widthFull u-objectCover" />

          <div className="Container Container--fluid u-positionRelative" style={{ marginTop: -110 }}>
            <div
              className="u-marginHorizontalAuto u-paddingTopMedium u-paddingBottomExtraLarge"
              style={{ width: '100%', maxWidth: 512 }}
            >
              <div
                className="u-widthHuge u-heightHuge u-border u-borderMedium u-roundedCircle u-marginHorizontalAuto u-positionRelative"
                style={{ boxSizing: 'content-box', borderColor: 'white' }}
              >
                <Avatar
                  src={this.state.avatar}
                  size="huge"
                  alt="Your Avatar"
                />
                {!hasCoppaSupport(user.enabled_features) && (
                  <AvatarUploader onChangeAvatar={this.handleChangeAvatar} />
                )}

              </div>
              {!hasCoppaSupport(user.enabled_features) && (
              <div className="u-border u-roundedMedium u-paddingSmall u-marginTopSmall">
                <h2 className="u-text300 u-fontMedium">Promo Code</h2>
                <Form onSubmit={this.handleSubmitPromoCode}>
                  <Form.Group controlId="settings.promo">
                    <Form.Label>Enter your Promo Code</Form.Label>
                    <Form.Input
                      type="text"
                      placeholder="Promo code here"
                      isInvalid={!!this.state.promoError}
                      onChange={this.handleChangePromoCode}
                      className="u-textUppercase"
                    />
                    <Form.Feedback type="invalid" visible={!!this.state.promoError}>{this.state.promoError}</Form.Feedback>
                  </Form.Group>
                  <Button
                    variant="primary_outline"
                    width="full"
                    onClick={this.handleSubmitPromoCode}
                    disabled={!this.state.promoCode}
                  >
                    Use promo code
                  </Button>
                </Form>
              </div>
              )}

              <div className="u-border u-roundedMedium u-paddingSmall u-marginTopSmall">
                <h2 className="u-text300 u-fontMedium">Profile</h2>
                {!hasCoppaSupport(user.enabled_features) && (
                <Form.Group controlId="settings.name">
                  <Form.Label>Name</Form.Label>
                  <Form.Input
                    type="text"
                    placeholder="Add your name"
                    value={this.state.fullname}
                    onChange={this.handleChangeName}
                  />
                </Form.Group>
                )}
                {user.phone_number && (
                  <Form.Group controlId="settings.phone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Input type="text" value={user.phone_number} readOnly />
                  </Form.Group>
                )}
                {user.email && !hasCoppaSupport(user.enabled_features) && (
                  <Form.Group controlId="settings.email">
                    <Form.Label>Email</Form.Label>
                    <Form.Input type="text" value={user.email} readOnly />
                  </Form.Group>
                )}
                {hasCoppaSupport(user.enabled_features) && (
                  <Form.Group controlId="settings.email">
                    <Form.Label>USER ID</Form.Label>
                    <Form.Input type="text" value={user.preset_id} readOnly />
                  </Form.Group>
                )}
                <Form.Group controlId="settings.grade">
                  <Form.Label>Grade</Form.Label>
                  <Dropdown
                    onToggle={this.handleToggleGradeList}
                    onKeyPress={this.handleToggleGradeList}
                    show={showGradeList}
                    drop="up"
                    aria-label="Select grade"
                    tabIndex={0}
                  >
                    <Dropdown.Toggle className="u-textLight u-lineHeightNone">
                      <div className="FormInput u-flex u-widthFull u-webkitScrollbar Dropdown-toggle u-cursorPointer u-textLight u-lineHeightNone">
                        <div className="u-textDark u-flexGrow-1 u-flex u-alignItemsCenter">
                          {gradeTitle}
                        </div>
                        <div className="u-flexShrink-0">
                          <Icon name="arrowDown" size="extraSmall" />
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Container className="u-paddingVerticalExtraSmall u-widthFull">
                      {config.grades.map(({ id, title }) => (
                        <div
                          key={id}
                          onClick={() => this.handleChangeGrade(id)}
                          onKeyPress={() => this.handleChangeGrade(id)}
                          className={`${+id === +grade ? 'u-fontBold' : ''} u-flex u-alignItemsCenter u-paddingHorizontalSmall u-paddingVerticalTiny hover:u-backgroundLightest u-cursorPointer`}
                          tabIndex={0}
                        >
                          {title}
                        </div>
                      ))}
                    </Dropdown.Container>
                  </Dropdown>
                </Form.Group>
                <Button
                  variant="primary_outline"
                  width="full"
                  onClick={this.handleSubmitProfileInfo}
                >
                  Save
                </Button>

                {hasTrioSupport(user.enabled_features) && !hasCoppaSupport(user.enabled_features) && (
                  <Button
                    variant="link"
                    width="full"
                    className="u-marginTopSmall"
                    onClick={this.handleChangePassword}
                  >
                    Change password
                  </Button>
                )}
              </div>

              <div className="u-border u-roundedMedium u-paddingSmall u-marginTopSmall">
                <h2 className="u-text300 u-fontMedium">Other Settings</h2>
                <Button
                  variant="primary_outline"
                  width="full"
                  onClick={this.handleShowPrivacySettings}
                >
                  Privacy settings
                </Button>
                <Button
                  variant="link"
                  width="full"
                  className="u-marginTopSmall u-textNegative hover:u-textNegativeDark"
                  onClick={this.handleShowLogoutConfirmModal}
                >
                  Log out
                </Button>
              </div>
            </div>
          </div>

          {this.state.redeemPromoCodeSuccessInfo && (
            <ModalRedeemPromoCodeSuccess
              info={this.state.redeemPromoCodeSuccessInfo}
              onClose={this.handelClosePromoCodeSuccessMsg}
            />
          )}

          {this.state.showLogout && (
            <ModalLogout
              onClose={this.handleHideLogoutConfirmModal}
              onLogout={this.handleLogOut}
            />
          )}

          {this.state.showChangingPasswordConfrimation && (
            <ModalChangingPasswordConfirm
              onClose={this.handleCloseChangingPasswordConfirmation}
              onAcceptChangePassword={this.handleAcceptChangePassword}
            />
          )}

          {this.state.requestPasswordRecoverySuccess && (
            <RPRS onClose={this.handleCloseRPRS} />
          )}
        </>
      );
    }


    return (
      <>
        <h1 className="sr-only">Update your profile</h1>
        {page}
        <QuestionActiveBar />
      </>
    );
  }
}

Setting.propTypes = {
  location: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
  onRedeemPromoCode: PropTypes.func.isRequired,
  onHotUpdateProfile: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  doRequestPasswordRecovery: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onRedeemPromoCode: code => dispatch(redeemPromoCode(code)),
  onHotUpdateProfile: data => dispatch(hotUpdateProfile(data)),
  logOut: () => dispatch(logOut()),
  doRequestPasswordRecovery: email => dispatch(requestPasswordRecovery(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
