import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from 'components/Common/Modal';

class ModalChangingPasswordConfirm extends React.Component {

  handleClose = () => {
    this.props.onClose();
  }

  handleAcceptChangePassword = () => {
    this.props.onAcceptChangePassword();
  }

  render() {
    return (
      <ModalBase
        headerText="Reset password"
        size="small"
        onHide={this.handleClose}
        body={(
          <>
            <img src={require('assets/images/keychain.svg')} alt="" className="u-marginHorizontalAuto u-block u-marginBottomSmall" />
            <p className="u-marginBottomNone">Are you sure you want to reset your password?</p>
          </>
        )}
        footerType="vertical-double"
        primaryButtonText="Yes"
        onClickPrimaryButton={this.handleAcceptChangePassword}
        secondaryButtonText="No"
        onClickSecondaryButton={this.handleClose}
      />
    );
  }
}

ModalChangingPasswordConfirm.propTypes = {
  onAcceptChangePassword: PropTypes.func.isRequired
};

export default ModalChangingPasswordConfirm;
