import { run } from './promise';
import { get, post } from '../utils/request';

import {
  BRAINTREE_TOKEN_GET,
  PAYMENT_METHOD_GET,
  PACKAGE_BUY,
  DEFAULT_METHOD_SET,
  SUBSCRIPTION_UPDATE,
} from '../constants/storefront.constant';

export const getBraintreeToken = () => (
  run(
    BRAINTREE_TOKEN_GET,
    get('/payment/clienttoken')
  )
);

export const getPaymentMethod = () => (
  run(
    PAYMENT_METHOD_GET,
    get('/payment/methods')
  )
);

export const buyPackage = (productId) => (
  run(
    PACKAGE_BUY,
    post('/payment/buycredit', { product_id: productId })
  )
);

export const setDefaultMethod = (nonce) => (
  run(
    DEFAULT_METHOD_SET,
    post('/payment/method', { nonce, make_default: 1 })
  )
);

export const updateSubScriptionPackage = (productId, enable) => (
  run(
    SUBSCRIPTION_UPDATE,
    post('/payment/auto', { product_id: productId, enabled: enable })
  )
);
