import React from 'react';
import PropTypes from 'prop-types';
import { modal } from '../../../utils/modal';

class School extends React.Component {
  constructor(props) {
    super(props);
    this.state = { school: this.props.enteredSchool };
  }

  handleChangeSchool = (e) => {
    this.setState({ school: e.target.value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onEnterSchool(this.state.school);
  }

  handleBack = (e) => {
    e.preventDefault();
    this.props.onBackToGrade(this.state.school);
  }

  handleSkip = (e) => {
    e.preventDefault();
    this.props.onEnterSchool('');
  }

  render() {
    return (
      <div>
        <div className="gi-Modal-header">
          <span
            className="u-colorNeutral600 u-floatLeft u-linkFake"
            onClick={this.handleBack}
          >
            <u>
              Back
            </u>
          </span>
          <span
            className="u-colorNeutral600 u-floatRight u-linkFake"
            onClick={this.handleSkip}
          >
            <u>
              Skip
            </u>
          </span>
          <div className="gi-Modal-title u-textCenter u-marginTop5">
            Help us match you with the
            <br />
            right Experts for you
          </div>
        </div>
        <div className="gi-Modal-body">
          <div className="field u-marginTop3">
            <label className="label" htmlFor="true">
              School
            </label>
            <div className="control">
              <input
                className="input"
                placeholder="School Name"
                defaultValue={this.state.school}
                onChange={this.handleChangeSchool}
              />
            </div>
          </div>
        </div>
        <div className="gi-Modal-footer">
          <button
            className="gi-Button gi-Button--primary u-sizeFull"
            onClick={this.handleSubmit}
            type="button"
            disabled={!this.state.school}
          >
            Continue
          </button>
        </div>
      </div>
    );
  }
}

School.propTypes = {
  enteredSchool: PropTypes.string,
  onEnterSchool: PropTypes.func.isRequired,
  onBackToGrade: PropTypes.func.isRequired
};

School.defaultProps = {
  enteredSchool: ''
};

export default modal(School, { size: 'small' });