import React from 'react';
import PropTypes from 'prop-types';

import AccountKitEmail from './AccountKitEmail';


class AvailableVersion extends React.Component {

  handleStartSignupAccountKitEmail = (accessToken) => {
    this.props.onSignup(accessToken);
  }

  handleLoggedinAccountKitEmail = (auth) => {
    this.props.onAuthenticated(auth);
  }

  handleLoginAccountKitEmailError = (data, errorCode, info) => {
    this.props.onError(data, errorCode, info);
  }

  render() {
    return (
      <section className="gi-landingSection4">
        <div className="gi-Container">
          <div className="gi-Grid gi-Grid--alignMiddle">
            <div className="gi-Grid-cell u-md-size3of12 u-flexAlignSelfStart gi-sectionScreenshot1">
              <img src="https://via.placeholder.com/500" />
            </div>
            <div className="gi-Grid-cell u-md-size6of12 gi-sectionContent">
              <p className="h1 u-marginBottom5 u-textCenter">One of the Top 10 Education Apps on App store Get your free session - try it&nbsp;now!</p>
              <div className="gi-landingB-downloadBlock">
                <AccountKitEmail
                  fullSize={true}
                  buttonText={"ASK ON YOUR LAPTOP"}
                  onSignup={this.handleStartSignupAccountKitEmail}
                  onAuthenticated={this.handleLoggedinAccountKitEmail}
                  onError={this.handleLoginAccountKitEmailError}
                />
                <div className="u-marginTopBottom3"><img src="https://via.placeholder.com/500" className="u-sizeFull" alt="or" /></div>
                <div className="gi-Grid gi-Grid--fit gi-Grid--withGutter">
                  <div className="gi-Grid-cell">
                    <a href="https://play.google.com/store/apps/details?id=co.gotitapp.android" className="gi-appDownloadItem" target="_blank"><img src="https://via.placeholder.com/500" className="u-block" alt="Get it on Google Play" /></a>
                  </div>
                  <div className="gi-Grid-cell">
                    <a href="https://itunes.apple.com/app/apple-store/id797535508?mt=8" className="gi-appDownloadItem" target="_blank"><img src="https://via.placeholder.com/500" className="u-block" alt="Download on the App Store" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="gi-Grid-cell u-md-size3of12 u-flexAlignSelfEnd gi-sectionScreenshot2">
              <img src="https://via.placeholder.com/500" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}


AvailableVersion.propTypes = {
  onSignup: PropTypes.func.isRequired,
  onAuthenticated: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AvailableVersion;
