export const ERROR_CODE_TRIO_BLOCKED = 'trio_blocked';
export const ERROR_CODE_DEVICE_CHECK = 'device_register_multi_account';
export const ERROR_CODE_PROHIBITED = 'prohibited';
export const ERROR_CODE_DELETED_PROFILE = 'deleted_profile';

export const ERROR_CODE_WRITING_FILE_SIZE_TOO_LARGE = 'FILE_SIZE_TOO_LARGE';
export const ERROR_CODE_WRITING_FILE_TYPE_INCORRECT = 'FILE_TYPE_INCORRECT';
export const ERROR_CODE_WRITING_GOOGLE_DOC_LINK_INCORRECT = 'GOOGLE_DOC_LINK_INCORRECT';
export const ERROR_CODE_WRITING_PRIVACY_SETTINGS_INCORRECT = 'PRIVACY_SETTINGS_INCORRECT';

export const ERROR_CODE_WRITING_INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE';
export const ERROR_CODE_WRITING_SESSION_IN_PROGRESS = 'SESSION_IN_PROGRESS';
export const ERROR_CODE_WRITING_INVALID_DESCRIPTION_CONTENT = 'INVALID_DESCRIPTION_CONTENT';

/**
 * Context: when a school purchases a plan then cancels it, its students
 * will be force logged out. When they try to log back in, they will receive
 * this message from whitelist email check.
 */
export const ERROR_MESSAGE_SCHOOL_PLAN_CHANGED = 'Your school has updated your subscription. Please contact your school administrator';
