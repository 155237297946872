import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form, Button } from '@ahaui/react';

import moment from 'moment';

import Tracking from 'utils/tracking';
import {
  AUTH_METHOD_EMAIL,
} from 'constants/app.constant';

import {
  requestOneTimeCode,
  verifyOneTimeCode,
} from '../../../actions/user.action';

class OneTimeCode extends React.Component {
  state = {
    code: '',
    errorMessage: '',
    submitting: false,
  };

  onError(apiError) {
    this.setState({ errorMessage: typeof apiError.data === 'string' ? apiError.data : apiError.data.message });
  }

  handleChangeCode = (e) => {
    this.setState({ code: e.target.value });
  }

  continueAuthentication = () => {
    const { code } = this.state;

    const {
      email, doVerifyOneTimeCode, onAuthenticated, onTryPreset, onSignup, registered,
    } = this.props;

    this.setState({ submitting: true });

    doVerifyOneTimeCode(email, code).then(
      (data) => {
        const { result } = data.payload;
        const { error } = data.payload;
        if (result) {
          onAuthenticated(result.auth, result.user);
        } else if (error && error.data) {
          const { info } = error.data;

          if (info) {
            if (info.is_whitelisted) onTryPreset(info);
            else if (info.error_code === 'profile_not_found') {
              onSignup(info.code);
              return;
            } else this.onError(error.data);
          } else {
            this.onError(error.data);
          }

          if (registered) {
            const now = new Date();

            Tracking.signup(false, {
              signup_method: AUTH_METHOD_EMAIL,
              created: moment.unix(now.getTime() / 1000).toISOString(),
              signup_liquidity_lever_enabled: 'NO',
              message: error.data.data,
            });
          } else Tracking.login(false, { login_method: AUTH_METHOD_EMAIL, message: error.data.data });
          logger.debug('Login failed', error);
        }
        this.setState({ submitting: false });
      }
    );
  }

  render() {
    const { errorMessage, submitting, code } = this.state;
    const { registered } = this.props;

    const continueBtnTitle = registered ? 'LOG IN NOW' : 'SIGN UP NOW';
    return (
      <>
        <Form.Group controlId="login.code">
          <Form.Label>Verification code</Form.Label>
          <Form.Input
            isInvalid={!!errorMessage}
            type="text"
            placeholder="Code"
            value={code}
            onChange={this.handleChangeCode}
          />
          <Form.Feedback type="invalid" visible={!!errorMessage} role="alert">
            {errorMessage}
          </Form.Feedback>
        </Form.Group>

        <div className="u-paddingTopExtraSmall">
          <Button
            width="full"
            disabled={submitting || !code}
            onClick={this.continueAuthentication}
          >
            <Button.Label>{continueBtnTitle}</Button.Label>
          </Button>
        </div>
      </>
    );
  }
}

OneTimeCode.propTypes = {
  email: PropTypes.string.isRequired,
  registered: PropTypes.bool.isRequired,
  doRequestOneTimeCode: PropTypes.func.isRequired,
  doVerifyOneTimeCode: PropTypes.func.isRequired,
  onSignup: PropTypes.func.isRequired,
  onAuthenticated: PropTypes.func.isRequired,
  onTryPreset: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  doVerifyOneTimeCode: (email, code) => dispatch(verifyOneTimeCode(email, code)),
  doRequestOneTimeCode: (email, code) => dispatch(requestOneTimeCode(email, code)),
});

const OneTimeCodeContainer = connect(null, mapDispatchToProps)(OneTimeCode);
export default withRouter(OneTimeCodeContainer);
