import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Icon } from '@ahaui/react';

class AutoBidBar extends React.Component {

  getAutobidEnabled = (selectedSubject, autobidStatus) => {
    let enabled = false;
    if (selectedSubject == null) {
      enabled = false;
      return enabled;
    }
    autobidStatus.forEach(autobidSt => {
      if (autobidSt.status == 1 && autobidSt.sid == selectedSubject.parent_tid) {
        enabled = true;
      }
    });
    return enabled;
  }

  render() {
    const { selectedSubject, autobidStatus } = this.props.askflow;
    let isAutoBidEnabled = this.getAutobidEnabled(selectedSubject, autobidStatus);

    if (!isAutoBidEnabled) {
      return null;
    }

    return (
      <div className="BottomBar u-positionFixed u-positionLeft u-positionRight u-positionBottom u-zIndex4">
        <div className="u-flex u-paddingVerticalExtraSmall u-paddingHorizontalSmall u-backgroundInformationLighter u-text200 u-alignItemsCenter u-justifyContentCenter">
          <Icon name="flash" size="extraSmall" className="u-marginRightExtraSmall" />
          5 seconds to connect to Expert
        </div>
      </div>
    );
  }
}


AutoBidBar.propTypes = {
  askflow: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  askflow: state.askflow,
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(AutoBidBar);
