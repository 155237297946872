import { maintenance, forceUpgrade, triggerForceLogoutModal } from './app.action';
import { forceLogout } from './user.action';
import {
  SYSTEM_STATUS_MAINTENANCE,
  SYSTEM_STATUS_UPGRADE
} from '../constants/app.constant';

let nonce = 0;
export default () => (next) => (action) => {
  if (!action.promise) {
    return next(action);
  }
  const { promise, skipLoadingState, ...rest } = action;
  if (!skipLoadingState) {
    nonce++;
    next({ ...rest, payload: { loading: true, nonce } });
  }
  nonce++;
  return action.promise.then(
    (result) => next({ ...rest, payload: { loading: false, result, nonce } }),
    (error) => {
      if (error.name && error.name === 'ServerAPIError') {
        if (error.status === 503 && error.data === SYSTEM_STATUS_MAINTENANCE) {
          return next({ ...maintenance(), payload: { loading: false, error, nonce } });
        }
        if (error.status === 503 && error.data === SYSTEM_STATUS_UPGRADE) {
          return next({ ...forceUpgrade(), payload: { loading: false, error, nonce } });
        }
        if (error.status === 401 && error.data.data?.modal) {
          return next(triggerForceLogoutModal(error.data.data.modal));
        }
        if (error.status === 401) {
          return next({ ...forceLogout(), payload: { loading: false, error, nonce } });
        }
      }
      return next({ ...rest, payload: { loading: false, error, nonce } });
    },
  );
};
