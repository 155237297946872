import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ModalBase from 'components/Common/Modal';

class ModalRedeemPromoCodeSuccess extends React.Component {
  static propTypes = {
    info: PropTypes.shape().isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    logger.debug(props);
  }

  handleClose = () => {
    this.props.onClose();
  }

  handleClickCTA = () => {
    this.props.history.push(this.props.info.cta.action);
  }

  render () {
    const { img_url, message, cta } = this.props.info;
    return (
      <ModalBase
        size="medium"
        onHide={this.handleClose}
        body={(
          <>
            <img src={img_url} alt="" className="u-widthFull u-marginBottomSmall" />
            <p className="u-marginBottomNone u-textCenter">{message}</p>
          </>
        )}
        primaryButtonText={cta.text}
        onClickPrimaryButton={this.handleClickCTA}
      />
    );
  }
}

export default withRouter(ModalRedeemPromoCodeSuccess);
