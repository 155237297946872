import React from 'react';
import PropTypes from 'prop-types';

export default class TrioBlockedError extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired
  }

  handleClose = () => {
    this.props.onClose();
  }
  render () {
    return (
      <div>
        <div className="gi-Modal-header">
          <div className="gi-Modal-close" onClick={this.handleClose}><span>&times;</span></div>
        </div>
        <div className="gi-Modal-body">
          <div className="u-textCenter u-marginBottom3"><img src="https://via.placeholder.com/500" alt="" className="u-inlineBlock" /></div>
          <p className="u-fontSizeMPlus u-marginBottom4">Oops! You already have a preset account. Currently, preset accounts can only be used on the mobile app. Please download the app below</p>
          <div className="gi-Grid gi-Grid--fit gi-Grid--withGutter u-paddingLeftRight5 u-marginBottom3">
            <div className="gi-Grid-cell">
              <a
                href="https://play.google.com/store/apps/details?id=co.gotitapp.android"
                className="gi-appDownloadItem"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://via.placeholder.com/500" className="u-block" alt="Get it on Google Play" />
              </a>
            </div>
            <div className="gi-Grid-cell">
              <a
                href="https://itunes.apple.com/app/apple-store/id797535508?mt=8"
                className="gi-appDownloadItem"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://via.placeholder.com/500" className="u-block" alt="Download on the App Store" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
