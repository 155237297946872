import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AccountKitEmail from './AccountKitEmail';


class Intro extends React.Component {

  handleStartSignupAccountKitEmail = (accessToken) => {
    this.props.onSignup(accessToken);
  }

  handleLoggedinAccountKitEmail = (auth) => {
    this.props.onAuthenticated(auth);
  }

  handleLoginAccountKitEmailError = (data, errorCode, info) => {
    this.props.onError(data, errorCode, info);
  }

  render() {
    return (
      <section className="gi-landingBSection1" >
        <div className="gi-Container">
          <div className="navbar-brand">
            <Link className="navbar-brandLogo" to="/">
              PhotoStudy
            </Link>
          </div>
        </div>
        <div className="gi-Container">
          <div className="gi-Grid gi-Grid--withGutter gi-Grid--alignCenter">
            <div className="gi-Grid-cell u-sizeFull u-md-size1of2">
              <h1 className="u-marginBottom4">Get the Right Solution.<br /><span className="u-colorPrimary">Instantly!</span></h1>
              <p className="u-colorNeutral700 u-fontSizeMdPlus u-marginBottom5">Get Step-by-Step chat explanations to help you with your homework in Math, Physics and Chemistry from live experts</p>
              <div>
                <AccountKitEmail
                  buttonText={"Get a free solution"}
                  onSignup={this.handleStartSignupAccountKitEmail}
                  onAuthenticated={this.handleLoggedinAccountKitEmail}
                  onError={this.handleLoginAccountKitEmailError}
                />
              </div>
              <div className="gi-landingBTestimonial">
                <div className="u-flex">
                  <div className="u-sizeFit">
                    <img className="u-imageCircle u-marginRight4" src="https://via.placeholder.com/500" width="90" height="90" alt="" />
                  </div>
                  <div className="u-sizeFull">
                    <p className="u-fontSizeMdPlus u-marginBottom2"><em>My Calculus grade last semester went from a low B to an A- and I couldn’t have done it without Got&nbsp;It.</em></p>
                    <div className="u-fontSizeSm u-colorSecondary">Hollie B -  Student at U of Arkansas</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gi-Grid-cell u-sizeFull u-md-size1of2">
              <div className="gi-landingBCover">
                <div className="gi-landingBCoverPhoto"></div>
              </div>
            </div>
          </div>
        </div>
      </section >
    );
  }
}

Intro.propTypes = {
  onSignup: PropTypes.func.isRequired,
  onAuthenticated: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export default Intro;
