import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { SafeAnchor, Avatar } from '@ahaui/react';
import { timeAgo } from 'utils/util';
import expertAvatar from 'assets/images/expert.svg';
import gotitAvatar from 'assets/images/gotit.svg';

export class NotiDetail extends React.Component {
  componentWillMount() {
    this.notiObj = this.buildNoti(this.props.notification);
  }

  flagReasons = {
    1: 'it contains multiple questions.',
    2: 'there was not enough information, please try again.',
    3: 'the question was not in English.',
    4: 'it was marked as spam.',
    5: 'it was not tagged with the appropriate subject. Please try again.',
    6: 'it was posted from an exam.',
    7: 'the image was not clear. Please try again.',
    8: 'it was not tagged with the appropriate subject. Please try again.',
  }

  gotoQuestion = (qid, action = '', search = null) => {
    const { history } = this.props;
    let nextAction = action ? `-${action}` : '';
    if (search) {
      const searchStr = Object.keys(search).map(key => (
        `${encodeURIComponent(key)}=${encodeURIComponent(search[key])}`
      )).join('&');
      nextAction = `${nextAction}?${searchStr}`;
    }
    history.push(`/my-sessions/${qid}${nextAction}`);
  }

  gotoPayment = () => {
    history.push('/storefront');
  }

  buildNoti = (noti) => {
    const {
      qid, action, actor_name,
    } = noti.raw_data;
    const notiObj = {
      nid: +noti.nid,
      read: !!+noti.read,
      created: +noti.created,
      actorAvatarUrl: gotitAvatar,
      text: '',
      handleClick: () => {},
    };

    /* eslint-disable brace-style */
    if (action === 'add_answer') {
      notiObj.text = `${actor_name} has provided a step-by-step explanation for you.`;
      notiObj.actorAvatarUrl = expertAvatar;
      notiObj.handleClick = () => this.gotoQuestion(qid);
    }
    else if (action === 'claim_question') {
      notiObj.text = `${actor_name} is working on your problem now. Tap to go to your chat session.`;
      notiObj.actorAvatarUrl = expertAvatar;
      notiObj.handleClick = () => this.gotoQuestion(qid);
    }
    else if (action === 'chat_extended') {
      const { message_id, response_id } = noti.raw_data;
      notiObj.text = 'Whoa...your chat session is ending! To extend it, tap to return to the session.';
      notiObj.handleClick = () => this.gotoQuestion(qid, 'chat_extension', { mid: message_id, response_id });
    }
    else if (action === 'question_rejected') {
      let { flag } = noti.raw_data;
      flag = +flag;
      notiObj.text = 'Your session has been flagged.';
      if (flag > 0 && flag <= 8) {
        notiObj.text = `Your session has been removed because ${this.flagReasons[flag]}`;
      }
      notiObj.handleClick = () => this.gotoQuestion(qid);
    }
    else if (action === 'referral_register') {
      let { amount, role } = noti.raw_data;
      amount = parseFloat(amount);
      if (role === 'referee') {
        notiObj.text = `You signed up with a friend's promo code! We've already put ${amount} sessions in your account!`;
      } else if (amount > 0) {
        notiObj.text = `A friend registered with your referral code! You've just earned ${amount} sessions!`;
      } else {
        notiObj.text = 'Your friend just signed up for PhotoStudy. Just one more step before you get free sessions.';
      }
      notiObj.handleClick = this.gotoPayment;
    }
    else if (action === 'credit_gifted') {
      const { amount } = noti.raw_data;
      notiObj.text = `You've just been gifted ${amount} sessions from someone you know!`;
      notiObj.handleClick = this.gotoPayment;
    }
    else if (action === 'problem_routed') {
      const { expert_name } = noti.raw_data;
      if (expert_name) {
        notiObj.text = `${expert_name} is grading your problem.`;
      } else {
        notiObj.text = 'An expert is grading your problem.';
      }
      notiObj.actorAvatarUrl = expertAvatar;
    }
    else if (action === 'problem_graded') {
      const { expert_name } = noti.raw_data;
      if (expert_name) {
        notiObj.text = `${expert_name} is grading your problem.`;
      } else {
        notiObj.text = 'An expert is grading your problem.';
      }
      notiObj.actorAvatarUrl = expertAvatar;
    }
    else if (action === 'problem_flagged') {
      notiObj.text = 'Your session has been flagged.';
    }
    else if (action === 'problem_dead') {
      notiObj.text = 'Your problem was not graded.';
    }
    else if (action === 'credits_converted') {
      const { credits_count, sessions_count } = noti.raw_data;
      if (sessions_count === '1') {
        notiObj.text = `We just converted ${credits_count} credit rewards to ${sessions_count} session.`;
      } else {
        notiObj.text = `We just converted ${credits_count} credit rewards to ${sessions_count} sessions.`;
      }
      notiObj.handleClick = this.gotoPayment;
    }
    /* eslint-enable */
    return notiObj;
  }

  render() {
    if (!this.notiObj.text) {
      return '';
    }

    return (
      <div>
        <SafeAnchor
          onClick={this.notiObj.handleClick}
          className={`u-flex u-alignItemsCenter u-borderBottom u-borderLightest u-paddingSmall u-text200 hover:u-textDecorationNone ${this.notiObj.read ? 'u-backgroundWhite' : 'u-backgroundLightest'}`}
          title=""
        >
          <div className="u-flexShrink-0">
            <Avatar
              src={this.notiObj.actorAvatarUrl}
              alt=""
              size="large"
            />
          </div>
          <div className="u-flexGrow-1 u-paddingHorizontalSmall">
            <div className="u-textDark">{this.notiObj.text}</div>
            <div className="u-textDark u-opacityHalf">{timeAgo(this.notiObj.created)}</div>
          </div>
        </SafeAnchor>
      </div>
    );
  }
}

NotiDetail.propTypes = {
  notification: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};

export default withRouter(NotiDetail);
