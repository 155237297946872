import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from '@ahaui/react';

class SubcriptionPackage extends React.Component {
  doEnable = () => {
    logger.debug('on handleEnable, package:', this.props.pkg);
    this.props.onEnable(this.props.pkg);
  }

  doDisable = () => {
    logger.debug('on handleDisable, package:', this.props.pkg);
    this.props.onDisable(this.props.pkg);
  }

  handleChange = (e) => {
    const { toggle } = this.props.pkg;

    logger.debug('on handleChange');
    e.preventDefault();
    if (+toggle === 0) {
      this.doEnable();
    }
    if (+toggle === 1) {
      this.doDisable();
    }
  }

  render() {
    const { pkg } = this.props;

    return (
      <div className="u-flex u-alignItemsCenter">
        <div className="u-flexGrow1">
          <h2 className="u-text200 u-fontMedium u-marginBottomTiny">{pkg.description}</h2>
          <p className="u-text200 u-textGray u-marginNone">{pkg.subdescription}</p>
        </div>
        <Toggle
          nonLabel
          checked={+pkg.toggle === 1}
          onClick={this.handleChange}
        />
      </div>
    );
  }
}

SubcriptionPackage.propTypes = {
  pkg: PropTypes.shape().isRequired,
  onEnable: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
};

export default SubcriptionPackage;
