import {
  PLATFORM_DESKTOP,
  PLATFORM_IOS,
  PLATFORM_ANDROID,
  PLATFORM_WINDOWS_PHONE,
  OS_MAC,
  OS_WINDOWS,
  OS_LINUX,
  OS_IOS,
  OS_ANDROID,
  OS_OTHER,
} from '../constants/app.constant';

import { getSessionStore } from './storage';

const navStorage = getSessionStore('gotit_nav_data');

export const versionCompare = (v1, v2, options) => {
  const lexicographical = options && options.lexicographical;
  const zeroExtend = options && options.zeroExtend;
  let v1parts = v1.split('.');
  let v2parts = v2.split('.');

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push('0');
    while (v2parts.length < v1parts.length) v2parts.push('0');
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number);
    v2parts = v2parts.map(Number);
  }

  for (let i = 0; i < v1parts.length; i += 1) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] !== v2parts[i]) {
      if (v1parts[i] > v2parts[i]) {
        return 1;
      }
      return -1;
    }
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
};

export const getBrowser = () => {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return `IE$${(tem[1] || '')}`;
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  tem = ua.match(/version\/(\d+)/i);
  if (tem != null) {
    M.splice(1, 1, tem[1]);
  }
  return M.join(' ');
};

export const getAppPlatformCode = (userAgent) => {
  if (/windows phone/i.test(userAgent)) {
    return PLATFORM_WINDOWS_PHONE;
  }
  if (/android/i.test(userAgent)) {
    return PLATFORM_ANDROID;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return PLATFORM_IOS;
  }
  return PLATFORM_DESKTOP;
};

export const navSend = (path, data) => {
  navStorage.set({ path, data });
};

export const navReceive = (path, remove = false) => {
  const navData = navStorage.get();
  let ret = navData;
  if (path && navData && navData.path === path) {
    ret = navData.data;
    if (remove) {
      navStorage.remove();
    }
  }

  return ret;
};

export const navClear = (path) => {
  const navData = navStorage.get();
  if (path && navData && navData.path === path) {
    navStorage.remove();
  }
};

export const getOS = () => {
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return OS_ANDROID;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return OS_IOS;
  }
  if (/Macintosh/.test(userAgent)) {
    return OS_MAC;
  }
  if (/Windows/.test(userAgent)) {
    return OS_WINDOWS;
  }
  if (/Linux/.test(userAgent)) {
    return OS_LINUX;
  }
  return OS_OTHER;
};

export const isMobile = () => {
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    // windowns
    return true;
  }
  if (/android/i.test(userAgent)) {
    // Android
    return true;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    // iOS.
    return true;
  }
  return false;
};

export default {
  versionCompare,
  getAppPlatformCode,
  getBrowser,
  getOS,
  navSend,
  navReceive,
  navClear,
};
