import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  loginAccountKit,
} from '../../actions/user.action';

import Tracking from '../../utils/tracking';

class AccountKitEmail extends React.Component {
  onError(apiError) {
    const errorCode = (apiError.info && apiError.info.error_code) ? apiError.info.error_code : '';
    this.props.onError(apiError.data, errorCode, apiError.info);
  }

  handleLogin = (e) => {
    logger.debug('signup with email');
    e.preventDefault();
    Tracking.startAuth({ source: 'accountkit_email' });

    window.AccountKit.login('EMAIL', {}, this.accountKitLoginCallback);
    return false;
  }

  accountKitLoginCallback = (response) => {
    logger.debug('account kit login callback');
    if (response.status === 'PARTIALLY_AUTHENTICATED') {
      this.doLogin('email', response.code);
    }
  }

  doLogin(type, code) {
    this.setState({
      processing: true,
    });
    this.props.doLogin(code).then(
      (data) => {
        const { result } = data.payload;
        const { error } = data.payload;
        if (result) {
          this.props.onAuthenticated(result.auth);
        } else if (error) {
          if (error.data.info
              && error.data.info.error_code
              && error.data.info.error_code === 'profile_not_found') {
            const accessToken = error.data.info.token;
            if (accessToken) {
              this.props.onSignup(accessToken);
            }
          } else {
            this.onError(error.data);
          }
        }
      }
    );
  }

  render() {
    const cl = this.props.fullSize ? 'gi-Button gi-Button--secondary is-large u-sizeFull' : 'gi-Button gi-Button--secondary is-large';
    return (
      <button className={cl} type="button" onClick={this.handleLogin}>
        <span className="gi-Button-label">{this.props.buttonText}</span>
      </button>
    );
  }
}

AccountKitEmail.propTypes = {
  fullSize: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  doLogin: PropTypes.func.isRequired,
  onSignup: PropTypes.func.isRequired,
  onAuthenticated: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

AccountKitEmail.defaultProps = {
  fullSize: null,
};

const mapDispatchToProps = dispatch => ({
  doLogin: token => dispatch(loginAccountKit(token)),
});

const AccountKitEmailContainer = connect(null, mapDispatchToProps)(AccountKitEmail);
export default AccountKitEmailContainer;