import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

class RouteGroup extends React.Component {
  pathMatch = (sourcePath, targetPaths) => {
    for (const path of targetPaths) {
      const r = new RegExp(path);
      if (sourcePath === path || sourcePath.match(r)) {
        return true;
      }
    }
    return false;
  }

  render() {
    const {
      when, location, otherPaths, publicPaths,
    } = this.props;
    const isPublicPage = this.pathMatch(location.pathname, publicPaths);
    const isExistedPage = this.pathMatch(location.pathname, otherPaths);
    // logger.debug('------------------', when, isPublicPage, isExistedPage, location.pathname);
    if (when) {
      if (!isPublicPage) {
        if (!isExistedPage) {
          return this.props.children;
        }
        return <Redirect to="/" />;
      }
    }
    return null;
  }
}

RouteGroup.propTypes = {
  when: PropTypes.bool,
  publicPaths: PropTypes.arrayOf(PropTypes.string),
  otherPaths: PropTypes.arrayOf(PropTypes.string),
};

RouteGroup.defaultProps = {
  when: true,
  publicPaths: [],
  otherPaths: [],
};

export default withRouter(RouteGroup);
