import { PROMISE_RUN } from '../constants/promise.constant';
import defaultState from '../store/defaultState';
import {
  EXPERT_WAITING_SHOW,
  EXPERT_WAITING_HIDE,
  QUESTION_EXTENDED,
  QUESTION_CHECK_OPEN,
  QUESTION_OPEN_GET,
  QUESTION_FLAG,
  CQ_MESSAGE_REPLY,
  MESSAGE_SEND,
  NEW_DISCUSSION_MESSAGE,
  EDIT_DISCUSSION_MESSAGE,
  QUESTION_REPOST,
  FIRST_REGULAR_CLAIMED,
  FIRST_REGULAR_ROUTED,
  REGULAR_CLAIM_MSG_UPDATE,
} from '../constants/chat.constant';

import { QUESTION_SUBMIT } from '../constants/askflow.constant';
import { questionStateConsts } from '../constants/common.constant';

const parseQuestion = (question) => {
  return {
    qid: question.qid,
    title: question.title,
    attachments: question.attachments,
    created: question.created,
    author: question.author,
    claimed_uid: question.claimed_uid,
    expert_name: question.expert_name,
    processing_status: question.processing_status,
    processing_data: question.processing_data,
    discussion_status: question.discussion_status,
    pending_review: question.pending_review,
    discussion_time_limit: parseInt(question.discussion_time_limit),
    discussion_end_time: parseInt(question.discussion_end_time),
    has_autobid: question.meta.has_autobid,
    in_autobid: question.meta.in_autobid,
    lang: question.lang,
  };
};

const chatReducer = (state = defaultState.chat, action) => {
  if (action.type === PROMISE_RUN) {
    if (action.key === QUESTION_CHECK_OPEN) {
      const result = action.payload.result;
      if (result) {
        const can_ask = result.can_ask;
        if (can_ask == 0) {
          return { ...state, qid: result.open_qid };
        } else {
          return { ...state, qid: 0 };
        }
      }
    }
    if (action.key === QUESTION_OPEN_GET) {
      const result = action.payload.result;
      if (result) {
        let meta = result.question.meta;
        let regularRouteShow = false;
        let regularRouteMessages = [];
        let regularClaimShow = false;
        let regularClaimMessage = '';
        if (meta) {
          if (meta.hasOwnProperty('footer_messages')) {
            let footerMessages = meta.footer_messages;
            if (footerMessages.length == 1) {
              regularClaimShow = true;
              regularClaimMessage = footerMessages;
            } else {
              regularRouteShow = true;
              regularRouteMessages = footerMessages;
            }
          }
        }
        return {
          ...state,
          regularClaimShow: regularClaimShow,
          regularClaimMessage: regularClaimMessage,
          regularRouteShow: regularRouteShow,
          regularRouteMessages: regularRouteMessages,
          question: parseQuestion(result.question),
          messages: result.discussion_messages,
          answ: result.answers,
        };
      }
    }
    if (action.key === QUESTION_SUBMIT) {
      const result = action.payload.result;
      if (result) {
        const has_autobid = result.has_autobid;
        let waitingExpertModalShow = true;
        if (has_autobid == 1) {
          waitingExpertModalShow = false;
        }
        return {
          ...state,
          qid: result.qid,
          waitingExpertModal: waitingExpertModalShow,
          regularRouteShow: false,
          regularRouteMessages: [],
          regularClaimShow: false,
          regularClaimMessage: '',
          questionGranted: false,
          question: defaultState.chat.question,
          messages: defaultState.chat.messages,
          answ: defaultState.chat.answers,
        };
      }
    }
    if (action.key === QUESTION_REPOST) {
      const result = action.payload.result;
      if (result) {
        const has_autobid = result.has_autobid;
        let waitingExpertModalShow = true;
        if (has_autobid == 1) {
          waitingExpertModalShow = false;
        }
        return {
          ...state,
          qid: result.qid,
          waitingExpertModal: waitingExpertModalShow,
          regularRouteShow: false,
          regularRouteMessages: [],
          regularClaimShow: false,
          regularClaimMessage: '',
          questionGranted: false,
          question: defaultState.chat.question,
          messages: defaultState.chat.messages,
          answ: defaultState.chat.answers,
        };
      }
    }
    if (action.key === QUESTION_FLAG) {
      const result = action.payload.result;
      if (result) {
        let questionUpdated = {
          ...state.question,
          processing_status: questionStateConsts.FLAGGED,
        };
        return { ...state, question: questionUpdated };
      }
    }
    if (action.key === MESSAGE_SEND) {
      const result = action.payload.result;
      if (result) {
        return state;
      }
    }
    if (action.key === CQ_MESSAGE_REPLY) {
      const result = action.payload.result;
      if (result) {
        const mid = result.mid;
        const id = result.option_id;
        const newMessages = state.messages.map((msg) => {
          if (msg.mid !== mid) {
            return msg;
          }

          const newOptions = msg.options.map((opt) => {
            if (opt.id !== id) {
              return opt;
            }
            return {
              ...opt,
              selected: 1,
            };
          });
          return {
            ...msg,
            options: newOptions,
          };
        });
        return {
          ...state,
          messages: newMessages,
        };
      }
    }
  }

  if (action.type === EXPERT_WAITING_SHOW) {
    return { ...state, waitingExpertModal: true };
  }

  if (action.type === EXPERT_WAITING_HIDE) {
    return { ...state, waitingExpertModal: false };
  }

  if (action.type === QUESTION_EXTENDED) {
    let questionUpdated = {
      ...state.question,
      discussion_end_time: action.discussionEndTime,
      discussion_time_limit: action.discussionTimeLimit,
    };
    return { ...state, question: questionUpdated };
  }

  if (action.type === NEW_DISCUSSION_MESSAGE) {
    let messages = state.messages.concat(action.msg);
    return { ...state, messages };
  }

  if (action.type === EDIT_DISCUSSION_MESSAGE) {
    let editedMsg = action.msg;
    const newMessages = state.messages.map((msg) => {
      if (msg.mid != editedMsg.mid) {
        return msg;
      }
      return editedMsg;
    });
    return {
      ...state,
      messages: newMessages,
    };
  }

  if (action.type === FIRST_REGULAR_ROUTED) {
    let messages = action.data.messages;
    return {
      ...state,
      regularRouteShow: true,
      regularRouteMessages: messages,
      regularClaimShow: false,
    };
  }

  if (action.type === FIRST_REGULAR_CLAIMED) {
    let message = action.data.message;
    return {
      ...state,
      regularClaimShow: true,
      regularClaimMessage: message,
      regularRouteShow: false,
    };
  }

  if (action.type === REGULAR_CLAIM_MSG_UPDATE) {
    return {
      ...state,
      regularClaimMessage: action.msg,
      questionGranted: true,
    };
  }

  return state;
};

export default chatReducer;
