import React from 'react';

const Subjects = (props) => (
  <section className="gi-landingBSection3 u-textCenter">
    <div className="gi-Container">
      <h1 className="u-marginBottom4">From Stuck to PhotoStudy in 10 Minutes<br />Try a Free Session!</h1>
      <p className="u-colorNeutral700 u-marginBottom5 u-paddingBottom5">Our personalized, Expert tutoring help is available 24/7 anytime, anywhere<br /> so you can learn whenever it’s convenient for you.</p>
      <div className="gi-Grid">
        <div className="gi-Grid-cell u-size1of3 item">
          <svg
            className="gi-subjectIcon"
            viewBox="0 0 120 98"
            width="120"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>
              Group 9
            </title>
            <g fill="none" fillRule="evenodd">
              <path
                d="M61.716 97.986c19.388-.43 56.35-25.753 58.1-53.79C121.566 16.162 82.779 0 53.902 0 25.025 0-8.4 8.075 1.912 48.79c3.859 15.239 15.04 26.458 23.113 33.86 13.192 12.098 24.663 15.603 36.69 15.336z"
                fill="#E5E7F1"
              ></path>
              <path
                d="M83.1 25.723a214.79 214.79 0 0 0-2.74 6.467 248.349 248.349 0 0 0-3 7.768 198.505 198.505 0 0 0-2.888 8.436 145.048 145.048 0 0 0-2.444 8.586 95.814 95.814 0 0 0-1.703 8.213c-.42 2.627-.63 5.03-.63 7.21 0 2.478 1.013 3.717 3.037 3.717.889 0 1.938-.458 3.147-1.375 1.21-.917 2.48-2.168 3.814-3.754 1.333-1.586 2.666-3.456 3.999-5.612a60.43 60.43 0 0 0 3.702-7.024h3.259c-1.481 3.617-3.11 6.838-4.888 9.663-1.777 2.824-3.591 5.203-5.443 7.136-1.851 1.932-3.678 3.407-5.48 4.422-1.802 1.016-3.517 1.524-5.146 1.524-2.518 0-4.53-.892-6.036-2.676-1.505-1.784-2.258-4.038-2.258-6.764 0-1.784.185-3.865.555-6.244.37-2.378.877-4.943 1.518-7.693.642-2.75 1.395-5.575 2.259-8.474a290.52 290.52 0 0 1 2.703-8.585 150.904 150.904 0 0 1 2.925-8.065 136.966 136.966 0 0 1 2.925-6.876H52.071L40.815 62.815a118.746 118.746 0 0 1-2.333 6.913c-.814 2.18-1.727 4.088-2.74 5.723-1.012 1.635-2.172 2.936-3.48 3.903-1.308.966-2.826 1.449-4.554 1.449-1.58 0-2.876-.409-3.888-1.227-1.012-.817-1.518-1.994-1.518-3.53 0-.644.099-1.313.296-2.007.198-.694.53-1.413 1-2.156.469-.743 1.11-1.536 1.925-2.378.815-.843 1.84-1.76 3.073-2.75a30.905 30.905 0 0 0 3.185-3.048c.987-1.09 1.962-2.453 2.925-4.089.962-1.635 1.925-3.642 2.888-6.02.963-2.38 2.012-5.328 3.147-8.846l5.998-19.03h-1.036c-3.16 0-5.826.249-7.998.744-2.172.496-3.999 1.165-5.48 2.007-1.481.843-2.703 1.821-3.666 2.936a19.885 19.885 0 0 0-2.48 3.605h-2.666C27.51 23.022 36.273 17.026 49.7 17.026h47.765c-.89 2.081-1.667 3.741-2.333 4.98-.667 1.24-1.308 2.193-1.926 2.862-.617.67-1.258 1.103-1.925 1.3-.666.2-1.444.298-2.333.298-.444 0-1.21-.062-2.295-.186a46.933 46.933 0 0 1-3.555-.557z"
                fill="#4229CC"
              ></path>
            </g>
          </svg>
          <div>
            Algebra
          </div>
        </div>
        <div className="gi-Grid-cell u-size1of3 item">
          <svg
            className="gi-subjectIcon"
            viewBox="0 0 120 98"
            width="120"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>
              Group 17
            </title>
            <g fill="none" fillRule="evenodd">
              <path
                d="M61.716 97.986c19.388-.43 56.35-25.753 58.1-53.79C121.566 16.162 82.779 0 53.902 0 25.025 0-8.4 8.075 1.912 48.79c3.859 15.239 15.04 26.458 23.113 33.86 13.192 12.098 24.663 15.603 36.69 15.336z"
                fill="#E5E7F1"
              ></path>
              <g transform="translate(22.36 8.167)">
                <path
                  d="M39.896 75.119c.492-.175.839-.246 1.13-.408 8.81-4.938 17.648-9.843 26.387-14.906.817-.47 1.441-1.943 1.45-2.958.111-9.29.068-18.58.055-27.87 0-.723-.136-1.447-.243-2.524L41.984 41.454c1.334 1.003 2.344 1.768 3.349 2.53l-.514.936a2443.51 2443.51 0 0 0-4.923-2.163v32.362zm-34.57-48.8c0 10.963-.043 21.3.081 31.639.013.749 1.078 1.722 1.9 2.184 7.99 4.56 16.031 9.028 24.06 13.52.911.508 1.835 1 2.874 1.557V42.848c-1.745.72-3.297 1.356-4.846 1.997-.145-.3-.295-.595-.445-.895 1.053-.81 2.105-1.618 3.294-2.537-8.948-5.017-17.653-9.897-26.918-15.094zm2.58-4.576c.594.441.86.678 1.163.853 8.811 4.95 17.649 9.86 26.413 14.89 1.476.848 2.477.449 3.713-.25 8.32-4.718 16.664-9.394 25.001-14.09.676-.38 1.318-.825 2.207-1.382-9.568-5.37-18.837-10.6-28.158-15.738-.612-.337-1.792-.262-2.43.091-9.234 5.11-18.426 10.297-27.91 15.626zm66.333 37.26c.035 1.996-.611 3.29-2.433 4.297-10.814 5.999-21.588 12.064-32.355 18.15-1.578.895-2.943.958-4.56.042-10.834-6.14-21.741-12.16-32.516-18.396-1.142-.662-2.229-2.455-2.246-3.74-.167-12.123-.068-24.25-.128-36.377-.009-1.935.795-3.1 2.468-4.031A5451.548 5451.548 0 0 0 34.819.792c1.587-.898 2.93-.96 4.551-.041a4593.293 4593.293 0 0 0 32.577 18.296c1.612.895 2.305 2.035 2.284 3.84-.069 6.107-.026 12.215-.026 18.326 0 5.928-.064 11.86.034 17.79z"
                  fill="#4229CC"
                ></path>
                <path
                  d="M23.175 47.047l4.299-2.583c-.009 2.334-1.078 3.124-3.773 3.34l-.526-.757zM5.991 56.473c1.454-.67 2.912-1.336 4.367-2.005.184.399.372.794.556 1.19-1.442.707-2.883 1.414-4.329 2.117l-.594-1.302m5.747-3.1c1.335-.778 2.673-1.556 4.213-2.45.116 2.37-1.39 3.403-3.884 3.157-.107-.237-.222-.47-.329-.707m10.522-3.914l-4.298 1.81a73.691 73.691 0 0 0-.355-.866c1.24-1.206 2.446-2.488 4.393-2.001.09.354.17.707.26 1.057m23.923-3.68c2.712-.686 4.034.167 4.53 2.746-1.81-1.098-3.17-1.922-4.53-2.745m15.873 8.578c-.487-.058-1.086.038-1.437-.208-.954-.661-1.8-1.464-2.69-2.217.243-.208.62-.65.714-.6 1.313.7 2.588 1.465 3.862 2.214l-.449.811zm-5.823-2.98l-4.534-2.18c1.835-1.269 2.956-.861 4.906 1.34-.124.283-.248.561-.372.84m11.42 6.482c-1.437-.74-2.87-1.48-4.307-2.226.133-.274.27-.553.402-.832 1.934-.403 3.161.82 4.385 2.064-.163.329-.321.661-.48.994"
                  fill="#54C9C1"
                  stroke="#54C9C1"
                  strokeWidth=".663"
                ></path>
              </g>
            </g>
          </svg>
          <div>
            Geometry
          </div>
        </div>
        <div className="gi-Grid-cell u-size1of3 item">
          <svg
            className="gi-subjectIcon"
            viewBox="0 0 120 98"
            width="120"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>
              Group 13
            </title>
            <g fill="none" fillRule="evenodd">
              <path
                d="M61.716 97.986c19.388-.43 56.35-25.753 58.1-53.79C121.566 16.162 82.779 0 53.902 0 25.025 0-8.4 8.075 1.912 48.79c3.859 15.239 15.04 26.458 23.113 33.86 13.192 12.098 24.663 15.603 36.69 15.336z"
                fill="#E5E7F1"
              ></path>
              <g transform="translate(23.106 11.879)">
                <path d="M57.007 57.238H69.92099999999999V70.201H57.007z" fill="#54C9C1"></path>
                <path
                  d="M3.91299502 70.5656756L17.0845976 58.3267521 19.44802 61.4624383 22.0794398 65.8748056 22.4331965 71.1328082z"
                  fill="#54C9C1"
                ></path>
                <path
                  d="M20.468 69.375c.044-2.983-2.285-8.02-3.77-9.115-3.088 3.097-6.136 6.15-9.095 9.115h12.865zm37.22-11.487v11.56c3.373 0 6.67.079 9.954-.08.537-.027 1.218-1.033 1.481-1.72.253-.672.055-1.514.055-2.283v-7.477h-11.49zM69.12 7.448L18.918 57.84c2.74 3.248 4.323 7.18 4.85 11.495h30.75V54.62H69.12V7.449zM.184 69.66c.968-.906 1.885-1.857 3.414-1.702.318.034.732-.395 1.023-.687 20.8-20.865 41.596-41.74 62.381-62.62.291-.293.719-.708.688-1.024-.157-1.538.794-2.455 1.693-3.426h2.586c1.967 1.696 2.422 3.512 1.047 4.875-.667.663-.777 1.318-.777 2.153.014 19.879.014 39.758 0 59.637 0 .879.069 1.596.814 2.283 1.043.958.907 2.853-.072 3.917-.978 1.065-2.958 1.435-3.878.45-.924-.989-1.926-1.016-3.075-.989-1.485.035-2.966.007-4.45.007-18.176 0-36.356 0-54.532.014-.493 0-1.102.017-1.46.288-2.334 1.765-3.476 1.669-5.402-.583V69.66z"
                  fill="#4229CC"
                ></path>
              </g>
            </g>
          </svg>
          <div>
            Trigonometry
          </div>
        </div>
        <div className="gi-Grid-cell u-size1of3 item">
          <svg
            className="gi-subjectIcon"
            viewBox="0 0 120 98"
            width="120"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>
              Group 14
            </title>
            <g fill="none" fillRule="evenodd">
              <path
                d="M61.716 97.986c19.388-.43 56.35-25.753 58.1-53.79C121.566 16.162 82.779 0 53.902 0 25.025 0-8.4 8.075 1.912 48.79c3.859 15.239 15.04 26.458 23.113 33.86 13.192 12.098 24.663 15.603 36.69 15.336z"
                fill="#E5E7F1"
              ></path>
              <g transform="translate(21.586 14.135)">
                <path
                  d="M15.305 55.667V.007h-3.266v55.66H.774v3.12h11.308v10.745h3.252V58.754h57.65v-3.087h-57.68zM51.48 36.279c-.835-.969-1.675-1.89-2.464-2.851-1.99-2.414-3.95-4.845-5.933-7.263-.251-.304-.324-.549-.17-.96.758-2.093.69-4.073-.763-5.908-.98-1.235-2.14-2.123-3.692-2.47-3.043-.677-5.336.493-6.862 2.542-1.97 2.65-1.364 6.011 1.321 8.275.345.292.405.497.218.896a61.795 61.795 0 0 0-1.369 3.074c-.78 1.891-1.56 3.782-2.28 5.703-.269.707-.559 1.17-1.407.994-.268-.056-.571.047-.857.086-3.678.48-6.065 4.133-5.123 7.709 1.138 4.317 6.637 6.246 10.46 3.018 3.073-2.594 2.72-7.516-.541-9.664.12-.231.247-.454.345-.69 1.283-3.057 2.553-6.114 3.849-9.162.094-.219.35-.506.546-.519 1.308-.073 2.557-.334 3.67-1.003.792.9 1.568 1.749 2.301 2.632a861.686 861.686 0 0 1 6.138 7.559c.141.171.175.544.094.759-.955 2.448-.648 4.63 1.142 6.624 2.673 2.975 7.741 2.95 10.316-.87 1.133-1.681 1.24-3.58.486-5.471-.188-.472-.107-.73.225-1.064 1.399-1.384 2.797-2.778 4.14-4.218.472-.51.873-.66 1.585-.52 3.47.708 5.933-.917 7.212-3.433 1.083-2.118.836-4.258-.699-6.277-2.289-3.006-7.22-3.717-10.37.026-.964 1.144-1.296 2.563-1.194 4.073.094 1.376.58 2.59 1.611 3.751l-4.79 4.82c-2.469-1.441-4.78-1.429-7.145-.198z"
                  fill="#4229CC"
                ></path>
                <path
                  d="M28.275 47.569c1.854-.034 3.133-1.333 3.112-3.156-.021-1.706-1.428-2.984-3.231-2.988-1.799-.004-3.197 1.792-3.124 3.139.072 1.397 1.35 3.112 3.243 3.005m29.8-6.02c-.005-1.775-1.36-3.052-3.223-3.035-1.577.013-3.171 1.243-3.171 3.044-.005 1.844 1.598 3.066 3.218 3.078 1.828.013 3.184-1.316 3.175-3.087m-21.04-21.758c-1.9-.026-3.269 1.243-3.294 3.052-.022 1.698 1.351 3.045 3.137 3.079 1.743.034 3.256-1.36 3.286-3.027.026-1.646-1.415-3.078-3.128-3.104M68.18 30.565c1.773.034 3.252-1.338 3.277-3.035.026-1.677-1.385-3.074-3.132-3.096-1.897-.026-3.27 1.252-3.287 3.053-.021 1.702 1.356 3.048 3.142 3.078"
                  fill="#54C9C1"
                ></path>
                <path
                  d="M15.3045568 55.6666354L72.9844169 55.6666354 72.9844169 58.7535723 15.3343941 58.7535723 15.3343941 69.5321272 12.082123 69.5321272 12.082123 58.7878716 0.773767301 58.7878716 0.773767301 55.6666354 12.0394982 55.6666354 12.0394982 0.00744724755 15.3045568 0.00744724755z"
                  fill="#4229CC"
                ></path>
              </g>
            </g>
          </svg>
          <div>
            Calculus
          </div>
        </div>
        <div className="gi-Grid-cell u-size1of3 item">
          <svg
            className="gi-subjectIcon"
            viewBox="0 0 120 99"
            width="120"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>
              Group 7
            </title>
            <g fill="none" fillRule="evenodd">
              <path
                d="M61.716 98.309c19.388-.432 56.35-25.838 58.1-53.967C121.566 16.214 82.779 0 53.902 0 25.025 0-8.4 8.102 1.912 48.95c3.859 15.29 15.04 26.545 23.113 33.973 13.192 12.137 24.663 15.653 36.69 15.386z"
                fill="#E5E7F1"
              ></path>
              <path
                d="M66.571 29.462V9.441l1.335-.471A3.589 3.589 0 0 0 66.712 2H52.543a3.587 3.587 0 0 0-1.194 6.97l1.334.47v20.022l-1.521.375c-15.214 3.75-26.056 17.187-26.056 32.732 0 18.632 15.446 33.754 34.521 33.754 19.076 0 34.522-15.122 34.522-33.754 0-15.545-10.842-28.983-26.056-32.732l-1.522-.375z"
                stroke="#4229CC"
                strokeWidth="4"
              ></path>
              <ellipse cx="57.019" cy="57.727" fill="#54C9C1" rx="2.609" ry="2.607"></ellipse>
              <ellipse cx="65.217" cy="45.809" fill="#54C9C1" rx="5.59" ry="5.587"></ellipse>
              <path
                d="M33.081 76.647s2.45 5.477 8.264 9.964c4.5 3.474 12.19 6.315 19.05 5.713 13.674-1.2 23.704-8.968 27.714-18.31 1.93-4.496 2.834-10.1 0-11.392-2.835-1.292-15.084-4.96-22.754 3.754-4.802 5.454-9.835 6.533-20.362 4.473-10.527-2.059-11.912 5.798-11.912 5.798z"
                fill="#54C9C1"
              ></path>
            </g>
          </svg>
          <div>
            Chemistry
          </div>
        </div>
        <div className="gi-Grid-cell u-size1of3 item">
          <svg
            className="gi-subjectIcon"
            viewBox="0 0 120 99"
            width="120"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>
              Group 16
            </title>
            <g fill="none" fillRule="evenodd">
              <path
                d="M61.716 98.309c19.388-.432 56.35-25.838 58.1-53.967C121.566 16.214 82.779 0 53.902 0 25.025 0-8.4 8.102 1.912 48.95c3.859 15.29 15.04 26.545 23.113 33.973 13.192 12.137 24.663 15.653 36.69 15.386z"
                fill="#E5E7F1"
              ></path>
              <g stroke="#4229CC" transform="translate(23.106 12.663)">
                <ellipse cx="36.83" cy="35.928" rx="11.489" ry="35.928" strokeWidth="3"></ellipse>
                <ellipse
                  cx="36.883"
                  cy="35.932"
                  rx="11.49"
                  ry="35.932"
                  strokeWidth="3"
                  transform="rotate(60 36.883 35.932)"
                ></ellipse>
                <ellipse
                  cx="36.883"
                  cy="35.932"
                  rx="11.49"
                  ry="35.932"
                  strokeWidth="3"
                  transform="scale(-1 1) rotate(60 0 -27.951)"
                ></ellipse>
                <ellipse
                  cx="59.266"
                  cy="15.135"
                  fill="#54C9C1"
                  rx="3.366"
                  ry="3.389"
                  strokeWidth="1.328"
                ></ellipse>
                <ellipse
                  cx="46.595"
                  cy="53.869"
                  fill="#54C9C1"
                  rx="3.366"
                  ry="3.389"
                  strokeWidth="1.328"
                ></ellipse>
                <ellipse
                  cx="9.328"
                  cy="41.951"
                  fill="#54C9C1"
                  rx="3.366"
                  ry="3.389"
                  strokeWidth="1.328"
                ></ellipse>
              </g>
            </g>
          </svg>
          <div>
            Physics
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Subjects;
