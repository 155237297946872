import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from 'components/Common/Modal';

export const SucceedModal = (props) => (
  <ModalBase
    headerText="Thank you"
    size="small"
    closable={false}
    body={(
      <p className="u-marginBottomNone">Thank you for your purchase</p>
    )}
    footerType="single"
    primaryButtonText="OK"
    onClickPrimaryButton={props.onHide}
  />
);

SucceedModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};


export const TransactionFailedModal = (props) => (
  <ModalBase
    headerText="Transaction failed"
    size="medium"
    closable={false}
    footerType='double'
    body={(
      <>
        <div>There was an error when processing your payment. Please try again or click <strong>"Need help"</strong> to send our expert team an email and we'll reach out to help.</div>
      </>
    )}
    primaryButtonText="Need help"
    onClickPrimaryButton={props.onNeedHelp}
    secondaryButtonText="Cancel"
    onClickSecondaryButton={props.onHide}
  />
);

TransactionFailedModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onNeedHelp: PropTypes.func.isRequired,
};


export const InvalidMethodFailedModal = (props) => (
  <ModalBase
    headerText="Invalid payment method"
    size="small"
    closable={false}
    body={(
      <p className="u-marginBottomNone">Please try again with different method</p>
    )}
    footerType="single"
    primaryButtonText="OK"
    onClickPrimaryButton={props.onHide}
  />
);

InvalidMethodFailedModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export const NotSupportedPaymentMethodModal = (props) => (
  <ModalBase
    headerText="Not supported payment method"
    size="small"
    closable={false}
    body={(
      <>
        <p className="u-marginBottomNone">Your mobile payment method is not available and you need to choose a new method.</p>
      </>
    )}
    footerType="single"
    primaryButtonText="OK"
    onClickPrimaryButton={props.onGotoBraintree}
  />
);

NotSupportedPaymentMethodModal.propTypes = {
  onGotoBraintree: PropTypes.func.isRequired,
};

export const UnknownErrorModal = (props) => (
  <ModalBase
    headerText="Error"
    size="small"
    closable={false}
    body={(
      <p className="u-marginBottomNone">
        An error has occurred during processing. Please try again.
      </p>
    )}
    footerType="single"
    primaryButtonText="OK"
    onClickPrimaryButton={props.onHide}
  />
);

UnknownErrorModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};
