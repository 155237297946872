import React from "react";
import { Tab as AhaTab, SafeAnchor } from "@ahaui/react";
import logoIcon from "assets/images/logo.svg";
import banner from "assets/images/banner.svg";
import testFlightIcon from "assets/images/testflight-icon.png";
import downloadIcon from "assets/images/download-icon.png";
import betaTestingIcon from "assets/images/beta-testing-icon.png";
import step1Android from "assets/images/beta-testing-invitation-android-img-1.png";
import step2Android from "assets/images/beta-testing-invitation-android-img-2.png";
import step3Android from "assets/images/beta-testing-invitation-android-img-3.png";
import step4Android from "assets/images/beta-testing-invitation-android-img-4.png";
import Video from "./Video";
import {
  BETA_TESTING_CATEGORY,
  BETA_TESTING_EVENT_LABEL,
} from "constants/betatesting.constant";

const Tab = {
  IOS: "ios",
  ANDROID: "android",
};
class BetaTestingInstruction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: this.isFromAndroidDevice() ? Tab.ANDROID : Tab.IOS,
    };
  }

  isFromAndroidDevice = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("d") === "android";
  };

  render() {
    const { currentTab } = this.state;
    return (
      <div
        id="beta-testing"
        className="App Container"
        style={{ paddingBottom: 110 }}
      >
        <div className="logo u-textLeft">
          <img
            className="u-marginTopMedium"
            src={logoIcon}
            alt="PhotoStudy logo"
            height="41"
            width="150"
          />
        </div>
        <div
          className="banner u-objectCover u-backgroundBlue50 u-lineHeightNone u-marginTopMedium u-positionRelative"
          style={{
            height: 150,
          }}
        >
          <img
            className="u-widthFull u-heightFull u-positionAbsolute u-positionBottom u-objectCover"
            src={banner}
            alt="PhotoStudy banner"
          />
        </div>
        <div className="Container">
          <div className="Grid">
            <h1 className="title u-marginBottomNone u-marginHorizontalAuto sm:u-marginTopExtraLarge u-marginTopLarge sm:u-text600 u-text500 u-fontMedium u-textCenter u-sizeFull sm:u-size8of12">
              We're going to guide you on how to install your brand new
              PhotoStudy app and access 10 FREE sessions.
            </h1>

            <div className="device-tab u-marginHorizontalAuto sm:u-marginTopExtraLarge u-marginTopLarge u-sizeFull sm:u-size10of12">
              <AhaTab
                current={currentTab}
                onSelect={(value) => this.setState({ currentTab: value })}
                visual="filled"
                fullWidth
              >
                <AhaTab.Item eventKey="ios">iOS</AhaTab.Item>
                <AhaTab.Item eventKey="android">Android</AhaTab.Item>
              </AhaTab>
              {currentTab === Tab.IOS && (
                <>
                  <div
                    className="ios-tab-content u-textLeft"
                    style={{
                      border: "1px solid #DFE1E6",
                    }}
                  >
                    <div className=" sm:u-paddingTopExtraLarge u-paddingTopLarge sm:u-paddingBottomNone Container">
                      <div className="Grid Grid--smallGutter sm:u-paddingHorizontalExtraLarge u-paddingHorizontalMedium">
                        <div className="step u-textCenter u-marginBottomMedium  sm:u-size1of3 u-sizeFull">
                          <div
                            className="u-backgroundOrange50 u-border u-roundedLarge  sm:u-paddingHorizontalSmall sm:u-paddingVerticalMedium u-paddingVerticalSmall u-paddingHorizontalSmall u-heightFull"
                            style={{
                              borderColor: "#FFB299",
                            }}
                          >
                            <div className="u-textCenter u-marginBottomMedium">
                              <img
                                src={testFlightIcon}
                                alt="step1ios"
                                className="u-heightExtraLarge u-widthExtraLarge"
                              />
                            </div>
                            <p className="u-text600 u-fontMedium u-marginNone u-lineHeightMedium">
                              Step 1:
                            </p>
                            <p className="u-text400 u-marginNone">
                              {`Install and open the `}
                              <SafeAnchor
                                href="https://apps.apple.com/vn/app/testflight/id899247664"
                                rel="noopener noreferrer"
                                onClick={() => {
                                  gtag("event", "Click", {
                                    event_category: BETA_TESTING_CATEGORY,
                                    event_label:
                                      BETA_TESTING_EVENT_LABEL.TEST_FLIGHT_LINK,
                                  });
                                }}
                              >
                                TestFlight
                              </SafeAnchor>
                              {` app on App Store.`}
                            </p>
                          </div>
                        </div>
                        <div className="step u-textCenter u-marginBottomMedium  sm:u-size1of3 u-sizeFull">
                          <div
                            className="u-backgroundLightGreen50 u-border u-roundedLarge  sm:u-paddingHorizontalSmall sm:u-paddingVerticalMedium u-paddingVerticalSmall u-paddingHorizontalSmall u-heightFull"
                            style={{
                              borderColor: "#A6E467",
                            }}
                          >
                            <div className="u-textCenter u-marginBottomMedium">
                              <img
                                src={downloadIcon}
                                alt="step2ios"
                                className="u-heightExtraLarge u-widthExtraLarge"
                              />
                            </div>
                            <p className="u-text600 u-fontMedium u-marginNone u-lineHeightMedium">
                              Step 2:
                            </p>
                            <p className="u-text400 u-marginNone">
                              {`Click on this `}
                              <SafeAnchor
                                href="https://testflight.apple.com/join/uvST9YAE"
                                rel="noopener noreferrer"
                                onClick={() => {
                                  gtag("event", "Click", {
                                    event_category: BETA_TESTING_CATEGORY,
                                    event_label:
                                      BETA_TESTING_EVENT_LABEL.IOS_INVITATION_LINK,
                                  });
                                }}
                              >
                                invitation link
                              </SafeAnchor>
                              {` to download the Beta app.`}
                            </p>
                          </div>
                        </div>
                        <div className="step u-textCenter u-marginBottomMedium  sm:u-size1of3 u-sizeFull">
                          <div
                            className="u-backgroundYellow50 u-border u-roundedLarge  sm:u-paddingHorizontalSmall sm:u-paddingVerticalMedium u-paddingVerticalSmall u-paddingHorizontalSmall u-heightFull u-paddingBottomMedium"
                            style={{
                              borderColor: "#FFE380",
                            }}
                          >
                            <div className="u-textCenter u-marginBottomMedium">
                              <img
                                src={betaTestingIcon}
                                alt="step3ios"
                                className="u-heightExtraLarge u-widthExtraLarge"
                              />
                            </div>
                            <p className="u-text600 u-fontMedium u-marginNone u-lineHeightMedium">
                              Step 3:
                            </p>
                            <p className="u-text400 u-marginNone">
                              Open the Beta app and click on Start Testing to
                              enjoy your 10 FREE sessions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Container">
                      <div className="Grid">
                        <div className=" u-marginHorizontalAuto u-sizeFull sm:u-size10of12">
                          <div className="step-note u-backgroundIndigo50 u-roundedExtraLarge sm:u-textNoWrap u-fontMedium u-fontItalic sm:u-paddingHorizontalLarge sm:u-paddingVerticalSmall u-paddingHorizontalSmall u-paddingVerticalSmall u-widthFull u-marginTopSmall">
                            * Don’t worry about overwriting the regular
                            PhotoStudy app. We will preserve all your data in
                            the PhotoStudy Beta app.
                          </div>
                        </div>
                      </div>
                    </div>
                    <Video
                      videoId="iP8uKhlX-Bo"
                      onView={() => {
                        gtag("event", "View", {
                          event_category: BETA_TESTING_CATEGORY,
                          event_label:
                            BETA_TESTING_EVENT_LABEL.BETA_TESTING_IOS_VIDEO,
                        });
                      }}
                    />
                  </div>
                </>
              )}

              {currentTab === Tab.ANDROID && (
                <>
                  <div className="android-tab-content u-border u-textLeft  u-paddingTopLarge u-paddingHorizontalNone  u-widthFull">
                    <div className="Container">
                      <div className="Grid step-row u-marginBottomMedium  sm:u-paddingHorizontalExtraLarge">
                        <div className="step-item u-flex u-flexColumn sm:u-size1of2 u-sizeFull sm:u-marginBottomNone u-marginBottomSmall">
                          <p className="sm:u-marginBottomNone">
                            <span className="u-fontBold">{`Step 1: `}</span>
                            <SafeAnchor
                              href="https://play.google.com/store/apps/details?id=co.gotitapp.android"
                              rel="noopener noreferrer"
                              onClick={() => {
                                gtag("event", "Click", {
                                  event_category: BETA_TESTING_CATEGORY,
                                  event_label:
                                    BETA_TESTING_EVENT_LABEL.ANDROID_INVITATION_LINK,
                                });
                              }}
                            >
                              Click here
                            </SafeAnchor>
                            {` to go to the PhotoStudy app on Google Play Store. `}
                          </p>
                          <div className="u-textCenter u-marginTopAuto sm:u-paddingLarge u-paddingHorizontalNone">
                            <img
                              src={step1Android}
                              alt="step1Android"
                              className="u-widthFull"
                            />
                          </div>
                        </div>
                        <div className="step-item u-flex u-flexColumn sm:u-size1of2 u-sizeFull">
                          <p className="sm:u-marginBottomNone">
                            <span className="u-fontBold">{`Step 2: `}</span>

                            {` Scroll down until you see "Join the beta" and click Join. `}
                          </p>
                          <div className="u-textCenter u-marginTopAuto sm:u-paddingLarge u-paddingHorizontalNone">
                            <img
                              src={step2Android}
                              alt="step2Android"
                              className="u-widthFull"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="Grid step-row u-marginBottomMedium  sm:u-paddingHorizontalExtraLarge">
                        <div className="step-item u-flex u-flexColumn sm:u-size1of2 u-sizeFull sm:u-marginBottomNone u-marginBottomSmall">
                          <p className="sm:u-marginBottomNone">
                            <span className="u-fontBold">{`Step 3: `}</span>
                            {`Wait for a few minutes so Google can add you to the beta program. It can take up to 10 minutes, but you can leave and come back.`}
                          </p>
                          <div className="u-textCenter u-marginTopAuto sm:u-paddingLarge u-paddingHorizontalNone">
                            <img
                              src={step3Android}
                              alt="step3Android"
                              className="u-widthFull"
                            />
                          </div>
                        </div>
                        <div className="step-item u-flex u-flexColumn sm:u-size1of2 u-sizeFull">
                          <p className="sm:u-marginBottomNone">
                            <span className="u-fontBold">{`Step 4 : `}</span>
                            {`Update the PhotoStudy app in the Play Store and enjoy your 10 FREE sessions.`}
                          </p>
                          <div className="u-textCenter u-marginTopAuto sm:u-paddingLarge u-paddingHorizontalNone">
                            <img
                              src={step4Android}
                              alt="step4Android"
                              className="u-widthFull"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="Grid">
                        <div className=" u-marginHorizontalAuto u-sizeFull sm:u-size10of12">
                          <div className="step-note u-backgroundIndigo50 u-roundedExtraLarge sm:u-textNoWrap u-fontMedium u-fontItalic sm:u-paddingHorizontalLarge sm:u-paddingVerticalSmall u-paddingHorizontalSmall u-paddingVerticalSmall u-widthFull u-marginTopSmall">
                            * Don’t worry about overwriting the regular
                            PhotoStudy app. We will preserve all your data in
                            the PhotoStudy Beta app.
                          </div>
                        </div>
                      </div>
                    </div>
                    <Video
                      videoId="tXVj1W2DqG0"
                      onView={() => {
                        gtag("event", "View", {
                          event_category: BETA_TESTING_CATEGORY,
                          event_label:
                            BETA_TESTING_EVENT_LABEL.BETA_TESTING_ANDROID_VIDEO,
                        });
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BetaTestingInstruction;
