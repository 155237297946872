import React from 'react';
import { withRouter } from 'react-router';
import { Icon } from '@ahaui/react';
import { navSend } from 'utils/app';

class FacebookAuth extends React.Component {
  goToAuthentication = () => {
    navSend('/', { from: 'shared_session_facebook_login', path: window.location.pathname });
    this.props.history.push('/');
  }

  render() {
    return (
      <button className="u-backgroundFacebook u-paddingExtraSmall u-roundedCircle u-borderNone u-cursorPointer hover:u-shadowLarge hover:u-scale110 u-transitionTransform u-easeInOut u-duration200 u-marginLeftMedium" onClick={this.goToAuthentication}>
        <span className="u-flex u-justifyContentCenter u-alignItemsCenter">
          <Icon name="facebook" size="medium" className="u-textWhite" />
        </span>
      </button>
    );
  }
}

export default withRouter(FacebookAuth);
