import { createStore, applyMiddleware, compose } from 'redux';
import promiseMiddleware from '../actions/middleware';
import loggerMiddleware from '../actions/loggerMiddleware';
import reducers from '../reducers';

function reduxStore(preloadedState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
      reducers,
      preloadedState,
      composeEnhancers(
        applyMiddleware(
          promiseMiddleware,
          loggerMiddleware
        )
      )
  );
  /* eslint-enable */

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      // We need to require for hot reloading to work properly.
      const nextReducer = require('../reducers');

      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

export default reduxStore;
