import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from '@ahaui/react';

import config from 'configuration';

import Tracking from '../../../../utils/tracking';

import { updateDescription } from '../../../../actions/askflow.action';


export class Description extends React.Component {
  constructor() {
    super();
    this.state = {
      focused: false,
    };
  }

  trackTextAdded = () => {
    Tracking.askFlow({
      action: Description.TRACK_ACTION,
    });
  }

  handleUpdate = (e) => {
    let { value } = e.target;
    if (value.trim().length > Description.MAX_LENGTH) {
      logger.debug('slicing description, original length', value.length);
      value = value.slice(0, Description.MAX_LENGTH);
    }
    this.props.descriptionUpdated(value);
  };

  // Handle show the required message when user focus to the box.
  handleFocus = () => {
    logger.debug('on handleClickFocus');
    this.setState({ focused: true });
  }

  // Handle track focus out (blur) event.
  handleBlur = () => {
    logger.debug('on handleBlur');
    this.trackTextAdded();
  }

  render() {
    const { errorMessage, description } = this.props;
    const { focused } = this.state;

    const descriptionLength = description.trim().length;
    const matchLengthRequiredWarning = focused && (descriptionLength < config.DESCRIPTION_REQUIRED_LENGTH);
    const isInvalid = errorMessage || matchLengthRequiredWarning;

    return (
      <Form.Group controlId="ask.description" onFocus={this.handleFocus} onBlur={this.handleBlur}>
        <Form.Label>Describe your problem</Form.Label>
        <Form.Input
          as="textarea"
          rows={3}
          isInvalid={isInvalid}
          onChange={this.handleUpdate}
          value={description}
        />
        <div className="u-flex u-text100">
          <div className="u-flexGrow-1 u-marginTopTiny">
            <span id="feedback-text" className={isInvalid ? 'u-textNegative' : 'u-textGray'}>
              {errorMessage
                ? errorMessage
                : `You need to write ${config.DESCRIPTION_REQUIRED_LENGTH} characters or more`}
            </span>
          </div>
          <div className="u-flexShrink-0 u-textGray u-marginTopTiny">
            <span>{descriptionLength}</span>
            <span>/{Description.MAX_LENGTH}</span>
          </div>
        </div>
      </Form.Group>
    );
  }
}

Description.MAX_LENGTH = 225;

Description.TRACK_ACTION = 'added_text';

Description.propTypes = {
  description: PropTypes.string.isRequired,
  descriptionUpdated: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

Description.defaultProps = {
  errorMessage: '',
}

const mapStateToProps = state => ({
  description: state.askflow.description,
});

const mapDispatchToProps = dispatch => ({
  descriptionUpdated: description => dispatch(updateDescription(description)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Description);
