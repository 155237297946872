import React, { useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { hasStemOnly, hasWritingOnly } from 'utils/feature-support';
import Tracking from 'utils/tracking';
import {
  ERROR_CODE_WRITING_INSUFFICIENT_BALANCE,
  ERROR_CODE_WRITING_SESSION_IN_PROGRESS,
} from 'constants/error.constant'

import { getAsker } from 'actions/english.action';
import InProgressWritingSessionBanner, { inProgressMessage } from './Common/PostView/Banners/InProgressWritingSessionBanner';
import InsufficientWritingBalanceBanner, { insufficientMessage } from './Common/PostView/Banners/InsufficientWritingBalanceBanner';

function AskSelection() {
  const history = useHistory();
  const dispatch = useDispatch();
  const enabledFeatures = useSelector(({ user }) => user.enabled_features);

  const [inProgressWritingSession, setInProgressWritingSession] = useState(false);
  const [insufficientWritingBalance, setInsufficientWritingBalance] = useState(false);

  // Check once after mount to redirect to the appropriate route
  useLayoutEffect(() => {
    if (hasStemOnly(enabledFeatures)) {
      history.push('/stem');
    } else if (hasWritingOnly(enabledFeatures)) {
      history.push('/english');
    }
  }, []); // eslint-disable-line react/exhaustive-deps

  const selectSubject = async (e, subject) => {
    e.preventDefault();

    if (subject === 'stem') {
      history.push('/stem');
      return;
    }

    // Hide previous banner
    setInProgressWritingSession(false);
    setInsufficientWritingBalance(false);

    Tracking.selectWritingHelp();
    const { payload: { result } } = await dispatch(getAsker());

    if (!result) {
      return;
    }

    if (result.question && result.question.qid > 0) {
      Tracking.selectWritingError({
        type: ERROR_CODE_WRITING_SESSION_IN_PROGRESS,
        message: inProgressMessage,
      });
      setInProgressWritingSession(true);
      return;
    }

    if (!result.writing_sessions) {
      Tracking.selectWritingError({
        type: ERROR_CODE_WRITING_INSUFFICIENT_BALANCE,
        message: insufficientMessage,
      });
      setInsufficientWritingBalance(true);
      return;
    }

    history.push('/english');
  };

  return (
    <>
      <h1 className="sr-only">Ask an Expert</h1>

      {inProgressWritingSession && <InProgressWritingSessionBanner />}
      {insufficientWritingBalance && <InsufficientWritingBalanceBanner />}

      <div className="Container Container--fluid">
        <div className="u-marginHorizontalAuto u-paddingVerticalMedium u-widthFull" style={{ maxWidth: 528 }}>
          <h2 className="u-text500 u-fontMedium u-textCenter u-marginTopSmall">Choose a subject</h2>

          <div className="Grid Grid--smallGutter u-marginTopLarge">
            <div className="u-size1of2">
              <button onClick={(e) => { selectSubject(e, 'english'); }} className="u-border u-roundedMedium u-paddingMedium u-flex u-flexColumn u-alignItemsCenter u-backgroundTransparent u-widthFull u-heightFull u-cursorPointer" data-testid="writing-button">
                <img src={require("assets/images/icon-writing.svg")} alt="" className="u-marginTopSmall u-marginBottomMedium" />
                <h1 className="u-text300 u-textPrimary u-textUppercase u-fontMedium u-marginBottomExtraSmall">Writing</h1>
                <p className="u-text200 u-textGray u-marginBottomNone">On-demand Writing support</p>
              </button>
            </div>
            <div className="u-size1of2">
              <button onClick={(e) => { selectSubject(e, 'stem'); }} className="u-border u-roundedMedium u-paddingMedium u-flex u-flexColumn u-alignItemsCenter u-backgroundTransparent u-widthFull u-heightFull u-cursorPointer" data-testid="stem-button">
                <img src={require("assets/images/icon-math-science.svg")} alt="" className="u-marginTopSmall u-marginBottomMedium" />
                <h1 className="u-text300 u-textPrimary u-textUppercase u-fontMedium u-marginBottomExtraSmall">Math &amp; Science</h1>
                <p className="u-text200 u-textGray u-marginBottomNone">On-demand support for Mathematics and Science topics</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AskSelection;
