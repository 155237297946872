import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PageLayout } from '@ahaui/react';

import pusher from '../../../utils/pusher';
import Tracking from '../../../utils/tracking';

import MainHeader from './MainHeader';
import MainNav from './MainNav';
import TriggerModal from '../TriggerModal';
import ScreenSupport from '../ScreenSupport';
import TOSModal from '../TOS';

import { sessionEndedType } from '../../../constants/common.constant';
import { SYSTEM_STATUS_UPGRADE } from '../../../constants/app.constant';

import { getSurgeInfo } from '../../../actions/user.action';
import { getNotis } from '../../../actions/notification.action';
import {
  getOpenQuestion, hideExpertWaiting, endQuestion, showRegularRouted, showRegularClaimed,
} from '../../../actions/chat.action';
import { getBraintreeToken } from '../../../actions/storefront.action';

import { navSend, navClear } from '../../../utils/app';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showScreenSupport: false,
    };
    this.sugreInfoTimer = 0;
    this.notiTimer = 0;
    pusher.connect();
    this.connected = false;
    const { email, missing_password } = props.user;
    if (missing_password) {
      navSend('/login', { email, from: 'missing_password' });
      props.history.push('/login');
    } else {
      navClear('/login');
    }
  }

  componentDidMount() {
    this.connect();
    this.props.onGetBraintreeToken();
    this.smallScreenDetect();
    window.addEventListener('resize', this.smallScreenDetect);
    // Move appOpened event to from App.js to here because want to keep the
    // mixpanel ID consistent for appOpen event when user redirected from SEO pages.
    Tracking.appOpened();
  }

  componentDidUpdate(prevProps) {
    if (this.props.systemStatus === SYSTEM_STATUS_UPGRADE) {
      this.stopConnecting();
    }
  }

  componentWillUnmount() {
    this.stopConnecting();
  }

  setUpInterval = () => {
    this.props.onGetSurgeInfo();
    this.props.onGetLatestNotis();
    this.sugreInfoTimer = window.setInterval(this.props.onGetSurgeInfo, 10 * 1000);
    this.notiTimer = window.setInterval(this.props.onGetLatestNotis, 10 * 1000);
  }

  setupPusher(uid) {
    logger.debug('Setup private connection for uid', uid);
    pusher.subscribe('private', uid);
    pusher.bind('private', 'questionRejected', this.handleQuestionRejected);
    pusher.bind('private', 'questionClaimed', this.handleQuestionClaimed);
    pusher.bind('private', 'questionAnswered', this.handleQuestionAnswered);
    // pusher.bind('private', 'sessionIdleTimeout', this.handleIdleTimeout);
    // pusher.bind('private', 'discussionSessionEnded', this.handleQuestionEnd);
    pusher.bind('private', 'questionDead', this.handleQuestionDead);
    pusher.bind('private', 'firstRegularRoute', this.handleRegularRoute);
    pusher.bind('private', 'firstRegularBid', this.handleRegularClaim);
  }

  connect = () => {
    this.setUpInterval();
    this.setupPusher(this.props.user.uid);
    this.connected = true;
  }

  stopConnecting = () => {
    if (this.connected) {
      this.teardownInterval();
      this.teardownPusher();
      this.connected = false;
    }
  }

  teardownInterval = () => {
    if (this.sugreInfoTimer) {
      window.clearInterval(this.sugreInfoTimer);
    }
    if (this.notiTimer) {
      window.clearInterval(this.notiTimer);
    }
  }

  teardownPusher = () => {
    pusher.unbind('private', 'questionRejected');
    pusher.unbind('private', 'questionClaimed');
    pusher.unbind('private', 'questionAnswered');
    // pusher.unbind('private', 'sessionIdleTimeout');
    // pusher.unbind('private', 'discussionSessionEnded');
    pusher.unbind('private', 'questionDead');
    pusher.unbind('private', 'firstRegularRoute');
    pusher.unbind('private', 'firstRegularBid');
    pusher.unsubscribe('private');
    pusher.disconnect();
  }

  smallScreenDetect = () => {
    const smallScreenWindowSize = document.body.clientWidth;
    if (smallScreenWindowSize < 992) {
      this.setState({
        showScreenSupport: true,
      });
    } else {
      this.setState({
        showScreenSupport: false,
      });
    }
  };

  handleQuestionRejected = (data) => {
    logger.debug('on handleQuestionRejected', data);
    this.props.onGetOpenQuestion(this.props.chat.qid);
    this.props.onHideExpertWaiting();
  }

  handleQuestionClaimed = (data) => {
    logger.debug('on handleQuestionClaimed', data);
    this.props.onGetOpenQuestion(this.props.chat.qid);
    this.props.onHideExpertWaiting();
  }

  handleQuestionAnswered = (data) => {
    logger.debug('on handleQuestionAnswered', data);
    this.props.onGetOpenQuestion(this.props.chat.qid);
    this.props.onHideExpertWaiting();
  }

  handleQuestionEnd = (data) => {
    logger.debug('on handleQuestionEnd', data);
    const { qid } = this.props.chat;
    this.props.onEndQuestion(qid).then((dataRes) => {
      const { result } = dataRes.payload;
      const { error } = dataRes.payload;
      if (result) {
        const dataTrack = {
          qid: Number(qid),
          session_length: Number(result.length),
          finishing_type: sessionEndedType.EXPIRED,
          first_bid: Number(result.first_bid),
          claim_time: Number(result.claim_time),
          number_of_messages: Number(result.message_count),
        };
        Tracking.sessionFinished(dataTrack);
      }
      if (error) {
        logger.error('Failed to call End Question endpoint to get tracking data');
      }
    });
  }

  handleIdleTimeout = (data) => {
    logger.debug('on handleIdleTimeout', data);
    const { qid } = this.props.chat;
    this.props.onEndQuestion(qid).then((dataRes) => {
      const { result } = dataRes.payload;
      const { error } = dataRes.payload;
      if (result) {
        const dataTrack = {
          qid: Number(qid),
          session_length: Number(result.length),
          finishing_type: sessionEndedType.IDLE_TIMEOUT,
          first_bid: Number(result.first_bid),
          claim_time: Number(result.claim_time),
          number_of_messages: Number(result.message_count),
        };
        Tracking.sessionFinished(dataTrack);
      }
      if (error) {
        logger.error('Failed to call End Question endpoint to get tracking data');
      }
    });
  }

  handleQuestionDead = (data) => {
    logger.debug('on handleQuestionDead', data);
    this.props.onGetOpenQuestion(this.props.chat.qid);
  }

  handleRegularRoute = (data) => {
    logger.debug('on handleRegularRoute', data);
    this.props.onShowRegularRouted(data);
  }

  handleRegularClaim = (data) => {
    logger.debug('on handleRegularClaim', data);
    this.props.onShowRegularClaimed(data);
  }

  handleFirst

  render () {
    return (
      <>
        {this.state.showScreenSupport && <ScreenSupport />}

        <PageLayout>
          <PageLayout.Header className="u-shadowSmall u-zIndex2">
            <MainHeader />
          </PageLayout.Header>

          <PageLayout.Body>
            <MainNav />

            <div className="u-flex u-flexColumn u-flexGrow-1 u-positionRelative">
              <div className="u-positionAbsolute u-positionFull u-overflowVerticalAuto u-webkitScrollbar">
                {this.props.children}
              </div>
            </div>

            <TriggerModal />
            <TOSModal />
          </PageLayout.Body>
        </PageLayout>
      </>
    );
  }
}

Home.propTypes = {
  user: PropTypes.shape().isRequired,
  chat: PropTypes.shape().isRequired,
  systemStatus: PropTypes.string.isRequired,
  onGetSurgeInfo: PropTypes.func.isRequired,
  onGetLatestNotis: PropTypes.func.isRequired,
  onGetOpenQuestion: PropTypes.func.isRequired,
  onGetBraintreeToken: PropTypes.func.isRequired,
  onHideExpertWaiting: PropTypes.func.isRequired,
  onEndQuestion: PropTypes.func.isRequired,
  onShowRegularClaimed: PropTypes.func.isRequired,
  onShowRegularRouted: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  chat: state.chat,
  systemStatus: state.app.systemStatus,
});

const mapDispatchToProps = dispatch => ({
  onGetSurgeInfo: () => dispatch(getSurgeInfo()),
  onGetLatestNotis: () => dispatch(getNotis()),
  onGetOpenQuestion: qid => dispatch(getOpenQuestion(qid)),
  onGetBraintreeToken: () => dispatch(getBraintreeToken()),
  onHideExpertWaiting: () => dispatch(hideExpertWaiting()),
  onEndQuestion: qid => dispatch(endQuestion(qid)),
  onShowRegularClaimed: data => dispatch(showRegularClaimed(data)),
  onShowRegularRouted: data => dispatch(showRegularRouted(data)),
});

const HomeWrapper = connect(mapStateToProps, mapDispatchToProps)(Home);

export default withRouter(HomeWrapper);
