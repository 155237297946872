import { PROMISE_RUN } from '../constants/promise.constant';
import {
  NOTI_LOAD,
  NOTI_READ,
  NOTI_ON_READ,
  NOTI_ON_READ_ALL,
  NOTI_BLOCK_UNREAD,
  NOTI_UNBLOCK_UNREAD
} from '../constants/notification.constant';

import defaultState from '../store/defaultState';

export default (state = defaultState.notification, action) => {
  if (action.type === PROMISE_RUN) {
    if (action.key === NOTI_LOAD) {
      let notifications = action.payload.result;

      if (!Array.isArray(notifications)) {
        return state;
      }
      let updatedState = {};
      if (notifications.length > 0) {
        let newUnreadNids = [];
        const newNids = notifications.map((n) => {
          if ((!+n.read) && !state.unreadNids.includes(n.nid)) {
            newUnreadNids.push(n.nid);
          }
          return n.nid;
        });

        const oldList = state.notifications.reduce((l, noti) => {
          if (!newNids.includes(noti.nid)) {
            l.push(noti);
          }
          return l;
        }, []);
        updatedState.notifications = [...notifications, ...oldList];
        updatedState.unreadNids = [...state.unreadNids, ...newUnreadNids];
      }

      return { ...state, ...updatedState };
    }
    if (action.key === NOTI_READ) {
      let rs = action.payload.result;
      if (rs && rs.status && !state.unreadBlocked) {
        const unreadNids = state.unreadNids.reduce((l, nid) => {
          if (!action.nids.includes(nid)) {
            l.push(nid);
          }
          return l;
        }, []);
        return { ...state, unreadNids };
      }
    }
  }
  if (action.type === NOTI_ON_READ) {
    if (!state.unreadBlocked) {
      let updatedState = {};
      const readNids = action.payload;
      updatedState.notifications = state.notifications.map((noti) => {
        if (readNids.includes(+noti.nid)) {
          noti.read = 1;
        }
        return noti;
      });
      updatedState.unreadNids = state.unreadNids.filter(nid => !readNids.includes(nid));
      return { ...state, ...updatedState };
    }
    return { ...state, unreadBlocked: true };
  }
  if (action.type === NOTI_ON_READ_ALL) {
    if (!state.unreadBlocked) {
      const notifications = state.notifications.map((noti) => {
        if (+noti.read !== 1) {
          noti.read = 1;
        }
        return noti;
      });
      return { ...state, notifications, unreadNids: [] };
    }
    return { ...state, unreadBlocked: true };
  }
  if (action.type === NOTI_BLOCK_UNREAD) {
    return { ...state, unreadBlocked: true };
  }
  if (action.type === NOTI_UNBLOCK_UNREAD) {
    return { ...state, unreadBlocked: false };
  }
  return state;
};
