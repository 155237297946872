import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from 'components/Common/Loading';
import PostView from './PostView';
import ChatView from './ChatView';

import {
  checkOpenQuestion,
} from '../../../actions/chat.action';


class Ask extends React.Component {
  componentDidMount() {
    this.props.checkOpenQuestion();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.key !== this.props.location.key) {
      logger.debug('location changes');
      this.props.checkOpenQuestion();
    }
  }

  render() {
    const { qid } = this.props.chat;
    if (qid === -1) return (<Loading />);
    if (qid === 0) return (<PostView />);
    return (<ChatView />);
  }
}

Ask.propTypes = {
  location: PropTypes.shape().isRequired,
  chat: PropTypes.shape().isRequired,
  checkOpenQuestion: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  chat: state.chat,
});

const mapPropsToDispatch = dispatch => ({
  checkOpenQuestion: () => dispatch(checkOpenQuestion()),
});

export default connect(mapStateToProps, mapPropsToDispatch)(Ask);
