import React from 'react';
import { withRouter } from 'react-router';
import { Button, Icon } from '@ahaui/react';

class PresetAuth extends React.Component {
  handleLogin = (e) => {
    e.preventDefault();

    const { history } = this.props;
    history.push('/login');
  }

  render() {
    return (
      <Button
        variant="white_outline"
        width="full"
        onClick={this.handleLogin}
      >
        <Button.Icon>
          <Icon name="contact" />
        </Button.Icon>
        <Button.Label>Preset account</Button.Label>
      </Button>
    );
  }
}

export default withRouter(PresetAuth);
