import React from 'react';
import PropTypes from 'prop-types';
import {
  BubbleChat,
  Avatar,
} from '@ahaui/react';
import moment from 'moment';
import gotitAvatar from 'assets/images/gotit.svg';
import expertAvatar from 'assets/images/avatar-default-expert-3.svg';

class ExpertMessageList extends React.Component {
  addAskerMessage = (messageEls, msg) => {
    if (msg.attachment) {
      const imageEl = (
        <BubbleChat
          key={`${msg.mid}-${msg.body.length}-1`}
          type="inbound"
          time={this.formatTime(msg.created)}
        >
          <BubbleChat.Image src={msg.attachment.url} />
        </BubbleChat>
      );
      messageEls.push(imageEl);

      const msgEl = (
        <BubbleChat
          key={`${msg.mid}-${msg.body.length}-2`}
          type="inbound"
          text={<div dangerouslySetInnerHTML={{ __html: msg.body }} />}
          time={this.formatTime(msg.created)}
          className="u-textWordBreak"
        />
      );
      messageEls.push(msgEl);

    } else {
      const msgEl = (
        <BubbleChat
          key={`${msg.mid}-${msg.body.length}`}
          type="inbound"
          text={<div dangerouslySetInnerHTML={{ __html: msg.body }} />}
          time={this.formatTime(msg.created)}
          className="u-textWordBreak"
        />
      );
      messageEls.push(msgEl);
    }
  }

  addExpertMessage = (messageEls, msg) => {
    if (msg.attachment) {
      const imageEl = (
        <BubbleChat
          key={`${msg.mid}-${msg.body.length}-1`}
          type="outbound"
          avatar={this.renderExpertAvatar}
          time={this.formatTime(msg.created)}
        >
          <BubbleChat.Image src={msg.attachment.url} />
        </BubbleChat>
      );
      messageEls.push(imageEl);

      const msgEl = (
        <BubbleChat
          key={`${msg.mid}-${msg.body.length}-2`}
          type="outbound"
          avatar={this.renderExpertAvatar}
          text={<div dangerouslySetInnerHTML={{ __html: msg.body }} />}
          time={this.formatTime(msg.created)}
          className="u-textWordBreak"
        />
      );
      messageEls.push(msgEl);

    } else {
      const msgEl = (
        <BubbleChat
          key={`${msg.mid}-${msg.body.length}`}
          type="outbound"
          avatar={this.renderExpertAvatar}
          text={<div dangerouslySetInnerHTML={{ __html: msg.body }} />}
          time={this.formatTime(msg.created)}
          className="u-textWordBreak"
        />
      );
      messageEls.push(msgEl);
    }
  }

  addSystemMessage = (messageEls, msg) => {
    const msgEl = (
      <BubbleChat
        key={`${msg.mid}-${msg.body.length}`}
        type="system"
        avatar={this.renderGotitAvatar}
        text={<div dangerouslySetInnerHTML={{ __html: msg.body }} />}
        time={this.formatTime(msg.created)}
        className="u-textWordBreak"
      />
    );
    messageEls.push(msgEl);
  }

  formatTime = (unixSeconds) => {
    return moment.unix(unixSeconds).format('HH:mm');
  }

  renderExpertAvatar = () => (
    <Avatar
      size="small"
      src={expertAvatar}
      alt="Expert avatar"
    />
  )

  renderGotitAvatar = () => (
    <Avatar
      size="small"
      src={gotitAvatar}
      alt="System avatar"
    />
  )

  render() {
    let question = this.props.question;
    let asker = question.author;
    let messages = this.props.messages;
    let messageEls = [];
    messages.forEach((msg) => {
      if (msg.sender.uid === 0) {
        this.addSystemMessage(messageEls, msg);
      } else if (msg.sender.uid === asker.uid) {
        this.addAskerMessage(messageEls, msg);
      } else {
        this.addExpertMessage(messageEls, msg);
      }
    });

    return messageEls;
  }

}

ExpertMessageList.propTypes = {
  question: PropTypes.shape().isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ExpertMessageList;
