import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadSharedSession } from 'actions/question.action';
import Loading from 'components/Common/Loading';

import ExpiredSession from './ExpiredSession';
import FullSession from './FullSession';
import PartialSession from './PartialSession';
import ErrorPage from './ErrorPage';
import TOSModal from './TOSModal';

export class SharedSession extends Component {
  PAGE_STATES = {
    READY: 'ready',
    LOADING: 'loading',
  };

  PAGE_VIEWS = {
    FULL_SESSION: 'full_session',
    PARTIAL_SESSION: 'partial_session',
    EXPIRED_SESSION: 'expired_session',
    ERROR: 'error',
  };

  ERRORS = {
    INVALID_SESSION: 1,
    EXPIRED_SESSION: 2,
  };

  constructor(props) {
    super(props);
    this.state = {
      pageState: this.PAGE_STATES.LOADING,
      view: '',
    };
    this.sessionKeyword = this.props.match.params.keyword;
    this.session = null;
  }

  componentDidMount() {
    this.getSession();
  }

  getSession = async () => {
    if (!this.sessionKeyword) {
      this.setState({ view: this.PAGE_VIEWS.ERROR });
      return;
    }

    const { payload: { result, error } } = await this.props.loadSharedSession(this.sessionKeyword);
    this.setState({ pageState: this.PAGE_STATES.READY });

    if (result) {
      this.session = result;
      if (this.props.isAuthenticated) {
        this.setState({ view: this.PAGE_VIEWS.FULL_SESSION });
      } else {
        this.setState({ view: this.PAGE_VIEWS.PARTIAL_SESSION });
      }

    } else if (error) {
      switch (error.data.data.code) {
        case this.ERRORS.EXPIRED_SESSION:
          this.setState({ view: this.PAGE_VIEWS.EXPIRED_SESSION });
          break;
        case this.ERRORS.INVALID_SESSION:
          this.setState({ view: this.PAGE_VIEWS.ERROR });
          break;
        default:
          break;
      }
    }
  }

  render() {
    if (this.state.pageState === this.PAGE_STATES.LOADING) return <Loading />;

    let view;
    switch (this.state.view) {
      case this.PAGE_VIEWS.EXPIRED_SESSION:
        view = <ExpiredSession />;
        break;
      case this.PAGE_VIEWS.FULL_SESSION:
        view = <FullSession session={this.session} onExpired={this.getSession}/>;
        break;
      case this.PAGE_VIEWS.PARTIAL_SESSION:
        view = <PartialSession session={this.session} />;
        break;
      case this.PAGE_VIEWS.ERROR:
        view = <ErrorPage />;
        break;
      default:
        view = '';
        break;
    }

    return (
      <>
        {view}
        <TOSModal />
      </>
    )
  }
}

const mapStateToProps = ({ app, user }) => ({
  isAuthenticated: !!(app.authToken && user.uid),
})

const mapDispatchToProps = {
  loadSharedSession,
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedSession)
