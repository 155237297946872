import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from 'components/Common/Modal';

export default class DeletedProfile extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired
  }

  handleClose = () => {
    this.props.onClose();
  }

  render () {
    return (
      <ModalBase
        headerText="Error"
        size="small"
        closable={false}
        body={(
          <>
            <p className="u-marginBottomNone">{this.props.message}</p>
          </>
        )}
        primaryButtonText="Close"
        onClickPrimaryButton={this.handleClose}
      />
    );
  }
}
