import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

export class ModalManage {
  static STACKING = false;
  constructor() {
    this.instances = {};
    this.stack = [];
  }

  // 8 character random string
  static generateId() {
    return Math.random().toString(36).substring(2, 6) +
           Math.random().toString(36).substring(6, 10);
  }
}

export const modal = (WrappedComponent, modalOptions = {}) => {
  const optionDefault = {
    size: '',
    allowDismiss: false
  };
  let options = { ...optionDefault, ...modalOptions };
  if (!options.modalRoot) {
    let modalRoot = document.getElementById('modal-root');
    if (modalRoot === null) {
      modalRoot = document.createElement("div");
      document.getElementsByTagName("body")[0].appendChild(modalRoot);
    }
    options.modalRoot = modalRoot;
  }
  return class Modal extends React.Component {
    static propTypes = {
      onClose: PropTypes.func
    };

    static defaultProps = {
      onClose: () => {}
    };

    constructor(props) {
      super(props);
      this.handleClose = this.handleClose.bind(this);
      this.el = document.createElement('div');
    }

    componentDidMount() {
      options.modalRoot.appendChild(this.el);
      if (options.allowDismiss) {
        document.addEventListener('mousedown', this.handleClickOutside);
      }
    }

    componentWillUnmount() {
      options.modalRoot.removeChild(this.el);
      if (options.allowDismiss) {
        document.removeEventListener('mousedown', this.handleClickOutside);
      }
    }

    handleClickOutside = (e) => {
      if (options.allowDismiss && this.me && !this.me.contains(e.target)) {
        this.props.onClose();
      }
    }

    handleClose(e) {
      if (e) {
        e.preventDefault();
      }
      this.props.onClose();
    }

    render() {
      let clsSize = `gi-Modal-content`;
      if (options.size) {
        clsSize = `${clsSize} is-${options.size}`;
      }
      return ReactDOM.createPortal(
        <div className="gi-Modal is-active">
          <div className="gi-Modal-background"></div>
          <div className="gi-Modal-inner">
            <div className={clsSize} ref={e => { this.me = e; }}>
              <WrappedComponent {...this.props} onClose={this.handleClose} />
            </div>
          </div>
        </div>,
        this.el
      );
    }
  };
};

export default {
  modal
};