import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Form, Icon } from '@ahaui/react';

import Tracking from '../../../../utils/tracking';

import { selectSubject } from '../../../../actions/askflow.action';

class Subjects extends React.Component {
  constructor() {
    super();
    this.state = {
      toggle: false,
      selected: false,
    };
  }

  trackAddedSubject = () => {
    Tracking.askFlow({
      action: Subjects.TRACK_ACTION,
    });
  }

  handleToggle = () => {
    logger.debug('on toggle subject list, state:', this.state.toggle);
    this.setState({
      toggle: !this.state.toggle,
    });
  }

  handleSubjectClicked = (subject) => {
    logger.debug('on handleSubjectClicked');
    this.props.onSelectingSubject(subject);
    this.setState({
      toggle: !this.state.toggle,
    });
    this.trackAddedSubject();
  }

  render() {
    const { selectedSubject, subjects } = this.props;

    let placeElement;
    if (!selectedSubject) {
      placeElement = (
        <div className="u-textDark u-flexGrow-1">
          Please select an option
        </div>
      );
    } else {
      placeElement = (
        <div className="u-textDark u-flexGrow-1 u-flex u-alignItemsCenter">
          <img src={selectedSubject.image} className="u-widthMedium u-heightMedium u-marginRightExtraSmall" />
          <span>{selectedSubject.title}</span>
        </div>
      );
    }

    return (
      <Form.Group controlId="ask.stem.subject">
        <Form.Label>Subject</Form.Label>
        <Dropdown
          onToggle={this.handleToggle}
          onKeyPress={this.handleToggle}
          show={this.state.toggle}
          drop="up"
          aria-label="Please select subject"
          tabIndex={0}
        >
          <Dropdown.Toggle className="u-textLight u-lineHeightNone">
            <div className="FormInput u-flex u-widthFull u-webkitScrollbar Dropdown-toggle u-cursorPointer u-textLight u-lineHeightNone">
              {placeElement}
              <div className="u-flexShrink-0">
                <Icon name="arrowDown" size="extraSmall" />
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Container className="u-paddingVerticalExtraSmall u-widthFull">
            <div className="u-overflowVerticalAuto u-webkitScrollbar" style={{ maxHeight: 200 }}>
              {subjects.map((subject) => (
                <div
                  key={subject.tid}
                  onClick={() => this.handleSubjectClicked(subject)}
                  onKeyPress={() => this.handleSubjectClicked(subject)}
                  className={`${subject.tid === selectedSubject?.tid ? 'u-fontBold' : ''} u-flex u-alignItemsCenter u-paddingHorizontalSmall u-paddingVerticalTiny hover:u-backgroundLightest u-cursorPointer`}
                  tabIndex={0}
                >
                  <img src={subject.image} alt="" className="u-widthMedium u-heightMedium u-marginRightExtraSmall" />
                  {subject.title}
                </div>
              ))}
            </div>
          </Dropdown.Container>
        </Dropdown>
      </Form.Group>
    );
  }
}

Subjects.TRACK_ACTION = 'added_subject';

Subjects.propTypes = {
  subjects: PropTypes.array.isRequired,
  selectedSubject: PropTypes.shape(),
  onSelectingSubject: PropTypes.func.isRequired,
};

Subjects.defaultProps = {
  selectedSubject: null,
};

const mapStateToProps = state => ({
  subjects: state.surgeInfo.subjects,
  selectedSubject: state.askflow.selectedSubject,
});

const mapDispatchToProps = dispatch => ({
  onSelectingSubject: subject => dispatch(selectSubject(subject)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subjects);
