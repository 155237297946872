import React from 'react';
import { Loader } from '@ahaui/react';
import ModalBase from 'components/Common/Modal';

const ProcessingModal = () => (
  <ModalBase
    headerText="Processing"
    size="small"
    closable={false}
    showFooter={false}
    body={(
      <>
        <div className="u-flex u-flexColumn u-alignItemsCenter">
          <div className="u-marginBottomExtraSmall">
            <Loader duration={500} size="large" />
          </div>
          <div>Please wait!</div>
        </div>
      </>
    )}
  />
);

export default ProcessingModal;
