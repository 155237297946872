import { PROMISE_RUN } from '../constants/promise.constant';
import { QUESTION_LOAD_SAMPLES } from '../constants/question.constant';

import defaultState from '../store/defaultState';

export default (state = defaultState.sampleQuestions, action) => {
  if (action.type === PROMISE_RUN) {
    if (action.key === QUESTION_LOAD_SAMPLES) {
      const questions = action.payload.result;
      if (Array.isArray(questions)) {
        return { ...state, questions };
      }
    }
  }
  return state;
};
