export const APP_CHECK_STATUS = 'app_check_status';
export const APP_READY = 'app_ready';
export const APP_MAINTENANCE = 'app_maintenance';
export const APP_FORCE_UPGRADE = 'app_force_upgrade';
export const APP_FORCE_LOGOUT = 'app_force_logout';

export const APP_LOADED = 'app_loaded';
export const APP_UNLOAD = 'app_unload';

export const APP_AUTH = 'app_auth';

export const SYSTEM_STATUS_READY = 'READY';
export const SYSTEM_STATUS_MAINTENANCE = 'MAINTENANCE';
export const SYSTEM_STATUS_UPGRADE = 'UPDATE';

export const PLATFORM_DESKTOP = 0;
export const PLATFORM_IOS = 0;
export const PLATFORM_ANDROID = 0;
export const PLATFORM_WINDOWS_PHONE = 0;

export const OS_MAC = 'MAC';
export const OS_WINDOWS = 'windows';
export const OS_LINUX = 'Linux';
export const OS_IOS = 'iOS';
export const OS_ANDROID = 'Android';
export const OS_OTHER = 'other';

export const AUTH_METHOD_FACEBOOK = 'facebook';
export const AUTH_METHOD_EMAIL = 'email';
export const AUTH_METHOD_PRESET = 'preset';
