import React from 'react';
import PropTypes from 'prop-types';

import Tracking from '../../../../utils/tracking';

export default class GeneralError extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired
  }

  static contextTypes = {
    openSupport: PropTypes.func
  }

  handleClose = () => {
    this.props.onClose();
  }
  handleSupport = () => {
    const subject = 'PhotoStudy Login/Signup Help';
    this.context.openSupport({ subject });
    Tracking.sendFeedback({ source: 'auth-error' });
    this.props.onClose();
  }
  render () {
    return (
      <div>
        <div className="gi-Modal-body u-textCenter">
          <p className="u-fontSizeLg">Error</p>
          <p>There was an error while logging into your account. Please contact Support for more help.</p>
        </div>
        <div className="gi-Modal-footer u-textCenter">
          <button className="gi-Button u-paddingLeftRight5" onClick={this.handleClose}>Close</button>
          <button className="gi-Button gi-Button--primary u-paddingLeftRight5" onClick={this.handleSupport}>Support</button>
        </div>
      </div>
    );
  }
}
