import React from 'react';
import ModalBase from 'components/Common/Modal';

const WaitingModal = () => (
  <ModalBase
    headerText="We are finding a match right now"
    size="medium"
    closable={false}
    body={(
      <>
        <p className="u-textCenter">
          <img src={require('assets/images/avatar-default-expert-3.svg')} className="u-widthHuge" alt="" />
        </p>
        <p className="u-textCenter u-marginBottomNone">Your chat session will not end before 10 minutes</p>
      </>
    )}
    showFooter={false}
  />
);

export default WaitingModal;
