import React from 'react';
import PropTypes from 'prop-types';
import { Media } from '@ahaui/react';
import ModalBase from 'components/Common/Modal';

const InfoModal = ({ onClose }) => (
  <ModalBase
    headerText="How to Ask an Expert?"
    size="large"
    closable={true}
    onHide={onClose}
    showFooter={false}
    body={(
      <Media as="iframe" aspectRatio="wide" src="https://www.youtube.com/embed/Ze13PrAsbwA" />
    )}
  />
);

InfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default InfoModal;
