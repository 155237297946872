import React from 'react';
import PropTypes from 'prop-types';
import { PageLayout } from '@ahaui/react';

import Header from '../Common/Header';
import Footer from './Footer';
import ExpertSession from '../Common/ExpertSession';
import PESSession from '../Common/PESSession';

class PartialSession extends React.Component {
  render () {
    let session = this.props.session;

    return (
      <PageLayout
        style={{
          minHeight: '100vh'
        }}
      >
        <PageLayout.Header className="u-shadowSmall u-zIndex2">
          <Header />
        </PageLayout.Header>

        <PageLayout.Body>
          <div className="Container u-paddingTopMedium" style={{ paddingBottom: 150 }}>
            {session.is_pes == 1 ? (
              <PESSession session={session} />
            ) : (
              <ExpertSession session={session} />
            )}
          </div>
          <Footer />
        </PageLayout.Body>
      </PageLayout>
    );
  }
}

PartialSession.propTypes = {
  session: PropTypes.object.isRequired,
}

export default PartialSession;
