import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon } from '@ahaui/react';

import CropperModal from './CropperModal';


function getDataTransferFiles(event) {
  let dataTransferItemsList = [];
  if (event.dataTransfer) {
    const dt = event.dataTransfer;
    if (dt.files && dt.files.length) {
      dataTransferItemsList = dt.files;
    } else if (dt.items && dt.items.length) {
      // During the drag even the dataTransfer.files is null
      // but Chrome implements some drag store, which is accessible via dataTransfer.items
      dataTransferItemsList = dt.items;
    }
  } else if (event.target && event.target.files) {
    dataTransferItemsList = event.target.files;
  }

  if (dataTransferItemsList.length > 0) {
    dataTransferItemsList = [dataTransferItemsList[0]];
  }

  return Array.prototype.slice.call(dataTransferItemsList);
}

export default class extends React.Component {
  static propTypes = {
    onChangeAvatar: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      selectedImage: '',
    };
    this.fileInputEl = null;
  }

  setImage = (imageUrl) => {
    if (this.state.selectedImage) {
      window.URL.revokeObjectURL(this.state.selectedImage);
    }
    this.setState({ selectedImage: imageUrl });
  }

  triggerFileBrowser = () => {
    this.fileInputEl.click();
  }

  /**
   * Handle update state when file loaded.
   */
  handleFileChange = (e) => {
    logger.debug('on handleFileChange');
    e.preventDefault();
    const fileList = getDataTransferFiles(e);
    this.setImage(window.URL.createObjectURL(fileList[0]));
    this.fileInputEl.value = null;
  }

  handleCrop = (dataCroppedUrl) => {
    this.props.onChangeAvatar(dataCroppedUrl);
    this.setState({ selectedImage: '' });
  }

  handleCancel = () => {
    this.setState({ selectedImage: '' });
  }

  render() {
    return (
      <>
        <button
          className={cn(
            'u-borderNone u-paddingNone', // reset button styling
            'u-positionAbsolute u-positionFull',
            'u-opacityNone hover:u-opacityReset u-transitionOpacity u-easeInOut u-duration100',
            'u-textWhite u-widthFull u-heightFull u-cursorPointer u-roundedCircle',
            'u-flex u-flexColumn u-justifyContentCenter u-alignItemsCenter',
          )}
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
          onClick={this.triggerFileBrowser}
        >
          <Icon name="images" size="large" />
          <div>Update</div>
        </button>

        <input
          ref={(el) => { this.fileInputEl = el; }}
          type="file"
          accept="image/*"
          hidden
          onChange={this.handleFileChange}
          aria-label="Upload new avatar"
        />

        {this.state.selectedImage && (
          <CropperModal
            onCrop={this.handleCrop}
            imageUrl={this.state.selectedImage}
            onClose={this.handleCancel}
          />
        )}
      </>
    );
  }
}
