import React from 'react';
import PropTypes from 'prop-types';
import MathJax from 'react-mathjax-preview';
import { Avatar, BubbleChat } from '@ahaui/react';
import botAvatar from 'assets/images/solver-bot-avatar.svg';

class PESMessageList extends React.Component {
  renderBotAvatar = () => (
    <Avatar
      size="small"
      src={botAvatar}
      alt="Solver bot avatar"
    />
  );

  render () {
    let steps = this.props.steps;
    let stepEls = [];
    steps.forEach((element, index) => {
      const indexEl = (
        <BubbleChat
          key={`step-${element}`}
          type="outbound"
          avatar={this.renderBotAvatar}
          text={`Step ${index + 1}`}
          className="u-textWordBreak"
        />
      )
      stepEls.push(indexEl);

      const msgEl = (
        <BubbleChat
          key={`message-${element}`}
          type="outbound"
          avatar={this.renderBotAvatar}
          text={(
            <MathJax math={`<math xmlns="http://www.w3.org/1998/Math/MathML">${element.replace(/\\(.)/mg, "$1")}</math>`}/>
          )}
          className="u-textWordBreak"
        />
      )
      stepEls.push(msgEl);
    });

    return stepEls;
  }
}

PESMessageList.propTypes = {
  steps: PropTypes.array.isRequired,
};

export default PESMessageList;
